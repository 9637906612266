import { takeLatest } from "redux-saga/effects";
import {
  fetchExercises,
  createExercise,
  updateExercise,
  deleteExercise,
} from "../../slices/exerciseSlice";
import {
  fetchExercisesSaga,
  createExerciseSaga,
  updateExerciseSaga,
  deleteExerciseSaga,
} from "./exerciseSaga";

function* exercisesSaga() {
  yield takeLatest(fetchExercises, fetchExercisesSaga);
  yield takeLatest(createExercise, createExerciseSaga);
  yield takeLatest(updateExercise, updateExerciseSaga);
  yield takeLatest(deleteExercise, deleteExerciseSaga);
}

export default exercisesSaga;
