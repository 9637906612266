import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import {
  PaymentsPackagesRequestResponseType,
  createPaymentsPackageRequest,
  deletePaymentsPackageRequest,
  fetchPaymentsPackagesRequest,
  setUserPaymentsPackageRequest,
  updatePaymentsPackageRequest,
} from "requests/paymentsPackagesRequests";
import {
  createPaymentsPackageSucceeded,
  deletePaymentsPackageSucceeded,
  fetchPaymentsPackagesSucceeded,
  setErrorMessage,
  setUserPaymentsPackageSucceeded,
  updatePaymentsPackageSucceeded,
} from "slices/paymentsPackagesSlice";
import {
  PaymentsPackage,
  CreatePaymentsPackage,
  SetPaymentsPackagePayload,
} from "types/paymentPackages";

export function* fetchPaymentsPackagesSaga(
  action: any,
  request = fetchPaymentsPackagesRequest
) {
  const response: PaymentsPackagesRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchPaymentsPackagesSucceeded(response.paymentsPackages));
  } else {
    setErrorMessage(response.errorMessage);
  }
}

export function* createPaymentsPackageSaga(
  action: PayloadAction<CreatePaymentsPackage>,
  request = createPaymentsPackageRequest
) {
  const response: PaymentsPackagesRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createPaymentsPackageSucceeded(response.paymentsPackage));
  } else {
    setErrorMessage(response.errorMessage);
  }
}

export function* updatePaymentsPackageSaga(
  action: PayloadAction<PaymentsPackage>,
  request = updatePaymentsPackageRequest
) {
  const response: PaymentsPackagesRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updatePaymentsPackageSucceeded(response.paymentsPackage));
  } else {
    setErrorMessage(response.errorMessage);
  }
}

export function* deletePaymentsPackageSaga(
  action: PayloadAction<string>,
  request = deletePaymentsPackageRequest
) {
  const response: PaymentsPackagesRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deletePaymentsPackageSucceeded(action.payload));
  } else {
    setErrorMessage(response.errorMessage);
  }
}

export function* setUserPaymentsPackageSaga(
  action: PayloadAction<SetPaymentsPackagePayload>,
  request = setUserPaymentsPackageRequest
) {
  const response: PaymentsPackagesRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(setUserPaymentsPackageSucceeded());
  } else {
    setErrorMessage(response.errorMessage);
  }
}
