import { axiosRequest, errorMessage } from "./request";
import {
  CreateOrUpdateEvaluationType,
  EvaluationsType,
  DeleteEvaluationType,
} from "../slices/evaluationsSlice";

export type EvaluationsRequestResponseType = {
  succeeded: boolean;
  evaluations: Array<EvaluationsType> | undefined;
  evaluation: EvaluationsType | undefined;
  errorMessage: string | undefined;
};

export const fetchEvaluationsRequest = async (
  userId: string
): Promise<EvaluationsRequestResponseType> => {
  try {
    const response = await axiosRequest().get(`users/${userId}/avaliations`);
    const evaluations: Array<EvaluationsType> = response.data.map(
      (evaluation: EvaluationsType) => evaluation
    );

    return {
      succeeded: true,
      evaluations: evaluations,
    } as EvaluationsRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as EvaluationsRequestResponseType;
  }
};

export const createEvaluationRequest = async (
  params: CreateOrUpdateEvaluationType
): Promise<EvaluationsRequestResponseType> => {
  try {
    const response = await axiosRequest().post(
      `users/${params.user_id}/avaliations`,
      {
        avaliation: params,
      }
    );
    const evaluation: EvaluationsType = response.data;

    return {
      succeeded: true,
      evaluation: evaluation,
    } as EvaluationsRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as EvaluationsRequestResponseType;
  }
};

export const updateEvaluationRequest = async (
  params: CreateOrUpdateEvaluationType
): Promise<EvaluationsRequestResponseType> => {
  try {
    const response = await axiosRequest().put(
      `users/${params.user_id}/avaliations/${params.id}`,
      {
        avaliation: params,
      }
    );
    const evaluation: EvaluationsType = response.data;

    return {
      succeeded: true,
      evaluation: evaluation,
    } as EvaluationsRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as EvaluationsRequestResponseType;
  }
};

export const deleteEvaluationRequest = async (
  params: DeleteEvaluationType
): Promise<EvaluationsRequestResponseType> => {
  try {
    await axiosRequest().delete(
      `users/${params.user_id}/avaliations/${params.id}`
    );

    return {
      succeeded: true,
    } as EvaluationsRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as EvaluationsRequestResponseType;
  }
};
