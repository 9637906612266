import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ChatGptChat, ChatGptMessage } from "../types/chatgpt";

type ChatgptState = {
  chats: Array<ChatGptChat>;
  currentChat: ChatGptChat;
  isLoading: boolean;
  error: string | undefined;
};

export type CreateChatgptMessageType = {
  chatgpt_chat_id: string | undefined;
  message: string;
};

const initialState: ChatgptState = {
  chats: [],
  currentChat: { id: undefined, title: "", messages: [] },
  isLoading: false,
  error: undefined,
};

const chatgptSlice = createSlice({
  name: "chatgpt",
  initialState: initialState,
  reducers: {
    createChatgptMessage(
      state: ChatgptState,
      action: PayloadAction<CreateChatgptMessageType>
    ) {
      state.isLoading = true;

      const newChatMessage = {
        message: action.payload.message,
        is_chatgpt_answer: false,
      };

      if (action.payload.chatgpt_chat_id !== undefined) {
        const index = state.chats.findIndex(
          (chat: ChatGptChat) => chat.id === action.payload.chatgpt_chat_id
        );

        state.chats[index].messages =
          state.chats[index].messages.concat(newChatMessage);
        state.currentChat!.messages =
          state.currentChat!.messages.concat(newChatMessage);
      } else {
        state.currentChat.messages = [newChatMessage];
        state.currentChat.title = action.payload.message;
      }
    },
    createChatgptMessageSucceeded(
      state: ChatgptState,
      action: PayloadAction<Array<ChatGptMessage>>
    ) {
      state.isLoading = false;

      const newChatMessages = action.payload.map((message: ChatGptMessage) => {
        return {
          message: message.message,
          is_chatgpt_answer: message.is_chatgpt_answer,
        };
      });

      if (state.currentChat.id === undefined) {
        state.currentChat.id = action.payload[0].chatgpt_chat_id;
        state.currentChat!.messages = newChatMessages;
        state.chats = state.chats.concat(state.currentChat);
      } else {
        const index = state.chats.findIndex(
          (chat: ChatGptChat) => chat.id === action.payload[0].chatgpt_chat_id
        );

        state.chats[index].messages =
          state.chats[index].messages.concat(newChatMessages);
        state.currentChat!.messages =
          state.currentChat!.messages.concat(newChatMessages);
      }
    },
    fetchChatgptChats(state: ChatgptState) {},
    fetchChatgptChatsSucceeded(
      state: ChatgptState,
      action: PayloadAction<Array<ChatGptChat>>
    ) {
      state.chats = action.payload;
    },
    fetchChatgptChat(state: ChatgptState, action: PayloadAction<string>) {},
    fetchChatgptChatSucceeded(
      state: ChatgptState,
      action: PayloadAction<ChatGptChat>
    ) {
      const index = state.chats.findIndex(
        (chat: ChatGptChat) => chat.id === action.payload.id
      );

      state.chats[index] = action.payload;
      state.currentChat = action.payload;
    },
    setCurrentChatgpt(state: ChatgptState, action: PayloadAction<ChatGptChat>) {
      state.currentChat = action.payload;
    },
  },
});

export const {
  createChatgptMessage,
  createChatgptMessageSucceeded,
  fetchChatgptChats,
  fetchChatgptChat,
  fetchChatgptChatSucceeded,
  fetchChatgptChatsSucceeded,
  setCurrentChatgpt,
} = chatgptSlice.actions;

export const selectChatgpts = (state: RootState) => state.chatgptSlice.chats;
export const selectCurrentChatgpt = (state: RootState) =>
  state.chatgptSlice.currentChat;
export const selectIsLoading = (state: RootState) =>
  state.chatgptSlice.isLoading;

export default chatgptSlice.reducer;
