import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { ProductsSection } from "components/ProductsPage/ProductsSection/ProductsSection";
import { TransactionsSection } from "components/ProductsPage/TransactionsSection/TransactionsSection";
import { DashboardTitle } from "components/DashboardTitle/DashboardTitle";
import { Tabs, TabsI } from "components/Tabs/Tabs";
import { fetchProducts, selectProducts } from "slices/productSlice";
import {
  fetchProductTransactions,
  selectTransactions,
} from "slices/productTransactionSlice";

import "components/ProductsPage/ProductsPage.scss";

export const ProductsPage = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const productTransactions = useSelector(selectTransactions);
  const [tabsData, setTabsData] = useState<TabsI>({
    tabs: ["Produtos", "Transações"],
    activeIndex: 0,
  });

  const onTabClick = (activeIdx: number) => {
    setTabsData({
      ...tabsData,
      activeIndex: activeIdx,
    });
  };

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchProductTransactions());
  }, [dispatch]);

  return (
    <Container fluid id="settings-page">
      <DashboardTitle
        title=""
        showAddButton={false}
        doNotShowBackButton
        countCards={[
          {
            title: "Produtos",
            count: products.length,
          },
          {
            title: "Transações",
            count: productTransactions.length,
          },
        ]}
      />
      <Tabs
        tabs={tabsData.tabs}
        activeIndex={tabsData.activeIndex}
        onTabClick={onTabClick}
      />
      <Container fluid id="personal-data-section">
        <Row className="account-section-row">
          <Col className="tabs-column">
            {tabsData.tabs[tabsData.activeIndex] === "Produtos" && (
              <ProductsSection />
            )}
            {tabsData.tabs[tabsData.activeIndex] === "Transações" && (
              <TransactionsSection />
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
