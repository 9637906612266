import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { differenceInDays, format } from "date-fns";
import { Outlet } from "react-router-dom";
import { AiFillMessage } from "react-icons/ai";
import { useSelector } from "react-redux";

import { selectChats } from "../../slices/chatSlice";
import { Chat } from "../../types/chat";
import { selectCurrentUser } from "slices/currentUserSlice";
import { AccountBlockedPage } from "components/AccountBlockedPage/AccountBlockedPage";
import { GButton } from "components/genericComponents/GButton/GButton";
import { Sidebar } from "../Sidebar/Sidebar";
import { ChatGptPage } from "../ChatGptPage/ChatGptPage";

import "./DashboardPage.css";
import { Link } from "react-router-dom";

export const DashboardPage = () => {
  const user = useSelector(selectCurrentUser);
  const chats = useSelector(selectChats);
  const [activePage, setActivePage] = useState("HomeDashboard");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showMessages, setShowMessages] = useState(false);
  const [isAccountBlocked, setIsAccountBlocked] = useState(false);
  const [isAccountAboutToExpire, setIsAccountAboutToExpire] = useState(false);
  const [isAccountExpired, setIsAccountExpired] = useState(false);
  const numberOfChatsWithMessagesToRead = chats.filter((chat: Chat) => {
    return chat.messages_to_read_count > 0;
  }).length;

  useEffect(() => {
    const chatDiv = document.getElementsByClassName("widget-visible")[0];
    chatDiv?.remove();
    const chatScript = document.querySelector(
      'script[data-name="tawk-script"]'
    );
    chatScript?.remove();
  }, []);

  useEffect(() => {
    if (user && user.company!.next_plan_payment_date) {
      const diffBetweenNowAndNextPaymentDate = differenceInDays(
        new Date(),
        new Date(user.company!.next_plan_payment_date)
      );
      setIsAccountBlocked(diffBetweenNowAndNextPaymentDate > 15);
      setIsAccountAboutToExpire(
        diffBetweenNowAndNextPaymentDate >= -7 &&
          diffBetweenNowAndNextPaymentDate <= 0
      );
      setIsAccountExpired(
        diffBetweenNowAndNextPaymentDate <= 14 &&
          diffBetweenNowAndNextPaymentDate > 0
      );
    }
  }, [user]);

  const handleCloseBanner = () => {
    setIsAccountAboutToExpire(false);
  };

  return (
    <div id="dashboard">
      {isAccountBlocked ? (
        <AccountBlockedPage />
      ) : (
        <>
          <div className="dashboard-row">
            <Col
              className={`dashboard-col-sidebar ${!isOpen ? "is-closed" : ""}`}
            >
              <Sidebar
                activePage={activePage}
                setActivePage={setActivePage}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </Col>
            <Col className="dashboard-col-pages">
              <Outlet />
            </Col>
          </div>
          {user && isAccountExpired && (
            <div className="dashboard-subscription-expired">
              <p className="dashboard-subscription-expired-text">
                A sua subscrição expirou a{" "}
                {format(
                  new Date(user.company!.next_plan_payment_date),
                  "dd/MM/yyyy"
                )}
                . Por favor, proceda ao pagamento do serviço para não perder o
                acesso.
              </p>
              <p className="dashboard-subscription-expired-text">
                Clique <Link to="settings/payments">aqui</Link> para proceder
                para a página de pagamento.
              </p>
            </div>
          )}
          {user && isAccountAboutToExpire && (
            <div className="dashboard-subscription-about-to-expire">
              <div>
                <p className="dashboard-subscription-about-to-expire-text">
                  A sua subscrição expira a{" "}
                  {format(
                    new Date(user.company!.next_plan_payment_date),
                    "dd/MM/yyyy"
                  )}
                  . Por favor, proceda ao pagamento do serviço para não perder o
                  acesso.
                </p>
                <p className="dashboard-subscription-about-to-expire-text">
                  Clique <Link to="settings/payments">aqui</Link> para proceder
                  para a página de pagamento.
                </p>
              </div>
              <GButton
                className="dashboard-subscription-about-to-expire-button"
                variant="transparent"
                onClick={handleCloseBanner}
              >
                <FaTimes />
              </GButton>
            </div>
          )}
          {window.innerWidth > 480 && ( // Can't mount this component on mobile, otherwise multiple websocket connections
            <>
              <div
                className="dashboard-show-messages-div"
                onClick={() => setShowMessages(!showMessages)}
              >
                <AiFillMessage className="dashboard-show-messages-button" />
                {numberOfChatsWithMessagesToRead > 0 && (
                  <p className="dashboard-show-messages-count">
                    {numberOfChatsWithMessagesToRead}
                  </p>
                )}
              </div>
              <div
                className={
                  showMessages
                    ? "dashboard-messages-window"
                    : "dashboard-messages-window-hidden"
                }
              >
                <div id="chatgpt-super-container">
                  <ChatGptPage />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
