import { Carousel, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import theSpot from "../../../assets/theSpot.png";
import vicente from "../../../assets/vicente.jpeg";
import pedroCunha from "../../../assets/pedroCunha.jpeg";
import "./GoActiveClients.scss";

export const GoActiveClients = () => {
  const { t } = useTranslation();

  return (
    <div id="goactive-clients">
      <Container className="gc-container">
        <Row>
          <Col className="gc-title-col">
            <h1 className="gc-title">{t("landingPage.goactiveClients.title")}</h1>
          </Col>
        </Row>
        <Row>
          <Col className="gc-col">
            <p>{t("landingPage.goactiveClients.description")}</p>
          </Col>
        </Row>
        <Row className="gc-clients-row-desktop">
          <Col className="gc-clients-col">
            <img src={theSpot} className="gc-clients-img" alt="" />
            <img src={vicente} className="gc-clients-img" alt="" />
            <img src={pedroCunha} className="gc-clients-img" alt="" />
          </Col>
        </Row>
        <Row className="gc-clients-row-mobile">
          <Col>
            <Carousel interval={2000} indicators={false}>
              <Carousel.Item className="gc-clients-carousel-item">
                <img src={theSpot} className="gc-clients-img" alt="" />
              </Carousel.Item>
              <Carousel.Item className="gc-clients-carousel-item">
                <img src={vicente} className="gc-clients-img" alt="" />
              </Carousel.Item>
              <Carousel.Item className="gc-clients-carousel-item">
                <img src={pedroCunha} className="gc-clients-img" alt="" />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="gc-clients-feedback-col">
            <div className="gc-clients-feedback-div">
              <div className="gc-clients-feedback-title">
                <img src={vicente} alt="Vicente" />
                <div className="title">
                  <p className="name">João Vicente</p>
                  <p className="occupation">Personal Trainer</p>
                </div>
              </div>
              <p className="description">
                "Fácil de usar e consigo ter o trabalho todo numa só aplicação, gerir planos, falar com os meus clientes
                e controlar os seus feedbacks e pagamentos. Poupo tempo e estou mais próximo dos meus atletas."
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
