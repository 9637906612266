import { call, put } from "redux-saga/effects";
import {
  NutritionRequestResponseType,
  createRecipeRequest,
  createUserNutritionSchedulerItemRequest,
  createUserNutritionSchedulerNoteRequest,
  deleteRecipeRequest,
  deleteUserNutritionSchedulerItemRequest,
  deleteUserNutritionSchedulerNoteRequest,
  fetchRecipeRequest,
  fetchRecipesRequest,
  fetchUserNutritionSchedulerRequest,
  searchFoodRequest,
  searchRecipesRequest,
  updateRecipeRequest,
  updateUserNutritionSchedulerItemRequest,
  updateUserNutritionSchedulerNoteRequest,
} from "requests/nutritionRequests";
import {
  createRecipeSucceeded,
  deleteRecipeSucceeded,
  fetchRecipeSucceeded,
  fetchRecipesSucceeded,
  setErrorMessage,
  updateRecipeSucceeded,
  fetchUserNutritionSchedulerSucceeded,
} from "slices/nutritionSlice";
import {
  createUserNutritionSchedulerItemSucceeded,
  createUserNutritionSchedulerNoteSucceeded,
  deleteUserNutritionSchedulerItemSucceeded,
  deleteUserNutritionSchedulerNoteSucceeded,
  searchFoodSucceeded,
  searchRecipesSucceeded,
  setErrorMessage as setErrorMessageScheduler,
  updateUserNutritionSchedulerItemSucceeded,
  updateUserNutritionSchedulerNoteSucceeded,
} from "slices/nutritionSchedulerSlice";

export function* fetchRecipesSaga(action: any, request = fetchRecipesRequest) {
  const response: NutritionRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchRecipesSucceeded(response.recipes));
  } else {
    yield put(setErrorMessage(response.errorMessage));
  }
}

export function* fetchRecipeSaga(action: any, request = fetchRecipeRequest) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchRecipeSucceeded(response.recipe));
  } else {
    yield put(setErrorMessage(response.errorMessage));
  }
}

export function* createRecipeSaga(action: any, request = createRecipeRequest) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createRecipeSucceeded(response.recipe));
  } else {
    yield put(setErrorMessage(response.errorMessage));
  }
}

export function* updateRecipeSaga(action: any, request = updateRecipeRequest) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload.id,
    action.payload.payload
  );

  if (response.succeeded) {
    yield put(updateRecipeSucceeded(response.recipe));
  } else {
    yield put(setErrorMessage(response.errorMessage));
  }
}

export function* deleteRecipeSaga(action: any, request = deleteRecipeRequest) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteRecipeSucceeded(action.payload));
  } else {
    yield put(setErrorMessage(response.errorMessage));
  }
}

export function* fetchUserNutritionSchedulerSaga(
  action: any,
  request = fetchUserNutritionSchedulerRequest
) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(
      fetchUserNutritionSchedulerSucceeded(response.userNutritionScheduler)
    );
  } else {
    yield put(setErrorMessage(response.errorMessage));
  }
}

export function* createUserNutritionSchedulerNoteSaga(
  action: any,
  request = createUserNutritionSchedulerNoteRequest
) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(
      createUserNutritionSchedulerNoteSucceeded(
        response.createUserNutritionSchedulerNote
      )
    );
  } else {
    yield put(setErrorMessageScheduler(response.errorMessage));
  }
}

export function* updateUserNutritionSchedulerNoteSaga(
  action: any,
  request = updateUserNutritionSchedulerNoteRequest
) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload.noteId,
    action.payload.note
  );

  if (response.succeeded) {
    yield put(
      updateUserNutritionSchedulerNoteSucceeded(
        response.createUserNutritionSchedulerNote
      )
    );
  } else {
    yield put(setErrorMessageScheduler(response.errorMessage));
  }
}

export function* deleteUserNutritionSchedulerNoteSaga(
  action: any,
  request = deleteUserNutritionSchedulerNoteRequest
) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteUserNutritionSchedulerNoteSucceeded(action.payload));
  } else {
    yield put(setErrorMessageScheduler(response.errorMessage));
  }
}

export function* createUserNutritionSchedulerItemSaga(
  action: any,
  request = createUserNutritionSchedulerItemRequest
) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(
      createUserNutritionSchedulerItemSucceeded(
        response.createUserNutritionSchedulerItem
      )
    );
  } else {
    yield put(setErrorMessageScheduler(response.errorMessage));
  }
}

export function* updateUserNutritionSchedulerItemSaga(
  action: any,
  request = updateUserNutritionSchedulerItemRequest
) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload.noteId,
    action.payload.note
  );

  if (response.succeeded) {
    yield put(
      updateUserNutritionSchedulerItemSucceeded(
        response.createUserNutritionSchedulerItem
      )
    );
  } else {
    yield put(setErrorMessageScheduler(response.errorMessage));
  }
}

export function* deleteUserNutritionSchedulerItemSaga(
  action: any,
  request = deleteUserNutritionSchedulerItemRequest
) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteUserNutritionSchedulerItemSucceeded(action.payload));
  } else {
    yield put(setErrorMessageScheduler(response.errorMessage));
  }
}

export function* searchFoodSaga(action: any, request = searchFoodRequest) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(searchFoodSucceeded(response.foods));
  } else {
    yield put(setErrorMessageScheduler(response.errorMessage));
  }
}

export function* searchRecipesSaga(
  action: any,
  request = searchRecipesRequest
) {
  const response: NutritionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(searchRecipesSucceeded(response.searchRecipes));
  } else {
    yield put(setErrorMessageScheduler(response.errorMessage));
  }
}
