import { takeLatest } from "redux-saga/effects";
import {
  fetchProductTransactions,
  createProductTransaction,
  updateProductTransaction,
  deleteProductTransaction,
} from "../../slices/productTransactionSlice";
import {
  fetchProductTransactionsSaga,
  createProductTransactionSaga,
  updateProductTransactionSaga,
  deleteProductTransactionSaga,
} from "./productTransactionSaga";

function* productSaga() {
  yield takeLatest(fetchProductTransactions, fetchProductTransactionsSaga);
  yield takeLatest(createProductTransaction, createProductTransactionSaga);
  yield takeLatest(updateProductTransaction, updateProductTransactionSaga);
  yield takeLatest(deleteProductTransaction, deleteProductTransactionSaga);
}

export default productSaga;
