import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchCurrentUser,
  selectCurrentUser,
  updateCompany,
} from "../../../../slices/currentUserSlice";
import {
  UpdateUserType,
  selectConfirmationMessage,
  selectErrorMessage,
  setConfirmationMessage,
  setErrorMessage,
  updateUser,
} from "../../../../slices/userSlice";
import { UserImageUpload } from "../../../UserImageUpload/UserImageUpload";

import "./ProfileSettings.css";
import { GInput } from "components/genericComponents/GInput/GInput";

export const ProfileSettings = () => {
  ReactGA.send("/settings");

  const [cookies] = useCookies(["current_user"]);
  const currentUserCookies = cookies.current_user;
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isOwner = (!currentUser?.company?.is_gym && localStorage.getItem("isGym") === "false") || (currentUser?.company?.is_gym && localStorage.getItem("isGym") === "true");
  const [name, setName] = useState<string | null>(
    currentUserCookies?.name ?? null
  );
  const [file, setFile] = useState<File | null>(null);
  const [imageKey, setImageKey] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | null>(
    currentUserCookies?.email ?? null
  );
  const [nif, setNif] = useState<string | null>(
    currentUserCookies?.nif ?? null
  );
  const [fiscalEmail, setFiscalEmail] = useState<string | null>(
    currentUserCookies?.fiscal_email ?? null
  );
  const [fiscalName, setFiscalName] = useState<string | null>(
    currentUserCookies?.fiscal_name ?? null
  );
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  useEffect(() => {
    if (currentUser === undefined && currentUserCookies?.id !== undefined) {
      dispatch(fetchCurrentUser(currentUserCookies?.id));
    }
    if (currentUser !== undefined) {
      setName(currentUser?.name);
      setEmail(currentUser?.email);
    }
  }, [currentUser, dispatch, currentUserCookies]);

  const saveUserChanges = () => {
    var isValid = true;

    if (!isValid) return;

    const image =
      file === null
        ? null
        : {
            id: imageKey!,
            storage: "cache",
            metadata: {
              size: file.size,
              mime_type: file.type,
            },
          };

    const user: UpdateUserType = {
      ...currentUser!,
      name: name,
      email: email,
      image: image,
      new_password: undefined,
      current_password: undefined,
    };

    dispatch(updateUser(user));

    if (isOwner) {
      const company = {
        id: currentUser!.company!.id!,
        image: image,
        name: name ?? '',
        nif: Number(nif),
        fiscal_email: fiscalEmail ?? '',
        fiscal_name: fiscalName ?? '',
      };
  
      dispatch(updateCompany(company)); 
    }
  };

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "profile-settings-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "profile-settings-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  return (
    <Container fluid id="profile-settings">
      <Row>
        <Col>
          <h4>Perfil</h4>
        </Col>
      </Row>
      <Container className="profile-fields-container">
        <Row>
          <Col className="profile-img-col">
            <UserImageUpload
              image={currentUser?.image_medium}
              file={file}
              setFile={setFile}
              setImageKey={setImageKey}
              showEditButton={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="company-settings-info-label">Nome</p>
            <GInput
              className="company-settings-info-input"
              value={name}
              onChange={(e: any) =>
                setName(e.target.value)
              }
            />
            <p className="company-settings-info-label">Email</p>
            <GInput
              className="company-settings-info-input"
              value={email}
              onChange={(e: any) =>
                setEmail(e.target.value)
              }
            />
            {isOwner && (
              <>
                <p className="company-settings-info-label">Email Fiscal</p>
                <GInput
                  className="company-settings-info-input"
                  value={fiscalEmail}
                  onChange={(e: any) =>
                    setFiscalEmail(e.target.value)
                  }
                />
                <p className="company-settings-info-label">Nome fiscal</p>
                <GInput
                  className="company-settings-info-input"
                  value={fiscalName}
                  onChange={(e: any) =>
                    setFiscalName(e.target.value)
                  }
                />
                <p className="company-settings-info-label">NIF</p>
                <GInput
                  className="company-settings-info-input"
                  value={nif}
                  onChange={(e: any) =>
                    setNif(e.target.value)
                  }
                />
              </>
            )}
          </Col>
        </Row>
        <Row className="profile-settings-justify-content-center">
          <Button
            className="submit-button profile-settings-margin-top"
            onClick={saveUserChanges}
          >
            Guardar
          </Button>
        </Row>
      </Container>
    </Container>
  );
};
