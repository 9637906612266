import React, { ElementType } from "react";
import { FloatingLabel, Form } from "react-bootstrap";

type InputFormProps = {
  label?: string;
  value: any;
  type?: string;
  onChange?: any;
  disabled?: boolean;
  errorMessage?: string | null | undefined;
  className?: string;
  as?: ElementType<any>;
  min?: number;
  max?: number;
};

export const InputForm = ({
  value,
  label,
  type = "text",
  onChange = () => {},
  disabled = false,
  errorMessage = null,
  className = "mb-3 profile-input",
  as,
  min,
  max,
}: InputFormProps) => {
  return (
    <>
      {label ? (
        <FloatingLabel className={className} label={label}>
          <Form.Control
            value={value ?? undefined}
            type={type}
            onChange={onChange}
            placeholder={label}
            disabled={disabled}
            as={as}
            min={type === "number" ? min : undefined}
            max={type === "number" ? max : undefined}
            onFocus={(e) => {
              if (type === "number")
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                );
            }}
          />
        </FloatingLabel>
      ) : (
        <Form.Control
          value={value ?? undefined}
          type={type}
          onChange={onChange}
          placeholder={label}
          disabled={disabled}
          as={as}
          min={type === "number" ? min : undefined}
          max={type === "number" ? max : undefined}
          onFocus={(e) => {
            if (type === "number")
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              );
          }}
        />
      )}
      <div className={className}>
        {errorMessage !== null && errorMessage !== undefined ? (
          <p className="form-error-message">{errorMessage}</p>
        ) : undefined}
      </div>
    </>
  );
};
