import { takeLatest } from "redux-saga/effects";
import {
  fetchNotifications,
  deleteNotification,
} from "../../slices/notificationSlice";
import {
  fetchNotificationsSaga,
  deleteNotificationSaga,
} from "./notificationSaga";

function* notificationsSaga() {
  yield takeLatest(fetchNotifications, fetchNotificationsSaga);
  yield takeLatest(deleteNotification, deleteNotificationSaga);
}

export default notificationsSaga;
