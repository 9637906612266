import { Col, Container, Form, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { selectGymRooms } from "../../../slices/gymRoomSlice";
import {
  selectPersonalTrainers,
  fetchPersonalTrainers,
} from "../../../slices/personalTrainerSlice";
import { selectCourses, fetchCourses } from "../../../slices/courseSlice";
import { selectIsLoading } from "../../../slices/courseScheduleSlice";
import { FilterDropdown } from "../../FilterDropdown/FilterDropdown";
import { SpinnerWrapper } from "../../SpinnerWrapper/SpinnerWrapper";
import { useState, useEffect } from "react";

import "./CalendarFilters.css";

type CalendarFiltersI = {
  onMyAppointementsChange: (showAppointement: boolean) => void;
  onGymAppointementsChange: (showAppointement: boolean) => void;
  onPersonalTrainerSelect: (personalTrainerId: string | undefined) => void;
  onRoomSelect: (roomId: string | undefined) => void;
  onCourseSelect: (courseId: string | undefined) => void;
};

export const CalendarFilters = ({
  onMyAppointementsChange,
  onGymAppointementsChange,
  onRoomSelect,
  onPersonalTrainerSelect,
  onCourseSelect,
}: CalendarFiltersI) => {
  const isGymView = localStorage.getItem("isGymView") === "true";
  const gymRooms = useSelector(selectGymRooms);
  const [showMyAppointments, setShowMyAppointments] = useState<boolean>(
    isGymView ? false : true
  );
  const [showGymAppointments, setShowGymAppointments] = useState<boolean>(
    isGymView ? true : false
  );
  const personalTrainers = useSelector(selectPersonalTrainers);
  const courses = useSelector(selectCourses);
  const dispatch = useDispatch();
  const isLoadingSchedules = useSelector(selectIsLoading);

  useEffect(() => {
    dispatch(fetchPersonalTrainers());
    dispatch(fetchCourses());
  }, [dispatch]);

  return (
    <Container fluid>
      <Row className="calendar-filters-row">
        <Col className="calendar-filters-col">
          {!isGymView && (
            <div className="calendar-filters-div">
              <Form.Check // prettier-ignore
                checked={showMyAppointments}
                type="switch"
                label="Mostrar as minhas marcações"
                className="purple-switch"
                onChange={() => {
                  onMyAppointementsChange(!showMyAppointments);
                  setShowMyAppointments(!showMyAppointments);
                }}
              />
              <Form.Check // prettier-ignore
                checked={showGymAppointments}
                type="switch"
                label="Mostrar as marcações do ginásio"
                className="purple-switch"
                onChange={() => {
                  onGymAppointementsChange(!showGymAppointments);
                  setShowGymAppointments(!showGymAppointments);
                }}
              />
            </div>
          )}
          <FilterDropdown
            title={"Divisão do Ginásio"}
            values={gymRooms}
            selectedValue={undefined}
            disabled={!showGymAppointments}
            onChangeValue={(gymRoomId: string) => {
              onRoomSelect(gymRoomId);
            }}
          />
          {isGymView && (
            <FilterDropdown
              title={"Personal Trainer"}
              values={personalTrainers}
              selectedValue={undefined}
              onChangeValue={(personalTrainerId: string) => {
                onPersonalTrainerSelect(personalTrainerId);
              }}
            />
          )}
          {isGymView && (
            <FilterDropdown
              title={"Aula"}
              values={courses}
              selectedValue={undefined}
              onChangeValue={(courseId: string) => {
                onCourseSelect(courseId);
              }}
            />
          )}
        </Col>
        {isLoadingSchedules && (
          <Col xs={1}>
            <SpinnerWrapper className="right" />
          </Col>
        )}
      </Row>
    </Container>
  );
};
