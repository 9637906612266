import { Carousel, Col, Container, Row } from "react-bootstrap";

import clientPage from "../../../assets/ClientPage.png";
import clientsPage from "../../../assets/ClientsPage.png";
import evaluations from "../../../assets/Evaluations.png";
import evolution from "../../../assets/Evolution.png";
import workoutsHistory from "../../../assets/WorkoutsHistory.png";

import "./ManageClients.css";
import { useTranslation } from "react-i18next";

export const ManageClients = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%" }} id="manage-clients">
      <Container className="mc-container">
        <Row>
          <Col className="mc-title-col">
            <h1 className="mc-title">{t("landingPage.manageClients.title")}</h1>
          </Col>
        </Row>
        <Row>
          <Col className="mc-col">
            <p>{t("landingPage.manageClients.description")}</p>
          </Col>
        </Row>
        <Row className="mc-images-row">
          <Col className="mc-col">
            <Carousel variant="dark">
              <Carousel.Item>
                <img src={clientsPage} className="mc-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={clientPage} className="mc-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={evaluations} className="mc-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={evolution} className="mc-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={workoutsHistory} className="mc-image" alt="" />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
