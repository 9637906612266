export const frequencies = {
  monthly: "Mensal",
  anual: "Anual",
};

type PaymentsPackageBase = {
  title: string;
  type: "RecurrentPaymentPackage" | "SessionsPaymentPackage";
  frequency: keyof typeof frequencies | null;
  max_entries_per_week: number | null;
  price: number;
  start_time: string;
  end_time: string;
  number_of_sessions: number | null;
  can_access_courses: boolean;
};

export type PaymentsPackage = PaymentsPackageBase & {
  id: string;
  term_in_days: number | null;
};

export type PaymentsPlan = PaymentsPackageBase & {
  id: string;
  term_in_days: Date | null;
};

export type CreatePaymentsPackage = Omit<PaymentsPackage, "id">;

export type SetPaymentsPackagePayload = {
  userId: string;
  startDate: string;
  package: PaymentsPackage;
  signInPrice: number;
};

export type SetPaymentsPackage = {
  userId: string;
  package: PaymentsPackage;
};

export type SetPaymentsPackageResponse = {
  type: string;
  id: string | null;
  user_id: string;
  payment_package_id: string;
  start_at: string;
};
