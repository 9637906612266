import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { WorkoutSchedule } from "../types/workoutSchedule";

type WorkoutScheduleState = {
  workoutsSchedules: Array<WorkoutSchedule>;
  confirmationMessage: string | undefined;
  errorMessage: string | undefined;
};

export type CreateWorkoutScheduleType = {
  startAt: string;
  workoutId: string;
};

export type UpdateWorkoutScheduleType = {
  id: string;
  startAt: string;
  workoutId: string;
};

const initialState: WorkoutScheduleState = {
  workoutsSchedules: [],
  confirmationMessage: undefined,
  errorMessage: undefined,
};

const workoutScheduleSlice = createSlice({
  name: "workoutSchedule",
  initialState: initialState,
  reducers: {
    fetchWorkoutSchedules(
      state: WorkoutScheduleState,
      action: PayloadAction<string>
    ) {},
    fetchWorkoutSchedulesSucceeded(
      state: WorkoutScheduleState,
      action: PayloadAction<Array<WorkoutSchedule>>
    ) {
      state.workoutsSchedules = action.payload;
    },
    createWorkoutSchedules(
      state: WorkoutScheduleState,
      action: PayloadAction<CreateWorkoutScheduleType>
    ) {},
    createWorkoutSchedulesSucceeded(
      state: WorkoutScheduleState,
      action: PayloadAction<WorkoutSchedule>
    ) {
      return {
        ...state,
        workoutsSchedules: [action.payload, ...state.workoutsSchedules],
      };
    },
    updateWorkoutSchedules(
      state: WorkoutScheduleState,
      action: PayloadAction<UpdateWorkoutScheduleType>
    ) {},
    updateWorkoutSchedulesSucceeded(
      state: WorkoutScheduleState,
      action: PayloadAction<WorkoutSchedule>
    ) {
      const index = state.workoutsSchedules.findIndex(
        (workoutSchedule: WorkoutSchedule) =>
          workoutSchedule.id === action.payload.id
      );

      state.workoutsSchedules[index] = action.payload;
    },
    deleteWorkoutSchedule(
      state: WorkoutScheduleState,
      action: PayloadAction<string>
    ) {},
    deleteWorkoutScheduleSucceeded(
      state: WorkoutScheduleState,
      action: PayloadAction<string>
    ) {
      const index = state.workoutsSchedules.findIndex(
        (workoutSchedule: WorkoutSchedule) =>
          workoutSchedule.id === action.payload
      );

      state.workoutsSchedules.splice(index, 1);
    },
    setErrorMessage(
      state: WorkoutScheduleState,
      action: PayloadAction<string>
    ) {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  fetchWorkoutSchedules,
  fetchWorkoutSchedulesSucceeded,
  createWorkoutSchedules,
  createWorkoutSchedulesSucceeded,
  updateWorkoutSchedules,
  updateWorkoutSchedulesSucceeded,
  deleteWorkoutSchedule,
  deleteWorkoutScheduleSucceeded,
  setErrorMessage,
} = workoutScheduleSlice.actions;

export const selectWorkoutSchedules = (state: RootState) =>
  state.workoutScheduleSlice.workoutsSchedules;

export default workoutScheduleSlice.reducer;
