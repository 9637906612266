import { Loader } from "@chatscope/chat-ui-kit-react";
import React, { HTMLProps, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { AddOrEditTransaction } from "components/ProductsPage/TransactionsSection/AddOrEditTransaction/AddOrEditTransaction";
import { TableComponent } from "components/TableComponent/TableComponent";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import {
  selectConfirmationMessage,
  selectErrorMessage,
  selectIsLoading,
  selectProducts,
  setConfirmationMessage,
  setErrorMessage,
} from "slices/productSlice";

import "components/ProductsPage/TransactionsSection/TransactionsSection.scss";
import { BsFillXCircleFill } from "react-icons/bs";
import { Product } from "types/product";

type ChooseProductProps = {
  handleClose: () => void;
};

export const ChooseProduct = ({ handleClose }: ChooseProductProps) => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const isLoading = useSelector(selectIsLoading);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [showAddTransaction, setShowAddTransaction] = useState<boolean>(false);
  const [productsPicked, setProductsPicked] = useState<Product[]>([]);
  const [productsIdsPicked, setProductsIdsPicked] = useState<string[]>([]);

  const onProductPickedForTransaction = useCallback(
    (product: Product) => {
      if (productsIdsPicked.includes(product.id)) {
        setProductsPicked(
          productsPicked.filter((e: Product) => e.id !== product.id)
        );
        setProductsIdsPicked(
          productsIdsPicked.filter((e: string) => e !== product.id)
        );
      } else {
        setProductsPicked([...productsPicked, product]);
        setProductsIdsPicked([...productsIdsPicked, product.id]);
      }
    },
    [productsIdsPicked, productsPicked]
  );

  const TableCheckbox = useCallback(
    ({ className = "", row }: { row: any } & HTMLProps<HTMLInputElement>) => {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onProductPickedForTransaction(row.original);
      };

      return (
        <input
          type="checkbox"
          checked={productsIdsPicked.includes(row.id)}
          className={className}
          onChange={handleChange}
        />
      );
    },
    [onProductPickedForTransaction, productsIdsPicked]
  );

  const tableColumns = React.useMemo(
    () => [
      {
        id: "select",
        Header: "",
        Cell: (props: any) => {
          return (
            <div className="px-1">
              <TableCheckbox row={props.row} />
            </div>
          );
        },
      },
      {
        Header: "Título",
        accessor: "title",
      },
      {
        Header: "Preço de custo (€)",
        accessor: "purchase_price",
      },
      {
        Header: "Preço de venda (€)",
        accessor: "sale_price",
      },
      {
        Header: "IVA (%)",
        accessor: "tax",
      },
      {
        Header: "Despesas (€)",
        accessor: "other_costs",
      },
    ],
    [TableCheckbox]
  );

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "choose-product-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "choose-product-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  return (
    <>
      {isLoading ? (
        <Loader className="chat-messages-loader full-screen" />
      ) : showAddTransaction ? (
        <AddOrEditTransaction
          products={productsPicked}
          handleClose={handleClose}
        />
      ) : (
        <GSection
          label="Escolher o produto"
          isSpacedBetween
          renderButtons={[
            <GButton
              disabled={productsIdsPicked.length === 0}
              label="Aplicar"
              onClick={() => {
                setShowAddTransaction(true);
              }}
            />,
            <GButton
              icon={<BsFillXCircleFill />}
              onClick={() => {
                handleClose();
              }}
            />,
          ]}
        >
          {products.length > 0 ? (
            <TableComponent
              name={""}
              columns={tableColumns}
              data={products}
              rowClickAction={() => {}}
            />
          ) : (
            <p style={{ textAlign: "center" }}>
              Não possui produtos. Adicione novos produtos na aba dos produtos.
            </p>
          )}
        </GSection>
      )}
    </>
  );
};
