import { Col, Container, Row } from "react-bootstrap";

import calendar from "../../../assets/Calendar.png";

import "./EffortlessScheduler.css";
import { useTranslation } from "react-i18next";

export const EffortlessScheduler = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%" }} id="effortless-scheduler">
      <Container className="es-container">
        <Row>
          <Col className="es-title-col">
            <h1 className="es-title">
              {t("landingPage.effortlessScheduler.title")}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="es-col">
            <p>{t("landingPage.effortlessScheduler.description")}</p>
          </Col>
          <Col lg={6} className="es-col">
            <img src={calendar} className="es-image" alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
