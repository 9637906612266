import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TableComponent } from "components/TableComponent/TableComponent";
import { toast } from "react-toastify";
import { formatToFirstTwoAndLastName } from "helpers/utils";
import { User } from "types/user";
import { selectCurrentUser } from "slices/currentUserSlice";
import { Form } from "react-bootstrap";
import { FilterDropdown } from "components/FilterDropdown/FilterDropdown";
import {
  deleteDiet,
  duplicateDiet,
  fetchDiets,
  selectDiets,
  selectIsLoading,
  selectConfirmationMessage,
  selectErrorMessage,
  setConfirmationMessage,
  setErrorMessage,
  clearDiet,
} from "slices/dietSlice";
import { Diet } from "types/diet";
import { fetchUsers, selectUsers } from "slices/userSlice";
import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";
import { DEFAULT_IMAGE } from "helpers/constants";

type creatorSelect = {
  id: string;
  name: string;
  image_small: string;
};

export const Diets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const diets = useSelector(selectDiets);
  const users = useSelector(selectUsers);
  const isLoading = useSelector(selectIsLoading);
  const [dietsToShow, setDietsToShow] = useState<Diet[]>(diets);
  const [clients, setClients] = useState<creatorSelect[]>([]);

  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const currentUser = useSelector(selectCurrentUser);
  const dietsFiltersLocalStorage = localStorage.getItem("dietFilters");
  const [searchInput, setSearchInput] = useState<string | undefined>(
    dietsFiltersLocalStorage ? JSON.parse(dietsFiltersLocalStorage).searchInput : undefined
  );
  const [selectedClient, setSelectedClient] = useState<creatorSelect | undefined>(
    dietsFiltersLocalStorage ? JSON.parse(dietsFiltersLocalStorage).selectedClient : undefined
  );

  useEffect(() => {
    dispatch(fetchDiets());
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser && users) {
      const clts = users.map((user: User) => {
        return {
          id: user.id!,
          name: formatToFirstTwoAndLastName(user.name),
          image_small: user.image_small ?? DEFAULT_IMAGE,
        };
      });

      setClients(clts);
    }
  }, [currentUser, users]);

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "title",
    },
    {
      Header: "Cliente",
      accessor: "customer",
      Cell: (props: any) => {
        const image = users.find((user) => user.id === props.row.original.user.id)?.image_small;
        const name = formatToFirstTwoAndLastName(props.row.original.user.name);

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              alt=""
              style={{ width: "30px", height: "30px", borderRadius: "50%" }}
              src={image ?? "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"}
            />
            <p style={{ margin: 0, marginLeft: 10 }}>{name}</p>
          </div>
        );
      },
    },
    {
      Header: "Peso",
      accessor: "totals.weight_in_g",
      Cell: (props: any) => {
        return `${props.row.original.totals.weight_in_g.toFixed(0)}g`;
      },
    },
    {
      Header: "Calorias",
      accessor: "totals.calories",
      Cell: (props: any) => {
        return `${props.row.original.totals.calories.toFixed(0)}kcal`;
      },
    },
    {
      Header: "Proteínas",
      accessor: "totals.protein",
      Cell: (props: any) => {
        return `${props.row.original.totals.protein.toFixed(0)}g`;
      },
    },
    {
      Header: "Hidratos",
      accessor: "totals.carbs",
      Cell: (props: any) => {
        return `${props.row.original.totals.carbs.toFixed(0)}g`;
      },
    },
    {
      Header: "Lipídos",
      accessor: "totals.lipids",
      Cell: (props: any) => {
        return `${props.row.original.totals.lipids.toFixed(0)}g`;
      },
    },
  ];

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "delete-diet-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "delete-diet-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
      dispatch(fetchDiets());
    }
  }, [dispatch, confirmationMessage, navigate]);

  useEffect(() => {
    localStorage.setItem(
      "dietFilters",
      JSON.stringify({
        searchInput,
        selectedClient,
      })
    );
  }, [searchInput, selectedClient]);

  useEffect(() => {
    var dietsFiltered = diets;

    if (searchInput !== undefined) {
      dietsFiltered = dietsFiltered.filter((diet: Diet) =>
        diet.title.toLowerCase().includes(searchInput.toLowerCase())
      );

      setDietsToShow(dietsFiltered);
    }

    if (selectedClient !== undefined) {
      dietsFiltered = dietsFiltered.filter((diet: Diet) => diet.user?.id === selectedClient.id);
      setDietsToShow(dietsFiltered);
    }

    if (searchInput === undefined && selectedClient === undefined) {
      setDietsToShow(diets);
    }
  }, [searchInput, selectedClient, diets]);

  return (
    <div>
      <div className="filter-container">
        <Form.Control
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Pesquisar"
          className="search-input"
        />
        <FilterDropdown
          title="Utilizador"
          values={clients}
          selectedValue={selectedClient}
          onChangeValue={(clientId: any) => {
            if (clientId === undefined) return setSelectedClient(undefined);

            const newValue = users.find((user) => user.id === clientId);

            setSelectedClient({
              id: newValue!.id,
              name: formatToFirstTwoAndLastName(newValue!.name),
              image_small: newValue!.image_small ?? DEFAULT_IMAGE,
            });
          }}
        />
      </div>
      {isLoading ? (
        <div style={{ padding: "8px" }}>
          <SpinnerWrapper />
        </div>
      ) : (
        <TableComponent
          name="diets"
          columns={tableColumns}
          data={dietsToShow}
          addAction={() => {}}
          rowClickAction={(row: any) => {
            dispatch(clearDiet());
            navigate(`${row.original.id}`);
          }}
          removeAction={(id: string) => {
            dispatch(deleteDiet(id));
          }}
          duplicateAction={(id: string) => {
            dispatch(duplicateDiet(id));
          }}
          showRemoveButton
          showDuplicateButton
          textWhenNoItems="Não tem nenhuma dieta criada"
        />
      )}
    </div>
  );
};
