import React, { useEffect, useState } from "react";
import { TableComponent } from "../TableComponent/TableComponent";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import {
  fetchPersonalTrainers,
  selectPersonalTrainers,
  selectErrorMessage,
  selectConfirmationMessage,
  setErrorMessage,
  setConfirmationMessage,
  selectIsLoading,
  deletePersonalTrainer,
} from "../../slices/personalTrainerSlice";
import { toast } from "react-toastify";
import { User } from "../../types/user";
import { SpinnerWrapper } from "../SpinnerWrapper/SpinnerWrapper";

import "./DashboardPersonalTrainersPage.css";
import { DashboardTitle } from "../DashboardTitle/DashboardTitle";
import { Navigate, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { formatToFirstTwoAndLastName } from "helpers/utils";
import { GChip } from "components/genericComponents/GChip/GChip";

export const DashBoardPersonalTrainersPage = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const users = useSelector(selectPersonalTrainers);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const isLoading = useSelector(selectIsLoading);
  const [usersAlreadyFetched, setUsersAlreadyFetched] = useState(false);

  useCookies(["current_user"]);

  const tableColumns = React.useMemo(() => {
    return [
      {
        accessor: "image_small",
        Cell: (props: any) => {
          return (
            <img
              className="dashboard-workouts-cell-image"
              src={
                props.cell.value ??
                "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
              }
              alt={""}
            />
          );
        },
        width: 10,
      },
      {
        Header: "Nome",
        accessor: "name",
        Cell: (props: any) =>
          formatToFirstTwoAndLastName(props.row.original.name),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Estado",
        accessor: "status",
        Cell: (props: any) => {
          if (props.cell.value === "completed")
            return <GChip label="Completo" variant="infoFinished" />;
          if (props.cell.value === "invited")
            return <GChip label="Convidado" variant="infoPending" />;
          if (props.cell.value === "discarded")
            return <GChip label="Inativo" variant="infoExpired" />;
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (!usersAlreadyFetched && users.length === 0) {
      dispatch(fetchPersonalTrainers());
      setUsersAlreadyFetched(true);
    }
  }, [dispatch, usersAlreadyFetched, users]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "personal-trainers-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "personal-trainers-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  const renderPersonalTrainerPage = (selectedUser: User) => {
    let path = `${selectedUser.id}`;
    navigate(path);
  };

  return (
    <Container fluid id="dashboard-customers">
      {localStorage.getItem("isGymView") === "true" ? (
        isLoading ? (
          <SpinnerWrapper />
        ) : (
          <>
            <DashboardTitle
              title=""
              showAddButton={true}
              linkObj={{ isLink: true, link: "add-personal-trainer" }}
              countCards={[
                {
                  title: "Personal Trainers",
                  count: users.length,
                },
              ]}
              infoLink="EhTnnCBCX1w"
            />
            <div className="table-component-upper-div">
              <TableComponent
                name="personal_trainers"
                columns={tableColumns}
                data={users}
                removeAction={(id: string) => {
                  dispatch(deletePersonalTrainer(id));
                }}
                addAction={() => {
                  /* setShowModal(true) */
                }}
                rowClickAction={(row: any) => {
                  renderPersonalTrainerPage(row.original);
                }}
                duplicateAction={(id: string) => {}}
                showRemoveButton
                textWhenNoItems="Não tem nenhum personal trainer"
              />
            </div>
          </>
        )
      ) : (
        <Navigate to="/dashboard/home" />
      )}
    </Container>
  );
};
