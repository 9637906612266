import { axiosRequest, errorMessage } from "./request";
import { GymRoom } from "../types/gymRoom";
import { SetGymRoomsType } from "../slices/gymRoomSlice";

export type GymRoomRequestResponseType = {
  succeeded: boolean;
  gymRooms: Array<GymRoom> | undefined;
  errorMessage: string | undefined;
};

export const fetchGymRoomsRequest =
  async (): Promise<GymRoomRequestResponseType> => {
    try {
      const response = await axiosRequest().get("rooms");
      const gymRooms = response.data as Array<GymRoom>;

      return {
        succeeded: true,
        gymRooms: gymRooms,
      } as GymRoomRequestResponseType;
    } catch (e) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as GymRoomRequestResponseType;
    }
  };

export const setGymRoomsRequest = async (
  params: Array<SetGymRoomsType>
): Promise<GymRoomRequestResponseType> => {
  try {
    const response = await axiosRequest().post("rooms", {
      rooms: params,
    });
    const gymRooms: Array<GymRoom> = response.data;

    return {
      succeeded: true,
      gymRooms: gymRooms,
    } as GymRoomRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as GymRoomRequestResponseType;
  }
};
