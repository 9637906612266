import { axiosRequest, errorMessage } from "./request";

export type StripeRequestResponseType = {
  succeeded: boolean;
  clientSecret?: string;
  url?: string;
  errorMessage: string;
};

export const createAccountSession =
  async (): Promise<StripeRequestResponseType> => {
    try {
      const response = await axiosRequest().post("stripe/account_sessions");

      return {
        succeeded: true,
        clientSecret: response.data.client_secret,
      } as StripeRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as StripeRequestResponseType;
    }
  };

export const createAccountOnboardingLink =
  async (): Promise<StripeRequestResponseType> => {
    try {
      const response = await axiosRequest().post("stripe/account_onboarding");

      return {
        succeeded: true,
        url: response.data.url,
      } as StripeRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as StripeRequestResponseType;
    }
  };
