import React, { useEffect, useState } from "react";
import { ChatMessage } from "../../types/chat";
import {
  addMessageToChat,
  selectCurrentChat,
  readChatMessage,
} from "../../slices/chatSlice";
import actionCable from "actioncable";
import { useDispatch, useSelector } from "react-redux";

type WebsocketMessage = {
  body: ChatMessage;
};

type TourniquetMessage = {
  openDoor: boolean;
  userName: string;
  birthdayDate: string;
  insuranceEndDate: string;
  paymentsInDelay: boolean;
  packageDoesNotAllowTime: boolean;
  recurrentPackageWithNoMoreEntriesOnCurrentWeek: boolean;
  sessionsPackageWithNoMoreSessions: boolean;
  noPackage: boolean;
};

type WebsocketTourniquet = {
  body: TourniquetMessage;
};

export const Websocket: React.FC = () => {
  const cable = actionCable.createConsumer(
    `wss://gym-application-backend.herokuapp.com/cable?token=${localStorage.getItem(
      "token"
    )}`
  );
  const [channel, setChannel] = useState<null | actionCable.Channel>(null);
  const dispatch = useDispatch();
  const currentChat = useSelector(selectCurrentChat);
  const [alreadyRan, setAlreadyRan] = useState<boolean>(false);

  useEffect(() => {
    const receivedMessage = (message: ChatMessage) => {
      console.log(message);
      dispatch(addMessageToChat(message));

      // Current chat is always nil so it's never reading automatically
      if (message.chat_id === currentChat?.id) {
        dispatch(readChatMessage(message.chat_id));
      }
    };

    const receivedTourniquet = (message: TourniquetMessage) => {
      console.log(message);
    };

    const createSubscription = () => {
      if (cable !== null) {
        cable.disconnect();
      }

      if (channel !== null) {
        channel.unsubscribe();
      }

      setChannel(
        cable.subscriptions.create(
          { channel: "MessagesChannel" },
          {
            received: (data: WebsocketMessage) => {
              receivedMessage(data.body);
            },
            connected() {
              console.log("Messages Websocket connected");
            },
            disconnect() {
              cable.disconnect();
            },
          }
        )
      );

      setChannel(
        cable.subscriptions.create(
          { channel: "TourniquetChannel" },
          {
            received: (data: WebsocketTourniquet) => {
              receivedTourniquet(data.body);
            },
            connected() {
              console.log("Tourniquet Websocket connected");
            },
            disconnect() {
              cable.disconnect();
            },
          }
        )
      );
    };

    if (!alreadyRan) {
      createSubscription();
      setAlreadyRan(true);
    }
  }, [
    alreadyRan,
    cable.subscriptions,
    channel,
    cable,
    currentChat?.id,
    dispatch,
  ]);

  return <div></div>;
};
