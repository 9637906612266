import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { Websocket } from "../Websocket/Websocket";
import {
  createChatgptMessage,
  selectCurrentChatgpt,
  selectChatgpts,
  fetchChatgptChat,
  fetchChatgptChats,
  setCurrentChatgpt,
  selectIsLoading,
} from "../../slices/chatgptSlice";
import {
  createChatMessage,
  fetchChats,
  fetchChatMessages,
  selectChats,
  setCurrentChat,
  selectCurrentChat,
  readChatMessage,
} from "../../slices/chatSlice";
import { selectCurrentUser } from "../../slices/currentUserSlice";
import { Chat, ChatMessage } from "../../types/chat";
import { ChatGptChat, ChatGptMessage } from "../../types/chatgpt";
import { useSelector, useDispatch } from "react-redux";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
  ConversationList,
  Conversation,
  Loader,
} from "@chatscope/chat-ui-kit-react";

import "./ChatGptPage.css";

import ReactGA from "react-ga4";
import {
  S3RequestResponseType,
  uploadFileRequest,
} from "../../requests/s3Requests";

export const ChatGptPage = () => {
  ReactGA.send("/chatgpt");

  const [input, setInput] = useState("");
  const messagesContainerRef = useRef<any>(null);
  const currentChatgpt = useSelector(selectCurrentChatgpt);
  const currentChat = useSelector(selectCurrentChat);
  const chatgpts = useSelector(selectChatgpts);
  const chats = useSelector(selectChats);
  const dispatch = useDispatch();
  const [showChatgpt, setShowChatgpt] = useState(false);
  const [isShowingChatOnMobile, setIsShowingChatOnMobile] = useState(false);
  const [isShowingChatgptOnMobile, setIsShowingChatgptOnMobile] =
    useState(false);
  const isLoading = useSelector(selectIsLoading);
  const currentUser = useSelector(selectCurrentUser);
  const isGymView = localStorage.getItem("isGymView") === "true";
  const [showModal, setShowModal] = useState<boolean>(false);
  const [imageToShow, setImageToShow] = useState<string | undefined>(undefined);
  const inputFile = useRef<HTMLInputElement>(null);
  const [imageKey, setImageKey] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | null>(null);

  const scrollToBottom = () => {
    messagesContainerRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendChatgptMessage = () => {
    dispatch(
      createChatgptMessage({
        message: input!,
        chatgpt_chat_id: currentChatgpt!.id,
      })
    );
    setInput("");
  };

  const handleSendMessage = () => {
    dispatch(
      createChatMessage({
        message: input!,
        image: null,
        chat_id: currentChat!.id!,
      })
    );
    setInput("");
  };

  const handleClickChatgpt = (chat: ChatGptChat) => {
    dispatch(fetchChatgptChat(chat.id!));
    dispatch(setCurrentChatgpt(chat));

    setIsShowingChatgptOnMobile(true);
  };

  const handleClickChat = (chat: Chat) => {
    if (chat.messages === undefined) {
      dispatch(fetchChatMessages(chat.id!));
    }

    dispatch(setCurrentChat(chat));

    if (chat.messages_to_read_count > 0) {
      dispatch(readChatMessage(chat.id!));
    }

    setIsShowingChatOnMobile(true);
  };

  useEffect(() => {
    dispatch(fetchChatgptChats());
    dispatch(fetchChats());
  }, [dispatch]);

  useEffect(() => {
    scrollToBottom();
  }, [currentChatgpt]);

  useEffect(() => {
    if (chats.length > 0 && currentChat === undefined) {
      dispatch(fetchChatMessages(chats[0].id!));
    }
  }, [chats, currentChat, dispatch]);

  useEffect(() => {
    if (imageKey === undefined) return;

    const image =
      file === null
        ? null
        : {
            id: imageKey!,
            storage: "cache",
            metadata: {
              size: file.size,
              mime_type: file.type,
            },
          };

    dispatch(
      createChatMessage({
        message: null,
        image: image,
        chat_id: currentChat!.id!,
      })
    );
  }, [imageKey]); // eslint-disable-line

  const onButtonClick = () => {
    inputFile.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      // update user data
      uploadFileRequest(e.target.files[0]).then(
        (response: S3RequestResponseType) => {
          if (!response.succeeded) return;

          setImageKey(response.key!);
        }
      );

      // then setFile
      setFile(e.target.files[0]);
    }
  };

  const chatgptsContainer = () => {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "row",
        }}
      >
        <div
          style={{ flex: 2 }}
          className={
            isShowingChatgptOnMobile ? "do-not-show-on-mobile" : undefined
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="chatgpt-start-other-chat-button-div">
              <Button
                className="chatgpt-start-other-chat-button"
                onClick={() => {
                  dispatch(
                    setCurrentChatgpt({
                      id: undefined,
                      title: "",
                      messages: [],
                    })
                  );
                  setIsShowingChatgptOnMobile(true);
                }}
              >
                {" "}
                Começar outro chat{" "}
              </Button>
            </div>
            <ConversationList style={{ flex: 1 }}>
              {chatgpts.map((chat: ChatGptChat) => (
                <Conversation
                  key={chat.id}
                  style={{
                    backgroundColor:
                      chat.id === currentChatgpt.id ? "#f0ecff" : "white",
                  }}
                  className="conversation-lib"
                  name={chat.title}
                  onClick={() => handleClickChatgpt(chat)}
                ></Conversation>
              ))}
            </ConversationList>
          </div>
        </div>
        <div
          style={{ height: "100%", flex: 3 }}
          className={
            !isShowingChatgptOnMobile ? "do-not-show-on-mobile" : undefined
          }
        >
          {currentChatgpt?.messages !== undefined && (
            <MainContainer style={{ borderRadius: 0 }}>
              <ChatContainer>
                <ConversationHeader
                  style={{ backgroundColor: "#F0ECFF", borderColor: "#F0ECFF" }}
                >
                  <ConversationHeader.Back
                    className="chat-back-button"
                    onClick={() => setIsShowingChatgptOnMobile(false)}
                  />
                  <Avatar
                    src={
                      "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                    }
                  />
                  <ConversationHeader.Content userName="AI Chatbot" />
                </ConversationHeader>
                <MessageList style={{ padding: 10 }}>
                  {currentChatgpt.messages?.map((message: ChatGptMessage) => (
                    <div
                      key={message.message + Math.random()}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: message.is_chatgpt_answer
                          ? "flex-start"
                          : "flex-end",
                      }}
                    >
                      <Message
                        style={{ padding: 4 }}
                        model={{
                          message: message.message.replace("&amp;", "&"),
                          sender: "Joe",
                          direction: message?.is_chatgpt_answer
                            ? "incoming"
                            : "outgoing",
                          position: "single",
                        }}
                      />
                      <div ref={messagesContainerRef} />
                    </div>
                  ))}
                  {isLoading ? (
                    <Loader className="chat-messages-loader" />
                  ) : undefined}
                </MessageList>
                <MessageInput
                  style={{
                    padding: 20,
                    borderColor: "#F0ECFF",
                    marginTop: 10,
                    color: "white",
                    backgroundColor: "#F0ECFF",
                  }}
                  value={input}
                  onSend={handleSendChatgptMessage}
                  placeholder="Escreve aqui a tua mensagem"
                  attachButton={false}
                  onChange={(e: any) => {
                    setInput(e);
                  }}
                />
              </ChatContainer>
            </MainContainer>
          )}
        </div>
      </div>
    );
  };

  const normalChatsContainer = () => {
    const last_message_text = (
      last_message: ChatMessage | null | undefined
    ) => {
      if (last_message === null || last_message === undefined) return "";

      if (last_message.message?.length > 19) {
        return `${last_message.message.slice(0, 20)}...`;
      } else {
        return last_message.message;
      }
    };

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "row",
        }}
      >
        <div
          style={{ flex: 2, maxWidth: "40%" }}
          className={
            isShowingChatOnMobile ? "do-not-show-on-mobile" : undefined
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <ConversationList style={{ flex: 1 }}>
              {chats.map((chat: Chat, index: number) => (
                <Conversation
                  key={`${index} ${Math.random()} ${index}`}
                  style={{
                    backgroundColor:
                      chat.id === currentChat?.id ? "#f0ecff" : "white",
                  }}
                  name={chat.title}
                  info={last_message_text(chat.last_message)}
                  unreadCnt={chat.messages_to_read_count}
                  onClick={() => handleClickChat(chat)}
                >
                  <Avatar
                    src={
                      chat.image ??
                      "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                    }
                  />
                </Conversation>
              ))}
            </ConversationList>
          </div>
        </div>
        <div
          style={{ height: "100%", flex: 3, maxWidth: "60%" }}
          className={
            !isShowingChatOnMobile ? "do-not-show-on-mobile" : undefined
          }
        >
          {currentChat !== undefined && (
            <MainContainer
              style={{ borderRadius: 0 }}
              className={
                !isShowingChatOnMobile ? "do-not-show-on-mobile" : undefined
              }
            >
              <ChatContainer>
                <ConversationHeader
                  style={{ backgroundColor: "#F0ECFF", borderColor: "#F0ECFF" }}
                >
                  <ConversationHeader.Back
                    className="chat-back-button"
                    onClick={() => setIsShowingChatOnMobile(false)}
                  />
                  <Avatar
                    src={
                      currentChat!.image ??
                      "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                    }
                  />
                  <ConversationHeader.Content userName={currentChat!.title} />
                </ConversationHeader>
                <MessageList style={{ padding: 10 }}>
                  {currentChat.messages?.map((message: ChatMessage) => (
                    <div
                      key={message.chat_id + Math.random()}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent:
                          message.user.id !== currentUser?.id
                            ? "flex-start"
                            : "flex-end",
                      }}
                    >
                      <Message
                        style={{ padding: 4 }}
                        type={message.image_url ? "image" : "text"}
                        model={{
                          sentTime: "just now",
                          sender: "Joe",
                          direction:
                            message.user.id !== currentUser?.id
                              ? "incoming"
                              : "outgoing",
                          position: "single",
                        }}
                        onClick={(e) => {
                          if ((e.target as HTMLImageElement).src) {
                            setImageToShow(message.image_url);
                            setShowModal(true);
                          }
                        }}
                      >
                        <Message.CustomContent>
                          {message.image_url && (
                            <Message.ImageContent
                              className="chat-image"
                              src={message.image_url}
                            />
                          )}
                          {message.message && (
                            <Message.TextContent
                              text={message.message.replace("&amp;", "&")}
                            />
                          )}
                        </Message.CustomContent>
                        <Message.Footer
                          sentTime={new Date(message.created_at)
                            .toString()
                            .slice(16, 21)}
                        />
                      </Message>
                      <div ref={messagesContainerRef} />
                    </div>
                  ))}
                  {isLoading ? (
                    <Loader className="chat-messages-loader" />
                  ) : undefined}
                </MessageList>
                <MessageInput
                  style={{
                    padding: 20,
                    borderColor: "#F0ECFF",
                    marginTop: 10,
                    color: "white",
                    backgroundColor: "#F0ECFF",
                  }}
                  value={input}
                  onSend={handleSendMessage}
                  placeholder="Escreve aqui a tua mensagem"
                  onChange={(e: any) => {
                    setInput(e);
                  }}
                  onAttachClick={onButtonClick}
                />
              </ChatContainer>
            </MainContainer>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="chat-container">
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <Websocket />
      <div className="chat-text-container">
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          {!isGymView && (
            <Button
              className={
                showChatgpt
                  ? "chatgpt-chats-left-button"
                  : "chatgpt-chats-left-button-active"
              }
              onClick={() => setShowChatgpt(false)}
            >
              Mensagens
            </Button>
          )}
          <Button
            disabled={isGymView}
            className={
              showChatgpt
                ? "chatgpt-chats-right-button-active"
                : "chatgpt-chats-right-button"
            }
            onClick={() => setShowChatgpt(true)}
          >
            Chatbot
          </Button>
        </div>
        <div className="before-chats-div">
          {(showChatgpt || isGymView) && chatgptsContainer()}
          {!showChatgpt && !isGymView && normalChatsContainer()}
        </div>
        <input
          type="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept="image/png, image/gif, image/jpeg"
        />
      </div>
      <Modal
        centered
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setTimeout(() => {
            setImageToShow(undefined);
          }, 200);
        }}
      >
        <Modal.Body>
          <Row className="modal-flex-center">
            <img src={imageToShow} alt="" />
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};
