import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";

import { selectCurrentUser } from "slices/currentUserSlice";

import profilePicture from "assets/profile-picture.png";
import logo from "assets/logo.svg";

import "components/SettingsPage/MobileAppSettings/HomeScreenApp/HomeScreenApp.scss";
import {
  FaCalendarDays,
  FaCommentDots,
  FaHouse,
  FaUser,
} from "react-icons/fa6";
import { FaDumbbell } from "react-icons/fa";

type HomeScreenAppProps = {
  color: string;
};

export const HomeScreenApp = ({ color }: HomeScreenAppProps) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <div className="home-screen-app">
      <div className="header-container">
        <div className="header-left-div">
          {currentUser?.company?.image_medium !== null &&
          currentUser?.company?.is_gym ? (
            <img
              className="header-logo"
              src={currentUser.company?.image_small}
              alt="company logo"
            />
          ) : !currentUser?.company?.is_gym &&
            currentUser?.image_small !== null ? (
            <img
              className="header-logo"
              src={currentUser?.image_small}
              alt="user logo"
            />
          ) : (
            <img className="header-logo" src={logo} alt="logo" />
          )}
          {currentUser?.company?.is_gym ? (
            <p className="header-text">{currentUser.company.name}</p>
          ) : (
            <p></p>
          )}
        </div>
        <div>
          <img
            className="header-profile-image"
            src={profilePicture}
            alt="profile"
          />
        </div>
      </div>
      <p className="hello">Olá, Maria 👋</p>
      <p className="next-event">Próximo evento</p>
      <div
        className="card"
        style={{
          background: `linear-gradient(to bottom, ${tinycolor(color)
            .lighten(20)
            .toString()} 0%, ${color} 100%)`,
        }}
      >
        <p
          style={{
            color: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
          }}
        >
          Pilates
        </p>
      </div>
      <div style={{ flex: "1 1 auto" }} />
      <div className="icons-container">
        <div className="icon-div">
          <FaHouse className="icon" style={{ color: color }} />
          <p style={{ color: color }}>Início</p>
        </div>
        <div className="icon-div">
          <FaDumbbell className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Planos de Treino</p>
        </div>
        <div className="icon-div">
          <FaCalendarDays className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Calendário</p>
        </div>
        <div className="icon-div">
          <FaCommentDots className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Mensagens</p>
        </div>
        <div className="icon-div">
          <FaUser className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Perfil</p>
        </div>
      </div>
    </div>
  );
};
