import { axiosRequest, errorMessage } from "./request";
import { Plans, FetchCompanyPlansType } from "../slices/companyPlanSlice";

export type CompanyPlanResponseType = {
  succeeded: boolean;
  plans: Plans;
  errorMessage: string | undefined;
};

export const fetchCompanyPlans = async (
  params: FetchCompanyPlansType
): Promise<CompanyPlanResponseType> => {
  try {
    const response = await axiosRequest().get(
      `/company_plans?code=${params.code}&locale=${params.locale}`
    );

    return {
      succeeded: true,
      plans: {
        monthly: response.data.monthly,
        yearly: response.data.yearly,
        has_code: response.data.has_code,
      },
    } as CompanyPlanResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CompanyPlanResponseType;
  }
};

export const fetchUpgradableCompanyPlans =
  async (): Promise<CompanyPlanResponseType> => {
    try {
      const response = await axiosRequest().get(`/upgradable_company_plans`);

      return {
        succeeded: true,
        plans: {
          monthly: response.data.monthly,
          yearly: response.data.yearly,
        },
      } as CompanyPlanResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as CompanyPlanResponseType;
    }
  };
