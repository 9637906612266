import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  userEvaluationSuccessfullyCreated,
  userEvaluationSuccessfullyUpdated,
  userEvaluationSuccessfullyDeleted,
} from "../helpers/messages";
import { Document, CreateDocumentType } from "../types/document";

type EvaluationsState = {
  isLoading: boolean;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
  evaluations: EvaluationsType[];
};

export type UserImageType = {
  id: string;
  storage: string;
  metadata: {
    size: number;
    mime_type: string;
  };
};


export type EvaluationsType = {
  id: string | null;
  user_id: string | null;
  created_at: Date | null;
  imc: number | null;
  fat_mass_percentage: number | null;
  blood_pressure_sistolic: number | null;
  blood_pressure_diastolic: number | null;
  resting_heart_beats: number | null;
  has_surgical_interventions: boolean;
  surgical_interventions: string | null;
  is_smoker: boolean;
  smoker_info: string | null;
  image?: UserImageType | null;
  weight_in_kg: number | null;
  height_in_cm: number | null;
  perimeter_bicep_left: number | null;
  perimeter_bicep_right: number | null;
  perimeter_chest: number | null;
  perimeter_upper_leg_left: number | null;
  perimeter_upper_leg_right: number | null;
  perimeter_waist: number | null;
  perimeter_hip: number | null;
  perimeter_neck: number | null;
  perimeter_abdominal: number | null;
  visceral_fat: number | null;
  h2o: number | null;
  metabolic_age: number | null;
  bone_mineral_density: number | null;
  muscular_mass: number | null;
  basal_metabolic_rate: number | null;
  max_heart_freq: number | null;
  hip_waist_relation: number | null;
  has_cardiac_diseases: boolean;
  has_chest_pain_while_doing_sports: boolean;
  has_chest_pain_while_not_doing_sports: boolean;
  has_balance_loss: boolean;
  has_bones_issues: boolean;
  has_medication_for_blood_pressure: boolean;
  has_limitations_for_sports: boolean;
  cardiac_diseases: string | null;
  chest_pain_while_doing_sports: string | null;
  chest_pain_while_not_doing_sports: string | null;
  balance_loss: string | null;
  bones_issues: string | null;
  medication_for_blood_pressure: string | null;
  limitations_for_sports: string | null;
  practiced_sports_answer: string | null;
  practiced_sports: boolean;
  notes: string | null;
  strenght_superior_left_part: number | null;
  strenght_superior_right_part: number | null;
  strenght_inferior_left_part: number | null;
  strenght_inferior_right_part: number | null;
  resistance: number | null;
  core_flexibility: number | null;
  ankle_flexibility: number | null;
  strength_power: number | null;
  left_balance: number | null;
  right_balance: number | null;
  blood_pressure_sistolic_on_effort: number | null
  blood_pressure_diastolic_on_effort: number | null
  spvo2: number | null
  documents: Array<Document>
};

export type CreateOrUpdateEvaluationType = {
  id?: string | null;
  user_id?: string | null;
  imc: number | null;
  fat_mass_percentage: number | null;
  blood_pressure_sistolic: number | null;
  blood_pressure_diastolic: number | null;
  resting_heart_beats: number | null;
  has_surgical_interventions: boolean;
  surgical_interventions: string | null;
  is_smoker: boolean;
  smoker_info: string | null;
  image?: UserImageType | null;
  weight_in_kg: number | null;
  height_in_cm: number | null;
  perimeter_bicep_left: number | null;
  perimeter_bicep_right: number | null;
  perimeter_chest: number | null;
  perimeter_upper_leg_left: number | null;
  perimeter_upper_leg_right: number | null;
  perimeter_waist: number | null;
  perimeter_hip: number | null;
  perimeter_neck: number | null;
  perimeter_abdominal: number | null;
  visceral_fat: number | null;
  h2o: number | null;
  metabolic_age: number | null;
  bone_mineral_density: number | null;
  muscular_mass: number | null;
  basal_metabolic_rate: number | null;
  max_heart_freq: number | null;
  hip_waist_relation: number | null;
  has_cardiac_diseases: boolean;
  has_chest_pain_while_doing_sports: boolean;
  has_chest_pain_while_not_doing_sports: boolean;
  has_balance_loss: boolean;
  has_bones_issues: boolean;
  has_medication_for_blood_pressure: boolean;
  has_limitations_for_sports: boolean;
  cardiac_diseases: string | null;
  chest_pain_while_doing_sports: string | null;
  chest_pain_while_not_doing_sports: string | null;
  balance_loss: string | null;
  bones_issues: string | null;
  medication_for_blood_pressure: string | null;
  limitations_for_sports: string | null;
  practiced_sports_answer: string | null;
  practiced_sports: boolean;
  notes: string | null;
  strenght_superior_left_part: number | null;
  strenght_superior_right_part: number | null;
  resistance: number | null;
  core_flexibility: number | null;
  ankle_flexibility: number | null;
  strength_power: number | null;
  left_balance: number | null;
  right_balance: number | null;
  blood_pressure_sistolic_on_effort: number | null
  blood_pressure_diastolic_on_effort: number | null
  spvo2: number | null
  documents_attributes: Array<CreateDocumentType>
};

export type DeleteEvaluationType = {
  id: string;
  user_id: string;
};

const initialState: EvaluationsState = {
  isLoading: false,
  errorMessage: undefined,
  confirmationMessage: undefined,
  evaluations: [],
};

const evaluationsSlice = createSlice({
  name: "evaluations",
  initialState: initialState,
  reducers: {
    fetchEvaluations(state: EvaluationsState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    fetchEvaluationsSucceeded(
      state: EvaluationsState,
      action: PayloadAction<Array<EvaluationsType>>
    ) {
      state.evaluations = action.payload;
      state.isLoading = false;
    },
    createEvaluation(
      state: EvaluationsState,
      action: PayloadAction<CreateOrUpdateEvaluationType>
    ) {},
    createEvaluationSucceeded(
      state: EvaluationsState,
      action: PayloadAction<EvaluationsType>
    ) {
      return {
        ...state,
        confirmationMessage: userEvaluationSuccessfullyCreated,
        evaluations: [action.payload, ...state.evaluations],
      };
    },
    updateEvaluation(
      state: EvaluationsState,
      action: PayloadAction<CreateOrUpdateEvaluationType>
    ) {},
    updateEvaluationSucceeded(
      state: EvaluationsState,
      action: PayloadAction<EvaluationsType>
    ) {
      return {
        ...state,
        confirmationMessage: userEvaluationSuccessfullyUpdated,
        evaluations: state.evaluations.map((evaluation: EvaluationsType) => {
          if (evaluation.id === action.payload.id) {
            return action.payload;
          } else {
            return evaluation;
          }
        }),
      };
    },
    deleteEvaluation(
      state: EvaluationsState,
      action: PayloadAction<DeleteEvaluationType>
    ) {},
    deleteEvaluationSucceeded(
      state: EvaluationsState,
      action: PayloadAction<string>
    ) {
      const index = state.evaluations.findIndex(
        (evaluation: EvaluationsType) => evaluation.id === action.payload
      );

      state.evaluations.splice(index, 1);
      state.confirmationMessage = userEvaluationSuccessfullyDeleted;
    },
    setErrorMessage(
      state: EvaluationsState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: EvaluationsState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
  },
});

export const {
  setErrorMessage,
  setConfirmationMessage,
  fetchEvaluations,
  fetchEvaluationsSucceeded,
  createEvaluation,
  createEvaluationSucceeded,
  updateEvaluation,
  updateEvaluationSucceeded,
  deleteEvaluation,
  deleteEvaluationSucceeded,
} = evaluationsSlice.actions;

export const selectErrorMessage = (state: RootState) =>
  state.evaluationsSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.evaluationsSlice.confirmationMessage;
export const selectIsLoading = (state: RootState) =>
  state.evaluationsSlice.isLoading;
export const selectEvaluations = (state: RootState) =>
  state.evaluationsSlice.evaluations;

export default evaluationsSlice.reducer;
