import { takeLatest } from "redux-saga/effects";
import {
  fetchUsers,
  fetchUser,
  createUser,
  updateUser,
  deleteUser,
  fetchUserCounters,
  reInviteUser,
  fetchUsersWithMissingPayments,
  fetchRevenue,
  reActivateUser,
} from "../../slices/userSlice";
import {
  fetchUsersSaga,
  fetchUserSaga,
  createUserSaga,
  updateUserSaga,
  deleteUserSaga,
  fetchUserCountersSaga,
  reInviteUserSaga,
  fetchUsersWithMissingPaymentsSaga,
  fetchRevenueSaga,
  reActivateUserSaga,
} from "./userSaga";

function* userSaga() {
  yield takeLatest(fetchUsers, fetchUsersSaga);
  yield takeLatest(fetchUser, fetchUserSaga);
  yield takeLatest(createUser, createUserSaga);
  yield takeLatest(updateUser, updateUserSaga);
  yield takeLatest(deleteUser, deleteUserSaga);
  yield takeLatest(fetchUserCounters, fetchUserCountersSaga);
  yield takeLatest(reInviteUser, reInviteUserSaga);
  yield takeLatest(
    fetchUsersWithMissingPayments,
    fetchUsersWithMissingPaymentsSaga
  );
  yield takeLatest(fetchRevenue, fetchRevenueSaga);
  yield takeLatest(reActivateUser, reActivateUserSaga);
}

export default userSaga;
