import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { JoinWaitlistModal } from "../JoinWaitlistModal/JoinWaitlistModal";

import logo from "../../assets/logo.svg";
import "./FooterComponent.css";

export const FooterComponent = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <JoinWaitlistModal showModal={showModal} setShowModal={setShowModal} />
      <Container fluid id="footer">
        <Row>
          <Col>
            <Container>
              <Row>
                <Col xs={12} md={6} className="footer-logo-col">
                  <img src={logo} alt="" />
                  <p className="footer-logo-text">
                    {process.env.REACT_APP_APP_NAME}
                  </p>
                </Col>
                <Col xs={12} md={6} className="footer-waitlist-col">
                  <a href="mailto:support@goactivedash.com">
                    support@goactivedash.com
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};
