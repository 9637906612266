import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { crmNoteSuccessfullyCreated } from "helpers/messages";
import { RootState } from "store";
import { CRMHistory, CRMNote, CreateCRMNote } from "types/crm";

type CRMState = {
  history: CRMHistory | undefined;
  notes: Array<CRMNote>;
  note: CRMNote | null;
  isLoading: boolean;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
};

const initialState: CRMState = {
  history: undefined,
  notes: [],
  note: null,
  isLoading: false,
  errorMessage: undefined,
  confirmationMessage: undefined,
};

const crmSlice = createSlice({
  name: "crm",
  initialState,
  reducers: {
    fetchUserCRMNotes(state: CRMState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    fetchUserCRMNotesSucceeded(
      state: CRMState,
      action: PayloadAction<Array<CRMNote>>
    ) {
      state.notes = action.payload;
      state.isLoading = false;
    },
    createUserCRMNote(
      state: CRMState,
      action: PayloadAction<CreateCRMNote & { id: string }>
    ) {
      state.isLoading = true;
    },
    createUserCRMNoteSucceeded(
      state: CRMState,
      action: PayloadAction<CRMNote>
    ) {
      state.note = action.payload;
      state.confirmationMessage = crmNoteSuccessfullyCreated;
      state.isLoading = false;
    },
    fetchCRMHistory(state: CRMState) {
      state.isLoading = true;
    },
    fetchCRMHistorySucceeded(
      state: CRMState,
      action: PayloadAction<CRMHistory>
    ) {
      state.history = action.payload;
      state.isLoading = false;
    },
    setErrorMessage(
      state: CRMState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: CRMState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
  },
});

export const {
  fetchUserCRMNotes,
  fetchUserCRMNotesSucceeded,
  createUserCRMNote,
  createUserCRMNoteSucceeded,
  fetchCRMHistory,
  fetchCRMHistorySucceeded,
  setErrorMessage,
  setConfirmationMessage,
} = crmSlice.actions;

export const selectCRMUserNotes = (state: RootState) => state.crmSlice.notes;
export const selectCRMHistory = (state: RootState) => state.crmSlice.history;
export const selectErrorMessage = (state: RootState) =>
  state.crmSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.crmSlice.confirmationMessage;
export const selectIsLoading = (state: RootState) => state.crmSlice.isLoading;

export default crmSlice.reducer;
