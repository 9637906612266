import { ReactElement, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BsFillXCircleFill, BsPencilSquare } from "react-icons/bs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import ReactGA from "react-ga4";

import {
  UpdateExerciseType,
  fetchExercises,
  selectExercises,
  updateExercise,
  createExercise,
  selectErrorMessage,
  selectConfirmationMessage,
  setErrorMessage,
  setConfirmationMessage,
} from "slices/exerciseSlice";
import {
  selectExerciseCategories,
  fetchExerciseCategories,
} from "slices/exerciseCategorySlice";
import { selectCurrentUser } from "slices/currentUserSlice";
import {
  Exercise,
  ExerciseCategory,
  isExerciseCreatedByCurrentUser,
} from "types/exercise";
import { SelectForm } from "components/SelectForm/SelectForm";
import { InputForm } from "components/InputForm/InputForm";
import { GButton } from "components/genericComponents/GButton/GButton";
import { DashboardTitle } from "components/DashboardTitle/DashboardTitle";
import { UserImageUpload } from "components/UserImageUpload/UserImageUpload";
import { getYoutubeIdFromUrl } from "helpers/utils";
import { muscleGroups } from "helpers/muscleGroups";

import "./ExercisePage.css";

export type SelectDataType = {
  value: string;
  label: string;
};

const initialUpdateExerciseState: UpdateExerciseType = {
  id: "",
  title: "",
  description: null,
  video_url: null,
  muscule_groups: [],
  exercise_category_id: null,
  image: null,
};

export const ExercisePage = () => {
  ReactGA.send("/workout-details");

  const dispatch = useDispatch();
  const exercises = useSelector(selectExercises);
  const exerciseId = window.location.href.split("/").slice(-1)[0];
  const exercise = exercises.find(
    (exercise: Exercise) => exercise.id === exerciseId
  );
  const [formData, setFormData] = useState<UpdateExerciseType>(
    initialUpdateExerciseState
  );
  const [isNotEditing, setIsNotEditing] = useState<boolean>(true);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const exerciseCategories = useSelector(selectExerciseCategories);
  const exerciseCategorySelected = exerciseCategories.find(
    (exerciseCategory: ExerciseCategory) =>
      formData.exercise_category_id === exerciseCategory.id
  );
  const [file, setFile] = useState<File | null>(null);
  const [imageKey, setImageKey] = useState<string | undefined>(undefined);
  const [categoryError, setCategoryError] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchExercises());
    dispatch(fetchExerciseCategories());
  }, [dispatch]);

  useEffect(() => {
    if (exercise !== undefined) {
      setFormData({
        id: exercise!.id,
        title: exercise!.title.trim(),
        description: exercise!.description!,
        video_url: exercise!.video_url!,
        muscule_groups: exercise!.muscules_groups ?? [],
        exercise_category_id: exercise!.exercise_category?.id ?? null,
        image: null,
      });
    }
    if (exerciseId === "add-exercise") {
      setIsNotEditing(false);
    }
  }, [exercise, exerciseId]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "exercise-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "exercise-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));

      navigate("../exercises");
    }
  }, [dispatch, confirmationMessage, navigate]);

  const onSubmitButtonClick = () => {
    if (!isCategoryValid()) return;

    const image =
      file === null
        ? null
        : {
            id: imageKey!,
            storage: "cache",
            metadata: {
              size: file.size,
              mime_type: file.type,
            },
          };

    if (exerciseId === "add-exercise") {
      dispatch(createExercise({ ...formData, image: image }));
    } else {
      dispatch(updateExercise({ ...formData, image: image }));
      setIsNotEditing(true);
    }
  };

  const isCategoryValid = (): boolean => {
    if (!formData.exercise_category_id) {
      setCategoryError("Insira a categoria");
      return false;
    }

    setCategoryError(null);
    return true;
  };

  return (
    <div id="workout-page">
      <DashboardTitle
        title={
          formData?.title !== null && formData?.title !== ""
            ? formData?.title
            : "Criar um exercício"
        }
        showAddButton={false}
      />
      <div id="workout-page-outer-container">
        <Container fluid className="workout-page-container">
          <Row>
            <Col className="workout-page-edit-training-plan-col">
              {exerciseId !== "add-exercise" &&
              isExerciseCreatedByCurrentUser(exercise, currentUser) ? (
                isNotEditing ? (
                  <Button
                    className="personal-data-edit-button"
                    onClick={() => setIsNotEditing(!isNotEditing)}
                  >
                    <BsPencilSquare />
                  </Button>
                ) : (
                  <Button
                    className="personal-data-edit-button-close"
                    onClick={() => setIsNotEditing(!isNotEditing)}
                  >
                    <BsFillXCircleFill />
                  </Button>
                )
              ) : undefined}
            </Col>
          </Row>
          <Row>
            <Col>
              <InputForm
                value={formData?.title}
                label="Título"
                disabled={isNotEditing}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    title: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={4}>
              <SelectForm
                options={exerciseCategories.map((exerciseCategory: any) => {
                  return {
                    id: exerciseCategory.id,
                    title: exerciseCategory.title,
                  };
                })}
                value={
                  exerciseCategorySelected === undefined ||
                  exerciseCategorySelected === null
                    ? undefined
                    : {
                        id: exerciseCategorySelected.id!,
                        title: exerciseCategorySelected.title!,
                      }
                }
                label="Categoria"
                onChange={(e: any) => {
                  setFormData({
                    ...formData,
                    exercise_category_id: e.value,
                  });
                  setCategoryError(null);
                }}
                disabled={isNotEditing}
                errorMessage={categoryError ?? undefined}
              />
            </Col>
            <Col xs={12} lg={4}>
              <SelectForm
                options={Object.keys(muscleGroups).map(
                  (muscleGroup: string) => {
                    return {
                      id: muscleGroup,
                      title: muscleGroups[muscleGroup].pt,
                    };
                  }
                )}
                multiValue={
                  formData.muscule_groups === null
                    ? undefined
                    : formData.muscule_groups.map((muscleGroup: string) => {
                        return {
                          id: muscleGroup,
                          title: muscleGroups[muscleGroup].pt,
                        };
                      })
                }
                label="Grupos de Músculos"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    muscule_groups:
                      e.length !== 0
                        ? e.map(
                            (obj: { value: string[]; label: ReactElement }) =>
                              obj.value
                          )
                        : null,
                  })
                }
                disabled={isNotEditing}
                isMultiSelect={true}
              />
            </Col>
            <Col xs={12} lg={4}>
              <InputForm
                value={formData?.video_url}
                label="Link do vídeo no Youtube"
                disabled={isNotEditing}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    video_url: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col className="submit-exercise-col">
              <UserImageUpload
                image={exercise?.image_large}
                file={file}
                setFile={setFile}
                setImageKey={setImageKey}
                showEditButton={!isNotEditing}
                isBigSquareImage={true}
              />
            </Col>
            <Col className="submit-exercise-col">
              <div className="exercise-page-youtube-video">
                {formData?.video_url !== null &&
                  formData?.video_url !== undefined && (
                    <YouTube
                      videoId={getYoutubeIdFromUrl(formData.video_url)}
                      opts={{ width: "400", height: "400" }}
                    />
                  )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="submit-exercise-col">
              {!isNotEditing && (
                <GButton label="Submeter" onClick={onSubmitButtonClick} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
