import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ExerciseCategory, ExerciseVariable } from "../types/exercise";
import {
  exerciseCategorySuccessfullyCreated,
  exerciseCategorySuccessfullyUpdated,
  exerciseCategorySuccessfullyDeleted,
} from "../helpers/messages";

interface ExerciseCategoryState {
  exerciseCategories: Array<ExerciseCategory>;
  isLoading: boolean;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
}

export type CreateExerciseCategoryType = {
  title: string;
  variables: Array<ExerciseVariable>;
};

export type UpdateExerciseCategoryType = {
  id: string;
  title: string;
  variables: Array<ExerciseVariable>;
};

const initialState: ExerciseCategoryState = {
  exerciseCategories: [],
  isLoading: false,
  errorMessage: undefined,
  confirmationMessage: undefined,
};

const exerciseCategorySlice = createSlice({
  name: "exerciseCategory",
  initialState: initialState,
  reducers: {
    fetchExerciseCategories(state: ExerciseCategoryState) {
      state.isLoading = true;
    },
    fetchExerciseCategoriesSucceeded(
      state: ExerciseCategoryState,
      action: PayloadAction<Array<ExerciseCategory>>
    ) {
      state.exerciseCategories = action.payload;
      state.isLoading = false;
    },
    createExerciseCategory(
      state: ExerciseCategoryState,
      action: PayloadAction<CreateExerciseCategoryType>
    ) {},
    createExerciseCategorySucceeded(
      state: ExerciseCategoryState,
      action: PayloadAction<ExerciseCategory>
    ) {
      return {
        ...state,
        confirmationMessage: exerciseCategorySuccessfullyCreated,
        exercises: [action.payload, ...state.exerciseCategories],
      };
    },
    updateExerciseCategory(
      state: ExerciseCategoryState,
      action: PayloadAction<UpdateExerciseCategoryType>
    ) {},
    updateExerciseCategorySucceeded(
      state: ExerciseCategoryState,
      action: PayloadAction<ExerciseCategory>
    ) {
      const index = state.exerciseCategories.findIndex(
        (exerciseCategory: ExerciseCategory) =>
          exerciseCategory.id === action.payload.id
      );

      state.exerciseCategories[index] = action.payload;
      state.confirmationMessage = exerciseCategorySuccessfullyUpdated;
    },
    deleteExerciseCategory(
      state: ExerciseCategoryState,
      action: PayloadAction<string>
    ) {},
    deleteExerciseCategorySucceeded(
      state: ExerciseCategoryState,
      action: PayloadAction<string>
    ) {
      const index = state.exerciseCategories.findIndex(
        (exerciseCategory: ExerciseCategory) =>
          exerciseCategory.id === action.payload
      );

      state.exerciseCategories.splice(index, 1);
      state.confirmationMessage = exerciseCategorySuccessfullyDeleted;
    },
    setErrorMessage(
      state: ExerciseCategoryState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: ExerciseCategoryState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
  },
});

export const {
  fetchExerciseCategories,
  fetchExerciseCategoriesSucceeded,
  createExerciseCategory,
  createExerciseCategorySucceeded,
  updateExerciseCategory,
  updateExerciseCategorySucceeded,
  deleteExerciseCategory,
  deleteExerciseCategorySucceeded,
  setErrorMessage,
  setConfirmationMessage,
} = exerciseCategorySlice.actions;

export const selectExerciseCategories = (state: RootState) =>
  state.exerciseCategorySlice.exerciseCategories;
export const selectIsLoading = (state: RootState) =>
  state.exerciseCategorySlice.isLoading;
export const selectErrorMessage = (state: RootState) =>
  state.exerciseCategorySlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.exerciseCategorySlice.confirmationMessage;

export default exerciseCategorySlice.reducer;
