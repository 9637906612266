import { SelectForm } from "components/SelectForm/SelectForm";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GModal } from "components/genericComponents/GModal/GModal";
import { useDispatch, useSelector } from "react-redux";

import "components/shared/UpdateAccountModal/UpdateAccountModal.scss";
import { useEffect, useState } from "react";
import { fetchUpgradablePlans, selectPlans } from "slices/companyPlanSlice";
import {
  updateCompany,
  fetchCurrentUser,
  selectCurrentUser,
  selectConfirmationMessage,
  setConfirmationMessage,
} from "slices/currentUserSlice";

type UpdateAccountModalType = {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
};

export const UpdateAccountModal = ({
  showModal,
  setShowModal,
}: UpdateAccountModalType) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const plans = useSelector(selectPlans);
  const [selectedPlan, setSelectedPlan] = useState<{
    id: string;
    title: string;
  }>();
  const [plansOptions, setPlansOptions] = useState<
    { id: string; title: string }[]
  >([]);
  const [selectedFrequency, setSelectedFrequency] = useState<{
    id: string;
    title: string;
  }>({ id: "monthly", title: "Mensal" });
  const [frequencyOptions, setFrequencyOptions] = useState<
    { id: string; title: string }[]
  >([
    { id: "monthly", title: "Mensal" },
    { id: "yearly", title: "Anual" },
  ]);
  const [price, setPrice] = useState("Free");
  const currentUser = useSelector(selectCurrentUser);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  useEffect(() => {
    dispatch(fetchUpgradablePlans());
  }, [dispatch]);

  useEffect(() => {
    if (confirmationMessage) {
      dispatch(setConfirmationMessage(undefined));
      dispatch(fetchCurrentUser(currentUser!.id));
      setShowModal(false);
    }
  }, [confirmationMessage, currentUser, dispatch, setShowModal]);

  useEffect(() => {
    if (plans && user) {
      setSelectedPlan({
        id: user!.company!.plan!.id,
        title: user!.company!.plan!.title,
      });
      if (user.company?.plan_frequency === "yearly") {
        setFrequencyOptions([{ id: "yearly", title: "Anual" }]);
        setSelectedFrequency({ id: "yearly", title: "Anual" });
      }
      const tempPlansOptions = plans.monthly.plans.map((plan) => ({
        id: plan.id,
        title: plan.description,
      }));
      setPlansOptions(tempPlansOptions);
      setPrice(`${user!.company!.plan_price}€`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans, user]);

  const handleConfirmUpdateAccount = () => {
    dispatch(
      updateCompany({
        id: currentUser!.company!.id!,
        company_plan_id: selectedPlan!.id,
        plan_frequency: selectedFrequency.id,
      })
    );
  };

  const handleNewPlan = (newPlan: string) => {
    setSelectedPlan({
      id: newPlan,
      title: plans!.monthly.plans.find((plan) => plan.id === newPlan)!
        .description,
    });
    if (selectedFrequency.id === "monthly") {
      setPrice(plans!.monthly.plans.find((plan) => plan.id === newPlan)!.cost);
    } else {
      const yearlyPlanPriceStr = plans!.yearly.plans.find(
        (plan) => plan.id === newPlan
      )!.cost;
      const yearlyPlanPriceNumber =
        parseFloat(
          yearlyPlanPriceStr.substring(0, yearlyPlanPriceStr.length - 1)
        ) * 12;
      setPrice(`${yearlyPlanPriceNumber.toFixed(2)}€`);
    }
  };

  const handleNewFreq = (newFreq: string) => {
    setSelectedFrequency(frequencyOptions.find((freq) => freq.id === newFreq)!);
    if (newFreq === "monthly") {
      setPrice(
        plans!.monthly.plans.find((plan) => plan.id === selectedPlan!.id)!.cost
      );
    } else {
      const yearlyPlanPriceStr = plans!.yearly.plans.find(
        (plan) => plan.id === selectedPlan!.id
      )!.cost;
      const yearlyPlanPriceNumber =
        parseFloat(
          yearlyPlanPriceStr.substring(0, yearlyPlanPriceStr.length - 1)
        ) * 12;
      setPrice(`${yearlyPlanPriceNumber.toFixed(2)}€`);
    }
  };

  return (
    <GModal
      showModalState={showModal}
      setShowModalState={(val) => setShowModal(val)}
      buttons={[
        <GButton
          key="confirm"
          label="Confirmar"
          onClick={handleConfirmUpdateAccount}
        />,
        <GButton
          key="cancel"
          label="Cancelar"
          onClick={() => setShowModal(false)}
          variant="secondary"
        />,
      ]}
    >
      <div className="update-account-modal">
        <h3>Atualizar plano</h3>
        <p className="update-account-title">Plano atual</p>
        <p className="current-plan">{`Plano ${
          user &&
          user.company &&
          plans &&
          user.company.plan_description.toLowerCase()
        }`}</p>
        <p className="update-account-title">Frequência</p>
        <SelectForm
          value={{
            id: selectedFrequency.id,
            title: selectedFrequency.title,
          }}
          label={"Frequência"}
          options={frequencyOptions}
          onChange={(e: any) => handleNewFreq(e.value)}
        />
        <p className="update-account-title">Novo plano</p>
        <SelectForm
          value={{
            id: selectedPlan ? selectedPlan.id : "",
            title: selectedPlan ? selectedPlan.title : "",
          }}
          label={"Novo plano"}
          options={plansOptions}
          onChange={(e: any) => handleNewPlan(e.value)}
        />
        <p className="update-account-title">Preço</p>
        <p className="price">{price}</p>
      </div>
    </GModal>
  );
};
