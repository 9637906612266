import { GSection } from "components/genericComponents/GSection/GSection";
import { format } from "date-fns";
import { CRMNote } from "types/crm";
import { User } from "types/user";

import "components/DashboardCustomersPage/CRM/CRMCreatedNote/CRMCreatedNote.scss";
import { useNavigate } from "react-router-dom";

type CRMCreatedNoteProps = {
  note: CRMNote;
  user?: User;
};

export const CRMCreatedNote = ({ note, user }: CRMCreatedNoteProps) => {
  const navigate = useNavigate();

  const title = () => {
    if (user) {
      return `${user.name} - ${format(
        new Date(note.created_at),
        "hh:mm:ss dd/MM/yyyy"
      )}`;
    }

    return format(new Date(note.created_at), "hh:mm:ss dd/MM/yyyy");
  };

  const classNames = () => {
    let names = "crm-note";
    if (user) names = names + " user";
    if (note.type === "UserPayment") names = names + " user-payment";
    return names;
  };

  const handleClick = () => {
    if (user) {
      navigate(`/dashboard/customers/${user.id}/crm`);
    }
  };

  return (
    <GSection className={classNames()} onClick={handleClick}>
      <p className="title">{title()}</p>
      <p className="note">{note.note}</p>
    </GSection>
  );
};
