import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchWorkoutHistorySucceeded,
  createWorkoutsHistorySucceeded,
  CreateWorkoutHistoryType,
  setErrorMessage,
} from "../../slices/workoutHistorySlice";
import { put, call } from "redux-saga/effects";
import {
  fetchWorkoutHistoryRequest,
  createWorkoutHistoryRequest,
  WorkoutsHistoryRequestResponseType,
} from "../../requests/userWorkoutsHistoryRequests";

export function* fetchWorkoutsHistorySaga(
  action: PayloadAction<string>,
  request = fetchWorkoutHistoryRequest
) {
  const response: WorkoutsHistoryRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchWorkoutHistorySucceeded(response.workouts_history!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createWorkoutsHistorySaga(
  action: PayloadAction<CreateWorkoutHistoryType>,
  request = createWorkoutHistoryRequest
) {
  const response: WorkoutsHistoryRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createWorkoutsHistorySucceeded());
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
