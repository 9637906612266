import { Col, Row } from "react-bootstrap";

import { ExercisesCategoriesSettings } from "./ExercisesCategoriesSettings/ExercisesCategoriesSettings";

import "./ExercisesSettings.css";

export const ExercisesSettings = () => {
  return (
    <div id="exercises-settings">
      <Row className="exercises-settings-row">
        <Col className="exercises-settings-col">
          <ExercisesCategoriesSettings />
        </Col>
      </Row>
    </div>
  );
};
