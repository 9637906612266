import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";

import "./Welcome.css";
import appLandingPage from "../../../assets/web&phone.png";
import { JoinWaitlistModal } from "../../../components/JoinWaitlistModal/JoinWaitlistModal";
import { useTranslation } from "react-i18next";
import { SignInModal } from "components/SignInModal/SignInModal";

export const Welcome = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showSignInModal, setSignInModal] = useState(false);

  return (
    <Container className="welcome-container">
      <JoinWaitlistModal showModal={showModal} setShowModal={setShowModal} />
      <Row className="welcome-text-row">
        <Col className="welcome-text-col">
          <h1 className="welcome-title">
            {t("landingPage.welcomeComponent.title")}
          </h1>
          <p className="welcome-text">
            {t("landingPage.welcomeComponent.subtitle")}
          </p>
          <div className="welcome-demo-btn-div">
            <Button
              className="welcome-demo-btn"
              onClick={() => setSignInModal(true)}
            >
              {t("landingPage.welcomeComponent.joinWaitlistModal.tryNow")}
            </Button>
            <Button
              style={{
                backgroundColor: "white",
                color: "black",
                width: 200,
                border: 0,
              }}
              onClick={() => setShowModal(true)}
            >
              {t("landingPage.welcomeComponent.joinWaitlistModal.askDemo")}
            </Button>
          </div>
        </Col>
        <Col className="welcome-img-col">
          <img src={appLandingPage} className="welcome-img" alt="welcome" />
        </Col>
      </Row>
      {showSignInModal ? (
        <SignInModal
          showSignInModal={showSignInModal}
          setShowSignInModal={setSignInModal}
          isMonthly={true}
        />
      ) : undefined}
    </Container>
  );
};
