import React from "react";
import Select, { createFilter } from "react-select";

type Option = {
  id: string | number;
  title: string | number;
  image?: string;
};

type InputFormProps = {
  label: string;
  value?: Option | undefined;
  multiValue?: Array<Option> | undefined;
  onChange?: any;
  disabled?: boolean;
  options: Array<Option>;
  hasImage?: boolean;
  isMultiSelect?: boolean;
  isClearable?: boolean;
  className?: string;
  height?: string;
  errorMessage?: string;
};

export const SelectForm = ({
  value,
  multiValue,
  label,
  onChange = () => {},
  disabled = false,
  options,
  hasImage = false,
  isMultiSelect = false,
  isClearable = false,
  className,
  height = "3.5rem",
  errorMessage = undefined,
}: InputFormProps) => {
  const valueLabel = (option: Option | undefined) => {
    if (option === undefined) return undefined;

    return {
      value: option.id,
      title: option.title,
      label: (
        <div>
          {hasImage ? (
            <img
              src={
                option.image ??
                "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
              }
              alt="something"
              height="30px"
              width="30px"
              className="select-img"
            />
          ) : undefined}{" "}
          {option.title}
        </div>
      ),
    };
  };

  const multiValueLabel = () => {
    return multiValue?.map((option: Option) => valueLabel(option));
  };

  return (
    <div className={className} style={{ marginBottom: "1rem" }}>
      <Select
        isSearchable
        filterOption={createFilter({
          ignoreCase: true,
          ignoreAccents: true,
          matchFrom: "any",
          stringify: (option) => `${option.data?.title}`,
          trim: true,
        })}
        isClearable={isClearable}
        isMulti={isMultiSelect}
        placeholder={label}
        isDisabled={disabled}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: `calc(${height} + 2px)`,
            border: "1px solid #ced4da",
            borderRadius: "0.375rem",
          }),
        }}
        value={isMultiSelect ? multiValueLabel() : valueLabel(value)}
        onChange={onChange}
        options={options.map((option: Option) => valueLabel(option))}
      />
      <div className={className}>
        {errorMessage !== undefined ? (
          <p className="form-error-message">{errorMessage}</p>
        ) : undefined}
      </div>
    </div>
  );
};
