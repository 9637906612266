import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { MdAdd, MdDeleteForever } from "react-icons/md";
import TimePicker from "react-time-picker";

import {
  CreateOrUpdateWorkoutType,
  CreateWorkoutExercisesType,
  CreateWorkoutSetsType,
  CreateWorkoutVariablesType,
} from "../../../../slices/workoutSlice";
import { SelectDataType } from "../WorkoutPage";
import { InputForm } from "../../../InputForm/InputForm";
import { TextAreaForm } from "../../../TextAreaForm/TextAreaForm";

import "./WorkoutExercises.css";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { secondsToTime } from "../../../../helpers/date";
import { LooseValue } from "react-time-picker/dist/cjs/shared/types";
import { GChip } from "components/genericComponents/GChip/GChip";

type WorkoutExercisesProps = {
  workoutExercise: CreateWorkoutExercisesType;
  exerciseIndex: number;
  isNotEditing: boolean;
  onExerciseSelect: (e: SelectDataType, exerciseIndex: number) => void;
  onExerciseNotesChange: (e: any, exerciseIndex: number) => void;
  removeExercise: (exerciseIndex: number) => void;
  formData: CreateOrUpdateWorkoutType;
  addSetToExercise: (exerciseIndex: number) => void;
  removeSetFromExercise: (
    exerciseIndex: number,
    workoutExercisesIdx: number
  ) => void;
  setCurrentExerciseIndex: Dispatch<SetStateAction<number | undefined>>;
  setPickingExercises: Dispatch<SetStateAction<boolean>>;
  onVariableSetValue: (
    exerciseIndex: number,
    setIndex: number,
    variableId: string,
    value: string | null
  ) => void;
  copyFirstSetForTheOthers: (exerciseIndex: number) => void;
};

export const WorkoutExercises = ({
  workoutExercise,
  exerciseIndex,
  isNotEditing,
  onExerciseNotesChange,
  onExerciseSelect,
  removeExercise,
  formData,
  addSetToExercise,
  removeSetFromExercise,
  setCurrentExerciseIndex,
  setPickingExercises,
  onVariableSetValue,
  copyFirstSetForTheOthers,
}: WorkoutExercisesProps) => {
  const exerciseVariables =
    workoutExercise.exercise.exercise_category?.variables ?? [];
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Row className="exercises-container">
      <Col>
        <div className="exercises-container-title">
          <Col className="exercises-container-title-col">
            <h5>{workoutExercise.exercise.title}</h5>
            <GChip
              label={
                workoutExercise.exercise?.exercise_category?.title ??
                "Sem Categoria"
              }
              variant="primary"
            />
          </Col>
          {!isNotEditing ? (
            <Col className="exercises-container-title-col right">
              <Button
                className="personal-data-submit-button-close"
                onClick={() => copyFirstSetForTheOthers(exerciseIndex)}
              >
                Copiar o primeiro set para os restantes
              </Button>
            </Col>
          ) : undefined}
          <div>
            {!isNotEditing ? (
              <Button
                className="exercises-container-button-close"
                onClick={() => removeExercise(exerciseIndex)}
              >
                <MdDeleteForever />
              </Button>
            ) : undefined}
          </div>
          <div className="carets-div">
            {isOpen ? (
              <BsFillCaretUpFill
                onClick={() => setIsOpen(false)}
                className="caret-icon"
              />
            ) : (
              <BsFillCaretDownFill
                onClick={() => setIsOpen(true)}
                className="caret-icon"
              />
            )}
          </div>
        </div>
        {workoutExercise.exercise!.exercise_category === null && (
          <p>
            (Para definir séries atríbua uma categoria ao exercício na página
            dos exercícios)
          </p>
        )}
        {
          //  workoutExercise.volume !== null ? (
          //  <p className="exercises-container-title-volume">{`Volume: ${exercise.volume} kg`}</p>
          //) : undefined
        }
        <div
          className={`exercises-top-container-sets${!isOpen ? " closed" : ""}`}
        >
          {workoutExercise.sets?.map(
            (set: CreateWorkoutSetsType, setIdx: number) => {
              return (
                <div className="exercises-container-sets-container">
                  <Col
                    xs={12}
                    lg
                    className="exercises-container-sets-text exercises-container-sets-cols"
                  >
                    <p className="exercises-container-sets-set-title">
                      Set {setIdx + 1}
                    </p>
                  </Col>
                  <Row className="exercises-container-sets-row" key={setIdx}>
                    <Col className="exercises-container-sets-cols">
                      {set.variables.map(
                        (
                          variableType: CreateWorkoutVariablesType,
                          index: number
                        ) => {
                          const variable = exerciseVariables[index];

                          if (variable === undefined) return undefined;

                          return (
                            <>
                              {variable.is_duration ||
                              variable.is_rest_before_next_set ? (
                                <div>
                                  <p className="variable-title">
                                    {variable.title}
                                  </p>
                                  <TimePicker
                                    disabled={isNotEditing}
                                    disableClock
                                    className="variable-time-input"
                                    value={
                                      (variableType.value !== null
                                        ? [variableType.value].map((val) => {
                                            return secondsToTime(val);
                                          })
                                        : "0:0:0") as LooseValue
                                    }
                                    format="H:m:s"
                                    maxDetail="second"
                                    onChange={(value) => {
                                      if (value !== null) {
                                        const timeArr = value.split(":");
                                        var hours = Number(timeArr[0]);
                                        var minutes = Number(timeArr[1]);
                                        var seconds = Number(timeArr[2]);

                                        const timeInSeconds = `${
                                          hours * 3600 + minutes * 60 + seconds
                                        }`;

                                        onVariableSetValue(
                                          exerciseIndex,
                                          setIdx,
                                          variableType.id,
                                          timeInSeconds
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              ) : (
                                <div>
                                  <p className="variable-title">
                                    {variable.title}
                                  </p>
                                  <InputForm
                                    value={variableType.value}
                                    type="text"
                                    disabled={isNotEditing}
                                    onChange={(e: any) => {
                                      onVariableSetValue(
                                        exerciseIndex,
                                        setIdx,
                                        variableType.id,
                                        e.target.value
                                      );
                                    }}
                                    className="profile-input"
                                  />
                                </div>
                              )}
                            </>
                          );
                        }
                      )}
                      {!isNotEditing ? (
                        <Col xs={12} lg className="remove-set-col">
                          {
                            <Button
                              className="exercises-container-button-close"
                              onClick={() =>
                                removeSetFromExercise(exerciseIndex, setIdx)
                              }
                            >
                              <MdDeleteForever />
                            </Button>
                          }
                        </Col>
                      ) : undefined}
                    </Col>
                  </Row>
                </div>
              );
            }
          )}
          {!isNotEditing &&
          workoutExercise.exercise!.exercise_category !== null ? (
            <Row>
              <Col className="add-set-col">
                <p>Adicionar Set</p>
                {
                  <Button
                    className="dashboard-title-btn-small"
                    onClick={() => addSetToExercise(exerciseIndex)}
                  >
                    <MdAdd />
                  </Button>
                }
              </Col>
            </Row>
          ) : undefined}
          <TextAreaForm
            label="Notas"
            value={formData.workout_exercises![exerciseIndex].notes}
            onChange={(e: any) => onExerciseNotesChange(e, exerciseIndex)}
            disabled={isNotEditing}
          />
        </div>
      </Col>
    </Row>
  );
};
