import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchWorkoutSchedulesRequest,
  createWorkoutSchedulesRequest,
  updateWorkoutSchedulesRequest,
  deleteWorkoutSchedulesRequest,
  WorkoutScheduleRequestResponseType,
} from "../../requests/workoutScheduleRequests";
import { put, call } from "redux-saga/effects";
import {
  fetchWorkoutSchedulesSucceeded,
  createWorkoutSchedulesSucceeded,
  updateWorkoutSchedulesSucceeded,
  deleteWorkoutScheduleSucceeded,
  setErrorMessage,
} from "../../slices/workoutScheduleSlice";
import {
  CreateWorkoutScheduleType,
  UpdateWorkoutScheduleType,
} from "../../slices/workoutScheduleSlice";

export function* fetchWorkoutSchedulesSaga(
  action: PayloadAction<string>,
  request = fetchWorkoutSchedulesRequest
) {
  const response: WorkoutScheduleRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchWorkoutSchedulesSucceeded(response.workoutSchedules!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createWorkoutSchedulesSaga(
  action: PayloadAction<CreateWorkoutScheduleType>,
  request = createWorkoutSchedulesRequest
) {
  const response: WorkoutScheduleRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createWorkoutSchedulesSucceeded(response.workoutSchedule!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateWorkoutSchedulesSaga(
  action: PayloadAction<UpdateWorkoutScheduleType>,
  request = updateWorkoutSchedulesRequest
) {
  const response: WorkoutScheduleRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateWorkoutSchedulesSucceeded(response.workoutSchedule!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deleteWorkoutSchedulesSaga(
  action: PayloadAction<string>,
  request = deleteWorkoutSchedulesRequest
) {
  const response: WorkoutScheduleRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteWorkoutScheduleSucceeded(action.payload));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
