import { Button, Col, Container, Row } from "react-bootstrap";

import { GButton } from "components/genericComponents/GButton/GButton";
import { useEffect, useState } from "react";
import { BsFillXCircleFill, BsPencilSquare } from "react-icons/bs";
import { MdAdd, MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchGymRooms,
  selectConfirmationMessage,
  selectErrorMessage,
  selectGymRooms,
  setConfirmationMessage,
  setErrorMessage,
  setGymRooms,
} from "../../../../slices/gymRoomSlice";
import { GymRoom } from "../../../../types/gymRoom";
import { InputForm } from "../../../InputForm/InputForm";
import "./GymRoomsSettings.css";

export const GymRoomsSettings = () => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const gymRooms = useSelector(selectGymRooms);
  const [rooms, setRooms] = useState<GymRoom[]>([]);
  const [showGymRoomTitleErrors, setShowGymRoomTitleErrors] = useState(false);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  const addRoom = () => {
    setRooms([{ id: null, title: null }, ...rooms]);
  };

  const onSubmitClick = () => {
    if (
      rooms.find(
        (room: GymRoom) => room.title === null || room.title === ""
      ) !== undefined
    ) {
      setShowGymRoomTitleErrors(true);
      return;
    }

    dispatch(
      setGymRooms(
        rooms.map((room: GymRoom) => {
          return { id: room.id, title: room.title! };
        })
      )
    );
    setIsEditing(false);
  };

  const onDeleteClick = (index: number) => {
    const tempRooms: GymRoom[] = JSON.parse(JSON.stringify(rooms));
    tempRooms.splice(index, 1);
    setRooms(tempRooms);
  };

  useEffect(() => {
    dispatch(fetchGymRooms());
  }, [dispatch]);

  useEffect(() => {
    setRooms(gymRooms);
  }, [gymRooms]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "gym-rooms-settings-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "gym-rooms-settings-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  return (
    <Container fluid id="gym-variables-settings">
      <Row>
        <Col className="gym-variables-title-col">
          <div className="gym-variables-title-div">
            <h4>Divisões do ginásio</h4>
            {isEditing && (
              <Button
                className="gym-variables-title-btn"
                onClick={() => addRoom()}
              >
                <MdAdd />
              </Button>
            )}
          </div>
          {isEditing ? (
            <Button
              className="gym-variables-edit-button"
              onClick={() => setIsEditing(false)}
            >
              <BsFillXCircleFill />
            </Button>
          ) : (
            <Button
              className="gym-variables-edit-button"
              onClick={() => setIsEditing(true)}
            >
              <BsPencilSquare />
            </Button>
          )}
        </Col>
      </Row>
      <Row className="gym-variables-settings-row">
        <Col className="gym-variables-settings-col">
          {rooms.map((room, index) => {
            let roomTitleErrorMessage;

            if (
              showGymRoomTitleErrors &&
              (room.title === null || room.title === "")
            ) {
              roomTitleErrorMessage = "Insira um título para a divisão";
            } else {
              roomTitleErrorMessage = null;
            }
            return (
              <div className="gym-variables-settings-btn-div">
                <div className="gym-variables-settings-btn-inner-div">
                  <InputForm
                    className="profile-input"
                    value={room.title || ""}
                    label="Nome da divisão"
                    onChange={(e: any) => {
                      setRooms(
                        rooms.map((room: GymRoom, roomIndex: number) => {
                          if (roomIndex === index)
                            return { ...room, title: e.target.value };
                          else return room;
                        })
                      );
                    }}
                    disabled={!isEditing}
                    errorMessage={roomTitleErrorMessage}
                  />
                </div>
                {isEditing && (
                  <Button
                    className="gym-variables-delete-button"
                    onClick={() => onDeleteClick(index)}
                  >
                    <MdDeleteForever />
                  </Button>
                )}
              </div>
            );
          })}
        </Col>
      </Row>
      {isEditing && (
        <Row className="gym-variables-submit-row">
          <Col className="gym-variables-submit-col">
            <GButton label="Submeter" onClick={() => onSubmitClick()} />
          </Col>
        </Row>
      )}
    </Container>
  );
};
