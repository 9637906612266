import { takeLatest } from "redux-saga/effects";
import {
  fetchPaymentsPackagesSaga,
  createPaymentsPackageSaga,
  updatePaymentsPackageSaga,
  deletePaymentsPackageSaga,
  setUserPaymentsPackageSaga,
} from "sagas/paymentsPackagesSaga/paymentsPackagesSaga";
import {
  createPaymentsPackage,
  deletePaymentsPackage,
  fetchPaymentsPackages,
  setUserPaymentsPackage,
  updatePaymentsPackage,
} from "slices/paymentsPackagesSlice";

function* paymentsPackagesSaga() {
  yield takeLatest(fetchPaymentsPackages, fetchPaymentsPackagesSaga);
  yield takeLatest(createPaymentsPackage, createPaymentsPackageSaga);
  yield takeLatest(updatePaymentsPackage, updatePaymentsPackageSaga);
  yield takeLatest(deletePaymentsPackage, deletePaymentsPackageSaga);
  yield takeLatest(setUserPaymentsPackage, setUserPaymentsPackageSaga);
}

export default paymentsPackagesSaga;
