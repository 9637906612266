import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { insuranceSuccessfullyUpdated } from "helpers/messages";
import { RootState } from "store";
import {
  Insurance,
  UpdateUserInsurancePayload,
  UserInsurance,
  UserInsurancePayload,
} from "types/insurance";

type InsuranceState = {
  insurance: Insurance | undefined;
  userInsurance: UserInsurance | undefined;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
};

const initialState: InsuranceState = {
  insurance: undefined,
  userInsurance: undefined,
  errorMessage: undefined,
  confirmationMessage: undefined,
};

const InsuranceSlice = createSlice({
  name: "insurance",
  initialState: initialState,
  reducers: {
    fetchInsurance(state: InsuranceState) {},
    fetchInsuranceSucceeded(
      state: InsuranceState,
      action: PayloadAction<Insurance>
    ) {
      state.insurance = action.payload;
    },
    setInsurance(state: InsuranceState, action: PayloadAction<number>) {},
    setInsuranceSucceeded(
      state: InsuranceState,
      action: PayloadAction<Insurance>
    ) {
      state.insurance = action.payload;
      state.confirmationMessage = insuranceSuccessfullyUpdated;
    },
    fetchUserInsurance(state: InsuranceState, action: PayloadAction<string>) {},
    fetchUserInsuranceSucceeded(
      state: InsuranceState,
      action: PayloadAction<UserInsurance>
    ) {
      if (action.payload !== null) state.userInsurance = action.payload;
    },
    setUserInsurance(
      state: InsuranceState,
      action: PayloadAction<UserInsurancePayload>
    ) {},
    setUserInsuranceSucceeded(
      state: InsuranceState,
      action: PayloadAction<UserInsurance>
    ) {
      state.userInsurance = action.payload;
      state.confirmationMessage = insuranceSuccessfullyUpdated;
    },
    updateUserInsurance(
      state: InsuranceState,
      action: PayloadAction<UpdateUserInsurancePayload>
    ) {},
    updateUserInsuranceSucceeded(
      state: InsuranceState,
      action: PayloadAction<UserInsurance>
    ) {
      state.userInsurance = action.payload;
      state.confirmationMessage = insuranceSuccessfullyUpdated;
    },
    setErrorMessage(
      state: InsuranceState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: InsuranceState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
    resetInsuranceState(state: InsuranceState) {
      state.insurance = undefined;
      state.userInsurance = undefined;
      state.errorMessage = undefined;
      state.confirmationMessage = undefined;
    },
  },
});

export const {
  fetchInsurance,
  fetchInsuranceSucceeded,
  setInsurance,
  setInsuranceSucceeded,
  fetchUserInsurance,
  fetchUserInsuranceSucceeded,
  setUserInsurance,
  setUserInsuranceSucceeded,
  updateUserInsurance,
  updateUserInsuranceSucceeded,
  setErrorMessage,
  setConfirmationMessage,
  resetInsuranceState,
} = InsuranceSlice.actions;

export const selectUserInsurance = (state: RootState) =>
  state.insuranceSlice.userInsurance;
export const selectInsurance = (state: RootState) =>
  state.insuranceSlice.insurance;
export const selectInsurancePrice = (state: RootState) =>
  state.insuranceSlice.insurance?.price;
export const selectErrorMessage = (state: RootState) =>
  state.insuranceSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.insuranceSlice.confirmationMessage;

export default InsuranceSlice.reducer;
