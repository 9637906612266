import { axiosRequest, errorMessage } from "./request";
import { User } from "../types/user";
import {
  LoginType,
  LogoutType,
  CreateAccountType,
} from "../slices/currentUserSlice";

export type LoginRequestResponseType = {
  succeeded: boolean;
  user: User | undefined;
  errorMessage: string | undefined;
};

export const loginRequest = async (
  params: LoginType
): Promise<LoginRequestResponseType> => {
  try {
    const response = await axiosRequest().post("login_personal_trainer", {
      email: params.email,
      password: params.password,
    });

    const user = response.data.user as User;
    const token = response.data.token as string;

    localStorage.setItem("token", token);

    return {
      succeeded: true,
      user: user,
    } as LoginRequestResponseType;
  } catch (e) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as LoginRequestResponseType;
  }
};

export const createAccountRequest = async (
  params: CreateAccountType
): Promise<LoginRequestResponseType> => {
  try {
    await axiosRequest().post("personal_trainer", {
      email: params.email,
      name: params.name,
      company_plan_id: params.company_plan_id,
      plan_frequency: params.plan_frequency,
      code: params.code,
    });

    return {
      succeeded: true,
    } as LoginRequestResponseType;
  } catch (e) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as LoginRequestResponseType;
  }
};

export const logoutRequest = async (
  params: LogoutType
): Promise<LoginRequestResponseType> => {
  try {
    const response = await axiosRequest().post("logout", {
      email: params.email,
    });
    const user = response.data.user as User;

    return {
      succeeded: true,
      user: user,
    } as LoginRequestResponseType;
  } catch (e) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as LoginRequestResponseType;
  }
};
