import { PayloadAction } from "@reduxjs/toolkit";
import { put, call } from "redux-saga/effects";

import {
  InsuranceRequestResponseType,
  fetchInsuranceRequest,
  fetchUserInsuranceRequest,
  setInsuranceRequest,
  setUserInsuranceRequest,
  updateUserInsuranceRequest,
} from "requests/insuranceRequests";
import {
  fetchInsuranceSucceeded,
  fetchUserInsuranceSucceeded,
  setErrorMessage,
  setInsuranceSucceeded,
  setUserInsuranceSucceeded,
  updateUserInsuranceSucceeded,
} from "slices/insuranceSlice";
import {
  UpdateUserInsurancePayload,
  UserInsurancePayload,
} from "types/insurance";

export function* fetchInsuranceSaga(
  action: any,
  request = fetchInsuranceRequest
) {
  const response: InsuranceRequestResponseType = yield call(request);

  if (response.insurance !== undefined) {
    yield put(fetchInsuranceSucceeded(response.insurance));
  }
}

export function* setInsuranceSaga(
  action: PayloadAction<number>,
  request = setInsuranceRequest
) {
  const response: InsuranceRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(setInsuranceSucceeded(response.insurance!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchUserInsuranceSaga(
  action: PayloadAction<string>,
  request = fetchUserInsuranceRequest
) {
  const response: InsuranceRequestResponseType = yield call(
    request,
    action.payload
  );

  if (
    response.userInsurance &&
    Object.keys(response.userInsurance).length > 0
  ) {
    yield put(fetchUserInsuranceSucceeded(response.userInsurance));
  }
}

export function* setUserInsuranceSaga(
  action: PayloadAction<UserInsurancePayload>,
  request = setUserInsuranceRequest
) {
  const response: InsuranceRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(setUserInsuranceSucceeded(response.userInsurance!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateUserInsuranceSaga(
  action: PayloadAction<UpdateUserInsurancePayload>,
  request = updateUserInsuranceRequest
) {
  const response: InsuranceRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateUserInsuranceSucceeded(response.userInsurance!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
