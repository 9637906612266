import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchCoursesSucceeded,
  setErrorMessage,
  createCourseSucceeded,
  updateCourseSucceeded,
  CreateCourseType,
  UpdateCourseType,
  deleteCourseSucceeded,
} from "../../slices/courseSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchCoursesRequest,
  CourseRequestResponseType,
  createCourseRequest,
  updateCourseRequest,
  deleteCourseRequest,
} from "../../requests/courseRequests";

export function* fetchCoursesSaga(action: any, request = fetchCoursesRequest) {
  const response: CourseRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchCoursesSucceeded(response.courses!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createCourseSaga(
  action: PayloadAction<CreateCourseType>,
  request = createCourseRequest
) {
  const response: CourseRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createCourseSucceeded(response.course!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateCourseSaga(
  action: PayloadAction<UpdateCourseType>,
  request = updateCourseRequest
) {
  const response: CourseRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateCourseSucceeded(response.course!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deleteCourseSaga(
  action: PayloadAction<string>,
  request = deleteCourseRequest
) {
  const response: CourseRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteCourseSucceeded(action.payload));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
