import { AddIngredientModal } from "components/NutritionPage/AddIngredientModal/AddIngredientModal";
import { TableComponent } from "components/TableComponent/TableComponent";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GInput } from "components/genericComponents/GInput/GInput";
import { GModal } from "components/genericComponents/GModal/GModal";
import { GSection } from "components/genericComponents/GSection/GSection";
import { useState } from "react";
import { FaChevronDown, FaChevronUp, FaTrash } from "react-icons/fa6";
import { Food, FoodCharacteristicsWithoutWeight } from "types/nutrition";

import "components/NutritionPage/Diets/DietMeal/DietMeal.scss";
import { MealItem } from "types/diet";
import tinycolor from "tinycolor2";
import { useDispatch, useSelector } from "react-redux";
import { selectAllIngredientsInADiet, selectDiet, deleteMeal, setMeal } from "slices/dietSlice";

type DietMealProps = {
  index: number;
  isEditing?: boolean;
};

export const DietMeal = ({ index, isEditing = false }: DietMealProps) => {
  const dispatch = useDispatch();
  const diet = useSelector(selectDiet);
  const allIngredientsInADiet = useSelector(selectAllIngredientsInADiet);
  const [showModal, setShowModal] = useState(false);
  const [showWeightModal, setShowWeightModal] = useState(false);
  const [activeWeightIndex, setActiveWeightIndex] = useState<number>(0);
  const [showMeal, setShowMeal] = useState(isEditing);
  const [activeIngredientWeight, setActiveIngredientWeight] = useState<number | undefined>(undefined);
  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      width: 400,
      Cell: (props: any) => {
        return <p style={{ margin: 0 }}>{props.row.original.item.name}</p>;
      },
    },
    {
      Header: "Peso (g)",
      accessor: "weight_in_g",
      Cell: (props: any) => {
        return (
          <div className="weight-input-div">
            <div>
              <GInput
                key={`weight-input-${props.row.index}`}
                type="number"
                className="weight-input"
                disabled
                value={diet.meals[index].meal_items[props.row.index].weight_in_g ?? 0}
              />
            </div>
            <GButton
              label="Editar"
              onClick={() => {
                setActiveWeightIndex(props.row.index);
                setActiveIngredientWeight(diet.meals[index].meal_items[props.row.index].weight_in_g);
                setShowWeightModal(true);
              }}
              disabled={!isEditing}
            />
          </div>
        );
      },
    },
    {
      Header: "Calorias",
      accessor: "calories",
      Cell: (props: any) => {
        const ingredient = diet.meals[index].meal_items[props.row.index].item as Food;
        if (ingredient) {
          return `${ingredient.calories.toFixed(0)}kcal`;
        }
        return "0kcal";
      },
    },
    {
      Header: "Proteínas",
      accessor: "protein",
      Cell: (props: any) => {
        const ingredient = diet.meals[index].meal_items[props.row.index].item as Food;
        if (ingredient) {
          return `${ingredient.protein.toFixed(0)}g`;
        }
        return "0g";
      },
    },
    {
      Header: "Hidratos",
      accessor: "carbs",
      Cell: (props: any) => {
        const ingredient = diet.meals[index].meal_items[props.row.index].item as Food;
        if (ingredient) {
          return `${ingredient.carbs.toFixed(0)}g`;
        }
        return "0g";
      },
    },
    {
      Header: "Lipídos",
      accessor: "lipids",
      Cell: (props: any) => {
        const ingredient = diet.meals[index].meal_items[props.row.index].item as Food;
        if (ingredient) {
          return `${ingredient.lipids.toFixed(0)}g`;
        }
        return "0g";
      },
    },
  ];

  return (
    <GSection
      label={diet.meals[index].title ? diet.meals[index].title : `Refeição ${index + 1}`}
      isSpacedBetween
      renderButtons={[
        ...(isEditing
          ? [
              <GButton
                label="Adicionar Ingredientes"
                onClick={() => {
                  setShowModal(true);
                }}
              />,
            ]
          : []),
        ...(isEditing
          ? [
              <GButton
                variant="delete"
                onClick={() => {
                  dispatch(deleteMeal(index));
                }}
                className="delete-button"
              >
                <FaTrash />
              </GButton>,
            ]
          : []),
        <GButton
          variant="secondary"
          onClick={() => {
            setShowMeal(!showMeal);
          }}
          className="show-button"
        >
          {showMeal ? <FaChevronDown /> : <FaChevronUp />}
        </GButton>,
      ]}
    >
      {showMeal && (
        <div>
          <div className="info-div">
            <div className="info-div-inputs">
              <div>
                <p className="name">Nome da refeição</p>
                <GInput
                  value={diet.meals[index].title}
                  onChange={(e: any) =>
                    dispatch(setMeal({ meal: { ...diet.meals[index], title: e.target.value }, index }))
                  }
                  disabled={!isEditing}
                />
              </div>
              <div>
                <p className="name">Descrição</p>
                <GInput
                  value={diet.meals[index].description}
                  onChange={(e: any) =>
                    dispatch(setMeal({ meal: { ...diet.meals[index], description: e.target.value }, index }))
                  }
                  as="textarea"
                  disabled={!isEditing}
                />
              </div>
              <div className="totals-div">
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#FF9800").brighten()} 0%, #FF9800 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {diet.meals[index].meal_items.length
                      ? diet.meals[index].meal_items
                          .reduce((acc: number, item: MealItem) => acc + (item.item as Food).calories, 0)
                          .toFixed(0)
                      : 0}
                    kcal
                  </p>
                  <p className="totals-div-info-container-name">Calorias</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#4CAF50").brighten()} 0%, #4CAF50 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {diet.meals[index].meal_items.length
                      ? diet.meals[index].meal_items
                          .reduce((acc: number, item: MealItem) => acc + (item.item as Food).protein, 0)
                          .toFixed(0)
                      : 0}
                    g
                  </p>
                  <p className="totals-div-info-container-name">Proteína</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#9C27B0").brighten()} 0%, #9C27B0 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {diet.meals[index].meal_items.length
                      ? diet.meals[index].meal_items
                          .reduce((acc: number, item: MealItem) => acc + (item.item as Food).carbs, 0)
                          .toFixed(0)
                      : 0}
                    g
                  </p>
                  <p className="totals-div-info-container-name">Hidratos</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#03A9F4").brighten()} 0%, #03A9F4 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {diet.meals[index].meal_items.length
                      ? diet.meals[index].meal_items
                          .reduce((acc: number, item: MealItem) => acc + (item.item as Food).lipids, 0)
                          .toFixed(0)
                      : 0}
                    g
                  </p>
                  <p className="totals-div-info-container-name">Lípidos</p>
                </div>
              </div>
            </div>
          </div>
          <TableComponent
            name="ingredients"
            columns={tableColumns}
            data={diet.meals[index].meal_items}
            addAction={() => {}}
            rowClickAction={(row: any) => {}}
            removeAction={(id: string) => {
              const newIngredients = diet.meals[index].meal_items.filter(
                (ingredient: MealItem) => ingredient.id !== id
              );
              dispatch(setMeal({ meal: { ...diet.meals[index], meal_items: newIngredients }, index }));
            }}
            duplicateAction={(id: string) => {}}
            showRemoveButton={isEditing}
            textWhenNoItems="Não tem nenhum prato adicionado"
          />
        </div>
      )}

      <AddIngredientModal showModal={showModal} setShowModal={setShowModal} mealIndex={index} />

      <GModal showModalState={showWeightModal} setShowModalState={setShowWeightModal}>
        <div className="add-ingredient-modal">
          <h4>Adicionar Peso</h4>
          <GInput
            className="weight-input"
            value={activeIngredientWeight}
            onChange={(e: any) => {
              if (e.target.value) {
                const newWeight = Number(e.target.value);
                setActiveIngredientWeight(newWeight);
              } else {
                setActiveIngredientWeight(undefined);
              }
            }}
          />
          <GButton
            label="Submeter"
            disabled={!activeIngredientWeight}
            onClick={() => {
              if (activeIngredientWeight) {
                let tempMeal = { ...diet.meals[index] };
                const ingredient = {
                  ...allIngredientsInADiet.find(
                    (ing: Food) => ing.id === tempMeal.meal_items[activeWeightIndex].item.id
                  )!,
                };
                let changedIng: FoodCharacteristicsWithoutWeight & { weight_in_g: number } = {
                  calories: 0,
                  protein: 0,
                  carbs: 0,
                  lipids: 0,
                  water: 0,
                  weight_in_g: 0,
                };
                if (ingredient.weight_in_g !== 100) {
                  changedIng = {
                    calories: (100 * ingredient.calories) / ingredient.weight_in_g!,
                    protein: (100 * ingredient.protein) / ingredient.weight_in_g!,
                    carbs: (100 * ingredient.carbs) / ingredient.weight_in_g!,
                    lipids: (100 * ingredient.lipids) / ingredient.weight_in_g!,
                    water: 0,
                    weight_in_g: 100,
                  };
                } else {
                  changedIng = { 
                    calories: ingredient.calories,
                    protein: ingredient.protein,
                    carbs: ingredient.carbs,
                    lipids: ingredient.lipids,
                    water: 0,
                    weight_in_g: 100,
                  };
                }

                // Create a new object for the meal item
                const updatedMealItem = {
                  ...tempMeal.meal_items[activeWeightIndex],
                  weight_in_g: activeIngredientWeight,
                  item: {
                    ...tempMeal.meal_items[activeWeightIndex].item,
                    calories: (activeIngredientWeight * changedIng.calories) / 100,
                    protein: (activeIngredientWeight * changedIng.protein) / 100,
                    carbs: (activeIngredientWeight * changedIng.carbs) / 100,
                    lipids: (activeIngredientWeight * changedIng.lipids) / 100,
                  },
                };

                // Update the meal_items array with the new object
                tempMeal.meal_items = [
                  ...tempMeal.meal_items.slice(0, activeWeightIndex),
                  updatedMealItem,
                  ...tempMeal.meal_items.slice(activeWeightIndex + 1),
                ];

                dispatch(setMeal({ meal: tempMeal, index }));
                setActiveIngredientWeight(0);
                setShowWeightModal(false);
              }
            }}
          />
        </div>
      </GModal>
    </GSection>
  );
};
