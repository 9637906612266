import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

type TextAreaFormProps = {
  label: string;
  value: string | undefined | null;
  onChange?: any;
  disabled?: boolean;
  large?: boolean;
};

export const TextAreaForm = ({
  label,
  value,
  onChange = () => {},
  disabled = false,
  large = false,
}: TextAreaFormProps) => {
  return (
    <>
      <FloatingLabel className="mb-3 profile-input " label={label}>
        <Form.Control
          disabled={disabled}
          value={value ?? undefined}
          onChange={onChange}
          placeholder={label}
          as="textarea"
          className={large ? "others-textarea" : "others-short-textarea"}
        />
      </FloatingLabel>
    </>
  );
};
