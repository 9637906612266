import { DashboardTitle } from "components/DashboardTitle/DashboardTitle";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import {
  TabletMode,
  TabletModeProps,
} from "components/TabletPage/TabletMode/TabletMode";

import "components/TabletPage/TabletPage.scss";
import { GModal } from "components/genericComponents/GModal/GModal";
import { useEffect, useState } from "react";
import { UserImageUpload } from "components/UserImageUpload/UserImageUpload";
import { useDispatch } from "react-redux";

export const TabletPage = () => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState<TabletModeProps | undefined>(
    undefined
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [imageKey, setImageKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (file !== undefined) {
      /* const image =
        file === null
          ? null
          : {
              id: imageKey!,
              storage: "cache",
              metadata: {
                size: file.size,
                mime_type: file.type,
              },
            }; */
      // TODO: Add image to BE
      //dispatch();
    }
  }, [file, dispatch, imageKey]);

  const initEntranceMode = () => {};

  const handlePreviewClick = (
    title: string,
    subtitle: string,
    hasBirthday?: boolean
  ) => {
    setModalData({
      title: title,
      subtitle: subtitle,
      hasBirthday: hasBirthday,
    });
    setShowModal(true);
  };

  return (
    <div id="tablet-page">
      <DashboardTitle title="Modo Controlo de Entradas" doNotShowBackButton />
      <GSection className="info-container">
        <p>
          O modo controlo de entradas proporciona-lhe a capacidade de exibir aos
          seus clientes detalhes sobre a sua entrada no ginásio, ao passarem o
          cartão pelo tourniquet.
        </p>
        <p>
          Se algum dos seus clientes se encontrar com pagamentos em atraso e,
          por conseguinte, não consiga entrar, terá acesso a essa informação no
          ecrã utilizado para visualizar o modo controlo de entradas.
        </p>
        <p>
          Mostre também uma mensagem de parabéns ao seu cliente no dia de
          aniversário.
        </p>
      </GSection>
      <GSection className="init-mode-container">
        <GButton
          label="Iniciar Modo Controlo de Entradas"
          onClick={initEntranceMode}
        />
      </GSection>
      <GSection label="Configurar imagem de fundo" className="config-container">
        <div className="config-div">
          <UserImageUpload
            image={undefined}
            file={file}
            setFile={setFile}
            setImageKey={setImageKey}
            showEditButton={true}
          />
        </div>
      </GSection>
      <GSection label="Pré-visualizar" className="preview-container">
        <div className="preview-div">
          <div
            className="clickable"
            onClick={() =>
              handlePreviewClick(
                "Bem-vindo!",
                "Passe o cartão no leitor para validar o seu acesso."
              )
            }
          >
            <TabletMode
              title="Bem-vindo!"
              subtitle="Passe o cartão no leitor para validar o seu acesso."
              isPreview
              bgImage="https://i.pinimg.com/736x/1e/cd/d5/1ecdd5186159231776c13df05c795f2e.jpg"
            />
          </div>
          <div
            className="clickable"
            onClick={() =>
              handlePreviewClick("Olá, Diogo!", "O seu acesso está válido.")
            }
          >
            <TabletMode
              title="Olá, Diogo!"
              subtitle="O seu acesso está válido."
              isPreview
            />
          </div>
          <div
            className="clickable"
            onClick={() =>
              handlePreviewClick("Olá, Diogo!", "O seu acesso está inválido.")
            }
          >
            <TabletMode
              title="Olá, Diogo!"
              subtitle="O seu acesso está inválido."
              isPreview
            />
          </div>
          <div
            className="clickable"
            onClick={() =>
              handlePreviewClick(
                "Olá, Diogo!",
                "O seu acesso está válido.",
                true
              )
            }
          >
            <TabletMode
              title="Olá, Diogo!"
              subtitle="O seu acesso está válido."
              isPreview
              hasBirthday
            />
          </div>
        </div>
      </GSection>
      <GModal
        dialogClassName="modal-preview"
        showModalState={showModal}
        setShowModalState={setShowModal}
      >
        <TabletMode
          title={modalData?.title!}
          subtitle={modalData?.subtitle!}
          hasBirthday={modalData?.hasBirthday}
          bgImage="https://i.pinimg.com/736x/1e/cd/d5/1ecdd5186159231776c13df05c795f2e.jpg"
          isModalPreview
        />
      </GModal>
    </div>
  );
};
