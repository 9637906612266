import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { GInput } from "components/genericComponents/GInput/GInput";
import { GSection } from "components/genericComponents/GSection/GSection";
import { GButton } from "components/genericComponents/GButton/GButton";
import {
  createUserCRMNote,
  fetchUserCRMNotes,
  selectCRMUserNotes,
  selectConfirmationMessage,
  selectErrorMessage,
  selectIsLoading,
  setConfirmationMessage,
  setErrorMessage,
} from "slices/crmSlice";
import { CRMNote } from "types/crm";
import { CRMCreatedNote } from "./CRMCreatedNote/CRMCreatedNote";
import { selectCurrentClient } from "slices/userSlice";

import "./CRM.scss";
import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";

export const CRM = () => {
  const dispatch = useDispatch();
  const [newNote, setNewNote] = useState<string>("");
  const crmNotes = useSelector(selectCRMUserNotes);
  const currentUser = useSelector(selectCurrentClient);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const isLoading = useSelector(selectIsLoading);
  const [notesFetched, setNotesFetched] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser && !notesFetched) {
      dispatch(fetchUserCRMNotes(currentUser!.id));
      setNotesFetched(true);
    }
  }, [dispatch, currentUser, notesFetched]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "crm-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "crm-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
      dispatch(fetchUserCRMNotes(currentUser!.id));
      setNewNote("");
    }
  }, [dispatch, confirmationMessage, currentUser]);

  return (
    <>
      <GSection className="crm-container" label="Adicionar nova nota">
        <GInput
          className="note-input"
          value={newNote}
          onChange={(e: any) => setNewNote(e.target.value)}
        />
        <div className="button-container">
          <GButton
            label="Submeter"
            onClick={() => {
              dispatch(
                createUserCRMNote({ id: currentUser!.id, note: newNote })
              );
            }}
          />
        </div>
      </GSection>
      {isLoading ? (
        <div style={{ height: "100%" }}>
          <SpinnerWrapper />
        </div>
      ) : (
        <GSection label="Notas">
          <div className="notes-filler" />
          {crmNotes.length === 0 ? (
            <p>Sem notas</p>
          ) : (
            crmNotes.map((note: CRMNote) => (
              <CRMCreatedNote key={note.id} note={note} />
            ))
          )}
        </GSection>
      )}
    </>
  );
};
