import { Col, Container, Row, Spinner } from "react-bootstrap";

import "./SpinnerWrapper.css";

type SpinnerWrapperI = {
  className?: string;
};

export const SpinnerWrapper = ({ className }: SpinnerWrapperI) => {
  return (
    <Container
      id="spinner"
      className={`spinner${className ? ` ${className}` : ""}`}
    >
      <Row>
        <Col className="spinner-col">
          <Spinner className="spinner" />
        </Col>
      </Row>
    </Container>
  );
};
