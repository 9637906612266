import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  CreateUserNutritionSchedulerItem,
  CreateUserNutritionSchedulerItemResponse,
  CreateUserNutritionSchedulerNote,
  CreateUserNutritionSchedulerNoteResponse,
  Food,
  SearchFood,
  SearchRecipe,
  UserNutritionScheduler,
} from "types/nutrition";

type NutritionSchedulerState = {
  userNutritionScheduler: UserNutritionScheduler[] | undefined;
  foods: SearchFood[] | undefined;
  recipes: SearchRecipe[] | undefined;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
  isLoading: boolean;
};

const initialNutritionSchedulerState = {
  userNutritionScheduler: undefined,
  foods: undefined,
  recipes: undefined,
  errorMessage: undefined,
  confirmationMessage: undefined,
  isLoading: false,
};

const nutritionSchedulerSlice = createSlice({
  name: "nutritionScheduler",
  initialState: initialNutritionSchedulerState,
  reducers: {
    createUserNutritionSchedulerNote: (
      state: NutritionSchedulerState,
      action: PayloadAction<CreateUserNutritionSchedulerNote>
    ) => {},
    createUserNutritionSchedulerNoteSucceeded: (
      state: NutritionSchedulerState,
      action: PayloadAction<CreateUserNutritionSchedulerNoteResponse>
    ) => {
      state.confirmationMessage = "Nota criada com sucesso";
    },
    updateUserNutritionSchedulerNote: (
      state: NutritionSchedulerState,
      action: PayloadAction<string & CreateUserNutritionSchedulerNote>
    ) => {},
    updateUserNutritionSchedulerNoteSucceeded: (
      state: NutritionSchedulerState,
      action: PayloadAction<CreateUserNutritionSchedulerNoteResponse>
    ) => {
      state.confirmationMessage = "Nota atualizada com sucesso";
    },
    deleteUserNutritionSchedulerNote: (
      state: NutritionSchedulerState,
      action: PayloadAction<string>
    ) => {},
    deleteUserNutritionSchedulerNoteSucceeded: (
      state: NutritionSchedulerState,
      action: PayloadAction<string>
    ) => {
      state.confirmationMessage = "Nota eliminada com sucesso";
    },
    createUserNutritionSchedulerItem: (
      state: NutritionSchedulerState,
      action: PayloadAction<CreateUserNutritionSchedulerItem>
    ) => {},
    createUserNutritionSchedulerItemSucceeded: (
      state: NutritionSchedulerState,
      action: PayloadAction<CreateUserNutritionSchedulerItemResponse>
    ) => {
      state.confirmationMessage = "Item criado com sucesso";
    },
    updateUserNutritionSchedulerItem: (
      state: NutritionSchedulerState,
      action: PayloadAction<CreateUserNutritionSchedulerItem>
    ) => {},
    updateUserNutritionSchedulerItemSucceeded: (
      state: NutritionSchedulerState,
      action: PayloadAction<CreateUserNutritionSchedulerItemResponse>
    ) => {
      state.confirmationMessage = "Item atualizado com sucesso";
    },
    deleteUserNutritionSchedulerItem: (
      state: NutritionSchedulerState,
      action: PayloadAction<string>
    ) => {},
    deleteUserNutritionSchedulerItemSucceeded: (
      state: NutritionSchedulerState,
      action: PayloadAction<string>
    ) => {
      state.confirmationMessage = "Item eliminado com sucesso";
    },
    searchFood: (
      state: NutritionSchedulerState,
      action: PayloadAction<string | null>
    ) => {
      state.isLoading = true;
    },
    searchFoodSucceeded: (
      state: NutritionSchedulerState,
      action: PayloadAction<SearchFood[]>
    ) => {
      state.foods = action.payload;
      state.isLoading = false;
    },
    searchRecipes: (
      state: NutritionSchedulerState,
      action: PayloadAction<string | null>
    ) => {
      state.isLoading = true;
    },
    searchRecipesSucceeded: (
      state: NutritionSchedulerState,
      action: PayloadAction<SearchRecipe[]>
    ) => {
      state.recipes = action.payload;
      state.isLoading = false;
    },
    setErrorMessage(
      state: NutritionSchedulerState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: NutritionSchedulerState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
    cleanErrorMessage(state: NutritionSchedulerState) {
      state.errorMessage = undefined;
    },
  },
});

export const {
  createUserNutritionSchedulerNote,
  createUserNutritionSchedulerNoteSucceeded,
  updateUserNutritionSchedulerNote,
  updateUserNutritionSchedulerNoteSucceeded,
  deleteUserNutritionSchedulerNote,
  deleteUserNutritionSchedulerNoteSucceeded,
  createUserNutritionSchedulerItem,
  createUserNutritionSchedulerItemSucceeded,
  updateUserNutritionSchedulerItem,
  updateUserNutritionSchedulerItemSucceeded,
  deleteUserNutritionSchedulerItem,
  deleteUserNutritionSchedulerItemSucceeded,
  searchFood,
  searchFoodSucceeded,
  searchRecipes,
  searchRecipesSucceeded,
  setErrorMessage,
  setConfirmationMessage,
  cleanErrorMessage,
} = nutritionSchedulerSlice.actions;

export const selectFoods = (state: RootState): Food[] | undefined =>
  state.nutritionSchedulerSlice.foods;
export const selectRecipes = (state: RootState): SearchRecipe[] | undefined =>
  state.nutritionSchedulerSlice.recipes;
export const selectUserNutritionScheduler = (
  state: RootState
): UserNutritionScheduler[] | undefined =>
  state.nutritionSchedulerSlice.userNutritionScheduler;
export const selectErrorMessage = (state: RootState) =>
  state.nutritionSchedulerSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.nutritionSchedulerSlice.confirmationMessage;
export const selectIsLoading = (state: RootState) =>
  state.nutritionSchedulerSlice.isLoading;

export default nutritionSchedulerSlice.reducer;
