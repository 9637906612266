import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";

import { selectCurrentUser } from "slices/currentUserSlice";

import logo from "assets/logo.svg";

import "components/SettingsPage/MobileAppSettings/WorkoutExerciseScreenApp/WorkoutExerciseScreenApp.scss";
import {
  FaCalendarDays,
  FaCommentDots,
  FaHouse,
  FaSquare,
  FaUser,
} from "react-icons/fa6";
import { FaDumbbell } from "react-icons/fa";

type WorkoutExerciseScreenAppProps = {
  color: string;
};

export const WorkoutExerciseScreenApp = ({
  color,
}: WorkoutExerciseScreenAppProps) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <div className="workout-screen-app">
      <div className="header-container">
        <div className="header-left-div">
          {currentUser?.company?.image_medium !== null &&
          currentUser?.company?.is_gym ? (
            <img
              className="header-logo"
              src={currentUser.company?.image_small}
              alt="compay logo"
            />
          ) : !currentUser?.company?.is_gym &&
            currentUser?.image_small !== null ? (
            <img
              className="header-logo"
              src={currentUser?.image_small}
              alt="user logo"
            />
          ) : (
            <img className="header-logo" src={logo} alt="logo" />
          )}
          <p className="header-text">Plano de Abdominais</p>
        </div>
      </div>
      <div
        className="card"
        style={{
          background: `linear-gradient(to bottom, ${tinycolor(color)
            .lighten(20)
            .toString()} 0%, ${color} 100%)`,
        }}
      >
        <div className="preview-card-header">
          <p
            style={{
              color: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
            }}
          >
            Abdominais
          </p>
          <FaSquare
            style={{
              color: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
            }}
          />
        </div>
      </div>
      <div
        className="card"
        style={{
          background: `linear-gradient(to bottom, ${tinycolor(color)
            .lighten(20)
            .toString()} 0%, ${color} 100%)`,
        }}
      >
        <div className="preview-card-header">
          <p
            style={{
              color: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
            }}
          >
            Prancha
          </p>
          <FaSquare
            style={{
              color: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
            }}
          />
        </div>
        <div
          className="video-container"
          style={{
            background: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
          }}
        >
          <p
            style={{
              color: `${!tinycolor(color).isLight() ? "#212121" : "white"}`,
            }}
          >
            Ver vídeo
          </p>
        </div>
        <hr
          style={{
            borderWidth: "2px",
            color: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
          }}
        />
        <div className="serie-header">
          <p
            style={{
              fontWeight: 600,
              color: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
            }}
          >
            Série 1
          </p>
          <FaSquare
            style={{
              color: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
            }}
          />
        </div>
        <p
          style={{
            color: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
          }}
        >
          Duração: 00:01:00
        </p>
        <div className="add-serie">
          <div
            className="video-container"
            style={{
              background: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
            }}
          >
            <p
              style={{
                color: `${!tinycolor(color).isLight() ? "#212121" : "white"}`,
              }}
            >
              Adicionar série
            </p>
          </div>
        </div>
      </div>
      <div style={{ flex: "1 1 auto" }} />
      <div className="icons-container">
        <div className="icon-div">
          <FaHouse className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Início</p>
        </div>
        <div className="icon-div">
          <FaDumbbell className="icon" style={{ color: color }} />
          <p style={{ color: color }}>Planos de Treino</p>
        </div>
        <div className="icon-div">
          <FaCalendarDays className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Calendário</p>
        </div>
        <div className="icon-div">
          <FaCommentDots className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Mensagens</p>
        </div>
        <div className="icon-div">
          <FaUser className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Perfil</p>
        </div>
      </div>
    </div>
  );
};
