import { Dispatch, SetStateAction, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsFillXCircleFill, BsPencilSquare } from "react-icons/bs";
import { useDispatch } from "react-redux";

import { InputForm } from "components/InputForm/InputForm";
import { SelectForm } from "components/SelectForm/SelectForm";

import "components/SettingsPage/PaymentsSettings/PaymentsPlan/PaymentsPlan.scss";
import { GButton } from "components/genericComponents/GButton/GButton";
import { format } from "date-fns";
import { MdDeleteForever } from "react-icons/md";
import { TimePicker } from "react-time-picker";
import { LooseValue } from "react-time-picker/dist/cjs/shared/types";
import {
  createPaymentsPackage,
  deletePaymentsPackage,
  updatePaymentsPackage,
} from "slices/paymentsPackagesSlice";
import {
  CreatePaymentsPackage,
  PaymentsPackage,
  frequencies,
} from "types/paymentPackages";

type PaymentsPlanI = {
  currPlan?: PaymentsPackage;
  setShowAddPlan: Dispatch<SetStateAction<boolean>>;
  isNewPlan?: boolean;
};

export const PaymentsPlan = ({
  currPlan,
  setShowAddPlan,
  isNewPlan,
}: PaymentsPlanI) => {
  const dispatch = useDispatch();

  const [isEditting, setIsEditting] = useState<boolean>(
    isNewPlan ? true : false
  );
  const [isRecurrent, setIsRecurrent] = useState<boolean>(
    isNewPlan
      ? true
      : currPlan!.type === "RecurrentPaymentPackage"
      ? true
      : false
  );
  const [plan, setPlan] = useState<PaymentsPackage | CreatePaymentsPackage>(
    currPlan
      ? {
          ...currPlan,
          start_time: format(new Date(currPlan.start_time), "HH:mm:ss"),
          end_time: format(new Date(currPlan.end_time), "HH:mm:ss"),
        }
      : {
          title: "",
          type: "RecurrentPaymentPackage",
          frequency: "monthly",
          max_entries_per_week: 7,
          price: 0,
          start_time: "06:00:00",
          end_time: "22:00:00",
          number_of_sessions: null,
          term_in_days: null,
          can_access_courses: true,
        }
  );
  const [sessionPrice, setSessionPrice] = useState<number>(
    currPlan ? currPlan.price : 0
  );

  const onSubmitButtonClick = () => {
    if (isNewPlan) {
      dispatch(createPaymentsPackage(plan));
      setShowAddPlan(false);
    } else dispatch(updatePaymentsPackage(plan as PaymentsPackage));
    setIsEditting(false);
  };

  const onDeleteClick = () => {
    dispatch(deletePaymentsPackage((plan as PaymentsPackage).id));
  };

  return (
    <div className="add-payments-plans-inner-container">
      <Row>
        <Col className="add-payments-plans-inner-container-title-col">
          <div className="title-div">
            <h4>{plan.title}</h4>
            {!isEditting ? (
              <Button
                className="title-edit-btn"
                onClick={() => {
                  setIsEditting(true);
                }}
              >
                <BsPencilSquare />
              </Button>
            ) : (
              <div className="payments-plans-btns-div">
                {!isNewPlan && (
                  <Button
                    className="gym-variables-delete-button"
                    onClick={() => onDeleteClick()}
                  >
                    <MdDeleteForever />
                  </Button>
                )}
                <Button
                  className="title-edit-btn"
                  onClick={() => {
                    setIsEditting(false);
                  }}
                >
                  <BsFillXCircleFill />
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="course-info-settings-input-col">
          <InputForm
            value={plan.title}
            label="Nome do Plano"
            className="course-info-settings-input"
            onChange={(e: any) => {
              setPlan({
                ...plan,
                title: e.target.value ? e.target.value : "",
              });
            }}
            disabled={!isEditting}
          />
        </Col>
      </Row>
      <Row className="add-payment-type-row">
        <Col className={`type-col${!isEditting ? " disabled" : ""}`}>
          <p
            onClick={() => {
              if (isEditting) {
                setIsRecurrent(true);
                setPlan({
                  ...plan,
                  type: "RecurrentPaymentPackage",
                  frequency: "monthly",
                  max_entries_per_week: 0,
                  number_of_sessions: null,
                  term_in_days: null,
                  price: 0,
                });
                setSessionPrice(0);
              }
            }}
            className={`pricing-schedule-text ${isRecurrent ? "active" : ""}`}
          >
            Recurrente
          </p>
          <p
            onClick={() => {
              if (isEditting) {
                setIsRecurrent(false);
                setPlan({
                  ...plan,
                  type: "SessionsPaymentPackage",
                  frequency: null,
                  max_entries_per_week: null,
                  number_of_sessions: 0,
                  term_in_days: 0,
                });
              }
            }}
            className={`pricing-schedule-text ${!isRecurrent ? "active" : ""}`}
          >
            Sessões
          </p>
        </Col>
      </Row>
      {isRecurrent && (
        <div>
          <p className="add-payment-title">Frequência</p>
          <Row>
            <Col className="frequency-select-col">
              <SelectForm
                className="frequency-select"
                disabled={!isEditting}
                options={Object.keys(frequencies).map((freq: string) => {
                  return {
                    id: freq,
                    title: frequencies[freq as keyof typeof frequencies],
                  };
                })}
                value={{
                  id: plan.frequency!,
                  title: frequencies[plan.frequency!],
                }}
                label="Frequência"
                onChange={(e: any) => {
                  setPlan({
                    ...plan,
                    frequency: e.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      )}
      <div>
        <p className="add-payment-title">Aulas</p>
        <Form.Check // prettier-ignore
          disabled={!isEditting}
          checked={plan.can_access_courses}
          type="switch"
          label="Acesso às aulas"
          className="purple-switch"
          onChange={(val: any) =>
            setPlan({ ...plan, can_access_courses: val.target.checked })
          }
        />
      </div>
      <div>
        <p className="add-payment-title">Horário de Utilização</p>
        <Row className="usage-schedule-row">
          <Col className="usage-schedule-col">
            <p className="usage-schedule-text">Início:</p>
            <TimePicker
              disableClock
              className="time-input"
              value={plan.start_time as LooseValue}
              format="H:m:s"
              maxDetail="second"
              onChange={(value) => {
                if (value !== null) {
                  const timeArr = value.split(":");
                  var hours = Number(timeArr[0]);
                  var minutes = Number(timeArr[1]);
                  var seconds = Number(timeArr[2]);

                  const timeInSeconds = hours * 3600 + minutes * 60 + seconds;

                  const endTimeArr = plan.end_time.split(":");
                  var endTimeHours = Number(endTimeArr[0]);
                  var endTimeMinutes = Number(endTimeArr[1]);
                  var endTimeSeconds = Number(endTimeArr[2]);

                  const endTimeInSeconds =
                    endTimeHours * 3600 + endTimeMinutes * 60 + endTimeSeconds;

                  setPlan({
                    ...plan,
                    start_time: value,
                    end_time:
                      endTimeInSeconds < timeInSeconds ? value : plan.end_time,
                  });
                }
              }}
            />
          </Col>
          <Col className="usage-schedule-col">
            <p className="usage-schedule-text">Fim:</p>
            <TimePicker
              disableClock
              className="time-input"
              value={plan.end_time as LooseValue}
              format="H:m:s"
              maxDetail="second"
              onChange={(value) => {
                if (value !== null) {
                  const timeArr = value.split(":");
                  var hours = Number(timeArr[0]);
                  var minutes = Number(timeArr[1]);
                  var seconds = Number(timeArr[2]);

                  const timeInSeconds = hours * 3600 + minutes * 60 + seconds;

                  const startTimeArr = plan.start_time.split(":");
                  var startTimeHours = Number(startTimeArr[0]);
                  var startTimeMinutes = Number(startTimeArr[1]);
                  var startTimeSeconds = Number(startTimeArr[2]);

                  const startTimeInSeconds =
                    startTimeHours * 3600 +
                    startTimeMinutes * 60 +
                    startTimeSeconds;

                  setPlan({
                    ...plan,
                    end_time: value,
                    start_time:
                      startTimeInSeconds > timeInSeconds
                        ? value
                        : plan.start_time,
                  });
                }
              }}
            />
          </Col>
        </Row>
      </div>
      {isRecurrent && (
        <Row className="max-entries-row">
          <p className="add-payment-title">Entradas por semana</p>
          <Col>
            <SelectForm
              disabled={!isEditting}
              isClearable={false}
              options={[1, 2, 3, 4, 5, 6, 7].map((value: number) => {
                let title;

                if (value === 7) {
                  title = "Ilimitado";
                } else {
                  title = value;
                }

                return {
                  id: value,
                  title: title,
                };
              })}
              value={
                plan.max_entries_per_week
                  ? {
                      id: plan.max_entries_per_week,
                      title:
                        plan.max_entries_per_week === 7
                          ? "Ilimitado"
                          : plan.max_entries_per_week,
                    }
                  : undefined
              }
              label="Máximo de entradas por semana"
              onChange={(e: any) => {
                if (e.value) {
                  setPlan({
                    ...plan,
                    max_entries_per_week: e.value,
                  });
                }
              }}
            />
          </Col>
        </Row>
      )}
      {!isRecurrent && (
        <Row className="sessions-number-row">
          <Col>
            <InputForm
              value={plan.term_in_days}
              type="number"
              label="Prazo (Em dias)"
              min={0}
              className="course-info-settings-input"
              onChange={(e: any) => {
                if (e.target.value) {
                  setPlan({
                    ...plan,
                    term_in_days: e.target.value,
                  });
                }
              }}
              disabled={!isEditting}
            />
          </Col>
        </Row>
      )}
      {!isRecurrent && (
        <Row className="sessions-number-row">
          <Col>
            <InputForm
              value={plan.number_of_sessions}
              type="number"
              label="Número de sessões"
              min={0}
              className="course-info-settings-input"
              onChange={(e: any) => {
                if (e.target.value) {
                  setPlan({
                    ...plan,
                    number_of_sessions: e.target.value,
                    price: e.target.value * sessionPrice,
                  });
                }
              }}
              disabled={!isEditting}
            />
          </Col>
        </Row>
      )}
      {!isRecurrent && (
        <Row className="sessions-number-row">
          <Col>
            <InputForm
              value={sessionPrice}
              type="number"
              label="Preço por sessão"
              className="course-info-settings-input"
              onChange={(e: any) => {
                setSessionPrice(e.target.value);
                setPlan({
                  ...plan,
                  price: plan.number_of_sessions! * e.target.value,
                });
              }}
              disabled={!isEditting}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col className="course-info-settings-input-col">
          <InputForm
            disabled={!isEditting || !isRecurrent}
            value={
              isRecurrent ? plan.price : sessionPrice * plan.number_of_sessions!
            }
            label="Preço"
            type="number"
            onChange={(e: any) => {
              setPlan({
                ...plan,
                price: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      {isEditting ? (
        <Row>
          <Col className="submit-col">
            <GButton label="Submeter" onClick={() => onSubmitButtonClick()} />
          </Col>
        </Row>
      ) : undefined}
    </div>
  );
};
