import { Col, Container, Row } from "react-bootstrap";

import chatgptLandingPage from "../../../assets/chatgptLandingPage.png";

import "./Chatgpt.css";
import { useTranslation } from "react-i18next";

export const Chatgpt = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%" }} id="custom-workouts">
      <Container className="cw-container">
        <Row>
          <Col className="cw-title-col">
            <h1 className="cw-title">{t("landingPage.chatgpt.title")}</h1>
          </Col>
        </Row>
        <Row>
          <Col className="cw-col">
            <p>{t("landingPage.chatgpt.description")}</p>
          </Col>
        </Row>
        <Row>
          <Col className="cw-col">
            <img src={chatgptLandingPage} className="cw-image" alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
