import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Button } from "react-bootstrap";

import { DashboardTitleI } from "../../types/dashboard";

import "./DashboardTitle.css";
import { CountCard, CountCardType } from "../CountCard/CountCard";
import { VideoModal } from "components/VideoModal/VideoModal";
import { GButton } from "components/genericComponents/GButton/GButton";
import { UpdateAccountModal } from "components/shared/UpdateAccountModal/UpdateAccountModal";
import { isOwner } from "helpers/validation";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "slices/currentUserSlice";

export const DashboardTitle: React.FC<DashboardTitleI> = ({
  title,
  onClickButton,
  showAddButton = false,
  linkObj,
  showUpgradePlanMessage,
  doNotShowBackButton,
  countCards,
  infoLink,
  navigateToLink,
}) => {
  let navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const [showModal, setShowModal] = useState(false);

  const handleUpdatePlan = () => {
    setShowModal(true);
  };

  return (
    <Container fluid>
      <Row>
        <Col className="dashboard-title-col">
          {title !== "" ? (
            <div className="dashboard-title-row">
              {!doNotShowBackButton && (
                <Button
                  className="dashboard-title-go-back-button"
                  onClick={(e: any) =>
                    navigateToLink ? navigate(navigateToLink) : navigate(-1)
                  }
                >
                  <FaArrowAltCircleLeft />
                </Button>
              )}
              <h1 className="dashboard-title">{title}</h1>
            </div>
          ) : undefined}
          {countCards ? (
            <div className="dashboard-title-count-cards">
              {countCards.map((countCard: CountCardType, index) => (
                <CountCard
                  key={countCard.count + countCard.title + index}
                  title={countCard.title}
                  count={countCard.count}
                />
              ))}
            </div>
          ) : undefined}
          <div className="dashboard-title-header-right-button">
            {infoLink && <VideoModal link={infoLink} />}
            {showUpgradePlanMessage && currentUser && isOwner(currentUser) ? (
              <GButton label="Atualizar plano" onClick={handleUpdatePlan} />
            ) : undefined}
            {showAddButton && linkObj?.isLink ? (
              <Link to={linkObj.link}>
                <button className="dashboard-title-btn" onClick={onClickButton}>
                  <MdAdd />
                </button>
              </Link>
            ) : showAddButton ? (
              <button className="dashboard-title-btn" onClick={onClickButton}>
                <MdAdd />
              </button>
            ) : undefined}
          </div>
        </Col>
      </Row>
      <UpdateAccountModal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
};
