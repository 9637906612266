import { takeLatest } from "redux-saga/effects";
import {
  createChatgptMessage,
  fetchChatgptChat,
  fetchChatgptChats,
} from "../../slices/chatgptSlice";
import {
  createChatgptMessageSaga,
  fetchChatgptChatSaga,
  fetchChatgptChatsSaga,
} from "./chatgptSaga";

function* chatgptSaga() {
  yield takeLatest(createChatgptMessage, createChatgptMessageSaga);
  yield takeLatest(fetchChatgptChats, fetchChatgptChatsSaga);
  yield takeLatest(fetchChatgptChat, fetchChatgptChatSaga);
}

export default chatgptSaga;
