import { takeLatest } from "redux-saga/effects";
import {
  fetchEvaluations,
  createEvaluation,
  updateEvaluation,
  deleteEvaluation,
} from "../../slices/evaluationsSlice";
import {
  fetchEvaluationsSaga,
  createEvaluationSaga,
  updateEvaluationSaga,
  deleteEvaluationSaga,
} from "./evaluationsSaga";

function* evaluationsSaga() {
  yield takeLatest(fetchEvaluations, fetchEvaluationsSaga);
  yield takeLatest(createEvaluation, createEvaluationSaga);
  yield takeLatest(updateEvaluation, updateEvaluationSaga);
  yield takeLatest(deleteEvaluation, deleteEvaluationSaga);
}

export default evaluationsSaga;
