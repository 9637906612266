import { User } from "types/user";

export const validEmailRegex = new RegExp(
  // Turned \t into \\t to escape it (if something is off, try removing a \)
  "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \\t]|(\\[\\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\\t -Z^-~]*])"
);

export const isOwner = (currentUser: User) => {
  if (currentUser.company?.is_gym) {
    if (localStorage.getItem("isGymView") === "true") {
      return true;
    } else {
      return false;
    }
  }
  return true;
};
