import { ProductTransaction } from "../types/productTransaction";
import { axiosRequest, errorMessage } from "./request";
import { CreateOrUpdateProductTransactionType } from "../slices/productTransactionSlice";

export type ProductTransactionRequestResponseType = {
  succeeded: boolean;
  productTransactions: Array<ProductTransaction> | undefined;
  productTransaction: ProductTransaction | undefined;
  errorMessage: string | undefined;
};

export const fetchProductTransactionsRequest =
  async (): Promise<ProductTransactionRequestResponseType> => {
    try {
      const response = await axiosRequest().get("product_transactions");
      const productTransactions: Array<ProductTransaction> = response.data;

      return {
        succeeded: true,
        productTransactions: productTransactions,
      } as ProductTransactionRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as ProductTransactionRequestResponseType;
    }
  };

export const createProductTransactionRequest = async (
  params: CreateOrUpdateProductTransactionType
): Promise<ProductTransactionRequestResponseType> => {
  try {
    const response = await axiosRequest().post("product_transactions", {
      product_transaction: params,
    });
    const productTransaction: ProductTransaction = response.data;

    return {
      succeeded: true,
      productTransaction: productTransaction,
    } as ProductTransactionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ProductTransactionRequestResponseType;
  }
};

export const updateProductTransactionRequest = async (
  params: CreateOrUpdateProductTransactionType
): Promise<ProductTransactionRequestResponseType> => {
  try {
    const response = await axiosRequest().put(
      `product_transactions/${params.id}`,
      {
        product_transaction: params,
      }
    );
    const productTransaction: ProductTransaction = response.data;

    return {
      succeeded: true,
      productTransaction: productTransaction,
    } as ProductTransactionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ProductTransactionRequestResponseType;
  }
};

export const deleteProductTransactionRequest = async (
  productTransactionId: string
): Promise<ProductTransactionRequestResponseType> => {
  try {
    await axiosRequest().delete(`product_transactions/${productTransactionId}`);

    return { succeeded: true } as ProductTransactionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ProductTransactionRequestResponseType;
  }
};
