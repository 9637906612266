import { Col, Container, Row } from "react-bootstrap";
import { DashboardTitle } from "../../DashboardTitle/DashboardTitle";
import { PersonalData } from "../PersonalData/PersonalData";

import "./AddCustomerPage.css";

export const AddCustomerPage = () => {
  return (
    <Container fluid id="add-customer-page">
      <DashboardTitle title="Adicionar um cliente" showAddButton={false} />
      <Container fluid id="personal-data-section">
        <Row className="account-section-row">
          <Col>
            <PersonalData clientExists={false} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
