import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectCurrentUser } from "../../../../slices/currentUserSlice";
import {
  selectConfirmationMessage,
  selectErrorMessage,
  setConfirmationMessage,
  setErrorMessage,
  updateUser,
} from "../../../../slices/userSlice";

import "./CredentialsSettings.css";

export const CredentialsSettings = () => {
  const [currentPassword, setCurrentPassword] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<
    string | null
  >(null);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<
    string | null
  >(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const isSubmitButtonDisabled =
    currentPassword === null ||
    currentPassword === "" ||
    newPassword === null ||
    newPassword === "" ||
    newPasswordConfirmation === null ||
    newPasswordConfirmation === "";
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const errorMessage = useSelector(selectErrorMessage);

  const handleSubmit = () => {
    if (newPassword!.length < 8) {
      return setPasswordError(
        "Palavra-passe tem de ter no mínimo 8 caracteres"
      );
    }

    if (newPassword !== newPasswordConfirmation) {
      return setPasswordConfirmationError(
        "Confirmação da palavra-passe inválida"
      );
    }

    setPasswordConfirmationError(null);

    dispatch(
      updateUser({
        ...currentUser!,
        current_password: currentPassword!,
        new_password: newPassword!,
      })
    );
  };

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "credentials-settings-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));

      setPasswordConfirmationError("");
      setCurrentPassword("");
      setNewPassword("");
      setNewPasswordConfirmation("");
    }
  }, [dispatch, confirmationMessage]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "credentials-settings-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  return (
    <Container fluid id="credentials-settings">
      <Row>
        <Col>
          <h4>Mudar palavra-passe</h4>
        </Col>
      </Row>
      <Row className="credentials-inputs-container">
        <Col lg={6}>
          <FloatingLabel
            className="mb-3 profile-input"
            label="Palavra-passe atual"
          >
            <Form.Control
              type="password"
              value={currentPassword ?? undefined}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Palavra-passe"
            />
          </FloatingLabel>
        </Col>
        <Col lg={6}>
          <FloatingLabel
            className="mb-3 profile-input"
            label="Nova palavra-passe"
          >
            <Form.Control
              type="password"
              value={newPassword ?? undefined}
              onChange={(e) => {
                setNewPassword(e.target.value);

                if (e.target.value.length > 8) setPasswordError(null);
              }}
              placeholder="Palavra-passe"
            />
            {passwordError !== undefined ? (
              <p className="form-error-message">{passwordError}</p>
            ) : undefined}
          </FloatingLabel>
        </Col>
        <Col lg={6}>
          <FloatingLabel
            className="mb-3 profile-input"
            label="Confirmar palavra-passe"
          >
            <Form.Control
              type="password"
              value={newPasswordConfirmation ?? undefined}
              onChange={(e) => setNewPasswordConfirmation(e.target.value)}
              placeholder="Palavra-passe"
            />
            {passwordConfirmationError !== undefined ? (
              <p className="form-error-message">{passwordConfirmationError}</p>
            ) : undefined}
          </FloatingLabel>
        </Col>
      </Row>
      {isSubmitButtonDisabled ? undefined : (
        <Row className="credentials-justify-content-center credentials-margin-top">
          <Button className="submit-button" onClick={handleSubmit}>
            Guardar
          </Button>
        </Row>
      )}
    </Container>
  );
};
