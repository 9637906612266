import { Col, Row } from "react-bootstrap";

import { GymRoomsSettings } from "./GymRoomsSettings/GymRoomsSettings";

import "./GymSettings.css";

export const GymSettings = () => {
  return (
    <div id="gym-settings">
      <Row className="gym-settings-row">
        <Col className="gym-settings-col">
          <GymRoomsSettings />
        </Col>
      </Row>
    </div>
  );
};
