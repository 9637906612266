import { PayloadAction } from "@reduxjs/toolkit";
import {
  setErrorMessage,
  setConfirmationMessage,
  CreateWaitlistType,
} from "../../slices/waitlistSlice";
import { put, call } from "redux-saga/effects";
import {
  createWaitlistEntryRequest,
  CreateWaitlistEntryResponseType,
} from "../../requests/waitlistRequests";

export function* createWaitilistEntrySaga(
  action: PayloadAction<CreateWaitlistType>,
  request = createWaitlistEntryRequest
) {
  const response: CreateWaitlistEntryResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(setConfirmationMessage(response.message));
  } else {
    yield put(setErrorMessage(response.message));
  }
}
