import { ReactNode, useEffect, useState } from "react";
import { Modal, ModalProps } from "react-bootstrap";

import "components/genericComponents/GModal/GModal.scss";

type GModalProps = ModalProps & {
  showModalState?: boolean;
  setShowModalState?: (val: boolean) => void;
  title?: string;
  description?: string;
  children?: ReactNode;
  buttons?: ReactNode[];
  isFullScreen?: boolean;
};

export const GModal = ({
  showModalState,
  setShowModalState,
  title,
  description,
  children,
  buttons,
  isFullScreen,
  ...props
}: GModalProps) => {
  const [showModal, setShowModal] = useState<boolean>(showModalState || false);

  useEffect(() => {
    if (showModalState !== undefined) setShowModal(showModalState);
  }, [showModalState]);

  const onHideHandler = () => {
    setShowModal(false);
    if (setShowModalState) setShowModalState(false);
  };

  return (
    <Modal
      {...props}
      className={isFullScreen ? "g-modal fullscreen" : "g-modal"}
      centered
      show={showModal}
      onHide={onHideHandler}
    >
      <Modal.Body>
        {title && <p className="title">{title}</p>}
        {description && <p className="description">{description}</p>}
        {children}
        {buttons && (
          <div className="buttons-div">{buttons.map((button) => button)}</div>
        )}
      </Modal.Body>
    </Modal>
  );
};
