import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  UpdateCompanyType,
  fetchCurrentUser,
  selectConfirmationMessage,
  selectCurrentUser,
  selectErrorMessage,
  setConfirmationMessage,
  setErrorMessage,
  updateCompany,
} from "../../../../slices/currentUserSlice";
import { UserImageUpload } from "../../../UserImageUpload/UserImageUpload";

import "./CompanySettings.css";
import { GInput } from "components/genericComponents/GInput/GInput";

export const CompanySettings = () => {
  ReactGA.send("/settings");

  const [cookies] = useCookies(["current_user"]);
  const currentUserCookies = cookies.current_user;
  const dispatch = useDispatch();
  const company = useSelector(selectCurrentUser)?.company;
  const currentUser = useSelector(selectCurrentUser);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [file, setFile] = useState<File | null>(null);
  const [imageKey, setImageKey] = useState<string | undefined>(undefined);
  const [formData, setFormData] = useState<UpdateCompanyType>({
    id: company?.id ?? "",
    name: company?.name ?? "",
    nif: company?.nif,
    fiscal_email: company?.fiscal_email,
    fiscal_name: company?.fiscal_name,
    fiscal_address: company?.fiscal_address,
    fiscal_city: company?.fiscal_city,
    fiscal_zip_code: company?.fiscal_zip_code,
  });

  useEffect(() => {
    if (company) {
      setFormData({
        ...formData,
        id: company.id,
        name: company.name,
        nif: company.nif,
        fiscal_email: company.fiscal_email,
        fiscal_name: company.fiscal_name,
        fiscal_address: company.fiscal_address,
        fiscal_city: company.fiscal_city,
        fiscal_zip_code: company.fiscal_zip_code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if (currentUser === undefined && currentUserCookies?.id !== undefined) {
      dispatch(fetchCurrentUser(currentUserCookies?.id));
    }
  }, [currentUser, dispatch, currentUserCookies]);

  const saveUserChanges = () => {
    var isValid = true;
    /* if (!isNameValid()) isValid = false;
    if (!isEmailValid()) isValid = false;
    if (!isFiscalNameValid()) isValid = false;
    if (!isNifValid()) isValid = false; */

    if (!isValid) return;

    const image =
      file === null
        ? null
        : {
            id: imageKey!,
            storage: "cache",
            metadata: {
              size: file.size,
              mime_type: file.type,
            },
          };

    const company = {
      id: currentUser!.company!.id!,
      image: image,
      name: formData.name,
      nif: formData.nif,
      fiscal_email: formData.fiscal_email,
      fiscal_name: formData.fiscal_name,
      fiscal_address: formData.fiscal_address,
      fiscal_city: formData.fiscal_city,
      fiscal_zip_code: formData.fiscal_zip_code,
    };

    dispatch(updateCompany(company));
  };

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "company-settings-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "company-settings-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  return (
    <Container fluid id="profile-settings">
      <Row>
        <Col>
          <h4>Perfil</h4>
        </Col>
      </Row>
      <Container className="profile-fields-container">
        <Row>
          <Col className="profile-img-col">
            <UserImageUpload
              image={currentUser?.company?.image_medium}
              file={file}
              setFile={setFile}
              setImageKey={setImageKey}
              showEditButton={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="company-settings-info-label">Nome</p>
            <GInput
              className="company-settings-info-input"
              value={formData.name}
              onChange={(e: any) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
            <p className="company-settings-info-label">Nome fiscal</p>
            <GInput
              className="company-settings-info-input"
              value={formData.fiscal_name}
              onChange={(e: any) =>
                setFormData({ ...formData, fiscal_name: e.target.value })
              }
            />
            <p className="company-settings-info-label">Email</p>
            <GInput
              className="company-settings-info-input"
              value={formData.fiscal_email}
              onChange={(e: any) =>
                setFormData({ ...formData, fiscal_email: e.target.value })
              }
            />
            <p className="company-settings-info-label">NIF</p>
            <GInput
              className="company-settings-info-input"
              value={formData.nif}
              onChange={(e: any) =>
                setFormData({ ...formData, nif: e.target.value })
              }
            />
            <p className="company-settings-info-label">Morada fiscal</p>
            <GInput
              className="company-settings-info-input"
              value={formData.fiscal_address}
              onChange={(e: any) =>
                setFormData({ ...formData, fiscal_address: e.target.value })
              }
            />
            <p className="company-settings-info-label">Cidade</p>
            <GInput
              className="company-settings-info-input"
              value={formData.fiscal_city}
              onChange={(e: any) =>
                setFormData({ ...formData, fiscal_city: e.target.value })
              }
            />
            <p className="company-settings-info-label">Código Postal</p>
            <GInput
              className="company-settings-info-input"
              value={formData.fiscal_zip_code}
              onChange={(e: any) =>
                setFormData({ ...formData, fiscal_zip_code: e.target.value })
              }
            />
          </Col>
        </Row>
        <Row className="profile-settings-justify-content-center">
          <Button
            className="submit-button profile-settings-margin-top"
            onClick={saveUserChanges}
          >
            Guardar
          </Button>
        </Row>
      </Container>
    </Container>
  );
};
