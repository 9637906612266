import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchCoursesSchedulesSucceeded,
  fetchCourseScheduleSucceeded,
  createCourseScheduleSucceeded,
  updateCourseScheduleSucceeded,
  deleteCourseScheduleSucceeded,
  CreateCourseScheduleType,
  UpdateCourseScheduleType,
  setErrorMessage,
  FetchCourseSchedulesType,
  FetchCourseScheduleCountersType,
  fetchCourseScheduleCountersSucceeded,
  fetchUserCourseScheduleCountersSucceeded,
  DeleteCourseSchedules,
} from "../../slices/courseScheduleSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchCoursesRequest,
  updateCourseScheduleRequest,
  createCourseScheduleRequest,
  deleteCourseScheduleRequest,
  fetchCourseRequest,
  CourseScheduleRequestResponseType,
  fetchCourseScheduleCountersRequest,
  fetchUserCourseScheduleCountersRequest,
} from "../../requests/courseScheduleRequests";

export function* fetchCoursesSchedulesSaga(
  action: PayloadAction<FetchCourseSchedulesType>,
  request = fetchCoursesRequest
) {
  const response: CourseScheduleRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchCoursesSchedulesSucceeded(response.coursesSchedules!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchCourseScheduleSaga(
  action: PayloadAction<string>,
  request = fetchCourseRequest
) {
  const response: CourseScheduleRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchCourseScheduleSucceeded(response.courseSchedule!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchCourseScheduleCountersSaga(
  action: PayloadAction<FetchCourseScheduleCountersType>,
  request = fetchCourseScheduleCountersRequest
) {
  const response: CourseScheduleRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchCourseScheduleCountersSucceeded(response.counters!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchUserCourseScheduleCountersSaga(
  action: PayloadAction<FetchCourseScheduleCountersType>,
  request = fetchUserCourseScheduleCountersRequest
) {
  const response: CourseScheduleRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchUserCourseScheduleCountersSucceeded(response.userCounters!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createCourseScheduleSaga(
  action: PayloadAction<CreateCourseScheduleType>,
  request = createCourseScheduleRequest
) {
  const response: CourseScheduleRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createCourseScheduleSucceeded(response.coursesSchedules!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateCourseScheduleSaga(
  action: PayloadAction<UpdateCourseScheduleType>,
  request = updateCourseScheduleRequest
) {
  const response: CourseScheduleRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateCourseScheduleSucceeded(response.courseSchedule!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deleteCourseScheduleSaga(
  action: PayloadAction<DeleteCourseSchedules>,
  request = deleteCourseScheduleRequest
) {
  const response: CourseScheduleRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteCourseScheduleSucceeded(action.payload.id));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
