import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { LoginModal } from "../LoginModal/LoginModal";
import { SignInModal } from "../SignInModal/SignInModal";
import logo from "../../assets/logo.svg";

import "./Header.css";
import { GButton } from "components/genericComponents/GButton/GButton";

export const Header = () => {
  const { t } = useTranslation();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignInModal, setSignInModal] = useState(false);

  return (
    <Navbar bg="white" expand="lg" className="nav">
      <Container>
        <Navbar.Brand href="#home" className="nav-title">
          <img src={logo} alt="" />
          {process.env.REACT_APP_APP_NAME}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav-links-container">
            <a href="#pricing" className="nav-links">
              {t("landingPage.pricing.title")}
            </a>
            <Link
              onClick={() => setShowLoginModal(true)}
              to="/"
              className="nav-links"
            >
              {t("landingPage.header.login")}
            </Link>
            <Link
              onClick={() => setSignInModal(true)}
              to="/"
              className="nav-links"
            >
              <GButton label={t("landingPage.header.signin")} />
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      {showLoginModal ? (
        <LoginModal
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
        />
      ) : undefined}
      {showSignInModal ? (
        <SignInModal
          showSignInModal={showSignInModal}
          setShowSignInModal={setSignInModal}
          isMonthly={true}
        />
      ) : undefined}
    </Navbar>
  );
};
