import {
  PaymentsPackage,
  CreatePaymentsPackage,
  SetPaymentsPackagePayload,
} from "types/paymentPackages";
import { axiosRequest, errorMessage } from "./request";

export type PaymentsPackagesRequestResponseType = {
  succeeded: boolean;
  paymentsPackages: PaymentsPackage[];
  paymentsPackage: PaymentsPackage;
  userPackage: PaymentsPackage;
  errorMessage: string | undefined;
};

export const fetchPaymentsPackagesRequest =
  async (): Promise<PaymentsPackagesRequestResponseType> => {
    try {
      const response = await axiosRequest().get("payment_packages");

      return {
        succeeded: true,
        paymentsPackages: response.data,
      } as PaymentsPackagesRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as PaymentsPackagesRequestResponseType;
    }
  };

export const createPaymentsPackageRequest = async (
  paymentsPackage: CreatePaymentsPackage
): Promise<PaymentsPackagesRequestResponseType> => {
  try {
    const response = await axiosRequest().post(`payment_packages`, {
      payment_package: paymentsPackage,
    });

    return {
      succeeded: true,
      paymentsPackage: response.data,
    } as PaymentsPackagesRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PaymentsPackagesRequestResponseType;
  }
};

export const updatePaymentsPackageRequest = async (
  paymentsPackage: PaymentsPackage
): Promise<PaymentsPackagesRequestResponseType> => {
  try {
    const response = await axiosRequest().put(
      `payment_packages/${paymentsPackage.id}`,
      { payment_package: paymentsPackage }
    );

    return {
      succeeded: true,
      paymentsPackage: response.data,
    } as PaymentsPackagesRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PaymentsPackagesRequestResponseType;
  }
};

export const deletePaymentsPackageRequest = async (
  packageId: string
): Promise<PaymentsPackagesRequestResponseType> => {
  try {
    await axiosRequest().delete(`payment_packages/${packageId}`);

    return { succeeded: true } as PaymentsPackagesRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PaymentsPackagesRequestResponseType;
  }
};

export const setUserPaymentsPackageRequest = async (
  params: SetPaymentsPackagePayload
): Promise<PaymentsPackagesRequestResponseType> => {
  try {
    const response = await axiosRequest().post(
      `users/${params.userId}/payment_packages`,
      {
        payment_package: {
          payment_package_id: params.package.id,
          start_at: params.startDate,
          sign_in_price: params.signInPrice,
        },
      }
    );

    return {
      succeeded: true,
      paymentsPackage: response.data,
    } as PaymentsPackagesRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PaymentsPackagesRequestResponseType;
  }
};
