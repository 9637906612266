import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { InsuranceSettings } from "components/SettingsPage/PaymentsSettings/InsuranceSettings/InsuranceSettings";
import { PaymentsPlan } from "components/SettingsPage/PaymentsSettings/PaymentsPlan/PaymentsPlan";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import { ExternalAPIs } from "components/SettingsPage/PaymentsSettings/ExternalAPIs/ExternalAPIs";
import { PaySubscription } from "components/SettingsPage/PaymentsSettings/PaySubscription/PaySubscription";
import { createAccountOnboardingLink } from "requests/stripeRequests";
import {
  fetchPaymentsPackages,
  selectConfirmationMessage,
  selectErrorMessage,
  selectPackages,
  setConfirmationMessage,
  setErrorMessage,
} from "slices/paymentsPackagesSlice";
import { PaymentsPackage } from "types/paymentPackages";
import { selectCurrentUser } from "slices/currentUserSlice";

import "components/SettingsPage/PaymentsSettings/PaymentsSettings.scss";

export const PaymentsSettings = () => {
  const dispatch = useDispatch();
  const company = useSelector(selectCurrentUser)?.company;

  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  const [showAddPlan, setShowAddPlan] = useState<boolean>(false);
  //const [stripeUrl, setStripeUrl] = useState("");

  const plans = useSelector(selectPackages);

  useEffect(() => {
    dispatch(fetchPaymentsPackages());
  }, [dispatch]);

  useEffect(() => {
    createAccountOnboardingLink().then((response) => {
      if (response.succeeded) {
        //setStripeUrl(response.url!)
      }
    });
  }, []);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "payments-settings-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "payments-settings-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  return (
    <div id="payments-settings">
      <PaySubscription />
      <ExternalAPIs />
      {company?.is_gym && <InsuranceSettings />}
      <GSection
        label="Planos"
        isSpacedBetween
        renderButtons={[
          <GButton variant="add" onClick={() => setShowAddPlan(true)}>
            <MdAdd />
          </GButton>,
        ]}
      >
        <div className="plans-container">
          <div className="plans-div">
            {showAddPlan ? (
              <PaymentsPlan isNewPlan setShowAddPlan={setShowAddPlan} />
            ) : undefined}
            {plans?.map((plan: PaymentsPackage) => {
              return (
                <PaymentsPlan
                  key={plan.id}
                  currPlan={plan}
                  setShowAddPlan={setShowAddPlan}
                />
              );
            })}
          </div>
        </div>
      </GSection>
    </div>
  );
};
