import { useRef, ChangeEvent } from "react";
import {
  S3RequestResponseType,
  uploadFileRequest,
} from "../../requests/s3Requests";
import { BiImageAdd } from "react-icons/bi";
import { HiPencilAlt } from "react-icons/hi";

type UserImageUploadProps = {
  image: string | undefined;
  setImageKey: (key: string) => void;
  file: File | null;
  setFile: (file: File) => void;
  showEditButton: boolean;
  isBigSquareImage?: boolean;
};

export const UserImageUpload = ({
  image,
  setImageKey,
  file,
  setFile,
  showEditButton,
  isBigSquareImage,
}: UserImageUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const imagePreview = () => {
    if (file !== null) {
      return <img src={URL.createObjectURL(file)} alt="something" />;
    } else if (image !== undefined) {
      return <img src={image} alt="something" />;
    }

    return undefined;
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      // update user data
      uploadFileRequest(e.target.files[0]).then(
        (response: S3RequestResponseType) => {
          if (!response.succeeded) return;

          setImageKey(response.key!);
        }
      );

      // then setFile
      setFile(e.target.files[0]);
    }
  };

  return (
    <>
      {file || image ? (
        <div
          className={
            isBigSquareImage ? "image-container-big-square" : "image-container"
          }
          ref={inputRef}
        >
          {imagePreview()}
          {showEditButton ? <HiPencilAlt className="edit-image" /> : undefined}
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/png, image/gif, image/jpeg"
          />
        </div>
      ) : (
        <>
          <div
            className={
              showEditButton
                ? "add-image-template"
                : "add-image-template-disabled"
            }
            ref={inputRef}
          >
            <input
              disabled={!showEditButton}
              type="file"
              onChange={handleFileChange}
              accept="image/png, image/gif, image/jpeg"
            />
            <BiImageAdd />
          </div>
        </>
      )}
    </>
  );
};
