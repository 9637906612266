import { BsFillXCircleFill } from "react-icons/bs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import { GInput } from "components/genericComponents/GInput/GInput";
import { CreateOrUpdateProductType, selectProducts } from "slices/productSlice";
import {
  CreateOrUpdateProductTransactionType,
  createProductTransaction,
  updateProductTransaction,
} from "slices/productTransactionSlice";

import "components/ProductsPage/TransactionsSection/AddOrEditTransaction/AddOrEditTransaction.scss";
import { ProductTransaction } from "types/productTransaction";
import { GDatePicker } from "components/genericComponents/GDatePicker/GDatePicker";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

type AddOrEditTransactionProps = {
  transaction?: ProductTransaction;
  products?: CreateOrUpdateProductType[];
  handleClose: () => void;
};

export const AddOrEditTransaction = ({
  transaction,
  products,
  handleClose,
}: AddOrEditTransactionProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const initialState = {
    id: transaction ? transaction.id : undefined,
    // need to make sure that the id is in the products transactions
    products: transaction
      ? transaction.products.map((product) => ({
          id: product.product_name,
          quantity: 0,
        }))
      : products!.map((product) => ({ id: product.id!, quantity: 0 })),
    date: transaction ? transaction.date : new Date().toISOString(),
    nif: transaction ? transaction.nif : undefined,
    email: transaction ? transaction.email : undefined,
    name: transaction ? transaction.name : undefined,
  };
  const dispatch = useDispatch();
  const [formData, setFormData] =
    useState<CreateOrUpdateProductTransactionType>(initialState);
  const allProducts = useSelector(selectProducts);

  const handleSubmitButton = () => {
    if (transaction) dispatch(updateProductTransaction(formData));
    else dispatch(createProductTransaction(formData));
    handleClose();
  };

  const handleSetCurrDate = (val: Date | undefined) => {
    setFormData({
      ...formData,
      date: val!.toISOString(),
    });
  };

  return (
    <GSection
      label={`${transaction ? "Editar" : "Criar"} Transação`}
      isSpacedBetween
      renderButtons={[
        <GButton icon={<BsFillXCircleFill />} onClick={handleClose} />,
      ]}
    >
      <div className="create-transaction">
        <div className="create-transaction-div">
          <p className="title">Dia da compra</p>
          <GDatePicker
            setCurrDate={handleSetCurrDate}
            currDate={new Date(formData.date)}
            className="date-picker"
          />
          <p className="title">Produtos</p>
          {formData?.products.map((product, index) => {
            return (
              <div className="products-div">
                <div>
                  <p className="title">Nome</p>
                  <GInput
                    value={
                      transaction
                        ? product.id
                        : allProducts.find((p) => p.id === product.id)?.title
                    }
                    disabled
                  />
                </div>
                <div>
                  <p className="title">Quantidade</p>
                  <GInput
                    value={formData.products[index].quantity}
                    type="number"
                    min={0}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        products: [
                          ...formData.products.slice(0, index),
                          {
                            ...formData.products[index],
                            quantity: Number.parseInt(e.target.value),
                          },
                          ...formData.products.slice(index + 1),
                        ],
                      })
                    }
                  />
                </div>
              </div>
            );
          })}
          <p className="title">Valor de venda (€)</p>
          <GInput
            value={
              transaction
                ? transaction.sale_price
                : formData.products.reduce((acc, curr) => {
                    const product = allProducts.find((p) => p.id === curr.id);
                    return acc + (product?.sale_price || 0) * curr.quantity;
                  }, 0)
            }
            disabled
          />
          {!transaction && (
            <div className="contribuinte-div">
              <div>
                <p className="title" style={{ marginTop: "0" }}>Deseja contribuinte?</p>
              </div>
              <GButton
                variant="transparent"
                className="invoice-check-button"
                icon={isChecked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
                iconSize="big"
                onClick={() => setIsChecked(!isChecked)}
              />
            </div>
          )}

          {((!transaction && isChecked) || (transaction && transaction.name)) && (
            <div>
              <p className="title">NIF</p>
              <GInput
                value={formData.nif}
                disabled={transaction !== undefined}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    nif: e.target.value
                  })
                }
              />
              <p className="title">Email</p>
              <GInput
                value={formData.email}
                disabled={transaction !== undefined}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    email: e.target.value
                  })
                }
              />
              <p className="title">Nome</p>
              <GInput
                value={formData.name}
                disabled={transaction !== undefined}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    name: e.target.value
                  })
                }
              />
            </div>
          )}

          <div className="submit-div">
            <GButton
              label="Submeter"
              onClick={handleSubmitButton}
              disabled={formData.products.some(
                (product) => product.quantity === 0
              ) || (isChecked && (formData.nif === '' || formData.nif === undefined || formData.email === '' || formData.email === undefined))}
            />
          </div>
        </div>
      </div>
    </GSection>
  );
};
