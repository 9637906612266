import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GSection } from "components/genericComponents/GSection/GSection";

import "components/SettingsPage/MobileAppSettings/MobileAppSettings.scss";
import { selectCurrentUser, updateCompany } from "slices/currentUserSlice";
import { Button } from "react-bootstrap";
import { GButton } from "components/genericComponents/GButton/GButton";
import { HomeScreenApp } from "components/SettingsPage/MobileAppSettings/HomeScreenApp/HomeScreenApp";
import { WorkoutExerciseScreenApp } from "components/SettingsPage/MobileAppSettings/WorkoutExerciseScreenApp/WorkoutExerciseScreenApp";
import { ChatScreenApp } from "components/SettingsPage/MobileAppSettings/ChatScreenApp/ChatScreenApp";

const { ChromePicker } = require("react-color");

export const MobileAppSettings = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const shouldShowInfo =
    localStorage.getItem("isGymView") === "true"
      ? currentUser?.company?.image_medium === null
      : currentUser?.image_medium === null;
  const [color, setColor] = useState<string>(
    currentUser?.company?.colors && currentUser.company.colors.length > 0
      ? currentUser.company.colors[0]
      : "#6842FF"
  );
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  const handleColorsSubmit = () => {
    dispatch(updateCompany({ id: currentUser?.company?.id!, colors: [color] }));
    setShowColorPicker(false);
  };

  return (
    <div className="mobile-app-settings">
      {shouldShowInfo && (
        <GSection className="info-div">
          <p className="info">
            Caso pretenda ter o seu logótipo disponível na aplicação móvel, tem
            apenas que alterar a imagem no separador Conta.
          </p>
        </GSection>
      )}
      <GSection label="Cores da Aplicação">
        <div className="app-colors">
          <div>
            <p className="color-title">Cor do Tema</p>
            <Button
              className="color-btn"
              style={{ backgroundColor: `${color}` }}
              onClick={() => setShowColorPicker(true)}
            />
          </div>
        </div>
        <div className="color-picker-div">
          {showColorPicker && (
            <ChromePicker
              color={color}
              onChangeComplete={(val: any) => {
                setColor(val.hex);
              }}
            />
          )}
        </div>
        <div className="submit-btn-div">
          <GButton label="Submeter" onClick={handleColorsSubmit} />
        </div>
      </GSection>
      <GSection label="Pré-visualizar">
        <div className="preview-div">
          <HomeScreenApp color={color} />
          <WorkoutExerciseScreenApp color={color} />
          <ChatScreenApp color={color} />
        </div>
      </GSection>
    </div>
  );
};
