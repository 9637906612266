import { PayloadAction } from "@reduxjs/toolkit";
import { put, call } from "redux-saga/effects";
import {
  loginRequest,
  logoutRequest,
  LoginRequestResponseType,
  createAccountRequest,
} from "requests/currentUserRequests";
import {
  fetchUserRequest,
  UserRequestResponseType,
} from "requests/userRequests";
import {
  updateCompanyRequest,
  updateCompanyCourseSettingsRequest,
  CompanyResponseType,
} from "requests/companyRequests";
import {
  loginSucceeded,
  LoginType,
  CreateAccountType,
  LogoutType,
  setErrorMessage,
  fetchCurrentUserSucceeded,
  logoutSucceeded,
  createAccountSucceeded,
  UpdateCompanyType,
  updateCompanySucceeded,
  UpdateCompanyCourseSettingsType,
  updateCompanyCourseSettingsSucceeded,
} from "slices/currentUserSlice";

export function* loginSaga(
  action: PayloadAction<LoginType>,
  request = loginRequest
) {
  const response: LoginRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.user !== undefined) {
    yield put(loginSucceeded(response.user));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createAccountSaga(
  action: PayloadAction<CreateAccountType>,
  request = createAccountRequest
) {
  const response: LoginRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createAccountSucceeded());
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* logoutSaga(
  action: PayloadAction<LogoutType>,
  request = logoutRequest
) {
  const response: LoginRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.user === undefined) {
    yield put(logoutSucceeded(response.user));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchCurrentUserSaga(
  action: PayloadAction<string>,
  request = fetchUserRequest
) {
  const response: UserRequestResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(fetchCurrentUserSucceeded(response.user!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateCompanySaga(
  action: PayloadAction<UpdateCompanyType>,
  request = updateCompanyRequest
) {
  const response: CompanyResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(updateCompanySucceeded(response.company!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateCompanyCourseSettingsSaga(
  action: PayloadAction<UpdateCompanyCourseSettingsType>,
  request = updateCompanyCourseSettingsRequest
) {
  const response: CompanyResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(updateCompanyCourseSettingsSucceeded(response.company!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
