import { Button, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";

import logo from "./../../../../assets/logo.svg";

import "./CalendarModal.css";

type CalendarModalType = {
  showModal: boolean;
  onSubmitModalButtonClick: (singleEvent: boolean) => void;
};

export const CalendarModal = ({
  showModal,
  onSubmitModalButtonClick,
}: CalendarModalType) => {
  const [singleEvent, setSingleEvent] = useState<boolean>(true);

  const handleChange = () => {
    setSingleEvent(!singleEvent);
  };

  return (
    <Modal
      className="modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
    >
      <Modal.Body>
        <Row className="modal-flex-center">
          <img className="logo" src={logo} alt="" />
        </Row>
        <p className="modal-title">Eliminar Eventos</p>
        <Form className="calendar-modal-form">
          <Form.Check
            type="radio"
            id={`this-event`}
            label={`Este evento`}
            name="events-radio"
            checked={singleEvent}
            onChange={handleChange}
            className="modal-radio"
          />
          <Form.Check
            type="radio"
            id={`all-events`}
            label={`Todos os eventos`}
            name="events-radio"
            checked={!singleEvent}
            onChange={handleChange}
            className="modal-radio last"
          />

          <Row className="modal-flex-center padding">
            <Button
              className="modal-submit-button"
              onClick={() => onSubmitModalButtonClick(singleEvent)}
            >
              Submeter
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
