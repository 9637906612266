import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { TableComponent } from "../../TableComponent/TableComponent";
import { Container } from "react-bootstrap";
import {
  fetchUsersWithMissingPayments,
  selectUsersWithMissingPayments,
} from "../../../slices/userSlice";
import "./UsersWithMissingPayments.css";
import { formatToFirstTwoAndLastName } from "helpers/utils";

export const UsersWithMissingPayments = () => {
  const dispatch = useDispatch();
  const usersWithMissingPayments = useSelector(selectUsersWithMissingPayments);
  const [date] = useState<Date>(moment().toDate());

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Utilizador",
        accessor: "user_name",
        Cell: (props: any) => {
          return `${formatToFirstTwoAndLastName(props.cell.value)}`;
        },
      },
      {
        Header: "Pagamentos em atraso",
        accessor: "payments_in_delay",
        Cell: (props: any) => {
          return `${props.cell.row.original.payments_in_delay} €`;
        },
      },
      {
        Header: "Plano",
        accessor: "plan_name",
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(fetchUsersWithMissingPayments());
  }, [dispatch, date]);

  return (
    <Container style={{ flex: 1 }}>
      <div className="courses-chart-container">
        <h4 style={{ textAlign: "center", marginTop: 10 }}>
          {" "}
          Pagamentos em atraso
        </h4>
        <div style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 20 }}>
          {usersWithMissingPayments.length ? (
            <TableComponent
              name="courses"
              className=""
              columns={tableColumns}
              data={usersWithMissingPayments}
              showRemoveButton={false}
              rowClickAction={(row: any) => {}}
            />
          ) : (
            <p style={{ textAlign: "center" }}>Sem pagamentos em atraso</p>
          )}
        </div>
      </div>
    </Container>
  );
};
