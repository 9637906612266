import { PayloadAction } from "@reduxjs/toolkit";
import {
  CreateChatgptMessageType,
  createChatgptMessageSucceeded,
  fetchChatgptChatSucceeded,
  fetchChatgptChatsSucceeded,
} from "../../slices/chatgptSlice";
import { put, call } from "redux-saga/effects";
import {
  ChatGptResponseType,
  createChatGptMessageRequest,
  fetchChatGptChatRequest,
  fetchChatGptChatsRequest,
} from "../../requests/chatgptRequests";

export function* createChatgptMessageSaga(
  action: PayloadAction<CreateChatgptMessageType>,
  request = createChatGptMessageRequest
) {
  const response: ChatGptResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    if (action.payload.chatgpt_chat_id) {
      yield put(createChatgptMessageSucceeded([response.message!]));
    } else {
      // If it is the first message we need to store the initial prompt, because the chat was not created yet
      // and there was no id. We have one now.
      yield put(
        createChatgptMessageSucceeded([
          {
            is_chatgpt_answer: false,
            message: action.payload.message,
            chatgpt_chat_id: response.message.chatgpt_chat_id,
          },
          response.message!,
        ])
      );
    }
  } else {
    // TODO
  }
}

export function* fetchChatgptChatsSaga(
  action: any,
  request = fetchChatGptChatsRequest
) {
  const response: ChatGptResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchChatgptChatsSucceeded(response.chats!));
  } else {
    // TODO
  }
}

export function* fetchChatgptChatSaga(
  action: PayloadAction<string>,
  request = fetchChatGptChatRequest
) {
  const response: ChatGptResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(fetchChatgptChatSucceeded(response.chat!));
  } else {
    // TODO
  }
}
