import { Col, Container, Row } from "react-bootstrap";

import "./BusinessSimplified.css";
import { useTranslation } from "react-i18next";

export const BusinessSimplified = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%" }} id="business-simplified">
      <Container className="bs-container">
        <Row>
          <Col className="bs-title-col">
            <h1 className="bs-title">
              {t("landingPage.simplifiedBusiness.title")}
            </h1>
          </Col>
        </Row>
        <Row>
          <p style={{ textAlign: "center" }}>
            {t("landingPage.simplifiedBusiness.description")}
          </p>
        </Row>
      </Container>
    </div>
  );
};
