import { takeLatest } from "redux-saga/effects";
import {
  fetchInsurance,
  fetchUserInsurance,
  setInsurance,
  setUserInsurance,
  updateUserInsurance,
} from "slices/insuranceSlice";
import {
  fetchInsuranceSaga,
  fetchUserInsuranceSaga,
  setInsuranceSaga,
  setUserInsuranceSaga,
  updateUserInsuranceSaga,
} from "./insuranceSaga";

function* insuranceSaga() {
  yield takeLatest(fetchInsurance, fetchInsuranceSaga);
  yield takeLatest(setInsurance, setInsuranceSaga);
  yield takeLatest(fetchUserInsurance, fetchUserInsuranceSaga);
  yield takeLatest(setUserInsurance, setUserInsuranceSaga);
  yield takeLatest(updateUserInsurance, updateUserInsuranceSaga);
}

export default insuranceSaga;
