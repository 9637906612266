import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import logo from "../../assets/logo.svg";
import { InputForm } from "../../components/InputForm/InputForm";
import {
  createWaitlistEntry,
  selectErrorMessage,
  selectConfirmationMessage,
  cleanMessages,
} from "../../slices/waitlistSlice";

import "./JoinWaitlistModal.css";
import { useTranslation } from "react-i18next";

type JoinWaitlistModalProps = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
};

export const JoinWaitlistModal = (props: JoinWaitlistModalProps) => {
  const { t } = useTranslation();
  const showModal = props.showModal;
  const setShowModal = props.setShowModal;
  const dispatch = useDispatch();
  const waitlistErrorMessage = useSelector(selectErrorMessage);
  const waitlistConfirmationMessage = useSelector(selectConfirmationMessage);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [nameError, setNameError] = useState<string | undefined>(undefined);

  const onSubmitButtonClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    var isValid = true;

    if (!isEmailValid()) isValid = false;
    if (!isNameValid()) isValid = false;

    if (!isValid) return;

    dispatch(createWaitlistEntry({ email: email, name: name }));
  };

  const isNameValid = (): boolean => {
    if (name === "") {
      setNameError(t("error.emptyName"));
      return false;
    }

    setNameError(undefined);

    return true;
  };

  const isEmailValid = (): boolean => {
    if (email === "") {
      setEmailError(t("error.emptyEmail"));
      return false;
    }

    const regex = new RegExp(
      // Turned \t into \\t to escape it (if something is off, try removing a \)
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \\t]|(\\[\\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\\t -Z^-~]*])"
    );

    if (!regex.test(email)) {
      setEmailError(t("error.invalidEmail"));
      return false;
    }

    setEmailError(undefined);
    return true;
  };

  useEffect(() => {
    if (waitlistErrorMessage !== undefined) {
      toast.error(waitlistErrorMessage, {
        toastId: "waitlist-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(cleanMessages());
    }
  }, [dispatch, waitlistErrorMessage]);

  useEffect(() => {
    if (waitlistConfirmationMessage !== undefined) {
      toast.success(waitlistConfirmationMessage, {
        toastId: "waitlist-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(cleanMessages());

      setShowModal(false);
    }
  }, [dispatch, setShowModal, waitlistConfirmationMessage]);

  return (
    <>
      <Modal
        className="modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <Row className="modal-flex-center">
            <img className="logo" src={logo} alt="logo" />
          </Row>
          <p className="modal-title">
            {t("landingPage.welcomeComponent.joinWaitlistModal.askDemo")}
          </p>
          <Form
            style={{ width: "80%", margin: "auto" }}
            onSubmit={onSubmitButtonClick}
          >
            <InputForm
              value={name}
              label={t("generic.name")}
              onChange={(e: any) => setName(e.target.value)}
              errorMessage={nameError}
            />
            <InputForm
              value={email}
              label={t("generic.email")}
              type="email"
              onChange={(e: any) => setEmail(e.target.value)}
              errorMessage={emailError}
            />
            <Row className="modal-flex-center padding">
              <Button
                className="modal-submit-button"
                onClick={onSubmitButtonClick}
              >
                {t("generic.submit")}
              </Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
