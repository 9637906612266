import { takeLatest } from "redux-saga/effects";
import {
  fetchPlans,
  fetchUpgradablePlans,
} from "../../slices/companyPlanSlice";
import {
  fetchCompanyPlansSaga,
  fetchUpgradableCompanyPlansSaga,
} from "./companyPlanSaga";

function* companyPlanSaga() {
  yield takeLatest(fetchPlans, fetchCompanyPlansSaga);
  yield takeLatest(fetchUpgradablePlans, fetchUpgradableCompanyPlansSaga);
}

export default companyPlanSaga;
