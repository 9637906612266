import { Col, Container, Row } from "react-bootstrap";

import { GButton } from "components/genericComponents/GButton/GButton";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchCurrentUser,
  selectConfirmationMessage,
  selectCurrentUser,
  selectErrorMessage,
  selectTimeConstraints,
  setConfirmationMessage,
  setErrorMessage,
  updateCompanyCourseSettings,
} from "../../../../slices/currentUserSlice";
import { InputForm } from "../../../InputForm/InputForm";
import "./CoursesScheduling.css";

export const CoursesScheduling = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const timeConstraints = useSelector(selectTimeConstraints);
  const [timeAllowedBeforeUnapply, setTimeAllowedBeforeUnapply] = useState<
    number | undefined
  >(timeConstraints.timeAllowedBeforeUnapply);
  const [timeAllowedForApply, setTimeAllowedForApply] = useState<
    number | undefined
  >(timeConstraints.timeAllowedForApply);
  const [showApply, setShowApply] = useState<boolean>(false);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  useEffect(() => {
    dispatch(fetchCurrentUser(currentUser?.id!));
  }, [dispatch, currentUser?.id]);

  const onSubmitButtonClick = () => {
    dispatch(
      updateCompanyCourseSettings({
        course_hours_before_users_can_start_applying:
          timeAllowedForApply === undefined ? 0 : timeAllowedForApply,
        course_minutes_before_users_cant_apply_anymore:
          timeAllowedBeforeUnapply === undefined ? 0 : timeAllowedBeforeUnapply,
      })
    );
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "courses-scheduling-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "courses-scheduling-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  return (
    <Container fluid id="courses-scheduling-container-settings">
      <Row>
        <Col className="title-col">
          <h4>Períodos de Inscrição</h4>
        </Col>
      </Row>
      <Container className="exercises-variables-settings-container">
        <Row>
          <Col>
            <InputForm
              value={timeAllowedBeforeUnapply}
              label="Tempo até poder cancelar inscrição (em minutos)"
              type="number"
              onChange={(e: BaseSyntheticEvent) => {
                setTimeAllowedBeforeUnapply(e.target.value);
                setShowApply(true);
              }}
              className="mb-3"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputForm
              value={timeAllowedForApply}
              label="Tempo para poder iniciar inscrição (em horas)"
              type="number"
              onChange={(e: BaseSyntheticEvent) => {
                setTimeAllowedForApply(e.target.value);
                setShowApply(true);
              }}
              className="mb-1"
            />
          </Col>
        </Row>
        {showApply && (
          <Row>
            <Col className="courses-scheduling-button-col">
              <GButton label="Submeter" onClick={() => onSubmitButtonClick()} />
            </Col>
          </Row>
        )}
      </Container>
    </Container>
  );
};
