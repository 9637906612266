import { Col, Container, Row } from "react-bootstrap";
import { MdAdd } from "react-icons/md";

import "./CoursesInfoContainerSettings.css";
import { useEffect, useState } from "react";
import { fetchCourses, selectCourses } from "../../../../slices/courseSlice";
import { useDispatch, useSelector } from "react-redux";
import { CourseInfoSettings } from "./CourseInfoSettings/CourseInfoSettings";
import { AddCourseInfoSettings } from "./AddCourseInfoSettings/AddCourseInfoSettings";
import { v4 as uuidv4 } from "uuid";

export const CoursesInfoContainerSettings = () => {
  const dispatch = useDispatch();
  const courses = useSelector(selectCourses);
  const [showAddCourse, setShowAddCourse] = useState(false);

  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  return (
    <Container fluid id="courses-info-container-settings">
      <Row>
        <Col className="title-col">
          <h4>Aulas</h4>
          <button
            className="dashboard-title-btn"
            onClick={() => setShowAddCourse(true)}
          >
            <MdAdd />
          </button>
        </Col>
      </Row>
      <Container className="exercises-variables-settings-container">
        {showAddCourse ? (
          <AddCourseInfoSettings
            key={uuidv4()}
            setShowAddCourse={setShowAddCourse}
          />
        ) : undefined}
        {courses?.map((course, index) => {
          return (
            <CourseInfoSettings key={course.id} course={course} index={index} />
          );
        })}
      </Container>
    </Container>
  );
};
