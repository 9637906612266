import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchExerciseCategoriesSucceeded,
  CreateExerciseCategoryType,
  UpdateExerciseCategoryType,
  createExerciseCategorySucceeded,
  updateExerciseCategorySucceeded,
  deleteExerciseCategorySucceeded,
  setErrorMessage,
} from "../../slices/exerciseCategorySlice";
import { put, call } from "redux-saga/effects";
import {
  fetchExerciseCategoriesRequest,
  createExerciseCategoryRequest,
  updateExerciseCategoryRequest,
  deleteExerciseCategoryRequest,
  ExerciseCategoryRequestResponseType,
} from "../../requests/exerciseCategoriesRequests";

export function* fetchExerciseCategoriesSaga(
  action: any,
  request = fetchExerciseCategoriesRequest
) {
  const response: ExerciseCategoryRequestResponseType = yield call(request);

  if (response.exerciseCategories !== undefined) {
    yield put(fetchExerciseCategoriesSucceeded(response.exerciseCategories));
  }
}

export function* createExerciseCategorySaga(
  action: PayloadAction<CreateExerciseCategoryType>,
  request = createExerciseCategoryRequest
) {
  const response: ExerciseCategoryRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createExerciseCategorySucceeded(response.exerciseCategory!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateExerciseCategorySaga(
  action: PayloadAction<UpdateExerciseCategoryType>,
  request = updateExerciseCategoryRequest
) {
  const response: ExerciseCategoryRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateExerciseCategorySucceeded(response.exerciseCategory!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deleteExerciseCategorySaga(
  action: PayloadAction<string>,
  request = deleteExerciseCategoryRequest
) {
  const response: ExerciseCategoryRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteExerciseCategorySucceeded(action.payload));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
