import { axiosRequest, errorMessage } from "./request";
import { Exercise } from "../types/exercise";
import {
  CreateExerciseType,
  UpdateExerciseType,
} from "../slices/exerciseSlice";

export type ExerciseRequestResponseType = {
  succeeded: boolean;
  exercises: Array<Exercise> | undefined;
  exercise: Exercise | undefined;
  errorMessage: string | undefined;
};

export const fetchExercisesRequest =
  async (): Promise<ExerciseRequestResponseType> => {
    try {
      const response = await axiosRequest().get("exercises");
      const exercises = response.data as Array<Exercise>;

      return {
        succeeded: true,
        exercises: exercises,
      } as ExerciseRequestResponseType;
    } catch (e) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as ExerciseRequestResponseType;
    }
  };

export const createExerciseRequest = async (
  params: CreateExerciseType
): Promise<ExerciseRequestResponseType> => {
  try {
    const response = await axiosRequest().post("exercises", {
      exercise: params,
    });
    const exercise: Exercise = response.data;

    return {
      succeeded: true,
      exercise: exercise,
    } as ExerciseRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ExerciseRequestResponseType;
  }
};

export const updateExerciseRequest = async (
  params: UpdateExerciseType
): Promise<ExerciseRequestResponseType> => {
  try {
    const response = await axiosRequest().put(`exercises/${params.id}`, {
      exercise: params,
    });
    const exercise: Exercise = response.data;

    return {
      succeeded: true,
      exercise: exercise,
    } as ExerciseRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ExerciseRequestResponseType;
  }
};

export const deleteExerciseRequest = async (
  exerciseId: string
): Promise<ExerciseRequestResponseType> => {
  try {
    await axiosRequest().delete(`exercises/${exerciseId}`);

    return { succeeded: true } as ExerciseRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ExerciseRequestResponseType;
  }
};
