import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
// TODO: Change locale to dynamic according to selected language
// The following import is what defines the translation of the calendar
import "moment/locale/pt";
import { datesAreOnSameDay } from "../../helpers/date";
import {
  selectCoursesSchedules,
  selectIsLoading,
} from "../../slices/courseScheduleSlice";
import { selectCurrentUser } from "../../slices/currentUserSlice";
import { CourseSchedule } from "../../types/courseSchedule";
import { CourseScheduleModal } from "../CourseScheduleModal/CourseScheduleModal";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./DashboardCoursesPage.css";
import { fetchUsers } from "../../slices/userSlice";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { getMonth, setDate, setMonth } from "date-fns";

type DashboardCoursesCalendarProps = {
  fetchNewDates: (view: string, date: Date) => void;
};

export const DashboardCoursesCalendar = (
  props: DashboardCoursesCalendarProps
) => {
  ReactGA.send("/calendar");

  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedCourseSchedule, setSelectedCourseSchedule] = useState<
    CourseSchedule | undefined
  >(undefined);
  const [startAt, setStartAt] = useState<Date | undefined>(undefined);
  const [endAt, setEndAt] = useState<Date | undefined>(undefined);
  const dispatch = useDispatch();
  const coursesSchedules = useSelector(selectCoursesSchedules);
  const localizer = momentLocalizer(moment);
  const currentUser = useSelector(selectCurrentUser);
  const isLoadingSchedules = useSelector(selectIsLoading);
  const isGymView = localStorage.getItem("isGymView") === "true";
  const [currentCalendarDate, setCurrentCalendarDate] = useState<Date>(
    moment().toDate()
  );

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleSelectEvent = useCallback(
    (event: any) => {
      if (!isGymView && event.personal_trainer.id !== currentUser?.id) return;
      if (isGymView && event.course === null) return;

      const scheduleSelected = coursesSchedules.find(
        (courseSchedule: CourseSchedule) => {
          return courseSchedule.id === event.id;
        }
      );

      let path = `${
        scheduleSelected!.id
      }?start_at=${event.start.toISOString()}&end_at=${event.end.toISOString()}`;
      navigate(path);
    },
    [coursesSchedules, currentUser, isGymView, navigate]
  );

  const handleSelectSlot = useCallback(
    (event: any) => {
      if (event.start.getHours() < 5) {
        event.start.setHours(5);
      } else if (event.start.getHours() >= 22) {
        event.start.setHours(21);
        event.start.setMinutes(0);
      }

      if (event.end.getHours() < 5) {
        event.end.setHours(6);
      } else if (event.end.getHours() >= 22) {
        event.end.setHours(22);
        event.end.setMinutes(0);
      }

      let endDate = new Date(event.end);
      endDate = setMonth(endDate, getMonth(event.start));
      endDate = setDate(endDate, event.start.getDate());

      setStartAt(event.start);
      setEndAt(endDate);
      setShowModal(true);

      let path = `add-schedule?start_at=${event.start.toISOString()}&end_at=${endDate.toISOString()}`;
      navigate(path);
    },
    [navigate]
  );

  const eventPropGetter = useCallback(
    (event: any) => {
      if (isGymView) {
        if (event.course !== null) {
          return {
            style: {
              backgroundColor: event.color ? event.color : "#6842FF",
              border: 0,
              boxShadow: "0px 0px 5px #000000",
            },
          };
        } else {
          return {
            style: {
              backgroundColor: "#2196F3",
              border: 0,
              boxShadow: "0px 0px 5px #000000",
            },
          };
        }
      } else {
        if (event.personal_trainer.id === currentUser?.id) {
          return {
            style: {
              backgroundColor: "#6842ff",
              border: 0,
              boxShadow: "0px 0px 5px #000000",
            },
          };
        } else {
          return {
            style: {
              backgroundColor: "#2196F3",
              border: 0,
              boxShadow: "0px 0px 5px #000000",
            },
          };
        }
      }
    },
    [currentUser, isGymView]
  );

  return (
    <>
      <CourseScheduleModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedCourseSchedule={selectedCourseSchedule}
        setSelectedCourseSchedule={setSelectedCourseSchedule}
        startAt={startAt}
        endAt={endAt}
      />
      <Calendar
        eventPropGetter={eventPropGetter}
        localizer={localizer}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        min={new Date(1972, 0, 0, 5, 0, 0, 0)}
        max={new Date(3000, 0, 0, 22, 0, 0, 0)}
        defaultView="week"
        onView={(view) => {
          props.fetchNewDates(view, currentCalendarDate);
        }}
        onNavigate={(date: Date, view: string) => {
          setCurrentCalendarDate(date);
          props.fetchNewDates(view, date);
        }}
        selectable
        components={{
          event: (props) => {
            if (isGymView) {
              return (
                <div className="dashboard-courses-calendar-event-slot">
                  {props.event.personal_trainer ? (
                    <img
                      alt=""
                      className="dashboard-courses-calendar-event-image"
                      src={
                        props.event.personal_trainer.image_small ??
                        "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                      }
                    />
                  ) : undefined}
                  <p className="dashboard-courses-calendar-event-title">
                    {" "}
                    {props.event.title}
                  </p>
                </div>
              );
            } else {
              return (
                <>
                  <div className="dashboard-courses-calendar-event-slot">
                    {props.event.personal_trainer.id === currentUser?.id &&
                    props.event.user !== undefined ? (
                      <img
                        alt=""
                        className="dashboard-courses-calendar-event-image"
                        src={
                          props.event.user.image_small ??
                          "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                        }
                      />
                    ) : undefined}
                    <p className="dashboard-courses-calendar-event-title">
                      {" "}
                      {props.event.title}
                    </p>
                  </div>
                </>
              );
            }
          },
        }}
        messages={{
          date: "Data",
          time: "Tempo",
          event: "Event",
          allDay: "Dia inteiro",
          week: "Semana",
          work_week: "Work Week",
          day: "Dia",
          month: "Mês",
          previous: "Anterior",
          next: "Próximo",
          yesterday: "Ontem",
          tomorrow: "Amanhã",
          today: "Hoje",
          agenda: "Agenda",

          noEventsInRange: "Não há eventos para estas datas.",

          showMore: (total) => `+${total} mais`,
        }}
        events={
          isLoadingSchedules
            ? []
            : coursesSchedules.map((courseSchedule: CourseSchedule) => {
                const start = new Date(courseSchedule.start_at);
                const end = new Date(courseSchedule.end_at);

                const title = courseSchedule.course
                  ? `${courseSchedule.title} ${courseSchedule.slots_filled}/${courseSchedule.slots_total}`
                  : courseSchedule.title;

                const user =
                  courseSchedule.users && courseSchedule.users.length > 0
                    ? courseSchedule.users[0]
                    : undefined;

                return {
                  id: courseSchedule.id,
                  start: new Date(start),
                  end: new Date(end),
                  title: title,
                  desc: "AAA",
                  allDay: !datesAreOnSameDay(start, end),
                  user: user,
                  personal_trainer: courseSchedule.personal_trainer,
                  color: courseSchedule.course?.color,
                };
              })
        }
        startAccessor="start"
        endAccessor="end"
      />
    </>
  );
};
