import { takeLatest } from "redux-saga/effects";
import {
  fetchRecipesSaga,
  fetchRecipeSaga,
  createRecipeSaga,
  updateRecipeSaga,
  deleteRecipeSaga,
  fetchUserNutritionSchedulerSaga,
  createUserNutritionSchedulerNoteSaga,
  updateUserNutritionSchedulerNoteSaga,
  deleteUserNutritionSchedulerNoteSaga,
  createUserNutritionSchedulerItemSaga,
  updateUserNutritionSchedulerItemSaga,
  deleteUserNutritionSchedulerItemSaga,
  searchFoodSaga,
  searchRecipesSaga,
} from "sagas/nutritionSaga/nutritionSaga";
import {
  fetchRecipes,
  fetchRecipe,
  createRecipe,
  updateRecipe,
  deleteRecipe,
  fetchUserNutritionScheduler,
} from "slices/nutritionSlice";
import {
  createUserNutritionSchedulerNote,
  updateUserNutritionSchedulerNote,
  deleteUserNutritionSchedulerNote,
  createUserNutritionSchedulerItem,
  updateUserNutritionSchedulerItem,
  deleteUserNutritionSchedulerItem,
  searchFood,
  searchRecipes,
} from "slices/nutritionSchedulerSlice";

function* nutritionSaga() {
  yield takeLatest(fetchRecipes, fetchRecipesSaga);
  yield takeLatest(fetchRecipe, fetchRecipeSaga);
  yield takeLatest(createRecipe, createRecipeSaga);
  yield takeLatest(updateRecipe, updateRecipeSaga);
  yield takeLatest(deleteRecipe, deleteRecipeSaga);
  yield takeLatest(
    fetchUserNutritionScheduler,
    fetchUserNutritionSchedulerSaga
  );
  yield takeLatest(
    createUserNutritionSchedulerNote,
    createUserNutritionSchedulerNoteSaga
  );
  yield takeLatest(
    updateUserNutritionSchedulerNote,
    updateUserNutritionSchedulerNoteSaga
  );
  yield takeLatest(
    deleteUserNutritionSchedulerNote,
    deleteUserNutritionSchedulerNoteSaga
  );
  yield takeLatest(
    createUserNutritionSchedulerItem,
    createUserNutritionSchedulerItemSaga
  );
  yield takeLatest(
    updateUserNutritionSchedulerItem,
    updateUserNutritionSchedulerItemSaga
  );
  yield takeLatest(
    deleteUserNutritionSchedulerItem,
    deleteUserNutritionSchedulerItemSaga
  );
  yield takeLatest(searchFood, searchFoodSaga);
  yield takeLatest(searchRecipes, searchRecipesSaga);
}

export default nutritionSaga;
