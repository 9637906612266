import { format } from "date-fns";
import { PaymentsPackage } from "types/paymentPackages";
import { User } from "types/user";

import "components/DashboardCustomersPage/CustomerPayments/PaymentPlanCard/PaymentPlanCard.scss";

type PaymentPlanCardI = {
  plan: PaymentsPackage;
  endDate?: string;
  handleClick?: (plan: PaymentsPackage) => void;
  user?: User;
};

export const PaymentPlanCard = ({
  plan,
  endDate,
  handleClick,
  user,
}: PaymentPlanCardI) => {
  const frequencyString = () => {
    if (plan.frequency !== null) {
      if (plan.frequency === "anual") return " anual";
      if (plan.frequency === "monthly") return " mensal";
    }
    return "";
  };

  return (
    <div
      className={`payment-plan-card${handleClick ? " clickable" : ""}`}
      onClick={() => handleClick && handleClick(plan)}
    >
      <h4>{plan.title}</h4>
      <p>
        {plan.type === "RecurrentPaymentPackage"
          ? `Recurrente${frequencyString()}`
          : "Sessões"}
      </p>
      <p>{`${format(new Date(plan.start_time), "HH:mm")} - ${format(
        new Date(plan.end_time),
        "HH:mm"
      )}`}</p>
      {plan.max_entries_per_week !== null ? (
        plan.max_entries_per_week < 6 ? (
          <p>{`Máximo ${plan.max_entries_per_week} vez${
            plan.max_entries_per_week === 1 ? "" : "es"
          } por semana`}</p>
        ) : (
          <p>Entradas Ilimitadas</p>
        )
      ) : undefined}
      {plan.number_of_sessions !== null ? (
        <p>{`${plan.number_of_sessions} sess${
          plan.number_of_sessions > 1 ? "ões" : "ão"
        }`}</p>
      ) : undefined}
      {plan.term_in_days !== null && endDate !== undefined ? (
        <p>{`Termina a ${format(new Date(endDate), "dd-MM-yy")}`}</p>
      ) : undefined}
      {plan.type === "RecurrentPaymentPackage" && user && (
        <p>
          Entradas disponíveis - {user.available_gym_entries_on_current_week}
        </p>
      )}
      {plan.type === "SessionsPaymentPackage" && user && (
        <p>Sessões disponíveis - {user.sessions_left}</p>
      )}
    </div>
  );
};
