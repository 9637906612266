import { UserWorkoutHistory } from "../types/user_workout_history";
import { axiosRequest, errorMessage } from "./request";
import { CreateWorkoutHistoryType } from "../slices/workoutHistorySlice";

export type WorkoutsHistoryRequestResponseType = {
  succeeded: boolean;
  workouts_history: Array<UserWorkoutHistory> | undefined;
  workout_history: UserWorkoutHistory | undefined;
  errorMessage: string | undefined;
};

export const fetchWorkoutHistoryRequest = async (
  userId: string
): Promise<WorkoutsHistoryRequestResponseType> => {
  try {
    const response = await axiosRequest().get(
      `users/${userId}/workouts_history`
    );
    const workoutsHistory: Array<UserWorkoutHistory> = response.data;

    return {
      succeeded: true,
      workouts_history: workoutsHistory,
    } as WorkoutsHistoryRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as WorkoutsHistoryRequestResponseType;
  }
};

export const createWorkoutHistoryRequest = async (
  createWorkoutHistoryType: CreateWorkoutHistoryType
): Promise<WorkoutsHistoryRequestResponseType> => {
  try {
    await axiosRequest().post(
      `users/${createWorkoutHistoryType.userId}/workouts_history`,
      {
        workout: createWorkoutHistoryType.workoutHistory,
        workout_id: createWorkoutHistoryType.workout_id,
      }
    );

    return { succeeded: true } as WorkoutsHistoryRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: "Não foi possível gravar o treino feito",
    } as WorkoutsHistoryRequestResponseType;
  }
};
