import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

type WaitlistState = {
  confirmationMessage: string | undefined;
  errorMessage: string | undefined;
};

export type CreateWaitlistType = {
  email: string;
  name: string;
};

const initialState: WaitlistState = {
  confirmationMessage: undefined,
  errorMessage: undefined,
};

const waitlistSlice = createSlice({
  name: "waitlist",
  initialState: initialState,
  reducers: {
    createWaitlistEntry(
      state: WaitlistState,
      action: PayloadAction<CreateWaitlistType>
    ) {},
    setErrorMessage(state: WaitlistState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: WaitlistState,
      action: PayloadAction<string>
    ) {
      state.confirmationMessage = action.payload;
    },
    cleanMessages(state: WaitlistState) {
      state.errorMessage = undefined;
      state.confirmationMessage = undefined;
    },
  },
});

export const {
  createWaitlistEntry,
  setConfirmationMessage,
  setErrorMessage,
  cleanMessages,
} = waitlistSlice.actions;

export const selectErrorMessage = (state: RootState) =>
  state.waitlistSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.waitlistSlice.confirmationMessage;

export default waitlistSlice.reducer;
