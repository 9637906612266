import { Button, Col, Container, Row } from "react-bootstrap";

import "components/Tabs/Tabs.css";

export type TabsI = {
  tabs: string[];
  activeIndex: number;
  onTabClick?: (arg: any) => any;
};

export const Tabs = ({ tabs, activeIndex, onTabClick }: TabsI) => {
  return (
    <Container fluid className="tabs-container">
      <Row>
        {tabs.map((tab, index) => (
          <Col key={tab + index} xs={6} style={{ width: "min-content" }}>
            <Button
              className={
                activeIndex === index ? "tab-button active" : "tab-button"
              }
              onClick={() => onTabClick!(index)}
            >
              {tab}
            </Button>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
