import React, { useEffect, useState } from "react";
import { DashboardTitle } from "../DashboardTitle/DashboardTitle";
import { DashboardCoursesCalendar } from "./DashboardCoursesCalendar";
import { CourseModal } from "../CourseModal/CourseModal";
import { Course } from "../../types/course";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchCourseSchedulesType,
  fetchCoursesSchedules,
  selectConfirmationMessage,
} from "../../slices/courseScheduleSlice";
import { selectCurrentUser } from "../../slices/currentUserSlice";
import { CalendarFilters } from "./CalendarFilters/CalendarFilters";
import moment from "moment";
import { Container } from "react-bootstrap";

export const DashboardCoursesPage = () => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<Course | undefined>(
    undefined
  );
  const isGymView = localStorage.getItem("isGymView") === "true";
  const now = Date.now();
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [appointmentsObj, setAppointmentsObj] =
    useState<FetchCourseSchedulesType>({
      room_id: undefined,
      include_my_schedules: isGymView ? false : true,
      include_company_schedules: isGymView ? true : false,
      personal_trainer_id: undefined,
      course_id: undefined,
      from: moment(now).startOf("month").subtract(7, "days").toISOString(),
      to: moment(now).endOf("month").add(7, "days").toISOString(),
    });

  useEffect(() => {
    dispatch(fetchCoursesSchedules(appointmentsObj));
  }, [dispatch, appointmentsObj]);

  useEffect(() => {
    if (confirmationMessage) dispatch(fetchCoursesSchedules(appointmentsObj));
  }, [appointmentsObj, confirmationMessage, dispatch]);

  const onMyAppointementsChange = (showAppointement: boolean) => {
    setAppointmentsObj({
      ...appointmentsObj,
      include_my_schedules: showAppointement,
    });
  };

  const onGymAppointementsChange = (showAppointement: boolean) => {
    setAppointmentsObj({
      ...appointmentsObj,
      include_company_schedules: showAppointement,
    });
  };

  const onRoomSelect = (roomId: string | undefined) => {
    setAppointmentsObj({ ...appointmentsObj, room_id: roomId });
  };

  const onPersonalTrainerSelect = (personalTrainerId: string | undefined) => {
    setAppointmentsObj({
      ...appointmentsObj,
      personal_trainer_id: personalTrainerId,
    });
  };

  const onCourseSelect = (courseId: string | undefined) => {
    setAppointmentsObj({ ...appointmentsObj, course_id: courseId });
  };

  const fetchNewDates = (view: string, date: Date) => {
    let from;
    let to;

    if (view === "day") {
      from = moment(date).startOf("day");
      to = moment(date).endOf("day");
    } else if (view === "week") {
      from = moment(date).startOf("isoWeek");
      to = moment(date).endOf("isoWeek");
    } else {
      from = moment(date).startOf("month").subtract(7, "days");
      to = moment(date).endOf("month").add(7, "days");
    }

    setAppointmentsObj({
      ...appointmentsObj,
      from: from.toISOString(),
      to: to.toISOString(),
    });
  };

  return (
    <Container fluid id="dashboard-courses">
      <CourseModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
      />
      <DashboardTitle
        title="Calendário"
        onClickButton={() => setShowModal(true)}
        showAddButton={false}
        doNotShowBackButton
        infoLink="wBwjEgdTuRk"
      />
      {currentUser?.company?.is_gym && (
        <CalendarFilters
          onMyAppointementsChange={onMyAppointementsChange}
          onGymAppointementsChange={onGymAppointementsChange}
          onRoomSelect={onRoomSelect}
          onPersonalTrainerSelect={onPersonalTrainerSelect}
          onCourseSelect={onCourseSelect}
        />
      )}
      <div className="dashboard-courses-calendar">
        <DashboardCoursesCalendar fetchNewDates={fetchNewDates} />
      </div>
    </Container>
  );
};
