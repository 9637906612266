import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { User } from "../types/user";
import { UserImageType } from "../slices/userSlice";
import {
  personalTrainerSuccessfullyCreated,
  personalTrainerSuccessfullyDeleted,
  personalTrainerSuccessfullyUpdated,
} from "../helpers/messages";

interface PersonalTrainerState {
  personalTrainers: Array<User>;
  isLoading: boolean;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
}

export type CreatePersonalTrainerType = {
  name: string | null;
  email: string | null;
  phone_number: number | null;
  birthday_date: string | null;
  other_issues: string | null;
  availability: string | null;
  image: UserImageType | null;
  nif: number | null;
  fiscal_address: string | null;
  fiscal_zip_code: string | null;
  fiscal_city: string | null;
};

export type UpdatePersonalTrainerType = {
  id: string;
  name: string | null;
  email: string | null;
  phone_number: number | null;
  birthday_date: string | null;
  other_issues: string | null;
  availability: string | null;
  image: UserImageType | null;
  nif: number | null;
  fiscal_address: string | null;
  fiscal_zip_code: string | null;
  fiscal_city: string | null;
};

const initialState: PersonalTrainerState = {
  personalTrainers: [],
  isLoading: false,
  errorMessage: undefined,
  confirmationMessage: undefined,
};

const personalTrainerSlice = createSlice({
  name: "personal_trainer",
  initialState: initialState,
  reducers: {
    fetchPersonalTrainers(state: PersonalTrainerState) {
      state.isLoading = true;
    },
    fetchPersonalTrainersSucceeded(
      state: PersonalTrainerState,
      action: PayloadAction<Array<User>>
    ) {
      state.personalTrainers = action.payload;
      state.isLoading = false;
    },
    fetchPersonalTrainer(
      state: PersonalTrainerState,
      action: PayloadAction<string>
    ) {},
    fetchPersonalTrainerSucceeded(
      state: PersonalTrainerState,
      action: PayloadAction<User>
    ) {
      const userIndex = state.personalTrainers.findIndex(
        (user: User) => user.id === action.payload.id
      );
      if (userIndex >= 0) {
        state.personalTrainers[userIndex] = action.payload;
      } else {
        state.personalTrainers = [action.payload];
      }
      state.isLoading = false;
    },
    createPersonalTrainer(
      state: PersonalTrainerState,
      action: PayloadAction<CreatePersonalTrainerType>
    ) {},
    createPersonalTrainerSucceeded(
      state: PersonalTrainerState,
      action: PayloadAction<User>
    ) {
      return {
        ...state,
        confirmationMessage: personalTrainerSuccessfullyCreated,
        personalTrainers: [action.payload, ...state.personalTrainers],
      };
    },
    updatePersonalTrainer(
      state: PersonalTrainerState,
      action: PayloadAction<UpdatePersonalTrainerType>
    ) {},
    updatePersonalTrainerSucceeded(
      state: PersonalTrainerState,
      action: PayloadAction<User>
    ) {
      const index = state.personalTrainers.findIndex(
        (user: User) => user.id === action.payload.id
      );

      state.personalTrainers[index] = action.payload;
      state.confirmationMessage = personalTrainerSuccessfullyUpdated;
    },
    deletePersonalTrainer(
      state: PersonalTrainerState,
      action: PayloadAction<string>
    ) {},
    deletePersonalTrainerSucceeded(
      state: PersonalTrainerState,
      action: PayloadAction<string>
    ) {
      const index = state.personalTrainers.findIndex(
        (user: User) => user.id === action.payload
      );

      state.personalTrainers.splice(index, 1);
      state.confirmationMessage = personalTrainerSuccessfullyDeleted;
    },
    setErrorMessage(
      state: PersonalTrainerState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: PersonalTrainerState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
  },
});

export const {
  fetchPersonalTrainers,
  fetchPersonalTrainersSucceeded,
  fetchPersonalTrainer,
  fetchPersonalTrainerSucceeded,
  createPersonalTrainer,
  createPersonalTrainerSucceeded,
  updatePersonalTrainer,
  updatePersonalTrainerSucceeded,
  deletePersonalTrainer,
  deletePersonalTrainerSucceeded,
  setErrorMessage,
  setConfirmationMessage,
} = personalTrainerSlice.actions;

export const selectPersonalTrainers = (state: RootState) =>
  state.personalTrainerSlice.personalTrainers;
export const selectErrorMessage = (state: RootState) =>
  state.personalTrainerSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.personalTrainerSlice.confirmationMessage;
export const selectIsLoading = (state: RootState) =>
  state.personalTrainerSlice.isLoading;

export default personalTrainerSlice.reducer;
