import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { User, Company } from "../types/user";

type CurrentUserState = {
  user: User | undefined;
  isLoading: boolean;
  error: string | undefined;
  confirmationMessage: string | undefined;
};

export type LoginType = {
  email: string;
  password: string;
};

export type CreateAccountType = {
  email: string;
  name: string;
  company_plan_id: string;
  plan_frequency: string;
  code: string | undefined;
};

export type UpdateCompanyType = {
  id: string;
  name?: string;
  image?: any;
  colors?: string[];
  viva_smart_checkout_client_id?: string;
  viva_smart_checkout_secret_key?: string;
  vendus_api_key?: string;
  company_plan_id?: string;
  plan_frequency?: string;
  nif?: number;
  fiscal_name?: string;
  fiscal_email?: string;
  fiscal_address?: string;
  fiscal_city?: string;
  fiscal_zip_code?: string;
};

export type UpdateCompanyCourseSettingsType = {
  course_hours_before_users_can_start_applying: number;
  course_minutes_before_users_cant_apply_anymore: number;
};

export type LogoutType = {
  email: string;
};

const initialState: CurrentUserState = {
  user: undefined,
  isLoading: false,
  error: undefined,
  confirmationMessage: undefined,
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: initialState,
  reducers: {
    login(state: CurrentUserState, action: PayloadAction<LoginType>) {
      state.isLoading = true;
    },
    loginSucceeded(state: CurrentUserState, action: PayloadAction<User>) {
      state.user = action.payload;
      state.isLoading = false;
    },
    createAccount(
      state: CurrentUserState,
      action: PayloadAction<CreateAccountType>
    ) {
      state.isLoading = true;
    },
    createAccountSucceeded(state: CurrentUserState) {
      state.isLoading = false;
      state.confirmationMessage =
        "Utilizador criado com successo! Por favor abra o email enviado para completar a sua conta!";
    },
    logout(state: CurrentUserState, action: PayloadAction<LogoutType>) {
      state.isLoading = true;
    },
    logoutSucceeded(state: CurrentUserState, action: PayloadAction<undefined>) {
      state.user = undefined;
      state.isLoading = false;
    },
    fetchCurrentUser(state: CurrentUserState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    fetchCurrentUserSucceeded(
      state: CurrentUserState,
      action: PayloadAction<User>
    ) {
      state.user = action.payload;
      state.isLoading = false;
    },
    updateCompany(
      state: CurrentUserState,
      action: PayloadAction<UpdateCompanyType>
    ) {},
    updateCompanySucceeded(
      state: CurrentUserState,
      action: PayloadAction<Company>
    ) {
      state.user!.company = action.payload;
      state.confirmationMessage = "Ginásio editado com successo!";
    },
    updateCompanyCourseSettings(
      state: CurrentUserState,
      action: PayloadAction<UpdateCompanyCourseSettingsType>
    ) {},
    updateCompanyCourseSettingsSucceeded(
      state: CurrentUserState,
      action: PayloadAction<Company>
    ) {
      state.user!.company = action.payload;
      state.confirmationMessage = "Ginásio editado com successo!";
    },
    setErrorMessage(
      state: CurrentUserState,
      action: PayloadAction<string | undefined>
    ) {
      state.error = action.payload;
    },
    setConfirmationMessage(
      state: CurrentUserState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
    cleanErrorMessage(state: CurrentUserState) {
      state.error = undefined;
    },
  },
});

export const {
  login,
  loginSucceeded,
  createAccount,
  createAccountSucceeded,
  logout,
  logoutSucceeded,
  setErrorMessage,
  setConfirmationMessage,
  cleanErrorMessage,
  fetchCurrentUser,
  fetchCurrentUserSucceeded,
  updateCompany,
  updateCompanySucceeded,
  updateCompanyCourseSettings,
  updateCompanyCourseSettingsSucceeded,
} = currentUserSlice.actions;

export const selectCurrentUser = (state: RootState) =>
  state.currentUserSlice.user;
export const selectErrorMessage = (state: RootState) =>
  state.currentUserSlice.error;
export const selectConfirmationMessage = (state: RootState) =>
  state.currentUserSlice.confirmationMessage;
export const selectTimeConstraints = (state: RootState) => ({
  timeAllowedBeforeUnapply:
    state.currentUserSlice.user?.company
      ?.course_minutes_before_users_cant_apply_anymore,
  timeAllowedForApply:
    state.currentUserSlice.user?.company
      ?.course_hours_before_users_can_start_applying,
});

export default currentUserSlice.reducer;
