import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { TableComponent } from "../../TableComponent/TableComponent";
import { MonthPicker } from "../../MonthPicker/MonthPicker";
import {
  fetchCourseScheduleCounters,
  selectCourseScheduleCounters,
} from "../../../slices/courseScheduleSlice";
import "./CoursesChart.css";
import { Container } from "react-bootstrap";

export const CoursesChart = () => {
  const dispatch = useDispatch();
  const counters = useSelector(selectCourseScheduleCounters);
  const [date, setDate] = useState<Date>(moment().toDate());

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Título",
        accessor: "title",
        minWidth: "150px",
        maxWidth: "150px",
      },
      {
        Header: "Número de Aulas",
        accessor: "count",
      },
      {
        Header: "Inscritos (média)",
        accessor: "users_average",
      },
    ];
  }, []);

  useEffect(() => {
    const startAt = moment(date).startOf("month").toDate().toISOString();
    const endAt = moment(date).endOf("month").toDate().toISOString();

    dispatch(fetchCourseScheduleCounters({ start_at: startAt, end_at: endAt }));
  }, [dispatch, date]);

  return (
    <Container style={{ flex: 1 }}>
      <div className="courses-chart-container">
        <h4 style={{ textAlign: "center", marginTop: 10 }}> Aulas do mês</h4>
        <MonthPicker setDate={setDate} date={date} />
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          <TableComponent
            name=""
            className=""
            columns={tableColumns}
            data={counters}
            showRemoveButton={false}
            rowClickAction={(row: any) => {}}
          />
        </div>
      </div>
    </Container>
  );
};
