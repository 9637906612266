import { IngredientModalLogic } from "components/NutritionPage/AddIngredientModal/IngredientModalLogic/IngredientModalLogic";
import { GModal } from "components/genericComponents/GModal/GModal";

type AddIngredientModalI = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  mealIndex: number;
};

export const AddIngredientModal = ({
  showModal,
  setShowModal,
  mealIndex,
}: AddIngredientModalI) => {
  return (
    <GModal showModalState={showModal} setShowModalState={setShowModal}>
      <div className="add-ingredient-modal">
        <h4>Adicionar Ingredientes</h4>
        <IngredientModalLogic
          showModal={showModal}
          setShowModal={setShowModal}
          mealIndex={mealIndex}
        />
      </div>
    </GModal>
  );
};
