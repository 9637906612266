import { axiosRequest, errorMessage } from "./request";
import { CreateWaitlistType } from "../slices/waitlistSlice";

export type CreateWaitlistEntryResponseType = {
  succeeded: boolean;
  message: string;
};

export const createWaitlistEntryRequest = async (
  params: CreateWaitlistType
): Promise<CreateWaitlistEntryResponseType> => {
  try {
    await axiosRequest().post("waitlist_entries", {
      email: params.email,
      name: params.name,
    });

    return {
      succeeded: true,
      message:
        "Obrigado pelo pedido de demonstração, entraremos em contacto consigo assim que possível!",
    };
  } catch (e: any) {
    return { succeeded: false, message: errorMessage(e) };
  }
};
