import "./CountCard.css";

export type CountCardType = {
  title: string;
  count: number;
};

export const CountCard = (props: CountCardType) => {
  return (
    <div className="count-card-container">
      <p className="count-card-title">{props.title}</p>
      <p>{props.count}</p>
    </div>
  );
};
