import { Col, Container, Row } from "react-bootstrap";

import workoutsPage from "../../../assets/WorkoutsPage.png";
import workoutsPageCalendar from "../../../assets/WorkoutsPageUserCalendar.png";

import "./CustomWorkouts.css";
import { useTranslation } from "react-i18next";

export const CustomWorkouts = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%" }} id="custom-workouts">
      <Container className="cw-container">
        <Row>
          <Col className="cw-title-col">
            <h1 className="cw-title">
              {t("landingPage.customizedWorkouts.title")}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="cw-col">
            <p>{t("landingPage.customizedWorkouts.description")}</p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="cw-col">
            <img src={workoutsPage} className="cw-image" alt="" />
          </Col>
          <Col lg={6} className="cw-col">
            <img src={workoutsPageCalendar} className="cw-image" alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
