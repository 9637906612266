import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import logo from "../../assets/logo.svg";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  uploadFileRequest,
  S3RequestResponseType,
} from "../../requests/s3Requests";
import {
  setErrorMessage,
  setConfirmationMessage,
  selectErrorMessage,
  selectConfirmationMessage,
  createCourse,
  CreateCourseType,
  updateCourse,
  UpdateCourseType,
} from "../../slices/courseSlice";
import { toast } from "react-toastify";
import { Course } from "../../types/course";

type CourseModalType = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  selectedCourse: Course | undefined;
  setSelectedCourse: (value: Course | undefined) => void;
};

export const CourseModal = (props: CourseModalType) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState<string | null>(
    props.selectedCourse?.title ?? null
  );
  const [description, setDescription] = useState<string | null>(
    props.selectedCourse?.description ?? null
  );
  const [titleError, setTitleError] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | null>(null);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  useEffect(() => {
    setTitle(props.selectedCourse?.title ?? null);
    setDescription(props.selectedCourse?.description ?? null);
  }, [props.selectedCourse]);

  const dispatchCreateWorkout = (s3_key: string | undefined) => {
    const image =
      file === null
        ? null
        : {
            id: s3_key!,
            storage: "cache",
            metadata: {
              size: file.size,
              mime_type: file.type,
            },
          };

    if (props.selectedCourse === undefined) {
      const course: CreateCourseType = {
        title: title!,
        description: description!,
        image: image,
        color: "#f44336",
      };

      dispatch(createCourse(course));
    } else {
      const course: UpdateCourseType = {
        id: props.selectedCourse.id,
        title: title,
        description: description,
        image: image,
        color: "#f44336",
      };

      dispatch(updateCourse(course));
    }
  };

  const onSubmitButtonClick = (event: any) => {
    var isValid = true;
    if (!isTitleValid()) isValid = false;

    if (!isValid) return;

    if (file === null) {
      dispatchCreateWorkout(undefined);
    } else {
      uploadFileRequest(file).then((response: S3RequestResponseType) => {
        if (!response.succeeded) return;

        dispatchCreateWorkout(response.key);
      });
    }
  };

  const isTitleValid = (): boolean => {
    if (title === null) {
      setTitleError("Please fill in the title");
      return false;
    }

    setTitleError(undefined);
    return true;
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const uploadFile = () => {
    if (inputRef.current?.files?.length === 1) {
      const file = inputRef.current?.files[0];
      setFile(file);
    }
  };

  const imagePreview = () => {
    if (file !== null) {
      return <img src={URL.createObjectURL(file)} alt="something" />;
    }

    if (props.selectedCourse?.image_small !== undefined) {
      return <img src={props.selectedCourse?.image_small} alt="something" />;
    }

    return undefined;
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "course-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "course-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));

      setTitle("");
      setDescription("");
      setFile(null);
      props.setShowModal(false);
      props.setSelectedCourse(undefined);
    }
  }, [dispatch, confirmationMessage, props]);

  return (
    <Modal
      className="modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={() => {
        props.setShowModal(false);
        props.setSelectedCourse(undefined);
      }}
    >
      <Modal.Body>
        <Row className="modal-flex-center">
          <img className="logo" src={logo} alt="" />
        </Row>
        <p className="modal-title">
          {props.selectedCourse === undefined
            ? "Create Course"
            : "Update Course"}
        </p>
        <Form onSubmit={onSubmitButtonClick}>
          <FloatingLabel className="mb-3 form-floating-label" label="Title">
            <Form.Control
              value={title ?? undefined}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
              autoFocus
            />
            {titleError !== undefined ? (
              <p className="form-error-message">{titleError}</p>
            ) : undefined}
          </FloatingLabel>
          <FloatingLabel
            className="mb-3 form-floating-label"
            label="Description"
          >
            <Form.Control
              value={description ?? undefined}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
              autoFocus
            />
          </FloatingLabel>
          <Form.Group
            controlId="formFile"
            className="mb-3 workout-modal-image-group"
          >
            <Form.Control
              type="file"
              onChange={uploadFile}
              autoFocus
              ref={inputRef}
            />
          </Form.Group>
          <div className="modal-image-uploaded">{imagePreview()}</div>

          <Row className="modal-flex-center padding">
            <Button
              className="modal-submit-button"
              onClick={onSubmitButtonClick}
            >
              Submeter
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
