import {
  CourseSchedule,
  CourseScheduleCounter,
  UserCourseScheduleCounter,
} from "../types/courseSchedule";
import { axiosRequest, errorMessage } from "./request";
import {
  CreateCourseScheduleType,
  UpdateCourseScheduleType,
  FetchCourseSchedulesType,
  FetchCourseScheduleCountersType,
  DeleteCourseSchedules,
} from "../slices/courseScheduleSlice";

export type CourseScheduleRequestResponseType = {
  succeeded: boolean;
  coursesSchedules: Array<CourseSchedule> | undefined;
  courseSchedule: CourseSchedule | undefined;
  counters: Array<CourseScheduleCounter>;
  userCounters: Array<UserCourseScheduleCounter>;
  errorMessage: string | undefined;
};

export const fetchCoursesRequest = async (
  params: FetchCourseSchedulesType
): Promise<CourseScheduleRequestResponseType> => {
  try {
    const queryParams =
      `${params.room_id !== undefined ? `room_id=${params.room_id}&` : ""}` +
      `include_my_schedules=${params.include_my_schedules}&include_company_schedules=${params.include_company_schedules}&` +
      `${
        params.personal_trainer_id !== undefined
          ? `personal_trainer_id=${params.personal_trainer_id}&`
          : ""
      }` +
      `${
        params.course_id !== undefined ? `course_id=${params.course_id}&` : "&"
      }` +
      `${
        params.from && params.to ? `from=${params.from}&to=${params.to}` : ""
      }`;

    const response = await axiosRequest().get(`schedules?${queryParams}`);
    const coursesSchedules: Array<CourseSchedule> = response.data;

    return {
      succeeded: true,
      coursesSchedules: coursesSchedules,
    } as CourseScheduleRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CourseScheduleRequestResponseType;
  }
};

export const fetchCourseScheduleCountersRequest = async (
  params: FetchCourseScheduleCountersType
): Promise<CourseScheduleRequestResponseType> => {
  try {
    const response = await axiosRequest().get(
      `counters/course_schedules?start_at=${params.start_at}&end_at=${params.end_at}`
    );
    const counters: Array<CourseScheduleCounter> = response.data;

    return {
      succeeded: true,
      counters: counters,
    } as CourseScheduleRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CourseScheduleRequestResponseType;
  }
};

export const fetchUserCourseScheduleCountersRequest = async (
  params: FetchCourseScheduleCountersType
): Promise<CourseScheduleRequestResponseType> => {
  try {
    const response = await axiosRequest().get(
      `counters/user_schedules?start_at=${params.start_at}&end_at=${params.end_at}`
    );
    const counters: Array<UserCourseScheduleCounter> = response.data;

    return {
      succeeded: true,
      userCounters: counters,
    } as CourseScheduleRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CourseScheduleRequestResponseType;
  }
};

export const fetchCourseRequest = async (
  id: string
): Promise<CourseScheduleRequestResponseType> => {
  try {
    const response = await axiosRequest().get(`schedules/${id}`);
    const coursesSchedule: CourseSchedule = response.data;

    return {
      succeeded: true,
      courseSchedule: coursesSchedule,
    } as CourseScheduleRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CourseScheduleRequestResponseType;
  }
};

export const createCourseScheduleRequest = async (
  params: CreateCourseScheduleType
): Promise<CourseScheduleRequestResponseType> => {
  try {
    const response = await axiosRequest().post("schedules", {
      course_schedule: params,
    });
    const courseSchedules = response.data as Array<CourseSchedule>;

    return {
      succeeded: true,
      coursesSchedules: courseSchedules,
    } as CourseScheduleRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CourseScheduleRequestResponseType;
  }
};

export const updateCourseScheduleRequest = async (
  params: UpdateCourseScheduleType
): Promise<CourseScheduleRequestResponseType> => {
  try {
    const response = await axiosRequest().put(`schedules/${params.id}`, {
      course_schedule: params,
    });
    const courseSchedule: CourseSchedule = response.data;

    return {
      succeeded: true,
      courseSchedule: courseSchedule,
    } as CourseScheduleRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CourseScheduleRequestResponseType;
  }
};

export const deleteCourseScheduleRequest = async (
  params: DeleteCourseSchedules
): Promise<CourseScheduleRequestResponseType> => {
  try {
    await axiosRequest().delete(`schedules/${params.id}`, {
      data: {
        delete_rule: params.delete_rule,
      },
    });

    return { succeeded: true } as CourseScheduleRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CourseScheduleRequestResponseType;
  }
};
