import { ChangeEvent, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";

import { GButton } from "components/genericComponents/GButton/GButton";
import { GModal } from "components/genericComponents/GModal/GModal";
import { S3RequestResponseType, uploadFileRequest } from "requests/s3Requests";

import "components/shared/MultiImageUpload/MultiImageUpload.scss";

type MultiImageUploadProps = {
  isDisabled?: boolean;
  files: File[];
  setFiles: (files: File[]) => void;
  fileKeys: string[];
  setFileKeys: (keys: string[]) => void;
  existingFiles: any[];
  setExistingFiles: (x: any) => void;
};

export const MultiImageUpload = ({
  isDisabled,
  files,
  setFiles,
  fileKeys,
  setFileKeys,
  existingFiles,
  setExistingFiles,
}: MultiImageUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [modalFile, setModalFile] = useState<File | null>(null);
  const [modalUrl, setModalUrl] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files); // Convert FileList to Array
      for (const file of filesArray) {
        // update user data
        await uploadFileRequest(file).then((response: S3RequestResponseType) => {
          if (!response.succeeded) return;

          setFileKeys(fileKeys.concat(response.key!));
        });
      }

      // then setFile
      setFiles([...files, ...filesArray]);
    }
  };

  const handleImageDelete = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleExistingFilesDelete = (index: number) => {
    const newFiles = [...existingFiles];
    newFiles.splice(index, 1);
    setExistingFiles(newFiles);
  };

  const noImages = files.length === 0 && existingFiles.length === 0;

  return (
    <div className="multi-image-upload">
      <div className="images-container">
        {existingFiles.map((file: any, index: number) => (
          <div key={file.url} className="image-container">
            <img
              src={file.url}
              alt={file.url}
              onClick={() => {
                setModalUrl(existingFiles[index].url);
                setShowModal(true);
              }}
            />
            {!isDisabled && (
              <GButton
                className="image-delete-button"
                variant="delete"
                disabled={isDisabled}
                onClick={() => handleExistingFilesDelete(index)}
              >
                <FaTimes />
              </GButton>
            )}
          </div>
        ))}
        {files.map((file: File, index: number) => (
          <div key={file.name} className="image-container">
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              onClick={() => {
                setModalFile(file);
                setShowModal(true);
              }}
            />
            {!isDisabled && (
              <GButton
                className="image-delete-button"
                variant="delete"
                disabled={isDisabled}
                onClick={() => handleImageDelete(index)}
              >
                <FaTimes />
              </GButton>
            )}
          </div>
        ))}
        {noImages && (
          <div className="no-images-container">
            <p>Nenhuma fotografia adicionada</p>
          </div>
        )}
      </div>
      {!isDisabled && (
        <div className="add-image-button">
          <GButton label="Adicionar fotografias" disabled={isDisabled} onClick={() => inputRef.current?.click()} />
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/png, image/jpeg"
            multiple
            ref={inputRef}
            style={{ display: "none" }}
          />
        </div>
      )}
      <GModal showModalState={showModal} setShowModalState={setShowModal} isFullScreen>
        <div className="modal-image-container">
          {modalFile && <img className="modal-image" src={URL.createObjectURL(modalFile!)} alt={modalFile?.name} />}
          {modalUrl && <img className="modal-image" src={modalUrl} alt="Modal" />}
        </div>
      </GModal>
    </div>
  );
};
