import "components/genericComponents/GChip/GChip.scss";

type GChipProps = {
  label: string;
  variant:
    | "primary"
    | "infoFinished"
    | "infoActive"
    | "infoExpired"
    | "infoPending";
  className?: string;
};

export const GChip = ({ label, variant, className }: GChipProps) => {
  const classNameString = () => {
    let result: string = variant;
    if (className !== undefined) result = result.concat(` ${className}`);
    return result;
  };

  return <div className={`g-chip ${classNameString()}`}>{label}</div>;
};
