import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";

import { selectCurrentUser } from "slices/currentUserSlice";

import logo from "assets/logo.svg";

import "components/SettingsPage/MobileAppSettings/ChatScreenApp/ChatScreenApp.scss";
import {
  FaCalendarDays,
  FaCommentDots,
  FaHouse,
  FaUser,
} from "react-icons/fa6";
import { FaDumbbell, FaPaperPlane } from "react-icons/fa";

type ChatScreenAppProps = {
  color: string;
};

export const ChatScreenApp = ({ color }: ChatScreenAppProps) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <div className="chat-screen-app">
      <div className="header-container">
        <div className="header-left-div">
          {currentUser?.company?.image_medium !== null &&
          currentUser?.company?.is_gym ? (
            <img
              className="header-logo"
              src={currentUser.company?.image_small}
              alt="company logo"
            />
          ) : !currentUser?.company?.is_gym &&
            currentUser?.image_small !== null ? (
            <img
              className="header-logo"
              src={currentUser?.image_small}
              alt="user logo"
            />
          ) : (
            <img className="header-logo" src={logo} alt="logo" />
          )}
          <p className="header-text">Mensagens</p>
        </div>
      </div>
      <div style={{ flex: "1 1 auto" }} />
      <div className="card-container">
        <div className="card" style={{ background: `lightgrey` }}>
          <p style={{ color: "#212121" }}>Olá, Maria!</p>
        </div>
      </div>
      <div className="card-container right">
        <div className="card" style={{ background: color }}>
          <p
            style={{
              color: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
            }}
          >
            Olá, João!
          </p>
        </div>
      </div>
      <div className="card-container">
        <div className="card" style={{ background: `lightgrey` }}>
          <p style={{ color: "#212121" }}>Como foi o treino?</p>
        </div>
      </div>
      <div className="card-container right">
        <div className="card" style={{ background: color }}>
          <p
            style={{
              color: `${tinycolor(color).isLight() ? "#212121" : "white"}`,
            }}
          >
            Correu às mil maravilhas!
          </p>
        </div>
      </div>
      <hr style={{ marginBottom: "8px" }} />
      <div className="message-div">
        <p>Escreva uma mensagem...</p>
        <FaPaperPlane style={{ color: color }} />
      </div>
      <hr style={{ marginTop: "8px" }} />
      <div className="icons-container">
        <div className="icon-div">
          <FaHouse className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Início</p>
        </div>
        <div className="icon-div">
          <FaDumbbell className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Planos de Treino</p>
        </div>
        <div className="icon-div">
          <FaCalendarDays className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Calendário</p>
        </div>
        <div className="icon-div">
          <FaCommentDots className="icon" style={{ color: color }} />
          <p style={{ color: color }}>Mensagens</p>
        </div>
        <div className="icon-div">
          <FaUser className="icon" style={{ color: "grey" }} />
          <p style={{ color: "grey" }}>Perfil</p>
        </div>
      </div>
    </div>
  );
};
