import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { Loader } from "@chatscope/chat-ui-kit-react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { TableComponent } from "components/TableComponent/TableComponent";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import { ChooseProduct } from "components/ProductsPage/TransactionsSection/ChooseProduct/ChooseProduct";
import { AddOrEditTransaction } from "components/ProductsPage/TransactionsSection/AddOrEditTransaction/AddOrEditTransaction";
import {
  deleteProductTransaction,
  fetchProductTransactions,
  selectTransactions,
  selectErrorMessage,
  selectIsLoading,
  setConfirmationMessage,
  setErrorMessage,
  selectConfirmationMessage,
} from "slices/productTransactionSlice";

import "components/ProductsPage/TransactionsSection/TransactionsSection.scss";
import { format } from "date-fns";
import { ProductTransaction } from "types/productTransaction";

export const TransactionsSection = () => {
  const dispatch = useDispatch();
  const transactions = useSelector(selectTransactions);
  const isLoading = useSelector(selectIsLoading);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [showAddTransaction, setShowAddTransaction] = useState<boolean>(false);
  const [showEditTransaction, setShowEditTransaction] =
    useState<boolean>(false);
  const [transaction, setTransaction] = useState<
    ProductTransaction | undefined
  >(undefined);

  const tableColumns = React.useMemo(
    () => [
      {
        Header: "Data",
        accessor: "date",
        Cell: (props: any) => {
          return (
            <span>{format(new Date(props.cell.value), "dd-MM-yyyy")}</span>
          );
        },
      },
      {
        Header: "Valor de venda (€)",
        accessor: "sale_price",
      },
      {
        Header: "Lucro (€)",
        accessor: "profit",
      },
    ],
    []
  );

  const handleClose = () => {
    setShowAddTransaction(false);
    setShowEditTransaction(false);
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "transactions-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "transactions-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(fetchProductTransactions());
      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  return (
    <>
      {isLoading ? (
        <Loader className="chat-messages-loader full-screen" />
      ) : showAddTransaction ? (
        <ChooseProduct handleClose={handleClose} />
      ) : showEditTransaction ? (
        <AddOrEditTransaction
          transaction={transaction}
          handleClose={handleClose}
        />
      ) : (
        <GSection
          label=" "
          isSpacedBetween
          renderButtons={[
            <GButton
              variant="add"
              onClick={() => {
                setTransaction(undefined);
                setShowAddTransaction(true);
              }}
            >
              <MdAdd />
            </GButton>,
          ]}
        >
          {transactions.length > 0 ? (
            <TableComponent
              name={""}
              columns={tableColumns}
              data={transactions}
              removeAction={(id: string) => {
                dispatch(deleteProductTransaction(id));
              }}
              showRemoveButton
              rowClickAction={(row: any) => {
                setTransaction(row.original);
                setShowEditTransaction(true);
              }}
            />
          ) : (
            <p style={{ textAlign: "center" }}>
              Não possui transações. Crie novas transações, clicando no botão.
            </p>
          )}
        </GSection>
      )}
    </>
  );
};
