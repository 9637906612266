import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { MdAdd, MdDeleteForever } from "react-icons/md";

import { GButton } from "components/genericComponents/GButton/GButton";
import {
  BsFillCaretDownFill,
  BsFillCaretUpFill,
  BsFillXCircleFill,
  BsPencilSquare,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectCurrentUser } from "../../../../slices/currentUserSlice";
import {
  createExerciseCategory,
  deleteExerciseCategory,
  fetchExerciseCategories,
  selectConfirmationMessage,
  selectErrorMessage,
  selectExerciseCategories,
  setConfirmationMessage,
  setErrorMessage,
  updateExerciseCategory,
} from "../../../../slices/exerciseCategorySlice";
import {
  ExerciseCategory,
  ExerciseVariable,
  isExerciseCategoryCreatedByCurrentUser,
} from "../../../../types/exercise";
import { InputForm } from "../../../InputForm/InputForm";
import { ExercisesVariablesSettings } from "../ExercisesVariablesSettings/ExercisesVariablesSettings";
import "./ExercisesCategoriesSettings.css";
import { formatToFirstTwoAndLastName } from "helpers/utils";

type Categories = ExerciseCategory[];

export const ExercisesCategoriesSettings = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const categories = useSelector(selectExerciseCategories);
  const [isEditting, setIsEditting] = useState<boolean[]>(
    new Array(categories.length).fill(false)
  );
  const [tempCategories, setTempCategories] = useState(categories);
  const [isOpen, setIsOpen] = useState<boolean[]>(
    new Array(categories.length).fill(false)
  );
  const [categoriesError, setCategoriesError] = useState<string[]>(
    new Array(categories.length).fill(null)
  );
  const [hasCategoryChanged, setHasCategoryChanged] = useState<boolean[]>(
    new Array(categories.length).fill(false)
  );
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  useEffect(() => {
    dispatch(fetchExerciseCategories());
  }, [dispatch]);

  useEffect(() => {
    setTempCategories(categories);
  }, [categories]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "exercises-categories-settings-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "exercises-categories-settings-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  const removeCategory = (index: number) => {
    dispatch(deleteExerciseCategory(categories[index]!.id!));

    const tempEditting: boolean[] = JSON.parse(JSON.stringify(isEditting));
    tempEditting.splice(index, 1);
    setIsEditting(tempEditting);
  };

  const onVariableChange = (
    categoryIndex: number,
    variable: ExerciseVariable,
    variableIndex: number
  ) => {
    const tempCats: Categories = JSON.parse(JSON.stringify(tempCategories));
    tempCats[categoryIndex].variables[variableIndex] = variable;
    setTempCategories(tempCats);

    let canSubmit = false;
    if (tempCats[categoryIndex].title !== "") canSubmit = true;
    tempCats[categoryIndex].variables.forEach((curr: ExerciseVariable) => {
      if (curr.title === "") canSubmit = false;
    });
    if (!canSubmit) {
      const tempHasCatChangedArr = JSON.parse(
        JSON.stringify(hasCategoryChanged)
      );
      tempHasCatChangedArr[categoryIndex] = false;
      setHasCategoryChanged(tempHasCatChangedArr);
    } else {
      const tempHasCatChangedArr = JSON.parse(
        JSON.stringify(hasCategoryChanged)
      );
      tempHasCatChangedArr[categoryIndex] = true;
      setHasCategoryChanged(tempHasCatChangedArr);
    }
  };

  const onAddCategory = () => {
    setTempCategories([
      {
        title: "",
        company: currentUser?.company,
        personal_trainer:
          localStorage.getItem("isGymView") === "true"
            ? undefined
            : currentUser,
        variables: [
          {
            title: "Descanso",
            is_rest_before_next_set: true,
            is_duration: false,
          },
          {
            title: "Duração",
            is_rest_before_next_set: false,
            is_duration: true,
          },
          {
            title: "",
            is_rest_before_next_set: false,
            is_duration: false,
          },
        ],
      },
      ...tempCategories,
    ]);
    const tempIsEditing = JSON.parse(JSON.stringify(isEditting));
    tempIsEditing[0] = true;
    setIsEditting(tempIsEditing);
    const tempIsOpen = JSON.parse(JSON.stringify(isOpen));
    tempIsOpen[0] = true;
    setIsOpen(tempIsOpen);
  };

  const onSubmitButtonClick = (index: number) => {
    if (tempCategories[index].title === "") {
      const tempCategoriesError = JSON.parse(JSON.stringify(categoriesError));
      tempCategoriesError[index] = "Insira um nome para a categoria";
      setCategoriesError(tempCategoriesError);
    } else {
      if (tempCategories[index].id === undefined) {
        dispatch(createExerciseCategory(tempCategories[index]));
      } else {
        dispatch(
          updateExerciseCategory({
            ...tempCategories[index],
            id: tempCategories[index].id!,
          })
        );
      }

      const tempEditting: boolean[] = JSON.parse(JSON.stringify(isEditting));
      tempEditting[index] = false;
      setIsEditting(tempEditting);

      const tempHasChanged = JSON.parse(JSON.stringify(hasCategoryChanged));
      tempHasChanged[index] = false;
      setHasCategoryChanged(tempHasChanged);
    }
  };

  const onAddVariable = (
    index: number,
    isRest: boolean,
    isDuration: boolean
  ) => {
    const tempCats: Categories = JSON.parse(JSON.stringify(tempCategories));
    tempCats[index].variables.push({
      title: isRest ? "Descanso" : isDuration ? "Duração" : "",
      is_rest_before_next_set: isRest ? true : false,
      is_duration: isDuration ? true : false,
    });
    setTempCategories(tempCats);
  };

  const onRemoveVariable = (categoryIndex: number, index: number) => {
    const tempCats: Categories = JSON.parse(JSON.stringify(tempCategories));
    tempCats[categoryIndex].variables.splice(index, 1);
    setTempCategories(tempCats);
  };

  return (
    <Container fluid id="exercises-categories-settings">
      <Row>
        <Col className="title-col">
          <h4>Categorias</h4>
          <button className="dashboard-title-btn" onClick={onAddCategory}>
            <MdAdd />
          </button>
        </Col>
      </Row>
      <Container className="exercises-variables-settings-container">
        {tempCategories.map((category: ExerciseCategory, index: number) => {
          return (
            <div className="exercises-variables-settings-inner-container">
              <Row>
                <Col className="exercises-variables-settings-inner-container-title-col">
                  <div className="title-div">
                    {category.title.length ? (
                      <h4>{category.title}</h4>
                    ) : undefined}
                    {isExerciseCategoryCreatedByCurrentUser(
                      currentUser,
                      category
                    ) ? (
                      isExerciseCategoryCreatedByCurrentUser(
                        currentUser,
                        category
                      ) && !isEditting[index] ? (
                        <Button
                          className="title-edit-btn"
                          onClick={() => {
                            const tempEditting: boolean[] = JSON.parse(
                              JSON.stringify(isEditting)
                            );
                            tempEditting[index] = true;
                            setIsEditting(tempEditting);
                          }}
                        >
                          <BsPencilSquare />
                        </Button>
                      ) : (
                        <Button
                          className="title-edit-btn"
                          onClick={() => {
                            const tempEditting: boolean[] = JSON.parse(
                              JSON.stringify(isEditting)
                            );
                            tempEditting[index] = false;
                            setIsEditting(tempEditting);
                          }}
                        >
                          <BsFillXCircleFill />
                        </Button>
                      )
                    ) : undefined}
                  </div>
                  {currentUser?.company?.is_gym && (
                    <>
                      {category.personal_trainer !== null ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p className="created-by-text">Criado por:</p>
                          <img
                            alt=""
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 5,
                              marginRight: "0.3rem",
                            }}
                            src={
                              category.personal_trainer?.image_small ??
                              "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                            }
                          />
                          <p>
                            {formatToFirstTwoAndLastName(
                              category.personal_trainer?.name
                            )}
                          </p>
                        </div>
                      ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p className="created-by-text">Criado por:</p>
                          <img
                            alt=""
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 5,
                              marginRight: "0.3rem",
                            }}
                            src={
                              category.company?.image_small ??
                              "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                            }
                          />
                          <p>{currentUser?.company?.name}</p>
                        </div>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <Row className="exercises-variables-settings-row">
                <Col className="exercises-variables-settings-col">
                  <div className="exercises-variables-settings-div first">
                    <div style={{ width: "100%" }}>
                      <InputForm
                        value={category.title}
                        label="Nome da Categoria"
                        className="exercises-variables-settings-input"
                        onChange={(e: any) => {
                          const tempCats: Categories = JSON.parse(
                            JSON.stringify(tempCategories)
                          );
                          tempCats[index] = {
                            ...tempCats[index],
                            title: e.target.value,
                          };
                          setTempCategories(tempCats);

                          let canSubmit = false;
                          if (tempCats[index].title !== "") {
                            canSubmit = true;

                            const tempCategoriesError = JSON.parse(
                              JSON.stringify(categoriesError)
                            );
                            tempCategoriesError[index] = null;
                            setCategoriesError(tempCategoriesError);
                          }
                          tempCats[index].variables.forEach(
                            (curr: ExerciseVariable) => {
                              if (curr.title === "") canSubmit = false;
                            }
                          );
                          if (!canSubmit) {
                            const tempHasCatChangedArr = JSON.parse(
                              JSON.stringify(hasCategoryChanged)
                            );
                            tempHasCatChangedArr[index] = false;
                            setHasCategoryChanged(tempHasCatChangedArr);
                          } else {
                            const tempHasCatChangedArr = JSON.parse(
                              JSON.stringify(hasCategoryChanged)
                            );
                            tempHasCatChangedArr[index] = true;
                            setHasCategoryChanged(tempHasCatChangedArr);
                          }
                        }}
                        disabled={
                          !isExerciseCategoryCreatedByCurrentUser(
                            currentUser,
                            category
                          ) || !isEditting[index]
                        }
                        errorMessage={categoriesError[index]}
                      />
                    </div>
                  </div>
                  <div className="exercises-variables-settings-div">
                    {isExerciseCategoryCreatedByCurrentUser(
                      currentUser,
                      category
                    ) && isEditting[index] ? (
                      <Button
                        className="exercises-container-button-close"
                        onClick={() => removeCategory(index)}
                      >
                        <MdDeleteForever />
                      </Button>
                    ) : (
                      <div style={{ width: "2.5rem" }}></div>
                    )}
                  </div>
                  <div className="caret-icon-div">
                    {isOpen[index] ? (
                      <BsFillCaretUpFill
                        onClick={() => {
                          const tempIsOpenArr = JSON.parse(
                            JSON.stringify(isOpen)
                          );
                          tempIsOpenArr[index] = false;
                          setIsOpen(tempIsOpenArr);
                        }}
                        className="caret-icon"
                      />
                    ) : (
                      <BsFillCaretDownFill
                        onClick={() => {
                          const tempIsOpenArr = JSON.parse(
                            JSON.stringify(isOpen)
                          );
                          tempIsOpenArr[index] = true;
                          setIsOpen(tempIsOpenArr);
                        }}
                        className="caret-icon"
                      />
                    )}
                  </div>
                </Col>
              </Row>
              {isOpen[index] ? (
                <div>
                  <ExercisesVariablesSettings
                    variables={category.variables}
                    onVariableChange={onVariableChange}
                    categoryIndex={index}
                    isOwner={isExerciseCategoryCreatedByCurrentUser(
                      currentUser,
                      category
                    )}
                    hasCategoryChanged={hasCategoryChanged}
                    setHasCategoryChanged={setHasCategoryChanged}
                    onAddVariable={onAddVariable}
                    onRemoveVariable={onRemoveVariable}
                    isEditting={isEditting}
                    setIsEditting={setIsEditting}
                  />
                </div>
              ) : undefined}
              {isEditting[index] ? (
                <Row>
                  <Col className="submit-col">
                    <GButton
                      label="Submeter"
                      onClick={() => onSubmitButtonClick(index)}
                    />
                  </Col>
                </Row>
              ) : undefined}
            </div>
          );
        })}
      </Container>
    </Container>
  );
};
