import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaChartLine } from "react-icons/fa";

import "./FeaturesComponent.css";
import { useTranslation } from "react-i18next";

export const FeaturesComponent = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%" }} id="features">
      <Container className="features-container">
        <Row>
          <Col className="features-title-col">
            <h1 className="features-title">
              {t("landingPage.featuresComponent.title")}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="features-text-col">
            <ul>
              <li>{t("landingPage.featuresComponent.noPaper")}</li>
              <li>{t("landingPage.featuresComponent.workouts")}</li>
              <li>{t("landingPage.featuresComponent.premiumQuality")}</li>
              <li>{t("landingPage.featuresComponent.classes")}</li>
              <li>{t("landingPage.featuresComponent.exercises")}</li>
              <li>{t("landingPage.featuresComponent.clientsApp")}</li>
            </ul>
          </Col>
          <Col className="features-img-col">
            <FaChartLine className="features-text-icon" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
