import { PayloadAction } from "@reduxjs/toolkit";
import {
  EvaluationsRequestResponseType,
  createEvaluationRequest,
  updateEvaluationRequest,
  fetchEvaluationsRequest,
  deleteEvaluationRequest,
} from "../../requests/evaluationsRequests";
import { call, put } from "redux-saga/effects";
import {
  CreateOrUpdateEvaluationType,
  DeleteEvaluationType,
  createEvaluationSucceeded,
  deleteEvaluationSucceeded,
  fetchEvaluationsSucceeded,
  setErrorMessage,
  updateEvaluationSucceeded,
} from "slices/evaluationsSlice";

export function* fetchEvaluationsSaga(
  action: PayloadAction<string>,
  request = fetchEvaluationsRequest
) {
  const response: EvaluationsRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchEvaluationsSucceeded(response.evaluations!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createEvaluationSaga(
  action: PayloadAction<CreateOrUpdateEvaluationType>,
  request = createEvaluationRequest
) {
  const response: EvaluationsRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createEvaluationSucceeded(response.evaluation!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateEvaluationSaga(
  action: PayloadAction<CreateOrUpdateEvaluationType>,
  request = updateEvaluationRequest
) {
  const response: EvaluationsRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateEvaluationSucceeded(response.evaluation!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deleteEvaluationSaga(
  action: PayloadAction<DeleteEvaluationType>,
  request = deleteEvaluationRequest
) {
  const response: EvaluationsRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteEvaluationSucceeded(action.payload.id));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
