import { Dispatch, SetStateAction, useState } from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import { WorkoutExerciseSetCard } from "components/DashboardWorkoutsPage/WorkoutMode/WorkoutExerciseCard/WorkoutExerciseCardSet/WorkoutExerciseSetCard";
import { WorkoutModeExercise, WorkoutModeExerciseSet } from "types/exercise";
import { WorkoutModeType } from "types/workout";

import "components/DashboardWorkoutsPage/WorkoutMode/WorkoutExerciseCard/WorkoutExerciseCard.scss";

type WorkoutExerciseCardProps = {
  exercise: WorkoutModeExercise;
  index: number;
  workoutToSubmit: WorkoutModeType | undefined;
  setWorkoutToSubmit: Dispatch<SetStateAction<WorkoutModeType | undefined>>;
};

export const WorkoutExerciseCard = ({
  exercise,
  index,
  workoutToSubmit,
  setWorkoutToSubmit,
}: WorkoutExerciseCardProps) => {
  const [exerciseDone, setExerciseDone] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggleExerciseDone = () => {
    setWorkoutToSubmit({
      ...workoutToSubmit!,
      exercises: workoutToSubmit!.exercises.reduce(
        (prev: WorkoutModeExercise[], curr, idx) => {
          if (index === idx) {
            curr.isChecked = !curr.isChecked;
            curr.sets.reduce((cPrev: WorkoutModeExerciseSet[], cCurr, cIdx) => {
              cCurr.isChecked = curr.isChecked;
              cPrev.push(cCurr);
              return cPrev;
            }, []);
          }
          prev.push(curr);
          return prev;
        },
        []
      ),
    });

    setExerciseDone(!exerciseDone);
  };

  const addSet = () => {
    setWorkoutToSubmit({
      ...workoutToSubmit!,
      exercises: workoutToSubmit!.exercises.reduce(
        (prev: WorkoutModeExercise[], curr, idx) => {
          if (index === idx) {
            curr.isChecked = !curr.isChecked;
            curr.sets.push({
              id: uuidv4(),
              isChecked: false,
              type: "workout_exercise_set",
              variables: curr.sets[0].variables.map((vari) => ({
                ...vari,
                id: null,
                value: null,
              })),
            });
          }
          prev.push(curr);
          return prev;
        },
        []
      ),
    });
  };

  return (
    <GSection
      className="workout-exercise-card"
      label={exercise.title!}
      isSpacedBetween
      renderButtons={[
        <GButton
          variant="transparent"
          className="buttons"
          icon={isOpen ? <FaCaretUp /> : <FaCaretDown />}
          iconSize="big"
          onClick={() => setIsOpen(!isOpen)}
        />,
        <GButton
          variant="transparent"
          className="buttons"
          icon={
            exercise.isChecked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />
          }
          iconSize="big"
          onClick={handleToggleExerciseDone}
        />,
      ]}
    >
      {exercise.notes !== undefined && isOpen && (
        <div className="notes-div">
          <p className="title">Notas</p>
          <p className="description">{exercise.notes}</p>
        </div>
      )}
      {exercise.sets.map((set, setIdx) => (
        <WorkoutExerciseSetCard
          key={set.variables[0].variable_id + setIdx}
          set={set}
          index={setIdx}
          exercise={exercise}
          exerciseIdx={index}
          isVisible={isOpen}
          workoutToSubmit={workoutToSubmit}
          setWorkoutToSubmit={setWorkoutToSubmit}
        />
      ))}
      {isOpen && (
        <div className="add-set-div">
          <GButton label="Adicionar Set" variant="secondary" onClick={addSet} />
        </div>
      )}
    </GSection>
  );
};
