import { GButton } from "components/genericComponents/GButton/GButton";
import { FaInfoCircle } from "react-icons/fa";

import "components/VideoModal/VideoModal.scss";
import { useState } from "react";
import { GModal } from "components/genericComponents/GModal/GModal";
import YouTube from "react-youtube";

type VideoModalProps = {
  link: string;
};

export const VideoModal = ({ link }: VideoModalProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div>
      <GButton onClick={() => setShowModal(true)}>
        <FaInfoCircle />
      </GButton>
      <GModal
        showModalState={showModal}
        setShowModalState={setShowModal}
        dialogClassName="video-modal-dialog"
      >
        <div>
          <YouTube className="video-modal-video" videoId={link} />
        </div>
      </GModal>
    </div>
  );
};
