import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type PlanInfoDetails = {
  id: string;
  cost: string;
  description: string;
};

export type PlanInfo = {
  discount_percentage: number;
  plans: Array<PlanInfoDetails>;
};

export type Plans = {
  monthly: PlanInfo;
  yearly: PlanInfo;
  has_code?: boolean;
};

export type CompanyPlanState = {
  plans: Plans | undefined;
  upgradablePlans: Plans | undefined;
};

export type FetchCompanyPlansType = {
  code: string | undefined;
  locale: string | undefined;
};

const initialState: CompanyPlanState = {
  plans: undefined,
  upgradablePlans: undefined,
};

const companyPlanSlice = createSlice({
  name: "companyPlan",
  initialState: initialState,
  reducers: {
    fetchPlans(
      state: CompanyPlanState,
      action: PayloadAction<FetchCompanyPlansType>
    ) {},
    fetchPlansSucceeded(state: CompanyPlanState, action: PayloadAction<Plans>) {
      state.plans = action.payload;
    },
    fetchUpgradablePlans(state: CompanyPlanState) {},
    fetchUpgradablePlansSucceeded(
      state: CompanyPlanState,
      action: PayloadAction<Plans>
    ) {
      state.plans = action.payload;
    },
  },
});

export const {
  fetchPlans,
  fetchPlansSucceeded,
  fetchUpgradablePlans,
  fetchUpgradablePlansSucceeded,
} = companyPlanSlice.actions;

export const selectPlans = (state: RootState): Plans | undefined =>
  state.companyPlanSlice.plans;

export const selectUpgradablePlans = (state: RootState): Plans | undefined =>
  state.companyPlanSlice.upgradablePlans;

export default companyPlanSlice.reducer;
