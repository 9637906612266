import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/pt";
import { v4 as uuidv4 } from "uuid";

import { TableComponent } from "../TableComponent/TableComponent";
import {
  fetchWorkouts,
  duplicateWorkout,
  selectWorkouts,
  setErrorMessage,
  setConfirmationMessage,
  selectIsLoading,
  selectErrorMessage,
  selectConfirmationMessage,
  deleteWorkout,
  setCurrentWorkout,
} from "../../slices/workoutSlice";
import {
  fetchWorkoutSchedules,
  selectWorkoutSchedules,
} from "../../slices/workoutScheduleSlice";
import { selectCurrentUser, fetchUsers } from "../../slices/userSlice";
import { toast } from "react-toastify";
import { Workout, workoutState } from "../../types/workout";
import { WorkoutSchedule } from "../../types/workoutSchedule";
import { FilterDropdown } from "../FilterDropdown/FilterDropdown";
import { WorkoutScheduleModal } from "../WorkoutScheduleModal/WorkoutScheduleModal";

import "./DashboardWorkoutsPage.css";
import { DashboardTitle } from "../DashboardTitle/DashboardTitle";
import { SpinnerWrapper } from "../SpinnerWrapper/SpinnerWrapper";
import "react-tooltip/dist/react-tooltip.css";
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import ReactGA from "react-ga4";
import {
  fetchPersonalTrainers,
  selectPersonalTrainers,
} from "../../slices/personalTrainerSlice";
import { User } from "../../types/user";
import { GButton } from "components/genericComponents/GButton/GButton";
import { WorkoutMode } from "./WorkoutMode/WorkoutMode";
import { GChip } from "components/genericComponents/GChip/GChip";
import { formatToFirstTwoAndLastName } from "helpers/utils";

type DashBoardWorkoutsPageProps = {
  navigateToExercisesPage?: () => void;
};

type SelectData = {
  id: string;
  name: string;
  image_small: string;
};

export const DashBoardWorkoutsPage = (props: DashBoardWorkoutsPageProps) => {
  ReactGA.send("/workouts");

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const workoutsFiltersLocalStorage = localStorage.getItem("workoutsFilters");
  const defaultWorkouts = useSelector(selectWorkouts);
  //const [workouts, setWorkouts] = useState(defaultWorkouts);
  const user = useSelector(selectCurrentUser);
  const isLoading = useSelector(selectIsLoading);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [workoutsAlreadyFetched, setWorkoutsAlreadyFetched] = useState(false);
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);
  const workoutSchedules = useSelector(selectWorkoutSchedules);
  const personalTrainers = useSelector(selectPersonalTrainers);
  const [workoutsToShowOnList, setWorkoutsToShowOnList] =
    useState<Workout[]>(defaultWorkouts);
  const [userForWorkoutMode, setUserForWorkoutMode] = useState<
    { userId: string; workoutId: string } | undefined
  >(undefined);
  const [selectedPT, setSelectedPT] = useState<SelectData | undefined>(
    workoutsFiltersLocalStorage
      ? JSON.parse(workoutsFiltersLocalStorage).selectedPT
      : undefined
  );
  const isGymView = localStorage.getItem("isGymView") === "true";
  const [selectedWorkoutState, setSelectedWorkoutState] = useState<
    { id: string; title: string } | undefined
  >(
    workoutsFiltersLocalStorage
      ? JSON.parse(workoutsFiltersLocalStorage).selectedWorkoutState
      : undefined
  );
  const [clientNumber, setClientNumber] = useState<number | undefined>(
    workoutsFiltersLocalStorage
      ? JSON.parse(workoutsFiltersLocalStorage).clientNumber
      : undefined
  );
  const [searchInput, setSearchInput] = useState<string | undefined>(
    workoutsFiltersLocalStorage
      ? JSON.parse(workoutsFiltersLocalStorage).searchInput
      : undefined
  );

  const workoutsFilteredByUser =
    user !== undefined
      ? defaultWorkouts.filter(
          (workout: Workout) => workout?.user?.id === user?.id
        )
      : defaultWorkouts;

  const workoutsToShowOnListFunc = useCallback(
    (workoutsFiltered: Workout[]) => {
      if (workoutsFiltered.length === 0) return [];

      if (selectedPT !== undefined && selectedWorkoutState !== undefined) {
        return workoutsFiltered.filter(
          (workout: Workout) =>
            workout.personal_trainer?.id === selectedPT.id &&
            workout.workout_state === selectedWorkoutState.id
        );
      }

      if (selectedPT !== undefined) {
        return workoutsFiltered.filter(
          (workout: Workout) => workout.personal_trainer?.id === selectedPT.id
        );
      }

      if (selectedWorkoutState !== undefined) {
        return workoutsFiltered.filter((workout: Workout) => {
          const result =
            workout.expiration_date === null ||
            workout.expiration_date === undefined
              ? false
              : workout.expiration_date !== undefined &&
                workout.expiration_date !== null &&
                new Date(workout.expiration_date).toISOString() <
                  new Date().toISOString()
              ? "EXPIRED" === selectedWorkoutState.id
              : workout.number_of_completed_sessions !== undefined &&
                workout.number_of_completed_sessions !== null &&
                workout.number_of_sessions !== undefined &&
                workout.number_of_sessions !== null &&
                workout.number_of_completed_sessions >=
                  workout.number_of_sessions
              ? "FINISHED" === selectedWorkoutState.id
              : "ACTIVE" === selectedWorkoutState.id;

          return result;
        });
      }

      return workoutsFiltered;
    },
    [selectedPT, selectedWorkoutState]
  );

  useEffect(() => {
    if (searchInput !== undefined && clientNumber === undefined) {
      const workoutsFiltered = defaultWorkouts.filter(
        (workout: Workout) =>
          workout.user?.name?.toLowerCase().includes(searchInput) ||
          workout.user?.identifier?.toString().includes(searchInput) ||
          workout.title.toLowerCase().includes(searchInput)
      );
      //setWorkouts(workoutsFiltered.length ? workoutsFiltered : []);

      setWorkoutsToShowOnList(workoutsToShowOnListFunc(workoutsFiltered));
    }
  }, [searchInput, defaultWorkouts, workoutsToShowOnListFunc]); //eslint-disable-line

  useEffect(() => {
    localStorage.setItem(
      "workoutsFilters",
      JSON.stringify({
        selectedPT,
        selectedWorkoutState,
        clientNumber,
        searchInput,
      })
    );
  }, [selectedPT, selectedWorkoutState, clientNumber, searchInput]);

  const changeWorkoutsList = (evt: any) => {
    setSearchInput(evt.target.value);
  };

  const filterWorkoutsByClientNumber = (evt: any) => {
    const inputValue =
      evt.target.value !== "" ? Number.parseInt(evt.target.value) : undefined;

    if (
      evt.target.value === "" ||
      evt.target.value === undefined ||
      evt.target.value === null
    ) {
      //setWorkouts(defaultWorkouts)
      setWorkoutsToShowOnList(workoutsToShowOnListFunc(defaultWorkouts));
    } else {
      const workoutsFiltered = defaultWorkouts.filter(
        (workout: Workout) => workout.user?.identifier === inputValue
      );

      setWorkoutsToShowOnList(
        workoutsToShowOnListFunc(
          workoutsFiltered.length ? workoutsFiltered : defaultWorkouts
        )
      );
    }
    setClientNumber(inputValue);
  };

  const tableColumns = React.useMemo(
    () => [
      {
        Header: "Título",
        accessor: "title",
        Cell: (props: any) => {
          return (
            <>
              <p id={uuidv4()} className="table-title">
                {props.cell.value}
              </p>
              <Tooltip
                id={uuidv4()}
                place="top"
                content={props.cell.value}
                delayShow={1000}
              />
            </>
          );
        },
      },
      {
        Header: "Cliente",
        accessor: "user",
        Cell: (props: any) => {
          return (
            <>
              {props.cell.value !== null ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    alt=""
                    style={{ width: 30, height: 30, borderRadius: 15 }}
                    src={
                      props.cell.value.image_small ??
                      "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                    }
                  />
                  <p style={{ margin: 0, marginLeft: 10 }}>
                    {formatToFirstTwoAndLastName(props.cell.value.name)}
                    {props.cell.value.identifier !== null &&
                    props.cell.value.identifier !== undefined
                      ? ` - ${props.cell.value.identifier}`
                      : ""}
                  </p>
                </div>
              ) : undefined}
            </>
          );
        },
      },
      {
        Header: "Personal Trainer",
        accessor: "personal_trainer",
        Cell: (props: any) => {
          return (
            <>
              {props.cell.value !== null ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    alt=""
                    style={{ width: 30, height: 30, borderRadius: 15 }}
                    src={
                      props.cell.value.image_small ??
                      "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                    }
                  />
                  <p style={{ margin: 0, marginLeft: 10 }}>
                    {formatToFirstTwoAndLastName(props.cell.value.name)}
                  </p>
                </div>
              ) : undefined}
            </>
          );
        },
      },
      {
        Header: "Estado",
        Cell: (props: any) => {
          return (
            <>
              {props.cell.row.original.expiration_date === null ||
              props.cell.row.original.expiration_date ===
                undefined ? undefined : props.cell.row.original
                  .expiration_date < new Date().toISOString() ? (
                <GChip label="Expirado" variant="infoExpired" />
              ) : props.cell.row.original.number_of_completed_sessions >=
                props.cell.row.original.number_of_sessions ? (
                <GChip label="Concluído" variant="infoFinished" />
              ) : (
                <GChip label="Ativo" variant="infoActive" />
              )}
            </>
          );
        },
      },
      {
        accessor: "start_workout",
        Cell: (props: any) => {
          if (props.row.original.user !== null) {
            return (
              <GButton
                label="Começar Treino"
                onClick={() =>
                  startWorkout(
                    props.row.original.user.id,
                    props.row.original.id
                  )
                }
              />
            );
          }
          return null;
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (!workoutsAlreadyFetched) {
      dispatch(fetchWorkouts());
      dispatch(fetchUsers());
      dispatch(fetchPersonalTrainers());
      setWorkoutsAlreadyFetched(true);
    }
    if (defaultWorkouts.length > 0) {
      //setWorkouts(defaultWorkouts)
      if (clientNumber !== undefined) {
        const event = { target: { value: clientNumber } };
        filterWorkoutsByClientNumber(event);
      } else {
        setWorkoutsToShowOnList(workoutsToShowOnListFunc(defaultWorkouts));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    defaultWorkouts,
    workoutsAlreadyFetched,
    workoutsToShowOnListFunc,
    clientNumber
  ]);

  useEffect(() => {
    if (user !== undefined) {
      dispatch(fetchWorkoutSchedules(user.id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "workouts-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "workouts-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  const renderCustomerPage = (selectedWorkout: Workout) => {
    dispatch(setCurrentWorkout(selectedWorkout.id));
    let path = `${selectedWorkout.id}`;
    navigate(path);
  };

  const startWorkout = (userId: string, workoutId: string) => {
    setUserForWorkoutMode({ userId, workoutId });
  };

  const Scheduler = () => {
    const [startAt, setStartAt] = useState<Date | undefined>(undefined);
    const [showModal, setShowModal] = useState(false);
    const [selectedWorkoutSchedule, setSelectedWorkoutSchedule] = useState<
      WorkoutSchedule | undefined
    >(undefined);
    const localizer = momentLocalizer(moment);
    const events = workoutSchedules.map((workoutSchedule: WorkoutSchedule) => {
      return {
        id: workoutSchedule.id,
        start: new Date(workoutSchedule.start_at),
        end: new Date(workoutSchedule.start_at),
        title: workoutSchedule.workout.title,
        allDay: true,
      };
    });

    const handleSelectEvent = useCallback((event: any) => {
      if (user === undefined) return;

      const workoutScheduleSelected = workoutSchedules.find(
        (workoutSchedule: WorkoutSchedule) => {
          return workoutSchedule.id === event.id;
        }
      );

      setSelectedWorkoutSchedule(workoutScheduleSelected);
      setStartAt(event.start);
      setShowModal(true);
    }, []);

    const handleSelectSlot = useCallback((event: any) => {
      if (user === undefined) return;

      const startAt = event.start as Date;

      setStartAt(startAt);
      setShowModal(true);
    }, []);

    return (
      <div className="dashboard-workouts-scheduler-container">
        <WorkoutScheduleModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedWorkoutSchedule={selectedWorkoutSchedule}
          setSelectedWorkoutSchedule={setSelectedWorkoutSchedule}
          startAt={startAt}
          workouts={defaultWorkouts.filter(
            (workout: Workout) => workout.user?.id === user?.id
          )}
        />
        {isSchedulerOpen && user === undefined ? (
          <p className="dashboard-workouts-scheduler-info-select-user">
            Selecione um utilizador no filtro acima para conseguir gerir os seus
            planos de treino
          </p>
        ) : undefined}
        <Calendar
          views={["month"]}
          localizer={localizer}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          selectable
          components={{
            event: (props) => {
              return (
                <>
                  <div className="dashboard-courses-calendar-event-slot">
                    <img
                      alt=""
                      className="dashboard-courses-calendar-event-image"
                      src={
                        user?.image_small ??
                        "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                      }
                    />
                    <p className="dashboard-courses-calendar-event-title">
                      {" "}
                      {props.event.title}
                    </p>
                  </div>
                </>
              );
            },
          }}
          messages={{
            date: "Data",
            time: "Tempo",
            event: "Event",
            allDay: "Dia inteiro",
            week: "Semana",
            work_week: "Work Week",
            day: "Dia",
            month: "Mês",
            previous: "Anterior",
            next: "Próximo",
            yesterday: "Ontem",
            tomorrow: "Amanhã",
            today: "Hoje",
            agenda: "Agenda",
            noEventsInRange: "Não há eventos para estas datas.",
          }}
          events={user !== undefined ? events : []}
          startAccessor="start"
          endAccessor="end"
        />
      </div>
    );
  };

  return (
    <Container fluid id="dashboard-workouts">
      {isLoading ? (
        <SpinnerWrapper />
      ) : (
        <>
          {userForWorkoutMode === undefined && (
            <>
              <DashboardTitle
                title=""
                showAddButton={!isSchedulerOpen}
                linkObj={{ isLink: true, link: "add-workout" }}
                countCards={[
                  {
                    title: "Planos de Treino",
                    count: workoutsFilteredByUser.length,
                  },
                ]}
                infoLink="3EUTO9vYv9M"
              />
              {!isGymView && (
                <Container fluid>
                  <Row className="pricing-schedule-row">
                    <Col className="pricing-schedule-col">
                      <p
                        onClick={() => setIsSchedulerOpen(false)}
                        className={`pricing-schedule-text ${
                          !isSchedulerOpen ? "active" : ""
                        }`}
                      >
                        Lista
                      </p>
                      <p
                        onClick={() => setIsSchedulerOpen(true)}
                        className={`pricing-schedule-text ${
                          isSchedulerOpen ? "active" : ""
                        }`}
                      >
                        Calendário
                      </p>
                    </Col>
                  </Row>
                </Container>
              )}
              <div className="filter-container">
                <Form.Control
                  value={clientNumber}
                  onChange={filterWorkoutsByClientNumber}
                  placeholder="Número de cliente"
                  type="number"
                  className="search-input"
                />
                <Form.Control
                  value={searchInput}
                  onChange={changeWorkoutsList}
                  placeholder="Pesquisar"
                  className="search-input"
                />
                {isGymView && (
                  <FilterDropdown
                    title={"Personal Trainer"}
                    values={personalTrainers.map((personal_trainer: User) => {
                      return {
                        id: personal_trainer.id!,
                        name: formatToFirstTwoAndLastName(
                          personal_trainer.name
                        ),
                        image_small: personal_trainer.image_small,
                      };
                    })}
                    selectedValue={selectedPT}
                    onChangeValue={(personalTrainerId: any) => {
                      if (personalTrainerId === undefined)
                        return setSelectedPT(undefined);

                      const newValue = personalTrainers.find(
                        (personalTrainer) =>
                          personalTrainer.id === personalTrainerId
                      );

                      setSelectedPT({
                        id: newValue!.id,
                        name: formatToFirstTwoAndLastName(newValue!.name),
                        image_small: newValue!.image_small,
                      });
                    }}
                  />
                )}
                <FilterDropdown
                  title={"Estado"}
                  values={Object.keys(workoutState).map((key) => {
                    return {
                      id: key,
                      title: workoutState[key as keyof typeof workoutState],
                    };
                  })}
                  selectedValue={selectedWorkoutState}
                  onChangeValue={(workoutStateKey: string | undefined) => {
                    if (workoutStateKey !== undefined) {
                      setSelectedWorkoutState({
                        id: workoutStateKey,
                        title:
                          workoutState[
                            workoutStateKey as keyof typeof workoutState
                          ],
                      });
                    } else {
                      setSelectedWorkoutState(undefined);
                    }
                  }}
                />
              </div>
              {isSchedulerOpen ? (
                <Scheduler />
              ) : (
                <div className="table-component-upper-div">
                  <TableComponent
                    name="workout"
                    columns={tableColumns}
                    data={workoutsToShowOnList}
                    removeAction={(id: string) => dispatch(deleteWorkout(id))}
                    addAction={() => {}}
                    editAction={() => {}}
                    duplicateAction={(id: string) => {
                      dispatch(duplicateWorkout(id));
                    }}
                    rowClickAction={(row: any, cell: any) => {
                      if (cell.column.id !== "start_workout")
                        renderCustomerPage(row.original);
                    }}
                    showEditButton={false}
                    showRemoveButton
                    showDuplicateButton
                    textWhenNoItems="Não tem nenhum plano de treino"
                  />
                </div>
              )}
            </>
          )}
          {userForWorkoutMode !== undefined && (
            <WorkoutMode
              userId={userForWorkoutMode.userId}
              workoutId={userForWorkoutMode.workoutId}
              setUserForWorkoutMode={setUserForWorkoutMode}
            />
          )}
        </>
      )}
    </Container>
  );
};
