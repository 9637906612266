import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { format, startOfMonth } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { PiFilePdfBold } from "react-icons/pi";

import { GSection } from "components/genericComponents/GSection/GSection";
import { GDatePicker } from "components/genericComponents/GDatePicker/GDatePicker";
import { GButton } from "components/genericComponents/GButton/GButton";
import { DateRange } from "react-day-picker";
import { TableComponent } from "components/TableComponent/TableComponent";
import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";
import {
  fetchPayments,
  selectIsLoading,
  selectPayments,
} from "slices/paymentsSlice";
import {
  fetchUsers,
  selectUsers,
  selectIsLoading as selectIsUsersLoading,
} from "slices/userSlice";

import "components/BillingPage/ExportInvoices/ExportInvoices.scss";

export const ExportInvoices = () => {
  const dispatch = useDispatch();
  const payments = useSelector(selectPayments);
  const users = useSelector(selectUsers);
  const isPaymentsLoading = useSelector(selectIsLoading);
  const isUsersLoading = useSelector(selectIsUsersLoading);
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
  const [dateInterval, setDateInterval] = useState<DateRange | undefined>({
    from: startOfMonth(new Date()),
    to: new Date(),
  });

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    if (dateInterval && dateInterval.from && dateInterval.to) {
      dispatch(
        fetchPayments({
          from: dateInterval.from.toISOString(),
          to: dateInterval.to.toISOString(),
          search: searchInput ?? null,
        })
      );
    }
  }, [dateInterval, dispatch, searchInput]);

  const columns = [
    {
      Header: "Título",
      accessor: "vendus_document_title",
    },
    {
      Header: "Cliente",
      accessor: "user_id",
      Cell: (props: any) => {
        const userId = props.row.original.user_id;
        const user = users.length
          ? users.find((usr) => usr.id === userId)!.name
          : "";

        return <span>{user}</span>;
      },
    },
    {
      Header: "Data de criação",
      accessor: "created_at",
      Cell: (props: any) => {
        return (
          <span>
            {format(new Date(props.row.original.created_at), "dd/MM/yyyy")}
          </span>
        );
      },
    },
    {
      Header: "Data de pagamento",
      accessor: "payment_date",
      Cell: (props: any) => {
        return (
          <span>
            {format(new Date(props.row.original.payment_date), "dd/MM/yyyy")}
          </span>
        );
      },
    },
    {
      Header: "Valor",
      accessor: "price",
      Cell: (props: any) => {
        return <span>{`${props.row.original.price}€`}</span>;
      },
    },
    {
      Header: "Descarregar Documento",
      accessor: "vendus_document_pdf_url",
      Cell: (props: any) => {
        return (
          <>
            {props.row.original.vendus_document_pdf_url !== null ? (
              <GButton
                iconSize="big"
                onClick={() =>
                  handleDownloadDocument(
                    props.row.original.vendus_document_pdf_url,
                    props.row.original.vendus_document_title
                  )
                }
              >
                <PiFilePdfBold />
              </GButton>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  const handleSetDateInterval = (dateInterval: DateRange) => {
    setDateInterval(dateInterval);
  };

  const handleDownloadDocument = (
    documentURL: string,
    documentName: string
  ) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = documentURL;
    downloadLink.download = documentName;
    downloadLink.target = "_blank";
    downloadLink.rel = "noreferrer noopener";
    downloadLink.click();
  };

  return (
    <GSection label="Exportar Faturas">
      <div className="filter-container">
        <Form.Control
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Pesquisar"
          className="search-input"
        />
        <GDatePicker
          mode="range"
          setDateInterval={handleSetDateInterval}
          currDateInterval={dateInterval}
          className="date-picker"
          clearable
        />
      </div>
      {isPaymentsLoading || isUsersLoading ? (
        <div style={{ padding: "8px" }}>
          <SpinnerWrapper />
        </div>
      ) : (
        <TableComponent
          name="Faturas"
          columns={columns}
          data={payments}
          rowClickAction={(row: any) => {}}
          textWhenNoItems="Não existe nenhum documento para as data selecionadas"
        />
      )}
    </GSection>
  );
};
