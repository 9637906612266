import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardTitle } from "../../DashboardTitle/DashboardTitle";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import { UserImageUpload } from "../../UserImageUpload/UserImageUpload";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../types/user";
import { InputForm } from "../../InputForm/InputForm";
import { toast } from "react-toastify";
import {
  selectPersonalTrainers,
  CreatePersonalTrainerType,
  selectErrorMessage,
  selectConfirmationMessage,
  createPersonalTrainer,
  updatePersonalTrainer,
  setErrorMessage,
  setConfirmationMessage,
} from "../../../slices/personalTrainerSlice";
import { TextAreaForm } from "../../TextAreaForm/TextAreaForm";
import {
  emailMessage,
  emailValidMessage,
  nameMessage,
} from "../../../helpers/messages";
import { validEmailRegex } from "../../../helpers/validation";

import "./PersonalTrainerPage.css";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GInput } from "components/genericComponents/GInput/GInput";

type ClientExists = {
  clientExists: boolean;
};

const initialCreateUserState = {
  name: null,
  email: null,
  phone_number: null,
  birthday_date: null,
  other_issues: null,
  availability: null,
  image: null,
  nif: null,
  fiscal_address: null,
  fiscal_zip_code: null,
  fiscal_city: null,
};

export const PersonalTrainerPage = ({ clientExists }: ClientExists) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isNotEditing, setIsNotEditing] = useState(clientExists);
  const users = useSelector(selectPersonalTrainers);
  const userId = window.location.href.split("/").slice(-1)[0];
  const user = users.find((user: User) => user.id === userId);
  const [file, setFile] = useState<File | null>(null);
  const [imageKey, setImageKey] = useState<string | undefined>(undefined);
  const [formData, setFormData] = useState<CreatePersonalTrainerType>(
    initialCreateUserState
  );
  const [, setNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  const dataToShowOnBirthdayPicker = () => {
    if (formData.birthday_date === undefined) return;
    if (formData.birthday_date === null) return;

    return `${formData.birthday_date.slice(
      0,
      4
    )}-${formData.birthday_date.slice(5, 7)}-${formData.birthday_date.slice(
      8,
      10
    )}`;
  };

  const isNameValid = (): boolean => {
    if (formData?.name === null) {
      setNameError(nameMessage);
      return false;
    }

    setNameError(null);
    return true;
  };

  const isEmailValid = (): boolean => {
    if (formData?.email === null) {
      setEmailError(emailMessage);
      return false;
    }

    if (!validEmailRegex.test(formData?.email!)) {
      setEmailError(emailValidMessage);
      return false;
    }

    setEmailError(null);
    return true;
  };

  const onSubmitButtonClick = () => {
    var isValid = true;
    if (!isNameValid()) isValid = false;
    if (!isEmailValid()) isValid = false;

    if (!isValid) return;

    const image =
      file === null
        ? null
        : {
            id: imageKey!,
            storage: "cache",
            metadata: {
              size: file.size,
              mime_type: file.type,
            },
          };

    setIsNotEditing(true);

    if (clientExists) {
      dispatch(
        updatePersonalTrainer({
          ...formData,
          id: user!.id,
          image: image,
        })
      );
    } else {
      dispatch(
        createPersonalTrainer({
          ...formData,
          image: image,
        })
      );
      navigate("/dashboard/personal_trainers");
    }
  };

  useEffect(() => {
    if (clientExists && user !== undefined && user !== null) {
      setFormData({
        name: user.name,
        email: user.email,
        phone_number: user.phone_number,
        birthday_date: user.birthday_date,
        other_issues: user.other_issues,
        availability: user.availability,
        image: null,
        nif: user.nif ?? null,
        fiscal_address: user.fiscal_address ?? null,
        fiscal_zip_code: user.fiscal_zip_code ?? null,
        fiscal_city: user.fiscal_city ?? null,
      });
    }
  }, [user, clientExists, userId]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "personal-trainer-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "personal-trainer-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  return (
    <Container fluid id="add-customer-page">
      <DashboardTitle
        title={clientExists ? `${user?.name}` : "Adicionar um Personal Trainer"}
        showAddButton={false}
      />
      <Container fluid id="personal-data-section">
        <Container fluid>
          <Row className="account-section-row">
            <Col className="account-section-col">
              <Container fluid id="personal-data">
                <Row>
                  <Col>
                    <div className="personal-data-flex-row">
                      <h4>Dados Pessoais</h4>
                      {isNotEditing && clientExists ? (
                        <Button
                          className="personal-data-edit-button"
                          onClick={() => setIsNotEditing(!isNotEditing)}
                        >
                          <BsPencilSquare />
                        </Button>
                      ) : undefined}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Row>
                    <Col className="profile-img-col">
                      <UserImageUpload
                        image={user?.image_medium}
                        file={file}
                        setFile={setFile}
                        setImageKey={setImageKey}
                        showEditButton={!isNotEditing}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <p className="section-title">Informação Geral</p>
                  </Row>
                  <Col lg={6}>
                    <InputForm
                      value={formData?.name}
                      label="Name"
                      disabled={isNotEditing && clientExists}
                      onChange={(e: any) =>
                        setFormData({
                          ...formData,
                          name: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row></Row>
                <Row>
                  <Col lg={6}>
                    <InputForm
                      value={formData?.email}
                      label="Email"
                      disabled={isNotEditing && clientExists}
                      errorMessage={emailError}
                      onChange={(e: any) =>
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col lg={6}>
                    <InputForm
                      value={formData?.phone_number}
                      label="Telemóvel"
                      disabled={isNotEditing && clientExists}
                      type="number"
                      onChange={(e: any) =>
                        setFormData({
                          ...formData,
                          phone_number: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <InputForm
                      value={dataToShowOnBirthdayPicker()}
                      label="Data de nascimento"
                      type="date"
                      disabled={isNotEditing && clientExists}
                      onChange={(e: any) => {
                        if (isNaN(Date.parse(e.target.value))) return;

                        const date = new Date(e.target.value);

                        setFormData({
                          ...formData,
                          birthday_date: date.toISOString(),
                        });
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <InputForm
                      value={user?.age}
                      label="Idade"
                      disabled={true}
                    />
                  </Col>
                  <Col lg={6}>
                    <InputForm
                      value={formData?.nif}
                      label="NIF"
                      disabled={isNotEditing && clientExists}
                      type="number"
                      onChange={(e: any) =>
                        setFormData({
                          ...formData,
                          nif: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col lg={6}>
                    <GInput
                      value={formData?.fiscal_address}
                      label="Morada"
                      disabled={isNotEditing && clientExists}
                      onChange={(e: any) =>
                        setFormData({
                          ...formData,
                          fiscal_address: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col lg={6}>
                    <GInput
                      value={formData?.fiscal_zip_code}
                      label="Código Postal"
                      disabled={isNotEditing && clientExists}
                      onChange={(e: any) =>
                        setFormData({
                          ...formData,
                          fiscal_zip_code: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col lg={6}>
                    <GInput
                      value={formData?.fiscal_city}
                      label="Cidade"
                      disabled={isNotEditing && clientExists}
                      onChange={(e: any) =>
                        setFormData({
                          ...formData,
                          fiscal_city: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <p className="section-title">Outras Informações</p>
                </Row>
                <Row>
                  <Col>
                    <TextAreaForm
                      label="Disponibilidade do utilizador"
                      value={formData.availability}
                      onChange={(e: any) =>
                        setFormData({
                          ...formData,
                          availability: e.target.value,
                        })
                      }
                      disabled={isNotEditing && clientExists}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextAreaForm
                      label="Outros"
                      value={formData.other_issues}
                      onChange={(e: any) =>
                        setFormData({
                          ...formData,
                          other_issues: e.target.value,
                        })
                      }
                      large={true}
                      disabled={isNotEditing && clientExists}
                    />
                  </Col>
                </Row>
                {isNotEditing ? undefined : (
                  <Row className="modal-flex-center padding">
                    <GButton
                      label="Submeter"
                      onClick={onSubmitButtonClick}
                      style={{ width: "200px" }}
                    />
                  </Row>
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
};
