export type MuscleGroup = {
  pt: string;
};

export type MuscleGroups = {
  [x: string]: MuscleGroup;
};

export const muscleGroups: MuscleGroups = {
  abductors: {
    pt: "Abdutores",
  },
  abs: {
    pt: "Abdominais",
  },
  adductors: {
    pt: "Adutores",
  },
  forearms: {
    pt: "Antebraços",
  },
  biceps: {
    pt: "Bícep",
  },
  calves: {
    pt: "Gémeo",
  },
  glutes: {
    pt: "Glúteos",
  },
  lower_back: {
    pt: "Lombar",
  },
  shoulders: {
    pt: "Ombros",
  },
  chest: {
    pt: "Peito",
  },
  hamstrings: {
    pt: "Posteriores da coxa/Isquiotibiais",
  },
  quads: {
    pt: "Quadricípite",
  },
  upper_back: {
    pt: "Dorsal",
  },
  traps: {
    pt: "Trapézio",
  },
  triceps: {
    pt: "Tríceps",
  },
};
