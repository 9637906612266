export const taxValues = {
  exempt: {
    value: 1,
    label: "1 - Isento",
  },
  reduced: {
    value: 6,
    label: "6 - Taxa Reduzida",
  },
  intermediate: {
    value: 13,
    label: "13 - Taxa Intermédia",
  },
  normal: {
    value: 23,
    label: "23 - Taxa Normal",
  },
  notSubject: {
    value: 0,
    label: "Não Sujeito",
  },
};

export const vatExemptionReasons = [
  "M00 - Não isento",
  "M01 - Artigo 16º nº6 do CIVA (ou similar)",
  "M02 - Artigo 6º do Decreto-Lei nº198/90 de 19 de Junho",
  "M03 - Exigibilidade de caixa (até 31-05-2022)",
  "M04 - Isento Artigo 13º do CIVA",
  "M05 - Isento Artigo 14º do CIVA",
  "M06 - Isento Artigo 15º do CIVA",
  "M07 - Isento Artigo 9º do CIVA",
  "M08 - IVA - Autoliquidação (até 31-05-2022)",
  "M09 - IVA - Não confere direito a dedução",
  "M10 - IVA - Regime de isenção",
  "M11 - Não tributado",
  "M12 - Regime da margem de lucro - Agências de viagens",
  "M13 - Regime da margem de lucro - Bens em segunda mão",
  "M14 - Regime da margem de lucro - Objetos de arte",
  "M15 - Regime da margem de lucro - Objetos de coleção e antiguidades",
  "M16 - Isento Artigo 14º do RITI",
  "M19 - Outras isenções (após 01-06-2022)",
  "M20 - IVA - Regime forfetário",
  "M21 - IVA - Não confere direito a dedução (ou expressão similar) (após 01-06-2022)",
  "M25 - Mercadorias à consignação (após 01-06-2022)",
  "M26 - Isenção de IVA com direito à dedução no cabaz alimentar (após 18-04-2023)",
  "M30 - IVA - autoliquidação (após 01-06-2022)",
  "M31 - IVA - autoliquidação (após 01-06-2022)",
  "M32 - IVA - autoliquidação (após 01-06-2022)",
  "M32 - IVA - autoliquidação (após 01-06-2022)",
  "M33 - IVA - autoliquidação (após 01-06-2022)",
  "M34 - IVA - autoliquidação (após 14-04-2023)",
  "M40 - IVA - autoliquidação (após 01-06-2022)",
  "M41 - IVA - autoliquidação (após 01-06-2022)",
  "M42 - IVA - autoliquidação (após 01-06-2022)",
  "M43 - IVA - autoliquidação (após 01-06-2022)",
  "M99 - Não sujeito; não tributado (ou similar)",
];
