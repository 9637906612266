import "components/TabletPage/TabletMode/TabletMode.scss";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "slices/currentUserSlice";
import logo from "assets/logo.svg";

export type TabletModeProps = {
  title: string;
  subtitle: string;
  hasBirthday?: boolean;
  isPreview?: boolean;
  isModalPreview?: boolean;
  bgImage?: string;
};

export const TabletMode = ({
  title,
  subtitle,
  hasBirthday,
  isPreview,
  isModalPreview,
  bgImage,
}: TabletModeProps) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <div
      className={`tablet-mode${isPreview ? " preview" : ""}${
        isModalPreview ? " modal-preview" : ""
      }`}
    >
      {bgImage && (
        <div className="bg-image-div">
          <div
            className="bg-image"
            style={{
              background: `center / cover no-repeat url(${bgImage}) #1F222A`,
            }}
          />
        </div>
      )}
      <div className={`inner-container${isPreview ? " preview" : ""}`}>
        <img
          src={currentUser?.company?.image_medium}
          className={isPreview ? "preview" : ""}
          alt="gym-logo"
        />
        <p className={`title${isPreview ? " preview" : ""}`}>{title}</p>
        <p className={`subtitle${isPreview ? " preview" : ""}`}>{subtitle}</p>
        {hasBirthday && (
          <p className={`birthday${isPreview ? " preview" : ""}`}>
            Feliz Aniversário!
          </p>
        )}
        <div className="goactive-logo-div">
          <img
            src={logo}
            className={`goactive-logo${isPreview ? " preview" : ""}`}
            alt="GoActive-logo"
          />
        </div>
      </div>
    </div>
  );
};
