import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import ReactGA from "react-ga4";

import { Header } from "components/Header/Header";
import { Welcome } from "components/LandingPage/Welcome/Welcome";
import { FeaturesComponent } from "components/LandingPage/FeaturesComponent/FeaturesComponent";
import { FooterComponent } from "components/FooterComponent/FooterComponent";
import { Pricing } from "components/LandingPage/Pricing/Pricing";
import { BusinessSimplified } from "components/LandingPage/BusinessSimplified/BusinessSimplified";
import { EffortlessScheduler } from "components/LandingPage/EffortlessScheduler/EffortlessScheduler";
import { ExerciseLibrary } from "components/LandingPage/ExerciseLibrary/ExerciseLibrary";
/* import { RealtimeNotifications } from "components/LandingPage/RealtimeNotifications/RealtimeNotifications"; */
import { ManageClients } from "components/LandingPage/ManageClients/ManageClients";
import { CustomWorkouts } from "components/LandingPage/CustomWorkouts/CustomWorkouts";
import { Chatgpt } from "components/LandingPage/Chatgpt/Chatgpt";
import { AppSection } from "components/LandingPage/AppSection/AppSection";
import { GoActiveClients } from "components/LandingPage/GoActiveClients/GoActiveClients";
import { TryForFree } from "components/LandingPage/TryForFree/TryForFree";

import "App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [cookies] = useCookies(["current_user"]);
  const currentUserCookies = cookies.current_user;

  ReactGA.send("/");

  useEffect(() => {
    if (currentUserCookies === undefined) {
      var Tawk_API: any = Tawk_API || {},
        Tawk_LoadStart: any = new Date(); // eslint-disable-line
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/65b69bfc8d261e1b5f58e2bc/1hl8l2knv";
        s1.setAttribute("charset", "UTF-8");
        s1.setAttribute("crossorigin", "*");
        s1.setAttribute("data-name", "tawk-script");
        s0.parentNode?.insertBefore(s1, s0);
      })();
    }
  }, [currentUserCookies]);

  return (
    <div className="App">
      {currentUserCookies !== undefined &&
      currentUserCookies?.id !== undefined ? (
        <Navigate to="/dashboard/home" replace={true} />
      ) : (
        <>
          <Container fluid className="app-nav-header-container">
            <Header />
            <Welcome />
          </Container>
          <FeaturesComponent />
          <GoActiveClients />
          <Pricing />
          <BusinessSimplified />
          <ManageClients />
          <CustomWorkouts />
          <EffortlessScheduler />
          <Chatgpt />
          <ExerciseLibrary />
          {/* <RealtimeNotifications /> */}
          <AppSection />
          {/* Try for free button */}
          <TryForFree />
          <FooterComponent />
        </>
      )}
    </div>
  );
};

export default App;
