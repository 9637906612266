import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  CreateRecipe,
  FetchUserNutritionScheduler,
  Recipe,
  RecipeOverview,
  UserNutritionScheduler,
} from "types/nutrition";

type NutritionState = {
  recipes: RecipeOverview[];
  recipe: Recipe | undefined;
  userNutritionScheduler: UserNutritionScheduler[] | undefined;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
  isLoading: boolean;
};

const initialNutritionState = {
  recipes: [],
  recipe: undefined,
  userNutritionScheduler: undefined,
  errorMessage: undefined,
  confirmationMessage: undefined,
  isLoading: false,
};

const nutritionSlice = createSlice({
  name: "nutrition",
  initialState: initialNutritionState,
  reducers: {
    fetchRecipes: (state: NutritionState) => {
      state.isLoading = true;
      state.recipes = [];
    },
    fetchRecipesSucceeded: (
      state: NutritionState,
      action: PayloadAction<RecipeOverview[]>
    ) => {
      state.recipes = action.payload;
      state.isLoading = false;
    },
    fetchRecipe: (state: NutritionState, action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    fetchRecipeSucceeded: (
      state: NutritionState,
      action: PayloadAction<Recipe>
    ) => {
      state.recipe = action.payload;
      state.isLoading = false;
    },
    createRecipe: (
      state: NutritionState,
      action: PayloadAction<CreateRecipe>
    ) => {},
    createRecipeSucceeded: (
      state: NutritionState,
      action: PayloadAction<Recipe>
    ) => {
      state.confirmationMessage = "Receita criada com sucesso";
    },
    updateRecipe: (
      state: NutritionState,
      action: PayloadAction<{ id: string; payload: CreateRecipe }>
    ) => {},
    updateRecipeSucceeded: (
      state: NutritionState,
      action: PayloadAction<Recipe>
    ) => {
      state.confirmationMessage = "Receita atualizada com sucesso";
    },
    deleteRecipe: (state: NutritionState, action: PayloadAction<string>) => {},
    deleteRecipeSucceeded: (
      state: NutritionState,
      action: PayloadAction<string>
    ) => {
      state.confirmationMessage = "Receita eliminada com sucesso";
    },
    fetchUserNutritionScheduler: (
      state: NutritionState,
      action: PayloadAction<FetchUserNutritionScheduler>
    ) => {
      state.isLoading = true;
    },
    fetchUserNutritionSchedulerSucceeded: (
      state: NutritionState,
      action: PayloadAction<UserNutritionScheduler[]>
    ) => {
      state.userNutritionScheduler = action.payload;
      state.isLoading = false;
    },
    setErrorMessage(
      state: NutritionState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: NutritionState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
    cleanErrorMessage(state: NutritionState) {
      state.errorMessage = undefined;
    },
  },
});

export const {
  fetchRecipes,
  fetchRecipesSucceeded,
  fetchRecipe,
  fetchRecipeSucceeded,
  createRecipe,
  createRecipeSucceeded,
  updateRecipe,
  updateRecipeSucceeded,
  deleteRecipe,
  deleteRecipeSucceeded,
  fetchUserNutritionScheduler,
  fetchUserNutritionSchedulerSucceeded,
  setErrorMessage,
  setConfirmationMessage,
  cleanErrorMessage,
} = nutritionSlice.actions;

export const selectRecipes = (state: RootState) => state.nutritionSlice.recipes;
export const selectRecipe = (state: RootState) =>
  state.nutritionSlice.recipe as Recipe | undefined;
export const selectUserNutritionScheduler = (
  state: RootState
): UserNutritionScheduler[] | undefined =>
  state.nutritionSlice.userNutritionScheduler;
export const selectErrorMessage = (state: RootState) =>
  state.nutritionSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.nutritionSlice.confirmationMessage;
export const selectIsLoading = (state: RootState) =>
  state.nutritionSlice.isLoading;

export default nutritionSlice.reducer;
