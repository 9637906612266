import { axiosRequest, errorMessage } from "./request";
import { WorkoutSchedule } from "../types/workoutSchedule";
import {
  CreateWorkoutScheduleType,
  UpdateWorkoutScheduleType,
} from "../slices/workoutScheduleSlice";

export type WorkoutScheduleRequestResponseType = {
  succeeded: boolean;
  workoutSchedules: Array<WorkoutSchedule> | undefined;
  workoutSchedule: WorkoutSchedule | undefined;
  errorMessage: string | undefined;
};

export const fetchWorkoutSchedulesRequest = async (
  userId: string
): Promise<WorkoutScheduleRequestResponseType> => {
  try {
    const response = await axiosRequest().get(
      `workouts_schedules?user_id=${userId}`
    );
    const workoutsSchedules: Array<WorkoutSchedule> = response.data;

    return {
      succeeded: true,
      workoutSchedules: workoutsSchedules,
    } as WorkoutScheduleRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as WorkoutScheduleRequestResponseType;
  }
};

export const createWorkoutSchedulesRequest = async (
  params: CreateWorkoutScheduleType
): Promise<WorkoutScheduleRequestResponseType> => {
  try {
    const response = await axiosRequest().post(`workouts_schedules`, {
      workout_schedule: {
        start_at: params.startAt,
        workout_id: params.workoutId,
      },
    });
    const workoutsSchedule: WorkoutSchedule = response.data;

    return {
      succeeded: true,
      workoutSchedule: workoutsSchedule,
    } as WorkoutScheduleRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as WorkoutScheduleRequestResponseType;
  }
};

export const updateWorkoutSchedulesRequest = async (
  params: UpdateWorkoutScheduleType
): Promise<WorkoutScheduleRequestResponseType> => {
  try {
    const response = await axiosRequest().put(
      `workouts_schedules/${params.id}`,
      {
        workout_schedule: {
          start_at: params.startAt,
          workout_id: params.workoutId,
        },
      }
    );
    const workoutsSchedule: WorkoutSchedule = response.data;

    return {
      succeeded: true,
      workoutSchedule: workoutsSchedule,
    } as WorkoutScheduleRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as WorkoutScheduleRequestResponseType;
  }
};

export const deleteWorkoutSchedulesRequest = async (
  workoutScheduleId: string
): Promise<WorkoutScheduleRequestResponseType> => {
  try {
    await axiosRequest().delete(`workouts_schedules/${workoutScheduleId}`);

    return {
      succeeded: true,
    } as WorkoutScheduleRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as WorkoutScheduleRequestResponseType;
  }
};
