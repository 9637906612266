import { axiosRequest, errorMessage } from "requests/request";
import { Company } from "types/user";
import {
  UpdateCompanyType,
  UpdateCompanyCourseSettingsType,
} from "slices/currentUserSlice";

export type CompanyResponseType = {
  succeeded: boolean;
  company: Company | undefined;
  errorMessage: string | undefined;
};

export const updateCompanyRequest = async (
  params: UpdateCompanyType
): Promise<CompanyResponseType> => {
  try {
    const response = await axiosRequest().put(`company/${params.id}`, {
      company: params,
    });

    const company = response.data as Company;

    return {
      succeeded: true,
      company: company,
    } as CompanyResponseType;
  } catch (e) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CompanyResponseType;
  }
};

export const updateCompanyCourseSettingsRequest = async (
  params: UpdateCompanyCourseSettingsType
): Promise<CompanyResponseType> => {
  try {
    const response = await axiosRequest().post(`company_course_settings`, {
      company: params,
    });

    const company = response.data as Company;

    return {
      succeeded: true,
      company: company,
    } as CompanyResponseType;
  } catch (e) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CompanyResponseType;
  }
};
