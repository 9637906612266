import { Product, ProductRevenueType } from "../types/product";
import { axiosRequest, errorMessage } from "./request";
import {
  CreateOrUpdateProductType,
  FetchProductRevenueType,
} from "../slices/productSlice";

export type ProductRequestResponseType = {
  succeeded: boolean;
  products: Array<Product> | undefined;
  product: Product | undefined;
  productRevenue: ProductRevenueType | undefined;
  errorMessage: string | undefined;
};

export const fetchProductsRequest =
  async (): Promise<ProductRequestResponseType> => {
    try {
      const response = await axiosRequest().get("products");
      const products: Array<Product> = response.data;

      return {
        succeeded: true,
        products: products,
      } as ProductRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as ProductRequestResponseType;
    }
  };

export const createProductRequest = async (
  params: CreateOrUpdateProductType
): Promise<ProductRequestResponseType> => {
  try {
    const response = await axiosRequest().post("products", {
      product: params,
    });
    const product: Product = response.data;

    return {
      succeeded: true,
      product: product,
    } as ProductRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ProductRequestResponseType;
  }
};

export const updateProductRequest = async (
  params: CreateOrUpdateProductType
): Promise<ProductRequestResponseType> => {
  try {
    const response = await axiosRequest().put(`products/${params.id}`, {
      product: params,
    });
    const product: Product = response.data;

    return {
      succeeded: true,
      product: product,
    } as ProductRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ProductRequestResponseType;
  }
};

export const deleteProductRequest = async (
  productId: string
): Promise<ProductRequestResponseType> => {
  try {
    await axiosRequest().delete(`products/${productId}`);

    return { succeeded: true } as ProductRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ProductRequestResponseType;
  }
};

export const fetchProductRevenueRequest = async (
  params: FetchProductRevenueType
): Promise<ProductRequestResponseType> => {
  try {
    const response = await axiosRequest().get(
      `counters/product_revenue?start_at=${params.start_at}&end_at=${params.end_at}`
    );
    const productRevenue: ProductRevenueType = response.data;

    return {
      succeeded: true,
      productRevenue: productRevenue,
    } as ProductRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ProductRequestResponseType;
  }
};
