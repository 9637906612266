import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { dietSuccessfullyCreated, dietSuccessfullyUpdated, dietSuccessfullyDeleted, dietSuccessfullyDuplicated } from "../helpers/messages";
import { Diet, Meal } from "../types/diet";
import { Food } from "types/nutrition";

export type CreateOrUpdateDietType = {
  id?: string;
  title: string;
  description: string;
  user_id: string;
  meals: Array<CreateOrUpdateMealType>;
};

export type CreateOrUpdateMealType = {
  id?: string;
  title: string;
  description: string;
  meal_items: Array<CreateOrUpdateMealItemType>;
};

export type CreateOrUpdateMealItemType = {
  id?: string;
  item_type: string; // Food or Recipe
  item_id: string;
  weight_in_g: number;
};

type DietState = {
  diets: Array<Diet>;
  diet: Diet;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
  isLoading: boolean;
  allIngredientsInADiet: Array<Food>;
};

export const dietInitialState: Diet = {
  title: "",
  description: "",
  meals: [],
  totals: {
    weight_in_g: 0,
    calories: 0,
    lipids: 0,
    water: 0,
    carbs: 0,
    protein: 0,
  },
  type: "",
}

const initialState: DietState = {
  diets: [],
  diet: dietInitialState,
  errorMessage: undefined,
  confirmationMessage: undefined,
  isLoading: false,
  allIngredientsInADiet: [],
};

const dietSlice = createSlice({
  name: "diets",
  initialState: initialState,
  reducers: {
    fetchDiets(state: DietState) {
      state.isLoading = true;
    },
    fetchDietsSucceeded(state: DietState, action: PayloadAction<Array<Diet>>) {
      state.diets = action.payload;
      state.isLoading = false;
    },
    fetchDiet(state: DietState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    fetchDietSucceeded(state: DietState, action: PayloadAction<Diet>) {
      state.diet = action.payload;
      state.isLoading = false;
    },
    createDiet(state: DietState, action: PayloadAction<CreateOrUpdateDietType>) {},
    createDietSucceeded(state: DietState, action: PayloadAction<Diet>) {
      return {
        ...state,
        confirmationMessage: dietSuccessfullyCreated,
        diets: [action.payload, ...state.diets],
      };
    },
    updateDiet(state: DietState, action: PayloadAction<CreateOrUpdateDietType>) {},
    updateDietSucceeded(state: DietState, action: PayloadAction<Diet>) {
      return {
        ...state,
        confirmationMessage: dietSuccessfullyUpdated,
        evaluations: state.diets.map((diet: Diet) => {
          if (diet.id === action.payload.id) {
            return action.payload;
          } else {
            return diet;
          }
        }),
      };
    },
    deleteDiet(state: DietState, action: PayloadAction<string>) {},
    deleteDietSucceeded(state: DietState, action: PayloadAction<string>) {
      const index = state.diets.findIndex((diet: Diet) => diet.id === action.payload);

      state.diets.splice(index, 1);
      state.confirmationMessage = dietSuccessfullyDeleted;
    },
    duplicateDiet(state: DietState, action: PayloadAction<string>) {},
    duplicateDietSucceeded(state: DietState, action: PayloadAction<Diet>) {
      return {
        ...state,
        confirmationMessage: dietSuccessfullyDuplicated,
        diets: [action.payload, ...state.diets],
      };
    },
    setErrorMessage(state: DietState, action: PayloadAction<string | undefined>) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(state: DietState, action: PayloadAction<string | undefined>) {
      state.confirmationMessage = action.payload;
    },
    setDiet(state: DietState, action: PayloadAction<Diet>) {
      state.diet = action.payload;
    },
    setMeal(state: DietState, action: PayloadAction<{ meal: Meal, index: number }>) {
      state.diet.meals[action.payload.index] = action.payload.meal;
      state.diet.totals = {
        weight_in_g: state.diet.meals.reduce((acc, curr) => acc + curr.totals.weight_in_g, 0),
        calories: state.diet.meals.reduce((acc, curr) => acc + curr.totals.calories, 0),
        lipids: state.diet.meals.reduce((acc, curr) => acc + curr.totals.lipids, 0),
        water: state.diet.meals.reduce((acc, curr) => acc + curr.totals.water, 0),
        carbs: state.diet.meals.reduce((acc, curr) => acc + curr.totals.carbs, 0),
        protein: state.diet.meals.reduce((acc, curr) => acc + curr.totals.protein, 0),
      };
    },
    deleteMeal(state: DietState, action: PayloadAction<number>) {
      state.diet.meals.splice(action.payload, 1);
    },
    clearDiet(state: DietState) {
      state.diet = dietInitialState;
    },
    setAllIngredientsInADiet(state: DietState, action: PayloadAction<Array<Food>>) {
      state.allIngredientsInADiet = action.payload;
    },
  },
});

export const {
  fetchDiets,
  fetchDietsSucceeded,
  fetchDiet,
  fetchDietSucceeded,
  createDiet,
  createDietSucceeded,
  updateDiet,
  updateDietSucceeded,
  deleteDiet,
  deleteDietSucceeded,
  duplicateDiet,
  duplicateDietSucceeded,
  setErrorMessage,
  setConfirmationMessage,
  clearDiet,
  setDiet,
  setMeal,
  deleteMeal,
  setAllIngredientsInADiet,
} = dietSlice.actions;

export const selectErrorMessage = (state: RootState) => state.dietSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) => state.dietSlice.confirmationMessage;
export const selectDiets = (state: RootState) => state.dietSlice.diets;
export const selectDiet = (state: RootState) => state.dietSlice.diet;
export const selectIsLoading = (state: RootState) => state.dietSlice.isLoading;
export const selectAllIngredientsInADiet = (state: RootState) => state.dietSlice.allIngredientsInADiet;

export default dietSlice.reducer;
