import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import { DashboardTitle } from "components/DashboardTitle/DashboardTitle";
import { Tabs, TabsI } from "components/Tabs/Tabs";
import { selectCurrentUser } from "slices/currentUserSlice";
import {
  fetchUser,
  fetchUsers,
  selectIsLoading,
  selectUsers,
  setCurrentClient,
} from "slices/userSlice";

import "./CustomerPage.css";
import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";
import { Outlet, useNavigate } from "react-router-dom";
import { useUnmount } from "helpers/utils";
import { resetInsuranceState } from "slices/insuranceSlice";

export const CustomerPage = () => {
  ReactGA.send("/customers");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector(selectUsers);
  const currentUser = useSelector(selectCurrentUser);
  const isUserLoading = useSelector(selectIsLoading);
  const isGymView = localStorage.getItem("isGymView") === "true";
  const tabs =
    isGymView || (currentUser && !currentUser.company!.is_gym)
      ? [
          "Dados Pessoais",
          "Avaliações",
          "Histórico de Treinos",
          "Evolução",
          "Pagamentos",
          "CRM",
        ]
      : ["Dados Pessoais", "Avaliações", "Histórico de Treinos", "Evolução"];
  const pathSegments = window.location.href.split("/");
  const userId = pathSegments[pathSegments.length - 2];
  const user = users.find((user) => user.id === userId);
  const clientTab = (): number => {
    const activeTab = pathSegments[pathSegments.length - 1];
    if (activeTab === "evaluations") return 1;
    if (activeTab === "workout-history") return 2;
    if (activeTab === "evolution") return 3;
    if (activeTab === "payments") return 4;
    if (activeTab === "crm") return 5;
    else return 0;
  };
  const [tabsData, setTabsData] = useState<TabsI>({
    tabs: tabs,
    activeIndex: clientTab(),
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useUnmount(() => {
    dispatch(resetInsuranceState());
  });

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(setCurrentClient(userId));
    setIsLoading(false);
  }, [dispatch, userId]);

  const onTabClick = (activeIdx: number) => {
    setTabsData({
      ...tabsData,
      activeIndex: activeIdx,
    });
    let path = "";
    if (activeIdx === 0) path = "personal-data";
    if (activeIdx === 1) path = "evaluations";
    if (activeIdx === 2) path = "workout-history";
    if (activeIdx === 3) path = "evolution";
    if (activeIdx === 4) path = "payments";
    if (activeIdx === 5) path = "crm";
    navigate(path);
  };

  return (
    <Container fluid id="add-customer-page">
      {isLoading || isUserLoading ? (
        <div className="spinner-wrapper">
          <SpinnerWrapper />
        </div>
      ) : (
        <>
          <DashboardTitle
            title={user?.name !== undefined ? `${user?.name!}` : ""}
            showAddButton={false}
            navigateToLink="/dashboard/customers"
          />
          <Tabs
            tabs={tabsData.tabs}
            activeIndex={tabsData.activeIndex}
            onTabClick={onTabClick}
          />
          <Container fluid id="personal-data-section">
            <Row className="account-section-row">
              <Col>
                <Outlet />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Container>
  );
};
