import { takeLatest } from "redux-saga/effects";
import {
  fetchPersonalTrainers,
  fetchPersonalTrainer,
  createPersonalTrainer,
  updatePersonalTrainer,
  deletePersonalTrainer,
} from "../../slices/personalTrainerSlice";
import {
  fetchPersonalTrainersSaga,
  fetchPersonalTrainerSaga,
  createPersonalTrainerSaga,
  updatePersonalTrainerSaga,
  deletePersonalTrainerSaga,
} from "./personapTrainerSaga";

function* personalTrainerSaga() {
  yield takeLatest(fetchPersonalTrainers, fetchPersonalTrainersSaga);
  yield takeLatest(fetchPersonalTrainer, fetchPersonalTrainerSaga);
  yield takeLatest(createPersonalTrainer, createPersonalTrainerSaga);
  yield takeLatest(updatePersonalTrainer, updatePersonalTrainerSaga);
  yield takeLatest(deletePersonalTrainer, deletePersonalTrainerSaga);
}

export default personalTrainerSaga;
