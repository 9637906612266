import React from "react";
import { Form } from "react-bootstrap";
import { TextAreaForm } from "../TextAreaForm/TextAreaForm";

type CheckWithAnswerFormProps = {
  label: string;
  checked: boolean;
  answer: string | undefined | null;
  answerPlaceHolder: string;
  disabled: boolean;
  onCheckedChange?: any;
  onAnswerChange?: any;
};

export const CheckWithAnswerForm = ({
  checked,
  label,
  answer,
  disabled,
  onCheckedChange,
  onAnswerChange,
  answerPlaceHolder,
}: CheckWithAnswerFormProps) => {
  return (
    <>
      <Form.Check // prettier-ignore
        disabled={disabled}
        checked={checked}
        type="switch"
        label={label}
        className="purple-switch"
        onChange={onCheckedChange}
      />
      <div style={{ marginTop: 10 }} />
      {checked ? (
        <TextAreaForm
          disabled={disabled}
          value={answer ?? undefined}
          onChange={onAnswerChange}
          label={answerPlaceHolder}
        />
      ) : null}
    </>
  );
};
