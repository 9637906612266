import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { SignInModal } from "components/SignInModal/SignInModal";

import "./TryForFree.scss";

export const TryForFree = () => {
  const { t } = useTranslation();
  const [showSignInModal, setSignInModal] = useState(false);

  return (
    <Container fluid id="try-for-free">
      <Row>
        <Col>
          <Container className="pricing-container">
            <Row>
              <Col className="pricing-no-vat-col">
                <h2 style={{ fontWeight: 700 }}>
                  {t("landingPage.tryForFree.tryNow")}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col className="pricing-try-now-btn">
                <Button
                  className="welcome-demo-btn"
                  onClick={() => setSignInModal(true)}
                >
                  {t("landingPage.welcomeComponent.joinWaitlistModal.tryNow")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      {showSignInModal ? (
        <SignInModal
          showSignInModal={showSignInModal}
          setShowSignInModal={setSignInModal}
        />
      ) : undefined}
    </Container>
  );
};
