import { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/logo.svg";
import {
  cleanErrorMessage,
  createAccount,
  selectConfirmationMessage,
  selectCurrentUser,
  selectErrorMessage,
  setConfirmationMessage,
} from "../../slices/currentUserSlice";

import { GButton } from "components/genericComponents/GButton/GButton";
import { useTranslation } from "react-i18next";
import "./SignInModal.css";
import { SelectForm } from "components/SelectForm/SelectForm";
import { fetchPlans, selectPlans } from "slices/companyPlanSlice";
import i18n from "i18n";

type SignInModalType = {
  showSignInModal: boolean;
  setShowSignInModal: (value: boolean) => void;
  discountCodeStr?: string;
  isMonthly?: boolean;
  nrOfMembers?: number;
};

export const SignInModal = (props: SignInModalType) => {
  const { t } = useTranslation();
  const showSignInModal = props.showSignInModal;
  const setShowSignInModal = props.setShowSignInModal;
  const dispatch = useDispatch();
  const plans = useSelector(selectPlans);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [nameError, setNameError] = useState<string | undefined>();
  const [name, setName] = useState<string>("");
  const [discountCode, setDiscountCode] = useState<string>(
    props.discountCodeStr ?? ""
  );
  const [timeFrame, setTimeFrame] = useState<"monthly" | "yearly">(
    props.isMonthly !== undefined
      ? props.isMonthly
        ? "monthly"
        : "yearly"
      : "monthly"
  );
  const timeFrames = [
    { id: "monthly", title: t("landingPage.pricing.monthly") },
    { id: "yearly", title: t("landingPage.pricing.yearly") },
  ];
  const [numberOfClients, setNumberOfClients] = useState<
    { id: string; title: string }[]
  >([]);
  const [selectedPlan, setSelectedPlan] = useState<{
    id: string;
    title: string;
  }>({ id: "", title: "" });
  const [isNew, setIsNew] = useState<boolean>(true);
  const [price, setPrice] = useState<string>("");
  const [checkCodeClicked, setCheckCodeClicked] = useState<boolean>(false);
  const currentUser = useSelector(selectCurrentUser);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [, setCookies] = useCookies(["current_user"]);

  const handleSetPrice = (priceIndex: number, timeframe: string) => {
    if (timeframe === "monthly") {
      setPrice(plans!.monthly.plans[priceIndex]!.cost);
    } else {
      setPrice(plans!.yearly.plans[priceIndex]!.cost);
    }
  };

  useEffect(() => {
    dispatch(fetchPlans({ code: discountCode, locale: i18n.language }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (plans && isNew) {
      handleSetPrice(props.nrOfMembers ?? 0, timeFrame);
      setNumberOfClients(
        plans[props.isMonthly ? "monthly" : "yearly"].plans.map((plan) => ({
          id: plan.id,
          title: plan.description,
        }))
      );
      setSelectedPlan({
        id: plans[props.isMonthly ? "monthly" : "yearly"].plans[
          props.nrOfMembers ?? 0
        ].id,
        title:
          plans[props.isMonthly ? "monthly" : "yearly"].plans[
            props.nrOfMembers ?? 0
          ].description,
      });
    } else if (plans) {
      handleSetPrice(numberOfClients.findIndex(
        (noc: { id: string; title: string }) => noc.id === selectedPlan.id
      ), timeFrame);
      setNumberOfClients(
        plans[props.isMonthly ? "monthly" : "yearly"].plans.map((plan) => ({
          id: plan.id,
          title: plan.description,
        }))
      );
      setSelectedPlan({
        id: plans[props.isMonthly ? "monthly" : "yearly"].plans[
          numberOfClients.findIndex(
            (noc: { id: string; title: string }) => noc.id === selectedPlan.id
          )
        ].id,
        title:
          plans[props.isMonthly ? "monthly" : "yearly"].plans[
            numberOfClients.findIndex(
              (noc: { id: string; title: string }) => noc.id === selectedPlan.id
            )
          ].description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  const onSubmitButtonClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    var isValid = true;
    if (!isEmailValid()) isValid = false;
    if (!isNameValid()) isValid = false;
    
    if (!isValid) return;

    dispatch(
      createAccount({
        name: name,
        email: email,
        plan_frequency: timeFrame,
        company_plan_id: selectedPlan.id,
        code: discountCode,
      })
    );
  };

  const isEmailValid = (): boolean => {
    if (email === "") {
      setEmailError(t("errors.emptyEmail"));
      return false;
    }

    const regex = new RegExp(
      // Turned \t into \\t to escape it (if something is off, try removing a \)
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \\t]|(\\[\\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\\t -Z^-~]*])"
    );

    if (!regex.test(email)) {
      setEmailError(t("errors.invalidEmail"));
      return false;
    }

    setEmailError(undefined);
    return true;
  };

  const isNameValid = (): boolean => {
    if (name === "") {
      setNameError(t("errors.emptyName"));
      return false;
    }

    setNameError(undefined);
    return true;
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "sign-in-modal-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(cleanErrorMessage());
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "sign-in-modal-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));

      setShowSignInModal(false);
    }
  }, [dispatch, confirmationMessage, setShowSignInModal]);

  useEffect(() => {
    if (currentUser !== undefined) {
      setCookies("current_user", {
        id: currentUser.id,
        email: currentUser.email,
        name: currentUser.name,
        image: currentUser.image_small,
      });
    }
  }, [currentUser, setCookies]);

  const handleCheckPrice = () => {
    if (discountCode !== undefined && discountCode !== "") {
      dispatch(fetchPlans({ code: discountCode, locale: i18n.language }));
      setIsNew(false);
    }
  };

  useEffect(() => {
    if (
      plans !== undefined &&
      discountCode !== undefined &&
      !plans.has_code &&
      checkCodeClicked
    ) {
      toast.error(t("landingPage.pricing.codeDoesNotExist"), {
        toastId: "course-schedule-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setCheckCodeClicked(false);
    } else if (
      plans !== undefined &&
      (discountCode !== undefined || discountCode !== "") &&
      plans.has_code &&
      checkCodeClicked
    ) {
      toast.success(t("landingPage.pricing.codeAppliedSuccessfully"), {
        toastId: "course-schedule-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setCheckCodeClicked(false);
      handleSetPrice(
        numberOfClients.findIndex(
          (noc: { id: string; title: string }) => noc.id === selectedPlan.id
        ),
        timeFrame
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, plans, checkCodeClicked]);

  return (
    <>
      {currentUser !== undefined ? (
        <Navigate to="/dashboard/home" replace={true} />
      ) : undefined}
      <Modal
        className="modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showSignInModal}
        onHide={() => setShowSignInModal(false)}
      >
        <Modal.Body>
          <Row className="modal-flex-center">
            <img className="logo" src={logo} alt="" />
          </Row>
          <p className="modal-title">
            {t("landingPage.header.signinModal.signinAsPT")}
          </p>
          <Form onSubmit={onSubmitButtonClick}>
            <FloatingLabel
              className="mb-3 form-floating-label"
              label={t("generic.name")}
            >
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={t("generic.name")}
                autoFocus
              />
              {nameError !== undefined ? (
                <p className="form-error-message">{nameError}</p>
              ) : undefined}
            </FloatingLabel>
            <FloatingLabel
              className="mb-3 form-floating-label"
              label={t("generic.email")}
            >
              <Form.Control
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder={t("generic.email")}
              />
              {emailError !== undefined ? (
                <p className="form-error-message">{emailError}</p>
              ) : undefined}
            </FloatingLabel>
            <SelectForm
              options={timeFrames.map(
                (timeFrame: { id: string; title: string }) => {
                  return {
                    id: timeFrame.id,
                    title: timeFrame.title,
                  };
                }
              )}
              className="time-frame-select"
              value={{
                id: timeFrames.find(
                  (tf: { id: string; title: string }) => tf.id === timeFrame
                )!.id,
                title: timeFrames.find(
                  (tf: { id: string; title: string }) => tf.id === timeFrame
                )!.title,
              }}
              label="Timeframe"
              onChange={(e: any) => {
                setTimeFrame(
                  timeFrames.find(
                    (tf: { id: string; title: string }) => tf.id === e.value
                  )!.id as "monthly" | "yearly"
                );
                handleSetPrice(
                  numberOfClients.findIndex(
                    (noc: { id: string; title: string }) =>
                      noc.id === selectedPlan.id
                  ),
                  timeFrames.find(
                    (tf: { id: string; title: string }) => tf.id === e.value
                  )!.id as "monthly" | "yearly"
                );
                setNumberOfClients(
                  plans![
                    e.value === "monthly" ? "monthly" : "yearly"
                  ].plans.map((plan) => ({
                    id: plan.id,
                    title: plan.description,
                  }))
                );
                setSelectedPlan({
                  id: plans![e.value === "monthly" ? "monthly" : "yearly"]
                    .plans[0]!.id,
                  title:
                    plans![e.value === "monthly" ? "monthly" : "yearly"]
                      .plans[0]!.description,
                });
              }}
            />
            <SelectForm
              options={numberOfClients}
              className="time-frame-select"
              value={selectedPlan}
              label="Numero clientes"
              onChange={(e: any) => {
                setSelectedPlan({
                  id: numberOfClients.find(
                    (noc: { id: string; title: string }) => noc.id === e.value
                  )!.id,
                  title: numberOfClients.find(
                    (noc: { id: string; title: string }) => noc.id === e.value
                  )!.title,
                });
                handleSetPrice(
                  numberOfClients.findIndex(
                    (noc: { id: string; title: string }) => noc.id === e.value
                  ),
                  timeFrame
                );
              }}
            />
            <FloatingLabel
              className="mb-3 form-floating-label"
              label={t("landingPage.pricing.discountCode")}
            >
              <Form.Control
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
                type="text"
                placeholder={t("landingPage.pricing.discountCode")}
              />
            </FloatingLabel>
            <GButton
              label={t("landingPage.pricing.checkCode")}
              onClick={handleCheckPrice}
            />
            <div className="sign-in-modal-total-div">
              <p className="sign-in-modal-total-div-title">Total</p>
              <p className="sign-in-modal-total-div-value">
                {timeFrame === "monthly"
                  ? price === plans?.monthly.plans[0].cost
                    ? "Grátis"
                    : price
                  : `${Number.parseFloat(
                      (Number.parseFloat(price.split("€")[0]) * 12).toFixed(2)
                    )}€`}
              </p>
            </div>
            <Row className="modal-flex-center padding">
              <GButton
                label={t("generic.submit")}
                type="submit"
                onClick={onSubmitButtonClick}
              />
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
