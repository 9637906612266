import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { DashboardTitle } from "components/DashboardTitle/DashboardTitle";
import { Tabs, TabsI } from "components/Tabs/Tabs";

import "./SettingsPage.css";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "slices/currentUserSlice";
import { Outlet, useNavigate } from "react-router-dom";

export const SettingsPage = () => {
  const company = useSelector(selectCurrentUser)?.company;
  const navigate = useNavigate();

  const initialState = () => {
    if (localStorage.getItem("isGymView") === "true") {
      return [
        "Conta",
        "Exercícios",
        "Ginásio",
        "Aulas",
        "Pagamentos",
        "Aplicação Móvel",
      ];
    } else {
      if (company?.is_gym) {
        return ["Conta", "Exercícios"];
      } else {
        return ["Conta", "Exercícios", "Pagamentos", "Aplicação Móvel"];
      }
    }
  };
  const [tabsData, setTabsData] = useState<TabsI>({
    tabs: initialState(),
    activeIndex: 0,
  });

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    const currentTab = pathSegments[pathSegments.length - 1];
    let tabMap = {};
    if (localStorage.getItem("isGymView") === "true") {
      tabMap = {
        account: 0,
        exercises: 1,
        gym: 2,
        classes: 3,
        payments: 4,
        mobile: 5,
      };
    } else {
      if (company?.is_gym) {
        tabMap = {
          account: 0,
          exercises: 1,
        };
      } else {
        tabMap = {
          account: 0,
          exercises: 1,
          payments: 2,
          mobile: 3,
        };
      }
    }
    const initialTab = tabMap[currentTab as keyof typeof tabMap] ?? 0;
    setTabsData({
      ...tabsData,
      activeIndex: initialTab,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabClick = (activeIdx: number) => {
    setTabsData({
      ...tabsData,
      activeIndex: activeIdx,
    });

    if (localStorage.getItem("isGymView") === "true") {
      let path = "";
      if (activeIdx === 0) path = "account";
      if (activeIdx === 1) path = "exercises";
      if (activeIdx === 2) path = "gym";
      if (activeIdx === 3) path = "classes";
      if (activeIdx === 4) path = "payments";
      if (activeIdx === 5) path = "mobile";
      navigate(path);
    } else {
      if (company?.is_gym) {
        let path = "";
        if (activeIdx === 0) path = "account";
        if (activeIdx === 1) path = "exercises";
        navigate(path);
      } else {
        let path = "";
        if (activeIdx === 0) path = "account";
        if (activeIdx === 1) path = "exercises";
        if (activeIdx === 2) path = "payments";
        if (activeIdx === 3) path = "mobile";
        navigate(path);
        return ["Conta", "Exercícios", "Pagamentos", "Aplicação Móvel"];
      }
    }
  };

  return (
    <Container fluid id="settings-page">
      <DashboardTitle
        title="Definições"
        showAddButton={false}
        doNotShowBackButton
        infoLink="mbGYXEO2zTI"
      />
      <Tabs
        tabs={tabsData.tabs}
        activeIndex={tabsData.activeIndex}
        onTabClick={onTabClick}
      />
      <Container fluid id="personal-data-section">
        <Row className="account-section-row">
          <Col className="tabs-column">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
