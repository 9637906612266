import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BsFillXCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import { GModal } from "components/genericComponents/GModal/GModal";
import { WorkoutExerciseCard } from "components/DashboardWorkoutsPage/WorkoutMode/WorkoutExerciseCard/WorkoutExerciseCard";
import { fetchWorkout, getWorkoutById } from "slices/workoutSlice";
import { WorkoutModeType } from "types/workout";

import "components/DashboardWorkoutsPage/WorkoutMode/WorkoutMode.scss";
import {
  createWorkoutHistory,
  selectErrorMessage,
  selectConfirmationMessage,
  setErrorMessage,
  setConfirmationMessage,
} from "slices/workoutHistorySlice";
import { UserWorkoutExerciseHistory } from "types/user_workout_exercise_history";
import { UserWorkoutExerciseHistorySet } from "types/user_workout_exercise_history_set";
import { UserWorkoutHistory } from "types/user_workout_history";

type WorkoutModeProps = {
  userId: string;
  workoutId: string;
  setUserForWorkoutMode: Dispatch<
    SetStateAction<{ userId: string; workoutId: string } | undefined>
  >;
};

export const WorkoutMode = ({
  userId,
  workoutId,
  setUserForWorkoutMode,
}: WorkoutModeProps) => {
  const dispatch = useDispatch();
  const workout = useSelector(getWorkoutById(workoutId));
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false);
  const [showFinishEmptyModal, setShowFinishEmptyModal] =
    useState<boolean>(false);
  const [workoutToSubmit, setWorkoutToSubmit] = useState<
    WorkoutModeType | undefined
  >(undefined);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const errorMessage = useSelector(selectErrorMessage);

  useEffect(() => {
    dispatch(fetchWorkout(workoutId));
  }, [dispatch, workoutId]);

  useEffect(() => {
    if (workout !== undefined) {
      setWorkoutToSubmit({
        ...workout!,
        exercises: workout!.exercises?.map((exercise) => {
          return {
            ...exercise,
            sets: exercise.sets.map((set) => {
              return {
                ...set,
                isChecked: false,
              };
            }),
            isChecked: false,
          };
        }),
      });
    }
  }, [workout]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "workout-mode-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "workout-mode-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));

      setUserForWorkoutMode(undefined);
    }
  }, [dispatch, confirmationMessage, setUserForWorkoutMode]);

  const onCancelClick = () => {
    setShowModal(true);
  };

  const handleConfirmCancel = () => {
    setShowModal(false);
    setShowFinishEmptyModal(false);
    setUserForWorkoutMode(undefined);
  };

  const handleConfirmFinished = () => {
    const workoutHistoryToSave: UserWorkoutHistory = {
      ...workoutToSubmit,
      user_id: userId,
      title: workoutToSubmit!.title!,
      description: workoutToSubmit!.description!,
      duration_in_seconds: 0,
      muscules_groups: workoutToSubmit!.muscules_groups,
      exercises: workoutToSubmit!.exercises.reduce(
        (prev: UserWorkoutExerciseHistory[], curr) => {
          let nrOfChecks = 0;
          const s = curr.sets.reduce(
            (cPrev: UserWorkoutExerciseHistorySet[], cCurr) => {
              if (cCurr.isChecked) {
                const { isChecked, ...setData } = cCurr;
                cPrev.push({
                  variable_values: setData.variables.map((variable) => {
                    return {
                      id: variable.id,
                      title: variable.variable_title,
                      value: variable.value,
                    };
                  }),
                });
                nrOfChecks += 1;
              }
              return cPrev;
            },
            []
          );
          if (nrOfChecks !== 0) {
            const { isChecked, ...exerciseData } = curr;
            prev.push({
              ...exerciseData,
              title: exerciseData.title!,
              description: exerciseData.description!,
              created_at: exerciseData.created_at!,
              updated_at: exerciseData.updated_at!,
              notes: exerciseData.notes!,
              workout_history_id: uuidv4(),
              sets: s,
            });
          }
          return prev;
        },
        []
      ),
    };
    dispatch(
      createWorkoutHistory({
        userId: userId,
        workout_id: workoutId,
        workoutHistory: workoutHistoryToSave,
      })
    );
  };

  const handleFinishWorkout = () => {
    let hasCheckedItems = false;
    workoutToSubmit!.exercises.forEach((exercise) => {
      exercise.sets.forEach((set) => {
        if (set.isChecked) hasCheckedItems = true;
      });
    });
    if (!hasCheckedItems) setShowFinishEmptyModal(true);
    else setShowFinishModal(true);
  };

  return (
    <GSection
      label={workout?.title}
      isSpacedBetween
      className="workout-mode"
      renderButtons={[
        <GButton
          key={userId}
          icon={<BsFillXCircleFill />}
          onClick={onCancelClick}
        />,
      ]}
    >
      {workoutToSubmit?.exercises?.map((exercise, index) => (
        <WorkoutExerciseCard
          key={exercise.id! + index}
          exercise={exercise}
          index={index}
          workoutToSubmit={workoutToSubmit}
          setWorkoutToSubmit={setWorkoutToSubmit}
        />
      ))}
      <div className="finished-button-div">
        <GButton
          className="button"
          label="Concluir"
          onClick={handleFinishWorkout}
        />
      </div>
      <GModal
        showModalState={showModal}
        setShowModalState={setShowModal}
        description="Tem a certeza que quer cancelar o treino?"
        buttons={[
          <GButton
            key="confirm"
            label="Continuar Treino"
            onClick={() => setShowModal(false)}
          />,
          <GButton
            key="cancel"
            label="Cancelar Treino"
            onClick={handleConfirmCancel}
            variant="secondary"
          />,
        ]}
      />
      <GModal
        showModalState={showFinishModal}
        setShowModalState={setShowFinishModal}
        description="Tem a certeza que quer concluir o treino?"
        buttons={[
          <GButton
            key="confirm"
            label="Concluir Treino"
            onClick={handleConfirmFinished}
          />,
          <GButton
            key="cancel"
            label="Continuar Treino"
            onClick={() => setShowFinishModal(false)}
            variant="secondary"
          />,
        ]}
      />
      <GModal
        showModalState={showFinishEmptyModal}
        setShowModalState={setShowFinishEmptyModal}
        description="Tem a certeza que quer submeter o treino? Não selecionou nenhum exercício"
        buttons={[
          <GButton
            key="confirm"
            label="Continuar Treino"
            onClick={() => setShowFinishEmptyModal(false)}
          />,
          <GButton
            key="cancel"
            label="Concluir Treino"
            onClick={handleConfirmFinished}
            variant="secondary"
          />,
        ]}
      />
    </GSection>
  );
};
