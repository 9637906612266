import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchNotificationsSucceeded,
  deleteNotificationSucceeded,
} from "../../slices/notificationSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchNotificationsRequest,
  deleteNotificationRequest,
  NotificationRequestResponseType,
} from "../../requests/notificationRequests";

export function* fetchNotificationsSaga(
  action: any,
  request = fetchNotificationsRequest
) {
  const response: NotificationRequestResponseType = yield call(request);

  if (response.notifications !== undefined) {
    yield put(fetchNotificationsSucceeded(response.notifications!));
  }
}

export function* deleteNotificationSaga(
  action: PayloadAction<string>,
  request = deleteNotificationRequest
) {
  const response: NotificationRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteNotificationSucceeded(action.payload));
  }
}
