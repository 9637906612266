import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import { differenceInDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "slices/currentUserSlice";
import {
  createPaymentToGoactive,
  selectPaymentUrl,
} from "slices/paymentsSlice";

import "components/SettingsPage/PaymentsSettings/PaySubscription/PaySubscription.scss";
import { UpdateAccountModal } from "components/shared/UpdateAccountModal/UpdateAccountModal";

export const PaySubscription = () => {
  const user = useSelector(selectCurrentUser);
  const [nextPaymentDate, setNextPaymentDate] = useState<string | null>(null);
  const [isNextPaymentDateIn10DaysOrLess, setIsNextPaymentDateIn10DaysOrLess] =
    useState<boolean>(false);
  const [nextPaymentValue, setNextPaymentValue] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const paymentUrl = useSelector(selectPaymentUrl);

  useEffect(() => {
    if (user) {
      setNextPaymentDate(
        format(new Date(user.company?.next_plan_payment_date!), "dd/MM/yyyy")
      );
      const diff = differenceInDays(
        new Date(user.company?.next_plan_payment_date!),
        new Date()
      );
      setIsNextPaymentDateIn10DaysOrLess(diff <= 10);

      if (user.company?.plan_frequency === "monthly")
        setNextPaymentValue(
          `${user.company?.plan_price.toFixed(2).toString()}€`
        );
      else
        setNextPaymentValue(
          `${(user.company?.plan_price ?? 0 * 12).toFixed(2).toString()}€`
        );
    }
  }, [user]);

  useEffect(() => {
    if (user && user.company!.next_plan_payment_date)
      dispatch(createPaymentToGoactive());
  }, [dispatch, user]);

  return (
    <GSection label="Subscrição">
      <div className="pay-subscription-container">
        {user && user.company?.plan.title !== "Até 2 clientes" ? (
          <>
            <p className="pay-subscription-label">Plano atual</p>
            <h3 className="pay-subscription-date">
              {user?.company?.plan.title}
            </h3>
            <p className="pay-subscription-label">Data do próximo pagamento</p>
            <h3 className="pay-subscription-date">{nextPaymentDate}</h3>
            <p className="pay-subscription-label">Valor do pagamento</p>
            <h3 className="pay-subscription-date">{nextPaymentValue}</h3>
            <div className="pay-subscription-buttons-container">
              {isNextPaymentDateIn10DaysOrLess && (
                <GButton
                  label="Pagar"
                  onClick={() => window.open(paymentUrl, "_blank")}
                />
              )}
              <GButton
                label="Atualizar plano"
                onClick={() => setShowModal(true)}
              />
            </div>
          </>
        ) : (
          <>
            <p className="pay-subscription-label">Plano atual</p>
            <h3 className="pay-subscription-date">
              {user?.company?.plan.title}
            </h3>
            <GButton
              className="sidebar-users-upgrade-plan-button"
              label="Atualizar plano"
              onClick={() => setShowModal(true)}
            />
          </>
        )}
      </div>
      <UpdateAccountModal showModal={showModal} setShowModal={setShowModal} />
    </GSection>
  );
};
