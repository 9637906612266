import { User } from "../types/user";
import { axiosRequest, errorMessage } from "./request";
import {
  CreatePersonalTrainerType,
  UpdatePersonalTrainerType,
} from "../slices/personalTrainerSlice";

export type PersonalTrainerRequestResponseType = {
  succeeded: boolean;
  personalTrainers: Array<User> | undefined;
  personalTrainer: User | undefined;
  errorMessage: string | undefined;
};

export const fetchPersonalTrainersRequest =
  async (): Promise<PersonalTrainerRequestResponseType> => {
    try {
      const response = await axiosRequest().get("users?role=personal_trainer");
      const personalTrainers: Array<User> = response.data.map(
        (user: User) => user
      );

      return {
        succeeded: true,
        personalTrainers: personalTrainers,
      } as PersonalTrainerRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as PersonalTrainerRequestResponseType;
    }
  };

export const fetchPersonalTrainerRequest = async (
  userId: string
): Promise<PersonalTrainerRequestResponseType> => {
  try {
    const response = await axiosRequest().get(`users/${userId}`);
    const personalTrainer: User = response.data;

    return {
      succeeded: true,
      personalTrainer: personalTrainer,
    } as PersonalTrainerRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PersonalTrainerRequestResponseType;
  }
};

export const createPersonalTrainerRequest = async (
  params: CreatePersonalTrainerType
): Promise<PersonalTrainerRequestResponseType> => {
  try {
    const response = await axiosRequest().post("users", {
      user: { ...params, role: "personal_trainer" },
    });
    const personalTrainer: User = response.data;

    return {
      succeeded: true,
      personalTrainer: personalTrainer,
    } as PersonalTrainerRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PersonalTrainerRequestResponseType;
  }
};

export const updatePersonalTrainerRequest = async (
  params: UpdatePersonalTrainerType
): Promise<PersonalTrainerRequestResponseType> => {
  try {
    const response = await axiosRequest().put(`users/${params.id}`, {
      user: params,
    });
    const personalTrainer: User = response.data;

    return {
      succeeded: true,
      personalTrainer: personalTrainer,
    } as PersonalTrainerRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PersonalTrainerRequestResponseType;
  }
};

export const deletePersonalTrainerRequest = async (
  userId: string
): Promise<PersonalTrainerRequestResponseType> => {
  try {
    await axiosRequest().delete(`users/${userId}`);

    return { succeeded: true } as PersonalTrainerRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PersonalTrainerRequestResponseType;
  }
};
