import logo from "../../assets/logo.svg";
import "components/AccountBlockedPage/AccountBlockedPage.scss";

export const AccountBlockedPage = () => {
  return (
    <div id="account-blocked-page">
      <div className="account-blocked-page-content">
        <div className="account-blocked-page-content-text">
          <h3>A sua conta está bloqueada</h3>
          <p>
            Para reativar a sua conta, entre em contacto com a equipa de
            suporte.
          </p>
          <a href="mailto:support@goactivedash.com">support@goactivedash.com</a>
        </div>
        <div className="account-blocked-page-content-img">
          <img className="gym-img" src={logo} alt="" />
        </div>
      </div>
    </div>
  );
};
