import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchGymRoomsSucceeded,
  setGymRoomsSucceeded,
  SetGymRoomsType,
  setErrorMessage,
} from "../../slices/gymRoomSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchGymRoomsRequest,
  setGymRoomsRequest,
  GymRoomRequestResponseType,
} from "../../requests/gymRoomRequests";

export function* fetchGymRoomsSaga(
  action: any,
  request = fetchGymRoomsRequest
) {
  const response: GymRoomRequestResponseType = yield call(request);

  if (response.gymRooms !== undefined) {
    yield put(fetchGymRoomsSucceeded(response.gymRooms));
  }
}

export function* setGymRoomsSaga(
  action: PayloadAction<Array<SetGymRoomsType>>,
  request = setGymRoomsRequest
) {
  const response: GymRoomRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(setGymRoomsSucceeded(response.gymRooms!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
