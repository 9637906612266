import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsPencilSquare, BsFillXCircleFill } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  EvaluationsType,
  createEvaluation,
  updateEvaluation,
  setErrorMessage,
  setConfirmationMessage,
  selectErrorMessage,
  selectConfirmationMessage,
  deleteEvaluation,
} from "slices/evaluationsSlice";
import { GButton } from "components/genericComponents/GButton/GButton";
import { CheckWithAnswerForm } from "components/CheckWithAnswerForm/CheckWithAnswerForm";
import { TextAreaForm } from "components/TextAreaForm/TextAreaForm";
import { InputForm } from "components/InputForm/InputForm";
import { MultiImageUpload } from "components/shared/MultiImageUpload/MultiImageUpload";

type EvaluationType = {
  evaluation: EvaluationsType;
  isCreating: boolean;
  userId: string;
  setIsAddingEvaluation: (param: boolean) => void;
};

export const Evaluation = ({
  evaluation,
  isCreating,
  userId,
  setIsAddingEvaluation,
}: EvaluationType) => {
  const [isOpen, setIsOpen] = useState<boolean>(isCreating);
  const [isEditing, setIsEditing] = useState<boolean>(isCreating);
  const [formData, setFormData] = useState<EvaluationsType>(evaluation);
  const [files, setFiles] = useState<File[]>([])
  const [fileKeys, setFileKeys] = useState<string[]>([])
  const [existingFiles, setExistingFiles] = useState(evaluation.documents);
  const dispatch = useDispatch();
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  useEffect(() => {
    setFormData(evaluation);
    setFiles([])
    setFileKeys([])
    setExistingFiles(evaluation.documents)
  }, [evaluation]);

  const onSubmitButtonClick = () => {
    const filesToSend: Array<any> = []
    
    files.forEach((file: any, index: number) => {
      filesToSend.push({file_data: { id: fileKeys[index], storage: "cache", metadata: {size: file.size, mime_type: file.type}}})
    })

    existingFiles.forEach((file: any, index: number) => {
      filesToSend.push({id: file.id})
    })

    if (isCreating) {
      dispatch(createEvaluation({ ...formData, user_id: userId, id: null, documents_attributes: filesToSend}))
    } else {
      dispatch(
        updateEvaluation({ ...formData, user_id: userId, id: evaluation.id!, documents_attributes: filesToSend})
      );
    }
  };

  const onDeleteClick = () => {
    dispatch(deleteEvaluation({ user_id: userId, id: evaluation.id! }));
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "evaluation-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "evaluation-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));

      if (isCreating) {
        setIsAddingEvaluation(false);
      } else {
        setIsOpen(false);
        setIsEditing(false);
      }
    }
  }, [dispatch, confirmationMessage, isCreating, setIsAddingEvaluation]);

  return (
    <Container fluid id="evaluation-card" key={formData?.id}>
      <Row className="evaluation-title-row">
        <Col className="evaluation-title-col">
          <h4 className="evaluation-title">
            {evaluation.created_at?.toString().split("T")[0]}
          </h4>
        </Col>
        <Col className="evaluation-title-col right">
          <Row>
            <Col>
              {!isCreating && !isEditing && isOpen && (
                <Button
                  className="personal-data-edit-button"
                  onClick={() => setIsEditing(true)}
                >
                  <BsPencilSquare />
                </Button>
              )}
              {!isCreating && isEditing && isOpen && (
                <Button
                  className="personal-data-edit-button-close"
                  onClick={() => setIsEditing(false)}
                >
                  <BsFillXCircleFill />
                </Button>
              )}
            </Col>
            <Col>
              {!isCreating && isOpen && (
                <Button
                  className="exercises-container-button-close"
                  onClick={onDeleteClick}
                >
                  <MdDeleteForever />
                </Button>
              )}
            </Col>
            <Col>
              {isOpen ? (
                <BsFillCaretUpFill
                  onClick={() => setIsOpen(false)}
                  className="caret-icon"
                />
              ) : (
                <BsFillCaretDownFill
                  onClick={() => setIsOpen(true)}
                  className="caret-icon"
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {isOpen && (
        <div>
          <Row>
            <p className="section-title">Anamnese</p>
          </Row>
          <Row className="switch-options-row">
            <Col>
              <CheckWithAnswerForm
                disabled={!isEditing}
                checked={formData.practiced_sports}
                label="Pratica ou já praticou atividade física regular?"
                answerPlaceHolder="Outras observações..."
                onCheckedChange={() =>
                  setFormData({
                    ...formData,
                    practiced_sports: !formData.practiced_sports,
                    practiced_sports_answer: null,
                  })
                }
                answer={formData.practiced_sports_answer}
                onAnswerChange={(e: any) =>
                  setFormData({
                    ...formData,
                    practiced_sports_answer: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="switch-options-row">
            <Col>
              <CheckWithAnswerForm
                disabled={!isEditing}
                checked={formData.has_cardiac_diseases}
                label="Alguma vez o seu médico disse que você possui algum problema cardíaco e recomendou que você só praticasse atividade física sob prescrição médica?"
                answerPlaceHolder="Insira alguma nota..."
                onCheckedChange={() =>
                  setFormData({
                    ...formData,
                    has_cardiac_diseases: !formData.has_cardiac_diseases,
                    cardiac_diseases: null,
                  })
                }
                answer={formData.cardiac_diseases}
                onAnswerChange={(e: any) =>
                  setFormData({
                    ...formData,
                    cardiac_diseases: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="switch-options-row">
            <Col>
              <CheckWithAnswerForm
                disabled={!isEditing}
                checked={formData.has_chest_pain_while_doing_sports}
                label="Sente dor no peito quando pratica atividade física?"
                answerPlaceHolder="Insira alguma nota..."
                onCheckedChange={() =>
                  setFormData({
                    ...formData,
                    has_chest_pain_while_doing_sports:
                      !formData.has_chest_pain_while_doing_sports,
                    chest_pain_while_doing_sports: null,
                  })
                }
                answer={formData.chest_pain_while_doing_sports}
                onAnswerChange={(e: any) =>
                  setFormData({
                    ...formData,
                    chest_pain_while_doing_sports: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="switch-options-row">
            <Col>
              <CheckWithAnswerForm
                disabled={!isEditing}
                checked={formData.has_chest_pain_while_not_doing_sports}
                label="Durante o último mês sentiu dores no peito quando não estava a praticar atividade física?"
                answerPlaceHolder="Insira alguma nota..."
                onCheckedChange={() =>
                  setFormData({
                    ...formData,
                    has_chest_pain_while_not_doing_sports:
                      !formData.has_chest_pain_while_not_doing_sports,
                    chest_pain_while_not_doing_sports: null,
                  })
                }
                answer={formData.chest_pain_while_not_doing_sports}
                onAnswerChange={(e: any) =>
                  setFormData({
                    ...formData,
                    chest_pain_while_not_doing_sports: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="switch-options-row">
            <Col>
              <CheckWithAnswerForm
                disabled={!isEditing}
                checked={formData.has_balance_loss}
                label="Sentiu perdas de equilíbrio em virtude de tonturas ou alguma vez perdeu a consciência?"
                answerPlaceHolder="Insira alguma nota..."
                onCheckedChange={() =>
                  setFormData({
                    ...formData,
                    has_balance_loss: !formData.has_balance_loss,
                    balance_loss: null,
                  })
                }
                answer={formData.balance_loss}
                onAnswerChange={(e: any) =>
                  setFormData({
                    ...formData,
                    balance_loss: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="switch-options-row">
            <Col>
              <CheckWithAnswerForm
                disabled={!isEditing}
                checked={formData.has_bones_issues}
                label="Tem algum problema ósseo ou articular (i.e., costas, joelho, ombro) que possa ser agravado com uma mudança na sua atividade física?"
                answerPlaceHolder="Insira alguma nota..."
                onCheckedChange={() =>
                  setFormData({
                    ...formData,
                    has_bones_issues: !formData.has_bones_issues,
                    bones_issues: null,
                  })
                }
                answer={formData.bones_issues}
                onAnswerChange={(e: any) =>
                  setFormData({
                    ...formData,
                    bones_issues: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="switch-options-row">
            <Col>
              <CheckWithAnswerForm
                disabled={!isEditing}
                checked={formData.has_medication_for_blood_pressure}
                label="Está atualmente a ser medicado pelo seu médico para a pressão arterial ou problemas cardíacos?"
                answerPlaceHolder="Insira alguma nota..."
                onCheckedChange={() =>
                  setFormData({
                    ...formData,
                    has_medication_for_blood_pressure:
                      !formData.has_medication_for_blood_pressure,
                    medication_for_blood_pressure: null,
                  })
                }
                answer={formData.medication_for_blood_pressure}
                onAnswerChange={(e: any) =>
                  setFormData({
                    ...formData,
                    medication_for_blood_pressure: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="switch-options-row">
            <Col>
              <CheckWithAnswerForm
                disabled={!isEditing}
                checked={formData.has_limitations_for_sports}
                label="Tem conhecimento de alguma outra razão que lhe limite a prática de atividade física?"
                answerPlaceHolder="Insira alguma nota..."
                onCheckedChange={() =>
                  setFormData({
                    ...formData,
                    has_limitations_for_sports:
                      !formData.has_limitations_for_sports,
                    limitations_for_sports: null,
                  })
                }
                answer={formData.limitations_for_sports}
                onAnswerChange={(e: any) =>
                  setFormData({
                    ...formData,
                    limitations_for_sports: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="switch-options-row">
            <Col>
              <CheckWithAnswerForm
                disabled={!isEditing}
                checked={formData.is_smoker}
                label="Tem hábitos tabagísticos?"
                answerPlaceHolder="Insira os hábitos tabagísticos..."
                onCheckedChange={() =>
                  setFormData({
                    ...formData,
                    is_smoker: !formData.is_smoker,
                    smoker_info: null,
                  })
                }
                answer={formData.smoker_info}
                onAnswerChange={(e: any) =>
                  setFormData({
                    ...formData,
                    smoker_info: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="switch-options-row">
            <Col>
              <CheckWithAnswerForm
                disabled={!isEditing}
                checked={formData.has_surgical_interventions}
                label="Sofreu intervenções cirúrgicas?"
                answerPlaceHolder="Insira as intervenções cirúrgicas..."
                onCheckedChange={() =>
                  setFormData({
                    ...formData,
                    has_surgical_interventions:
                      !formData.has_surgical_interventions,
                    surgical_interventions: null,
                  })
                }
                answer={formData.surgical_interventions}
                onAnswerChange={(e: any) =>
                  setFormData({
                    ...formData,
                    surgical_interventions: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <p className="section-title">Elementos Cardiovasculares</p>
          </Row>
          <Row>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.resting_heart_beats}
                label="Frequência Cardíaca em Descanso (bpm)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    resting_heart_beats: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={true}
                value={formData?.max_heart_freq}
                label="Frequência Cardíaca Máxima"
                type="number"
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.blood_pressure_sistolic}
                label="Pressão Arterial Sistólica em repouso (mmHg)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    blood_pressure_sistolic: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.blood_pressure_diastolic}
                label="Pressão Arterial Diastólica em repouso (mmHg)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    blood_pressure_diastolic: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.blood_pressure_sistolic_on_effort}
                label="Pressão Arterial Sistólica em esforço (mmHg)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    blood_pressure_sistolic_on_effort: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.blood_pressure_diastolic_on_effort}
                label="Pressão Arterial Diastólica em esforço (mmHg)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    blood_pressure_diastolic_on_effort: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.spvo2}
                label="SPVO2"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    spvo2: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <p className="section-title">Bioimpedância</p>
          </Row>
          <Row>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.height_in_cm}
                label="Altura (cm)"
                type="number"
                onChange={(e: any) => {
                  setFormData({
                    ...formData,
                    height_in_cm: e.target.value,
                    imc: formData.weight_in_kg
                      ? Number.parseFloat(
                          (
                            formData.weight_in_kg /
                            Math.pow(e.target.value / 100, 2)
                          ).toFixed(1)
                        )
                      : null,
                  });
                }}
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.weight_in_kg}
                label="Peso (kg)"
                type="number"
                onChange={(e: any) => {
                  setFormData({
                    ...formData,
                    weight_in_kg: e.target.value,
                    imc: formData.height_in_cm
                      ? Number.parseFloat(
                          (
                            e.target.value /
                            Math.pow(formData?.height_in_cm! / 100, 2)
                          ).toFixed(1)
                        )
                      : null,
                  });
                }}
              />
            </Col>
            <Col lg={6}>
              <InputForm
                value={formData?.imc}
                label="IMC"
                type="number"
                disabled
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.fat_mass_percentage}
                label="% Massa Gorda"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    fat_mass_percentage: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.visceral_fat}
                label="Gordura Visceral"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    visceral_fat: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.h2o}
                label="Água (%)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    h2o: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.metabolic_age}
                label="Idade Metabólica"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    metabolic_age: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.bone_mineral_density}
                label="Densidade Mineral Óssea (kg)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    bone_mineral_density: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.muscular_mass}
                label="Massa Muscular (kg)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    muscular_mass: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.basal_metabolic_rate}
                label="Taxa Metabólica Basal (kcal)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    basal_metabolic_rate: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <p className="section-title">Perímetros</p>
          </Row>
          <Row>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.perimeter_bicep_left}
                label="Bícep Esquerdo (cm)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    perimeter_bicep_left: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.perimeter_bicep_right}
                label="Bícep Direito (cm)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    perimeter_bicep_right: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.perimeter_chest}
                label="Peito (cm)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    perimeter_chest: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.perimeter_upper_leg_left}
                label="Perna Esquerda (cm)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    perimeter_upper_leg_left: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.perimeter_upper_leg_right}
                label="Perna Direita (cm)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    perimeter_upper_leg_right: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.perimeter_waist}
                label="Cintura (cm)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    perimeter_waist: e.target.value,
                    hip_waist_relation:
                      formData.perimeter_hip !== null
                        ? Number.parseFloat(
                            (e.target.value / formData.perimeter_hip).toFixed(2)
                          )
                        : null,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.perimeter_hip}
                label="Anca (cm)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    perimeter_hip: e.target.value,
                    hip_waist_relation:
                      formData.perimeter_waist !== null
                        ? Number.parseFloat(
                            (formData.perimeter_waist / e.target.value).toFixed(
                              2
                            )
                          )
                        : null,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.perimeter_neck}
                label="Pescoço (cm)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    perimeter_neck: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.perimeter_abdominal}
                label="Abdominal (cm)"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    perimeter_abdominal: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                value={formData?.hip_waist_relation}
                label="Relação Cintura/Anca"
                type="number"
                disabled
              />
            </Col>
          </Row>
          <Row>
            <p className="section-title">Avaliação Física</p>
          </Row>
          <Row>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.strenght_superior_left_part}
                label="Força do membro superior esquerdo"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    strenght_superior_left_part: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.strenght_superior_right_part}
                label="Força do membro superior direito"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    strenght_superior_right_part: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.strenght_inferior_left_part}
                label="Força do membro inferior esquerdo"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    strenght_inferior_left_part: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.strenght_inferior_right_part}
                label="Força do membro inferior direito"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    strenght_inferior_right_part: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.resistance}
                label="Resistência"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    resistance: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.core_flexibility}
                label="Flexibilidade do tronco"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    core_flexibility: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.ankle_flexibility}
                label="Flexibilidade do tornozelo"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    ankle_flexibility: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.strength_power}
                label="Potência muscular"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    strength_power: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.left_balance}
                label="Equilíbrio esquerdo"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    left_balance: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={6}>
              <InputForm
                disabled={!isEditing}
                value={formData?.right_balance}
                label="Equilíbrio direito"
                type="number"
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    right_balance: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <p className="section-title">Fotografias</p>
          </Row>
          <Row>
            <MultiImageUpload
              isDisabled={!isEditing}
              existingFiles={isCreating ? [] : existingFiles}
              setExistingFiles={setExistingFiles}
              files={files}
              setFiles={setFiles}
              fileKeys={fileKeys}
              setFileKeys={setFileKeys}
            />
          </Row>
          <Row>
            <p className="section-title">Outras Informações</p>
          </Row>
          <Row>
            <Col>
              <TextAreaForm
                disabled={!isEditing}
                label="Notas"
                value={formData.notes}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    notes: e.target.value,
                  })
                }
                large={true}
              />
            </Col>
          </Row>
          {(isCreating || isEditing) && (
            <Row>
              <Col className="center-items">
                <GButton label="Submeter" onClick={onSubmitButtonClick} />
              </Col>
            </Row>
          )}
        </div>
      )}
    </Container>
  );
};
