import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MdAdd } from "react-icons/md";
import ReactGA from "react-ga4";

import {
  EvaluationsType,
  fetchEvaluations,
  selectEvaluations,
} from "../../../slices/evaluationsSlice";
import { selectCurrentClient } from "../../../slices/userSlice";
import { Evaluation } from "components/DashboardCustomersPage/Evaluations/Evaluation";

import "./Evaluations.css";

const initialEvaluationState: EvaluationsType = {
  id: null,
  user_id: null,
  created_at: null,
  imc: null,
  fat_mass_percentage: null,
  blood_pressure_sistolic: null,
  blood_pressure_diastolic: null,
  resting_heart_beats: null,
  has_surgical_interventions: false,
  surgical_interventions: null,
  is_smoker: false,
  smoker_info: null,
  image: null,
  weight_in_kg: null,
  height_in_cm: null,
  perimeter_bicep_left: null,
  perimeter_bicep_right: null,
  perimeter_chest: null,
  perimeter_upper_leg_left: null,
  perimeter_upper_leg_right: null,
  perimeter_waist: null,
  perimeter_neck: null,
  perimeter_hip: null,
  perimeter_abdominal: null,
  visceral_fat: null,
  h2o: null,
  metabolic_age: null,
  bone_mineral_density: null,
  muscular_mass: null,
  basal_metabolic_rate: null,
  max_heart_freq: null,
  hip_waist_relation: null,
  has_cardiac_diseases: false,
  has_chest_pain_while_doing_sports: false,
  has_chest_pain_while_not_doing_sports: false,
  has_balance_loss: false,
  has_bones_issues: false,
  has_medication_for_blood_pressure: false,
  has_limitations_for_sports: false,
  cardiac_diseases: null,
  chest_pain_while_doing_sports: null,
  chest_pain_while_not_doing_sports: null,
  balance_loss: null,
  bones_issues: null,
  medication_for_blood_pressure: null,
  limitations_for_sports: null,
  practiced_sports_answer: null,
  practiced_sports: false,
  notes: null,
  strenght_superior_left_part: null,
  strenght_superior_right_part: null,
  strenght_inferior_left_part: null,
  strenght_inferior_right_part: null,
  resistance: null,
  core_flexibility: null,
  ankle_flexibility: null,
  strength_power: null,
  left_balance: null,
  right_balance: null,
  blood_pressure_sistolic_on_effort: null,
  blood_pressure_diastolic_on_effort: null,
  spvo2: null,
  documents: [],
};

export const Evaluations = () => {
  ReactGA.send("/customer-evaluations");

  const dispatch = useDispatch();
  const evaluations = useSelector(selectEvaluations);
  const pathSegments = window.location.href.split("/");
  const userId = pathSegments[pathSegments.length - 2];
  const [formData, setFormData] = useState<EvaluationsType>(
    initialEvaluationState
  );
  const user = useSelector(selectCurrentClient);
  const [isAddingEvaluation, setIsAddingEvaluation] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchEvaluations(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (user === undefined || user === null || evaluations.length === 0) return;

    const maxHeartFrequency = user?.age ? 217 - 0.85 * user.age : undefined;

    // Only update formData if it's different from the current state
    const newFormData = {
      ...evaluations[0],
      max_heart_freq: maxHeartFrequency ?? null,
    };

    if (JSON.stringify(formData) !== JSON.stringify(newFormData)) {
      setFormData(newFormData);
    }
  }, [evaluations, user, formData]);

  const onClickButton = () => {
    setIsAddingEvaluation(true);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="add-evaluation-col">
            <h4>Adicionar Avaliação</h4>
            <button className="add-evaluation-btn" onClick={onClickButton}>
              <MdAdd />
            </button>
          </Col>
        </Row>
      </Container>
      {isAddingEvaluation && (
        <Evaluation
          evaluation={formData}
          isCreating={true}
          userId={userId}
          setIsAddingEvaluation={setIsAddingEvaluation}
        />
      )}
      {evaluations.map((evaluation, index) => (
        <Evaluation
          key={evaluation.id}
          evaluation={evaluation}
          isCreating={false}
          userId={userId}
          setIsAddingEvaluation={setIsAddingEvaluation}
        />
      ))}
    </>
  );
};
