import { takeLatest } from "redux-saga/effects";
import {
  fetchCourses,
  createCourse,
  updateCourse,
  deleteCourse,
} from "../../slices/courseSlice";
import {
  fetchCoursesSaga,
  createCourseSaga,
  updateCourseSaga,
  deleteCourseSaga,
} from "./courseSaga";

function* courseSaga() {
  yield takeLatest(fetchCourses, fetchCoursesSaga);
  yield takeLatest(createCourse, createCourseSaga);
  yield takeLatest(updateCourse, updateCourseSaga);
  yield takeLatest(deleteCourse, deleteCourseSaga);
}

export default courseSaga;
