import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { selectCurrentUser } from "../../slices/currentUserSlice";
import { getMonthAndYearForDate } from "../../helpers/date";
import "./MonthPicker.css";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

type MonthPickerType = {
  date: Date;
  setDate: (date: Date) => void;
};

export const MonthPicker = (props: MonthPickerType) => {
  const currentUser = useSelector(selectCurrentUser);
  const [dates, setDates] = useState<Array<any>>([]);
  const [dateIndex, setDateIndex] = useState(0);

  useEffect(() => {
    if (currentUser === undefined || currentUser === null) return;

    const companyCreatedAt = currentUser.company!.created_at;
    var companyCreatedDate = moment(new Date(companyCreatedAt));
    const currentDate = moment();
    var datesToSet: Array<any> = [];

    while (companyCreatedDate < currentDate.endOf("month")) {
      datesToSet.push(companyCreatedDate.toDate());
      companyCreatedDate.add(1, "month");
    }

    setDates(datesToSet);
    setDateIndex(datesToSet.length - 1);
  }, [currentUser]);

  return (
    <div className="month-picker-flex">
      <div className="month-picker-flex">
        {dateIndex !== 0 ? (
          <AiFillCaretLeft
            className="month-picker-button"
            onClick={() => {
              props.setDate(dates[dateIndex - 1]);
              setDateIndex(dateIndex - 1);
            }}
          />
        ) : (
          <div style={{ width: 20 }} />
        )}
      </div>
      <div className="month-picker-flex">
        {dates.length > 0 && (
          <p className="month-picker-title">
            {getMonthAndYearForDate(props.date)}
          </p>
        )}
      </div>
      <div className="month-picker-flex">
        {dateIndex !== dates.length - 1 ? (
          <AiFillCaretRight
            className="month-picker-button"
            onClick={() => {
              props.setDate(dates[dateIndex + 1]);
              setDateIndex(dateIndex + 1);
            }}
          />
        ) : (
          <div style={{ width: 20 }} />
        )}
      </div>
    </div>
  );
};
