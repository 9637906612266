import { getMonth } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SelectForm } from "components/SelectForm/SelectForm";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import { fetchSaft, selectSaft } from "slices/paymentsSlice";

import "components/BillingPage/ExportSaft/ExportSaft.scss";

export const ExportSaft = () => {
  const dispatch = useDispatch();
  const saft = useSelector(selectSaft);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedMonth, setSelectedMonth] = useState(getMonth(new Date()) + 1);

  const yearsOptions = [
    { id: 2013, title: 2013 },
    { id: 2014, title: 2014 },
    { id: 2015, title: 2015 },
    { id: 2016, title: 2016 },
    { id: 2017, title: 2017 },
    { id: 2018, title: 2018 },
    { id: 2019, title: 2019 },
    { id: 2020, title: 2020 },
    { id: 2021, title: 2021 },
    { id: 2022, title: 2022 },
    { id: 2023, title: 2023 },
    { id: 2024, title: 2024 },
  ];

  const monthsOptions = [
    { id: 1, title: "Janeiro" },
    { id: 2, title: "Fevereiro" },
    { id: 3, title: "Março" },
    { id: 4, title: "Abril" },
    { id: 5, title: "Maio" },
    { id: 6, title: "Junho" },
    { id: 7, title: "Julho" },
    { id: 8, title: "Agosto" },
    { id: 9, title: "Setembro" },
    { id: 10, title: "Outubro" },
    { id: 11, title: "Novembro" },
    { id: 12, title: "Dezembro" },
  ];

  useEffect(() => {
    if (saft) {
      const linkSource = `data:application/xml;base64,${saft}`;
      const downloadLink = document.createElement("a");
      const fileName = `saft-${selectedMonth}-${selectedYear}.xml`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saft]);

  const handleDownloadSaft = () => {
    dispatch(
      fetchSaft({
        year: selectedYear,
        month: selectedMonth,
      })
    );
  };

  return (
    <GSection label="Exportar Saft">
      <p className="export-saft-description">
        Este ficheiro, atualmente na versão 1.04, serve para comunicar à
        Autoridade Tributária, os documentos emitidos pela sua Empresa, tendo
        que o submeter no Portal das Finanças até ao dia 5 do mês seguinte
        àquele a que diz respeito.
      </p>
      <div className="export-saft-container">
        <div className="export-saft-form">
          <SelectForm
            label="Ano"
            value={{
              id: selectedYear,
              title: selectedYear,
            }}
            onChange={(e: any) => setSelectedYear(e.value)}
            options={yearsOptions}
          />
          <SelectForm
            label="Mês"
            value={{
              id: selectedMonth,
              title: monthsOptions.find((month) => month.id === selectedMonth)!
                .title,
            }}
            onChange={(e: any) => setSelectedMonth(e.value)}
            options={monthsOptions}
          />
          <div className="export-saft-button-div">
            <GButton label="Descarregar SAF-T" onClick={handleDownloadSaft} />
          </div>
        </div>
      </div>
    </GSection>
  );
};
