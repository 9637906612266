import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { Payment, UpdatePayment, DestroyPayment } from "types/payments";

type PaymentsState = {
  payments: Payment[];
  userPayments: Payment[];
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
  saft: string | undefined; // xml
  isLoading: boolean;
  paymentUrl: string | undefined;
};

export type FetchPaymentsType = {
  from: string;
  to: string;
  search: string | null;
};

export type FetchSaftType = {
  year: number;
  month: number; // 1 to 12
};

export type CompletePaymentType = {
  id: string;
  create_invoice: boolean;
  date?: string;
  payment_method?: string;
};

const initialPaymentsState = {
  payments: [],
  userPayments: [],
  errorMessage: undefined,
  confirmationMessage: undefined,
  saft: undefined,
  isLoading: false,
  paymentUrl: undefined,
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState: initialPaymentsState,
  reducers: {
    // Payments
    fetchPayments(
      state: PaymentsState,
      action: PayloadAction<FetchPaymentsType>
    ) {
      state.isLoading = true;
    },
    fetchPaymentsSucceeded(
      state: PaymentsState,
      action: PayloadAction<Payment[]>
    ) {
      state.payments = action.payload;
      state.isLoading = false;
    },
    // User Payments
    fetchUserPayments(state: PaymentsState, action: PayloadAction<string>) {},
    fetchUserPaymentsSucceeded(
      state: PaymentsState,
      action: PayloadAction<Payment[]>
    ) {
      state.userPayments = action.payload;
    },
    fetchSaft(state: PaymentsState, action: PayloadAction<FetchSaftType>) {},
    fetchSaftSucceeded(state: PaymentsState, action: PayloadAction<string>) {
      state.saft = action.payload;
    },
    clearSaftData(state: PaymentsState) {
      state.saft = undefined;
    },
    updateUserPayment(
      state: PaymentsState,
      action: PayloadAction<UpdatePayment>
    ) {},
    updateUserPaymentSucceeded(
      state: PaymentsState,
      action: PayloadAction<Payment>
    ) {
      const index = state.userPayments.findIndex(
        (payment: Payment) => payment.id === action.payload.id
      );
      state.userPayments[index] = action.payload;
      state.confirmationMessage = "Pagamento atualizado com sucesso";
    },
    createPaymentToGoactive(state: PaymentsState) {},
    createPaymentToGoactiveSucceeded(
      state: PaymentsState,
      action: PayloadAction<string>
    ) {
      state.paymentUrl = action.payload;
    },
    completePayment(state: PaymentsState, action: PayloadAction<CompletePaymentType>) {},
    completePaymentSucceeded(
      state: PaymentsState,
      action: PayloadAction<string>
    ) {
      const index = state.userPayments.findIndex(
        (payment: Payment) => `${payment.id}` === `${action.payload}`
      );
      state.userPayments[index] = {...state.userPayments[index], is_paid: true }
      state.confirmationMessage = "Pagamento atualizado com sucesso"
    },
    deleteUserPayment(
      state: PaymentsState,
      action: PayloadAction<DestroyPayment>
    ) {},
    deleteUserPaymentSucceeded(
      state: PaymentsState,
      action: PayloadAction<string>
    ) {
      const index = state.userPayments.findIndex(
        (userPayment: Payment) => userPayment.id === action.payload
      );

      state.userPayments.splice(index, 1);
      state.confirmationMessage = "Pagamento apagado com sucesso";
    },
    // Messages
    setErrorMessage(
      state: PaymentsState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: PaymentsState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
    cleanErrorMessage(state: PaymentsState) {
      state.errorMessage = undefined;
    },
  },
});

export const {
  fetchPayments,
  fetchPaymentsSucceeded,
  fetchUserPayments,
  fetchUserPaymentsSucceeded,
  updateUserPayment,
  updateUserPaymentSucceeded,
  setErrorMessage,
  setConfirmationMessage,
  cleanErrorMessage,
  deleteUserPayment,
  deleteUserPaymentSucceeded,
  fetchSaft,
  fetchSaftSucceeded,
  createPaymentToGoactive,
  createPaymentToGoactiveSucceeded,
  completePayment,
  completePaymentSucceeded
} = paymentsSlice.actions;

export const selectPayments = (state: RootState) =>
  state.paymentsSlice.payments;
export const selectUserPayments = (state: RootState) =>
  state.paymentsSlice.userPayments;
export const selectErrorMessage = (state: RootState) =>
  state.paymentsSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.paymentsSlice.confirmationMessage;
export const selectSaft = (state: RootState): string =>
  state.paymentsSlice.saft!;
export const selectIsLoading = (state: RootState): boolean =>
  state.paymentsSlice.isLoading;
export const selectPaymentUrl = (state: RootState): string | undefined =>
  state.paymentsSlice.paymentUrl;

export default paymentsSlice.reducer;
