import { takeLatest } from "redux-saga/effects";
import {
  fetchCoursesSchedules,
  createCourseSchedule,
  updateCourseSchedule,
  deleteCourseSchedule,
  fetchCourseSchedule,
  fetchCourseScheduleCounters,
  fetchUserCourseScheduleCounters,
} from "../../slices/courseScheduleSlice";
import {
  fetchCoursesSchedulesSaga,
  createCourseScheduleSaga,
  updateCourseScheduleSaga,
  deleteCourseScheduleSaga,
  fetchCourseScheduleSaga,
  fetchCourseScheduleCountersSaga,
  fetchUserCourseScheduleCountersSaga,
} from "./courseScheduleSaga";

function* courseScheduleSaga() {
  yield takeLatest(fetchCoursesSchedules, fetchCoursesSchedulesSaga);
  yield takeLatest(fetchCourseSchedule, fetchCourseScheduleSaga);
  yield takeLatest(createCourseSchedule, createCourseScheduleSaga);
  yield takeLatest(updateCourseSchedule, updateCourseScheduleSaga);
  yield takeLatest(deleteCourseSchedule, deleteCourseScheduleSaga);
  yield takeLatest(
    fetchCourseScheduleCounters,
    fetchCourseScheduleCountersSaga
  );
  yield takeLatest(
    fetchUserCourseScheduleCounters,
    fetchUserCourseScheduleCountersSaga
  );
}

export default courseScheduleSaga;
