import { takeLatest } from "redux-saga/effects";
import {
  fetchWorkouts,
  fetchWorkout,
  createWorkout,
  duplicateWorkout,
  updateWorkout,
  deleteWorkout,
} from "../../slices/workoutSlice";
import {
  fetchWorkoutsSaga,
  fetchWorkoutSaga,
  createWorkoutSaga,
  duplicateWorkoutSaga,
  updateWorkoutSaga,
  deleteWorkoutSaga,
} from "./workoutSaga";

function* workoutSaga() {
  yield takeLatest(fetchWorkouts, fetchWorkoutsSaga);
  yield takeLatest(fetchWorkout, fetchWorkoutSaga);
  yield takeLatest(createWorkout, createWorkoutSaga);
  yield takeLatest(duplicateWorkout, duplicateWorkoutSaga);
  yield takeLatest(updateWorkout, updateWorkoutSaga);
  yield takeLatest(deleteWorkout, deleteWorkoutSaga);
}

export default workoutSaga;
