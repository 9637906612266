import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import {
  login,
  selectCurrentUser,
  selectErrorMessage,
  cleanErrorMessage,
} from "../../slices/currentUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/logo.svg";
import { useCookies } from "react-cookie";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "./LoginModal.css";
import { ForgotPassword } from "./ForgotPassword/ForgotPassword";
import { useTranslation } from "react-i18next";
import { GButton } from "components/genericComponents/GButton/GButton";
import { InputGroup } from "react-bootstrap";

type LoginModalType = {
  showLoginModal: boolean;
  setShowLoginModal: (value: boolean) => void;
};

export const LoginModal = (props: LoginModalType) => {
  const { t } = useTranslation();
  const showLoginModal = props.showLoginModal;
  const setShowLoginModal = props.setShowLoginModal;
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState<string | undefined>(
    undefined
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const currentUser = useSelector(selectCurrentUser);
  const errorMessage = useSelector(selectErrorMessage);
  const [, setCookies] = useCookies(["current_user"]);
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);

  const onSubmitButtonClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    var isValid = true;
    if (!isEmailValid()) isValid = false;
    if (!isPasswordValid()) isValid = false;

    if (isValid) dispatch(login({ email: email, password: password }));
  };

  const isEmailValid = (): boolean => {
    if (email === "") {
      setEmailError(t("errors.emptyEmail"));
      return false;
    }

    const regex = new RegExp(
      // Turned \t into \\t to escape it (if something is off, try removing a \)
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \\t]|(\\[\\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\\t -Z^-~]*])"
    );

    if (!regex.test(email)) {
      setEmailError(t("errors.invalidEmail"));
      return false;
    }

    setEmailError(undefined);
    return true;
  };

  const isPasswordValid = (): boolean => {
    if (password === "") {
      setPasswordError(t("errors.emptyPassword"));
      return false;
    }

    setPasswordError(undefined);
    return true;
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "login-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(cleanErrorMessage());
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (currentUser !== undefined) {
      setCookies("current_user", {
        id: currentUser.id,
        email: currentUser.email,
        name: currentUser.name,
        image: currentUser.image_small,
      });
      if (
        currentUser.company?.is_gym &&
        (currentUser.role === "admin" || currentUser.role === "gym")
      ) {
        localStorage.setItem("isGymView", "true");
      } else {
        localStorage.setItem("isGymView", "false");
      }
    }
  }, [currentUser, setCookies]);

  return (
    <>
      {currentUser !== undefined ? (
        <Navigate to="/dashboard/home" replace={true} />
      ) : undefined}
      <Modal
        className="modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      >
        <Modal.Body>
          <Row className="modal-flex-center">
            <img className="logo" src={logo} alt="" />
          </Row>
          {showForgotPassword ? (
            <ForgotPassword />
          ) : (
            <>
              <p className="modal-title">{t("landingPage.header.login")}</p>
              <Form onSubmit={onSubmitButtonClick} className="modal-form">
                <FloatingLabel
                  className="mb-3 form-floating-label"
                  label={t("generic.email")}
                >
                  <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder={t("generic.email")}
                    autoFocus
                  />
                  {emailError !== undefined ? (
                    <p className="form-error-message">{emailError}</p>
                  ) : undefined}
                </FloatingLabel>
                <InputGroup className="mb-3">
                  <FloatingLabel label={t("generic.password")}>
                    <Form.Control
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder={t("generic.password")}
                      type={showPassword ? "text" : "password"}
                    />
                    {passwordError !== undefined ? (
                      <p className="form-error-message">{passwordError}</p>
                    ) : undefined}
                  </FloatingLabel>
                  <InputGroup.Text
                    onClick={() => setShowPassword(!showPassword)}
                    className="show-password"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                </InputGroup>
                <Row>
                  <span
                    className="modal-forget-password"
                    onClick={() => setShowForgotPassword(true)}
                  >
                    {t("generic.forgotPassword")}
                  </span>
                </Row>
                <Row className="modal-flex-center padding">
                  <GButton
                    label={t("generic.submit")}
                    type="submit"
                    onClick={onSubmitButtonClick}
                  />
                </Row>
              </Form>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
