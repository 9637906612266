import { BsFillXCircleFill } from "react-icons/bs";

import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import { GDatePicker } from "components/genericComponents/GDatePicker/GDatePicker";
import { GInput } from "components/genericComponents/GInput/GInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchInsurance,
  selectInsurance,
  setUserInsurance,
  updateUserInsurance,
} from "slices/insuranceSlice";

import "components/DashboardCustomersPage/CustomerPayments/ChangeUserInsurance/ChangeUserInsurance.scss";

type ChangeUserInsuranceProps = {
  insuranceId: string | undefined;
  userId: string;
  handleCloseChooseInsurance: () => void;
};

export const ChangeUserInsurance = ({
  insuranceId,
  userId,
  handleCloseChooseInsurance,
}: ChangeUserInsuranceProps) => {
  const dispatch = useDispatch();
  const insurance = useSelector(selectInsurance);
  const [startDate, setStartDate] = useState<Date>(new Date());

  useEffect(() => {
    dispatch(fetchInsurance());
  }, [dispatch]);

  const handleSetCurrDate = (val: Date | undefined) => {
    setStartDate(val!);
  };

  const handleSubmitInsuranceStart = () => {
    if (insuranceId)
      dispatch(
        updateUserInsurance({
          id: userId,
          insurance_id: insuranceId,
          start_date: `${startDate}`,
        })
      );
    else dispatch(setUserInsurance({ id: userId, start_date: `${startDate}` }));
    handleCloseChooseInsurance();
  };

  return (
    <GSection
      label="Alterar Início do Seguro"
      isSpacedBetween
      renderButtons={[
        <GButton
          icon={<BsFillXCircleFill />}
          onClick={handleCloseChooseInsurance}
        />,
      ]}
    >
      <div className="change-user-insurance-start-container">
        <div className="change-user-insurance-start-div">
          <p className="titles">Início do plano:</p>
          <GDatePicker
            setCurrDate={handleSetCurrDate}
            currDate={startDate}
            className="date-picker"
          />
          <p className="titles">Valor (€):</p>
          <GInput value={insurance?.price} className="no-margin" disabled />
          <p className="can-change-val">
            Este valor pode ser alterado nas definições dos pagamentos
          </p>
          <div className="submit-btn-div">
            <GButton
              label="Submeter"
              className="submit-btn"
              onClick={handleSubmitInsuranceStart}
            />
          </div>
        </div>
      </div>
    </GSection>
  );
};
