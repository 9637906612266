import { axiosRequest, errorMessage } from "./request";
import { ExerciseCategory } from "../types/exercise";
import {
  CreateExerciseCategoryType,
  UpdateExerciseCategoryType,
} from "../slices/exerciseCategorySlice";

export type ExerciseCategoryRequestResponseType = {
  succeeded: boolean;
  exerciseCategories: Array<ExerciseCategory> | undefined;
  exerciseCategory: ExerciseCategory | undefined;
  errorMessage: string | undefined;
};

export const fetchExerciseCategoriesRequest =
  async (): Promise<ExerciseCategoryRequestResponseType> => {
    try {
      const response = await axiosRequest().get("exercise_categories");
      const exerciseCategories = response.data as Array<ExerciseCategory>;

      return {
        succeeded: true,
        exerciseCategories: exerciseCategories,
      } as ExerciseCategoryRequestResponseType;
    } catch (e) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as ExerciseCategoryRequestResponseType;
    }
  };

export const createExerciseCategoryRequest = async (
  params: CreateExerciseCategoryType
): Promise<ExerciseCategoryRequestResponseType> => {
  try {
    const response = await axiosRequest().post("exercise_categories", {
      exercise_category: params,
    });
    const exerciseCategory: ExerciseCategory = response.data;

    return {
      succeeded: true,
      exerciseCategory: exerciseCategory,
    } as ExerciseCategoryRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ExerciseCategoryRequestResponseType;
  }
};

export const updateExerciseCategoryRequest = async (
  params: UpdateExerciseCategoryType
): Promise<ExerciseCategoryRequestResponseType> => {
  try {
    const response = await axiosRequest().put(
      `exercise_categories/${params.id}`,
      {
        exercise_category: params,
      }
    );
    const exerciseCategory: ExerciseCategory = response.data;

    return {
      succeeded: true,
      exerciseCategory: exerciseCategory,
    } as ExerciseCategoryRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ExerciseCategoryRequestResponseType;
  }
};

export const deleteExerciseCategoryRequest = async (
  exerciseId: string
): Promise<ExerciseCategoryRequestResponseType> => {
  try {
    await axiosRequest().delete(`exercise_categories/${exerciseId}`);

    return { succeeded: true } as ExerciseCategoryRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ExerciseCategoryRequestResponseType;
  }
};
