import axios from "axios";
import { ResponseError } from "../types/response_error";

export const axiosRequest = () =>
  axios.create({
    baseURL: "https://gym-application-backend.herokuapp.com/",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Is_gym_view: localStorage.getItem("isGymView"),
    },
  });

export const errorMessage = (e: any) => {
  if (e.response.status > 499) {
    return "Pedido falhou";
  }

  const errors: [ResponseError] = e.response.data.errors;

  return errors.map((e: ResponseError) => e.message).join(". ");
};
