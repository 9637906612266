import { takeLatest } from "redux-saga/effects";
import {
  fetchWorkoutSchedules,
  createWorkoutSchedules,
  updateWorkoutSchedules,
  deleteWorkoutSchedule,
} from "../../slices/workoutScheduleSlice";
import {
  fetchWorkoutSchedulesSaga,
  createWorkoutSchedulesSaga,
  updateWorkoutSchedulesSaga,
  deleteWorkoutSchedulesSaga,
} from "./workoutScheduleSaga";

function* workoutScheduleSaga() {
  yield takeLatest(fetchWorkoutSchedules, fetchWorkoutSchedulesSaga);
  yield takeLatest(createWorkoutSchedules, createWorkoutSchedulesSaga);
  yield takeLatest(updateWorkoutSchedules, updateWorkoutSchedulesSaga);
  yield takeLatest(deleteWorkoutSchedule, deleteWorkoutSchedulesSaga);
}

export default workoutScheduleSaga;
