import { Col, Row } from "react-bootstrap";

import "./AccountSection.css";
import { ProfileSettings } from "./ProfileSettings/ProfileSettings";
import { CredentialsSettings } from "./CredentialsSettings/CredentialsSettings";
import { CompanySettings } from "./CompanySettings/CompanySettings";

export const AccountSection = () => {
  return (
    <div id="account-section">
      <Row className="account-section-row">
        {localStorage.getItem("isGymView") === "true" ? (
          <Col>
            <CompanySettings />
          </Col>
        ) : (
          <Col className="pt-account-section-col">
            <ProfileSettings />
            <CredentialsSettings />
          </Col>
        )}
      </Row>
    </div>
  );
};
