import { GButton } from "components/genericComponents/GButton/GButton";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BsFillXCircleFill, BsPencilSquare } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  CreateCourseType,
  createCourse,
  selectConfirmationMessage,
  selectErrorMessage,
  setConfirmationMessage,
  setErrorMessage,
} from "../../../../../slices/courseSlice";
import { InputForm } from "../../../../InputForm/InputForm";
import { UserImageUpload } from "../../../../UserImageUpload/UserImageUpload";

import "./AddCourseInfoSettings.css";

const { CirclePicker } = require("react-color");

type AddCourseInfoSettingsI = {
  setShowAddCourse: Dispatch<SetStateAction<boolean>>;
};

export const AddCourseInfoSettings = ({
  setShowAddCourse,
}: AddCourseInfoSettingsI) => {
  const dispatch = useDispatch();
  const [isEditting, setIsEditting] = useState<boolean>(true);
  const [file, setFile] = useState<File | null>(null);
  const [imageKey, setImageKey] = useState<string | undefined>(undefined);
  const [course, setCourse] = useState<CreateCourseType>({
    title: "",
    description: "",
    image: null,
    color: "#6842FF",
  });
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "add-course-info-settings-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "add-course-info-settings-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  const onSubmitButtonClick = () => {
    const image =
      file === null
        ? null
        : {
            id: imageKey!,
            storage: "cache",
            metadata: {
              size: file.size,
              mime_type: file.type,
            },
          };
    dispatch(
      createCourse({
        ...course,
        image: image,
      })
    );
    setIsEditting(false);
    setShowAddCourse(false);
  };

  return (
    <div className="exercises-variables-settings-inner-container">
      <Row>
        <Col className="exercises-variables-settings-inner-container-title-col">
          <div className="title-div">
            <h4>{course?.title}</h4>
            {!isEditting ? (
              <Button
                className="title-edit-btn"
                onClick={() => {
                  setIsEditting(true);
                }}
              >
                <BsPencilSquare />
              </Button>
            ) : (
              <Button
                className="title-edit-btn"
                onClick={() => {
                  setIsEditting(false);
                }}
              >
                <BsFillXCircleFill />
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="course-info-settings-image-col">
          <UserImageUpload
            image={undefined}
            file={file}
            setFile={setFile}
            setImageKey={setImageKey}
            showEditButton={isEditting}
          />
        </Col>
      </Row>
      <Row>
        <Col className="course-info-settings-input-col">
          <InputForm
            value={course?.title}
            label="Nome da Aula"
            className="course-info-settings-input"
            onChange={(e: any) => {
              if (e.target.value) {
                setCourse({
                  ...course,
                  title: e.target.value,
                });
              }
            }}
            disabled={!isEditting}
          />
        </Col>
      </Row>
      <Row>
        <Col className="course-info-settings-input-col">
          <InputForm
            value={course?.description}
            label="Descrição da Aula"
            as="textarea"
            className="course-info-settings-input"
            onChange={(e: any) => {
              setCourse({
                ...course,
                description: e.target.value,
              });
            }}
            disabled={!isEditting}
          />
        </Col>
      </Row>
      <Row>
        <Col className="course-info-settings-input-col">
          <CirclePicker
            color={course.color}
            width="100%"
            colors={[
              "#f44336",
              "#e91e63",
              "#6842FF",
              "#673ab7",
              "#3f51b5",
              "#2196f3",
              "#03a9f4",
              "#00bcd4",
              "#009688",
              "#4caf50",
              "#8bc34a",
              "#cddc39",
              "#ecc80e",
              "#ffc107",
              "#ff9800",
              "#ff5722",
              "#795548",
              "#607d8b",
            ]}
            onChangeComplete={(val: any) => {
              setCourse({
                ...course,
                color: val.hex,
              });
            }}
          />
        </Col>
      </Row>
      {isEditting ? (
        <Row>
          <Col className="submit-col">
            <GButton label="Submeter" onClick={() => onSubmitButtonClick()} />
          </Col>
        </Row>
      ) : undefined}
    </div>
  );
};
