import { useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Navbar,
  Row,
} from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { toast } from "react-toastify";

import android from "../../assets/google-play-badge.png";
import apple from "../../assets/apple-badge.svg";
import { axiosRequest } from "../../requests/request";

import "./CreatePasswordPage.css";

type CreatePasswordPageProps = {
  isPersonalTrainer: boolean;
};

export const CreatePasswordPage = ({
  isPersonalTrainer,
}: CreatePasswordPageProps) => {
  const email = window.location.href.split("/").slice(-1)[0];
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<
    string | null
  >(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<
    string | null
  >(null);
  const isSubmitButtonDisabled =
    newPassword === null ||
    newPassword === "" ||
    newPasswordConfirmation === null ||
    newPasswordConfirmation === "";
  const [isPasswordCreated, setIsPasswordCreated] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState<boolean>(false);

  const handleSubmit = async () => {
    if (newPassword!.length < 8) {
      return setPasswordError(
        "Palavra-passe tem de ter no mínimo 8 caracteres"
      );
    }

    if (newPassword !== newPasswordConfirmation) {
      return setPasswordConfirmationError(
        "Confirmação da palavra-passe inválida"
      );
    }

    try {
      await axiosRequest().post("user_accept_invitation", {
        user: { email: email, password: newPassword },
      });

      toast.success("Palavra-passe criada com sucesso", {
        toastId: "set-password-success",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setPasswordConfirmationError(null);

      if (isPersonalTrainer) {
        window.location.replace("https://goactivedash.com/");
      } else {
        setIsPasswordCreated(true);
      }
    } catch (error) {
      toast.error("Pedido falhou", {
        toastId: "set-password-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <Container fluid id="create-password">
      <Navbar bg="white" expand="lg" className="nav">
        <Container>
          <Navbar.Brand
            href={process.env.REACT_APP_SITE_LINK}
            className="nav-title create-password"
          >
            {process.env.REACT_APP_APP_NAME}
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Row>
        <Col className="create-password-col">
          {isPasswordCreated ? (
            <div className="create-password-created-div">
              <h1>Instale agora a aplicação</h1>
              <div className="create-password-stores-div">
                <a href="https://play.google.com/store/apps/details?id=com.goactivedash.gymsappfrontend">
                  <img
                    src={android}
                    className="create-password-stores-img"
                    alt=""
                  />
                </a>
                <a href="https://apps.apple.com/us/app/goactive-app/id6451086309">
                  <img
                    src={apple}
                    className="create-password-stores-img"
                    alt=""
                  />
                </a>
              </div>
            </div>
          ) : (
            <div className="create-password-created-div">
              <h1 className="create-password-title">Criar uma palavra-passe</h1>
              <Col className="create-password-inner-col">
                <FloatingLabel className="mb-3 profile-input" label="Email">
                  <Form.Control
                    type="text"
                    value={email ?? undefined}
                    disabled
                    placeholder="Palavra-passe"
                  />
                </FloatingLabel>
              </Col>
              <Col className="create-password-inner-col">
                <InputGroup className="mb-3">
                  <FloatingLabel label="Nova palavra-passe">
                    <Form.Control
                      value={newPassword ?? undefined}
                      onChange={(e) => {
                        setNewPassword(e.target.value);

                        if (e.target.value.length > 8) setPasswordError(null);
                      }}
                      placeholder="Nova palavra-passe"
                      type={showPassword ? "text" : "password"}
                    />
                  </FloatingLabel>
                  <InputGroup.Text
                    onClick={() => setShowPassword(!showPassword)}
                    className="show-password"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col>
                {passwordError !== undefined ? (
                  <p className="form-error-message">{passwordError}</p>
                ) : undefined}
              </Col>
              <Col className="create-password-inner-col">
                <InputGroup className="mb-3">
                  <FloatingLabel label="Confirmar palavra-passe">
                    <Form.Control
                      value={newPasswordConfirmation ?? undefined}
                      onChange={(e) =>
                        setNewPasswordConfirmation(e.target.value)
                      }
                      placeholder="Confirmar palavra-passe"
                      type={showPasswordConfirmation ? "text" : "password"}
                    />
                  </FloatingLabel>
                  <InputGroup.Text
                    onClick={() =>
                      setShowPasswordConfirmation(!showPasswordConfirmation)
                    }
                    className="show-password"
                  >
                    {showPasswordConfirmation ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col>
                {passwordConfirmationError !== undefined ? (
                  <p className="form-error-message">
                    {passwordConfirmationError}
                  </p>
                ) : undefined}
              </Col>
              {isSubmitButtonDisabled ? undefined : (
                <Row className="credentials-justify-content-center credentials-margin-top">
                  <Button className="submit-button" onClick={handleSubmit}>
                    Guardar palavra-passe
                  </Button>
                </Row>
              )}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
