import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import {
  CRMRequestResponseType,
  createCRMUserNoteRequest,
  fetchCRMHistoryRequest,
  fetchCRMUserNotesRequest,
} from "requests/crmRequests";
import {
  createUserCRMNoteSucceeded,
  fetchCRMHistorySucceeded,
  fetchUserCRMNotesSucceeded,
  setErrorMessage,
} from "slices/crmSlice";
import { CreateCRMNote } from "types/crm";

export function* fetchUserCRMNotesSaga(
  action: PayloadAction<string>,
  request = fetchCRMUserNotesRequest
) {
  const response: CRMRequestResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(fetchUserCRMNotesSucceeded(response.notes!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createUserCRMNoteSaga(
  action: PayloadAction<CreateCRMNote & { id: string }>,
  request = createCRMUserNoteRequest
) {
  const response: CRMRequestResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(createUserCRMNoteSucceeded(response.note!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchCRMHistorySaga(
  action: any,
  request = fetchCRMHistoryRequest
) {
  const response: CRMRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchCRMHistorySucceeded(response.history));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
