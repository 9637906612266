import { Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";
import { selectIsLoading } from "slices/productSlice";

import "components/ProductsPage/ProductsSection/ProductsFilters/ProductsFilters.scss";

type ProductsFiltersProps = {
  productFilter: string | undefined;
  onProductChange: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const ProductsFilters = ({
  productFilter,
  onProductChange,
}: ProductsFiltersProps) => {
  const isLoadingProducts = useSelector(selectIsLoading);

  return (
    <Container fluid style={{ padding: 0, marginBottom: "8px" }}>
      <Row className="calendar-filters-row">
        <Col className="calendar-filters-col">
          <div className="calendar-filters-div">
            <Form.Control
              value={productFilter}
              onChange={(e) =>
                onProductChange(
                  e.target.value !== "" ? e.target.value : undefined
                )
              }
              placeholder="Filtrar..."
            />
          </div>
        </Col>
        {isLoadingProducts && (
          <Col xs={1}>
            <SpinnerWrapper className="right" />
          </Col>
        )}
      </Row>
    </Container>
  );
};
