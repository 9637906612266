import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchPersonalTrainerSucceeded,
  fetchPersonalTrainersSucceeded,
  createPersonalTrainerSucceeded,
  updatePersonalTrainerSucceeded,
  deletePersonalTrainerSucceeded,
  setErrorMessage,
  CreatePersonalTrainerType,
  UpdatePersonalTrainerType,
} from "../../slices/personalTrainerSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchPersonalTrainerRequest,
  fetchPersonalTrainersRequest,
  createPersonalTrainerRequest,
  updatePersonalTrainerRequest,
  deletePersonalTrainerRequest,
  PersonalTrainerRequestResponseType,
} from "../../requests/personalTrainerRequests";

export function* fetchPersonalTrainersSaga(
  action: any,
  request = fetchPersonalTrainersRequest
) {
  const response: PersonalTrainerRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchPersonalTrainersSucceeded(response.personalTrainers!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchPersonalTrainerSaga(
  action: PayloadAction<string>,
  request = fetchPersonalTrainerRequest
) {
  const response: PersonalTrainerRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchPersonalTrainerSucceeded(response.personalTrainer!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createPersonalTrainerSaga(
  action: PayloadAction<CreatePersonalTrainerType>,
  request = createPersonalTrainerRequest
) {
  const response: PersonalTrainerRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createPersonalTrainerSucceeded(response.personalTrainer!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updatePersonalTrainerSaga(
  action: PayloadAction<UpdatePersonalTrainerType>,
  request = updatePersonalTrainerRequest
) {
  const response: PersonalTrainerRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updatePersonalTrainerSucceeded(response.personalTrainer!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deletePersonalTrainerSaga(
  action: PayloadAction<string>,
  request = deletePersonalTrainerRequest
) {
  const response: PersonalTrainerRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deletePersonalTrainerSucceeded(action.payload));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
