import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchPlansSucceeded,
  FetchCompanyPlansType,
  fetchUpgradablePlansSucceeded,
} from "../../slices/companyPlanSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchCompanyPlans,
  fetchUpgradableCompanyPlans,
  CompanyPlanResponseType,
} from "../../requests/companyPlanRequests";

export function* fetchCompanyPlansSaga(
  action: PayloadAction<FetchCompanyPlansType>,
  request = fetchCompanyPlans
) {
  const response: CompanyPlanResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(fetchPlansSucceeded(response.plans!));
  } else {
    // TODO
  }
}

export function* fetchUpgradableCompanyPlansSaga(
  action: any,
  request = fetchUpgradableCompanyPlans
) {
  const response: CompanyPlanResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchUpgradablePlansSucceeded(response.plans!));
  } else {
    // TODO
  }
}
