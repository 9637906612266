import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  CreatePaymentsPackage,
  PaymentsPackage,
  SetPaymentsPackagePayload,
} from "types/paymentPackages";

type PaymentsPackagesState = {
  paymentsPackages: PaymentsPackage[];
  userPackage: PaymentsPackage | undefined;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
};

const initialPaymentsPackagesState = {
  paymentsPackages: [],
  userPackage: undefined,
  errorMessage: undefined,
  confirmationMessage: undefined,
};

const paymentsPackagesSlice = createSlice({
  name: "paymentsPackages",
  initialState: initialPaymentsPackagesState,
  reducers: {
    // Packages
    fetchPaymentsPackages(state: PaymentsPackagesState) {},
    fetchPaymentsPackagesSucceeded(
      state: PaymentsPackagesState,
      action: PayloadAction<PaymentsPackage[]>
    ) {
      state.paymentsPackages = action.payload;
    },
    createPaymentsPackage(
      state: PaymentsPackagesState,
      action: PayloadAction<CreatePaymentsPackage>
    ) {},
    createPaymentsPackageSucceeded(
      state: PaymentsPackagesState,
      action: PayloadAction<PaymentsPackage>
    ) {
      state.confirmationMessage = "Plano adicionado com sucesso";
      state.paymentsPackages = [action.payload, ...state.paymentsPackages];
    },
    updatePaymentsPackage(
      state: PaymentsPackagesState,
      action: PayloadAction<PaymentsPackage>
    ) {},
    updatePaymentsPackageSucceeded(
      state: PaymentsPackagesState,
      action: PayloadAction<PaymentsPackage>
    ) {
      const index = state.paymentsPackages.findIndex(
        (payment: PaymentsPackage) => payment.id === action.payload.id
      );
      state.paymentsPackages[index] = action.payload;
      state.confirmationMessage = "Plano atualizado com sucesso";
    },
    deletePaymentsPackage(
      state: PaymentsPackagesState,
      action: PayloadAction<string>
    ) {},
    deletePaymentsPackageSucceeded(
      state: PaymentsPackagesState,
      action: PayloadAction<string>
    ) {
      const index = state.paymentsPackages.findIndex(
        (payment: PaymentsPackage) => payment.id === action.payload
      );
      state.paymentsPackages.splice(index, 1);
      state.confirmationMessage = "Plano eliminado com sucesso";
    },
    // Client Package
    setUserPaymentsPackage(
      state: PaymentsPackagesState,
      action: PayloadAction<SetPaymentsPackagePayload>
    ) {},
    setUserPaymentsPackageSucceeded(
      state: PaymentsPackagesState,
      action: PayloadAction<undefined>
    ) {
      // TODO: weird hack. Need to fix this
      state.userPackage = {
        id: "2",
        title: "tt",
        type: "RecurrentPaymentPackage",
        price: 0,
        start_time: new Date().toISOString(),
        end_time: new Date().toISOString(),
        frequency: null,
        max_entries_per_week: null,
        number_of_sessions: null,
        term_in_days: null,
        can_access_courses: true,
      };
      state.confirmationMessage = "Plano adicionado com sucesso";
    },
    setErrorMessage(
      state: PaymentsPackagesState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: PaymentsPackagesState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
    cleanErrorMessage(state: PaymentsPackagesState) {
      state.errorMessage = undefined;
    },
  },
});

export const {
  fetchPaymentsPackages,
  fetchPaymentsPackagesSucceeded,
  createPaymentsPackage,
  createPaymentsPackageSucceeded,
  updatePaymentsPackage,
  updatePaymentsPackageSucceeded,
  deletePaymentsPackage,
  deletePaymentsPackageSucceeded,
  setUserPaymentsPackage,
  setUserPaymentsPackageSucceeded,
  setErrorMessage,
  setConfirmationMessage,
  cleanErrorMessage,
} = paymentsPackagesSlice.actions;

export const selectPackages = (state: RootState) =>
  state.paymentsPackagesSlice.paymentsPackages;
export const selectUserPaymentsPackage = (state: RootState) =>
  state.paymentsPackagesSlice.userPackage;
export const selectErrorMessage = (state: RootState) =>
  state.paymentsPackagesSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.paymentsPackagesSlice.confirmationMessage;

export default paymentsPackagesSlice.reducer;
