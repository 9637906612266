import { takeLatest } from "redux-saga/effects";
import {
  fetchProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  fetchProductRevenue,
} from "../../slices/productSlice";
import {
  fetchProductsSaga,
  createProductSaga,
  updateProductSaga,
  deleteProductSaga,
  fetchProductRevenueSaga,
} from "./productSaga";

function* productSaga() {
  yield takeLatest(fetchProducts, fetchProductsSaga);
  yield takeLatest(createProduct, createProductSaga);
  yield takeLatest(updateProduct, updateProductSaga);
  yield takeLatest(deleteProduct, deleteProductSaga);
  yield takeLatest(fetchProductRevenue, fetchProductRevenueSaga);
}

export default productSaga;
