import { Carousel, Col, Container, Row } from "react-bootstrap";

import calendar from "../../../assets/calendarSection.png";
import evaluations from "../../../assets/evaluationsSection.png";
import evolution from "../../../assets/evolutionSection.png";
import homepage from "../../../assets/homepage.png";
import login from "../../../assets/login.png";
import profile from "../../../assets/profileSection.png";
import workout from "../../../assets/startWorkout.png";
import workoutHistory from "../../../assets/workoutHistory.png";
import workoutsSection from "../../../assets/workoutsSection.png";
import android from "../../../assets/google-play-badge.png";
import apple from "../../../assets/apple-badge.svg";

import "./AppSection.css";
import { useTranslation } from "react-i18next";

export const AppSection = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%" }} id="app-section">
      <Container className="as-container">
        <Row>
          <Col className="as-title-col">
            <h1 className="as-title">{t("landingPage.appSection.title")}</h1>
          </Col>
        </Row>
        <Row>
          <Col className="as-col">
            <p>{t("landingPage.appSection.description")}</p>
          </Col>
        </Row>
        <Row className="as-images-row">
          <Col className="as-col">
            <Carousel variant="dark">
              <Carousel.Item>
                <img src={homepage} className="as-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={login} className="as-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={evaluations} className="as-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={evolution} className="as-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={calendar} className="as-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={profile} className="as-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={workout} className="as-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={workoutHistory} className="as-image" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={workoutsSection} className="as-image" alt="" />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="as-stores-col">
            <a href="https://play.google.com/store/apps/details?id=com.goactivedash.gymsappfrontend">
              <img
                src={android}
                className="create-password-stores-img"
                alt=""
              />
            </a>
            <a href="https://apps.apple.com/us/app/goactive-app/id6451086309">
              <img src={apple} className="create-password-stores-img" alt="" />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
