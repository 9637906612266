import { takeLatest } from "redux-saga/effects";
import { fetchGymRooms, setGymRooms } from "../../slices/gymRoomSlice";
import { fetchGymRoomsSaga, setGymRoomsSaga } from "./gymRoomSaga";

function* gymRoomSaga() {
  yield takeLatest(fetchGymRooms, fetchGymRoomsSaga);
  yield takeLatest(setGymRooms, setGymRoomsSaga);
}

export default gymRoomSaga;
