import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UserWorkoutHistory } from "../types/user_workout_history";
import { workoutHistorySuccessfullyCreated } from "../helpers/messages";

type WorkoutHistoryState = {
  workoutsHistory: Array<UserWorkoutHistory>;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
};

export type CreateWorkoutHistoryType = {
  userId: string;
  workout_id: string;
  workoutHistory: UserWorkoutHistory;
};

const initialState: WorkoutHistoryState = {
  workoutsHistory: [],
  errorMessage: undefined,
  confirmationMessage: undefined,
};

const workoutHistorySlice = createSlice({
  name: "workoutHistory",
  initialState: initialState,
  reducers: {
    fetchWorkoutsHistory(
      state: WorkoutHistoryState,
      action: PayloadAction<string>
    ) {},
    fetchWorkoutHistorySucceeded(
      state: WorkoutHistoryState,
      action: PayloadAction<Array<UserWorkoutHistory>>
    ) {
      state.workoutsHistory = action.payload;
    },
    createWorkoutHistory(
      state: WorkoutHistoryState,
      action: PayloadAction<CreateWorkoutHistoryType>
    ) {},
    createWorkoutsHistorySucceeded(state: WorkoutHistoryState) {
      state.confirmationMessage = workoutHistorySuccessfullyCreated;
    },
    setErrorMessage(
      state: WorkoutHistoryState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: WorkoutHistoryState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
  },
});

export const {
  fetchWorkoutsHistory,
  fetchWorkoutHistorySucceeded,
  createWorkoutHistory,
  createWorkoutsHistorySucceeded,
  setErrorMessage,
  setConfirmationMessage,
} = workoutHistorySlice.actions;

export const selectErrorMessage = (state: RootState) =>
  state.workoutHistorySlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.workoutHistorySlice.confirmationMessage;
export const selectWorkoutsHistory = (state: RootState) =>
  state.workoutHistorySlice.workoutsHistory;

export default workoutHistorySlice.reducer;
