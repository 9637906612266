import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "components/SettingsPage/PaymentsSettings/InsuranceSettings/InsuranceSettings.scss";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GInput } from "components/genericComponents/GInput/GInput";
import { GSection } from "components/genericComponents/GSection/GSection";
import { toast } from "react-toastify";
import {
  fetchInsurance,
  selectConfirmationMessage,
  selectErrorMessage,
  selectInsurancePrice,
  setConfirmationMessage,
  setErrorMessage,
  setInsurance,
} from "slices/insuranceSlice";

export const InsuranceSettings = () => {
  const dispatch = useDispatch();
  const storeInsuranceValue = useSelector(selectInsurancePrice);
  const [insuranceValue, setInsuranceValue] = useState<number | undefined>(
    storeInsuranceValue
  );
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  useEffect(() => {
    dispatch(fetchInsurance());
  }, [dispatch]);

  useEffect(() => {
    setInsuranceValue(storeInsuranceValue);
  }, [storeInsuranceValue]);

  const submitInsurance = () => dispatch(setInsurance(insuranceValue!));

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "insurance-settings-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "insurance-settings-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  return (
    <GSection label="Seguro">
      <div className="insurance-container">
        <div className="insurance-value-div">
          <p className="title">Valor (€)</p>
          <GInput
            value={insuranceValue}
            type="number"
            min={0}
            onChange={(e: any) => setInsuranceValue(e.target.value)}
          />
          <div className="submit-div">
            <GButton label="Submeter" onClick={submitInsurance} />
          </div>
        </div>
      </div>
    </GSection>
  );
};
