import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Workout } from "../types/workout";
import { WorkoutExercise, Exercise } from "../types/exercise";
import {
  workoutSuccessfullyCreated,
  workoutSuccessfullyUpdated,
  workoutSuccessfullyDeleted,
} from "../helpers/messages";

interface WorkoutState {
  workouts: Array<Workout>;
  currentWorkout: Workout | undefined;
  isLoading: boolean;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
}

export type FetchWorkoutExercisesSuccessedType = {
  workoutId: string;
  exercises: Array<WorkoutExercise>;
};

export type WorkoutImageType = {
  id: string;
  storage: string;
  metadata: {
    size: number;
    mime_type: string;
  };
};

export type CreateOrUpdateWorkoutType = {
  id: string | undefined;
  title: string | null;
  description: string | null;
  muscules_groups: Array<string> | null;
  user_id: string | null;
  workout_exercises: CreateWorkoutExercisesType[];
  expiration_date?: string;
  number_of_sessions?: number;
  should_reset_sessions?: boolean;
  personal_trainer_id?: string;
};

export type CreateWorkoutExercisesType = {
  id?: string | null;
  exercise: Exercise;
  notes: string | null;
  sets: Array<CreateWorkoutSetsType>;
  order: number | null;
};

export type CreateWorkoutSetsType = {
  variables: Array<CreateWorkoutVariablesType>;
};

export type CreateWorkoutVariablesType = {
  id: string;
  value: string | null;
};

const initialState: WorkoutState = {
  workouts: [],
  currentWorkout: undefined,
  isLoading: false,
  errorMessage: undefined,
  confirmationMessage: undefined,
};

const workoutSlice = createSlice({
  name: "workout",
  initialState: initialState,
  reducers: {
    fetchWorkouts(state: WorkoutState) {
      state.isLoading = true;
    },
    fetchWorkoutsSucceeded(
      state: WorkoutState,
      action: PayloadAction<Array<Workout>>
    ) {
      state.workouts = action.payload;
      state.isLoading = false;
    },
    fetchWorkout(state: WorkoutState, action: PayloadAction<string>) {},
    fetchWorkoutSucceeded(state: WorkoutState, action: PayloadAction<Workout>) {
      const index = state.workouts.findIndex(
        (workout: Workout) => workout.id === action.payload.id
      );

      if (index >= 0) state.workouts[index] = action.payload;
      else state.workouts = [action.payload];
    },
    createWorkout(
      state: WorkoutState,
      action: PayloadAction<CreateOrUpdateWorkoutType>
    ) {},
    createWorkoutSucceeded(
      state: WorkoutState,
      action: PayloadAction<Workout>
    ) {
      return {
        ...state,
        confirmationMessage: workoutSuccessfullyCreated,
        workouts: [action.payload, ...state.workouts],
      };
    },
    duplicateWorkout(state: WorkoutState, action: PayloadAction<string>) {},
    duplicateWorkoutSucceeded(
      state: WorkoutState,
      action: PayloadAction<Workout>
    ) {
      return {
        ...state,
        confirmationMessage: workoutSuccessfullyCreated,
        workouts: [action.payload, ...state.workouts],
      };
    },
    updateWorkout(
      state: WorkoutState,
      action: PayloadAction<CreateOrUpdateWorkoutType>
    ) {},
    updateWorkoutSucceeded(
      state: WorkoutState,
      action: PayloadAction<Workout>
    ) {
      const index = state.workouts.findIndex(
        (workout: Workout) => workout.id === action.payload.id
      );

      state.workouts[index] = action.payload;
      state.confirmationMessage = workoutSuccessfullyUpdated;
    },
    deleteWorkout(state: WorkoutState, action: PayloadAction<string>) {},
    deleteWorkoutSucceeded(state: WorkoutState, action: PayloadAction<string>) {
      const index = state.workouts.findIndex(
        (workout: Workout) => workout.id === action.payload
      );

      state.workouts.splice(index, 1);
      state.confirmationMessage = workoutSuccessfullyDeleted;
    },
    setCurrentWorkout(
      state: WorkoutState,
      action: PayloadAction<string | undefined>
    ) {
      state.currentWorkout = state.workouts.find(
        (workout: Workout) => action.payload === workout.id
      );
    },
    setErrorMessage(
      state: WorkoutState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: WorkoutState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
  },
});

export const {
  fetchWorkouts,
  fetchWorkoutsSucceeded,
  fetchWorkout,
  fetchWorkoutSucceeded,
  createWorkout,
  createWorkoutSucceeded,
  duplicateWorkout,
  duplicateWorkoutSucceeded,
  updateWorkout,
  updateWorkoutSucceeded,
  deleteWorkout,
  deleteWorkoutSucceeded,
  setCurrentWorkout,
  setErrorMessage,
  setConfirmationMessage,
} = workoutSlice.actions;

export const selectWorkouts = (state: RootState) => state.workoutSlice.workouts;
export const selectCurrentWorkout = (state: RootState) =>
  state.workoutSlice.currentWorkout;
export const selectErrorMessage = (state: RootState) =>
  state.workoutSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.workoutSlice.confirmationMessage;
export const selectIsLoading = (state: RootState) =>
  state.workoutSlice.isLoading;
export const getWorkoutById = (workoutId: string) => (state: RootState) =>
  state.workoutSlice.workouts.find((workout) => workout.id === workoutId);

export default workoutSlice.reducer;
