import { takeLatest } from "redux-saga/effects";
import {
  createChatMessage,
  fetchChats,
  fetchChatMessages,
  readChatMessage,
} from "../../slices/chatSlice";
import {
  createChatMessageSaga,
  fetchChatsSaga,
  fetchChatMessagesSaga,
  readMessagesSaga,
} from "./chatSaga";

function* chatgptSaga() {
  yield takeLatest(createChatMessage, createChatMessageSaga);
  yield takeLatest(fetchChats, fetchChatsSaga);
  yield takeLatest(fetchChatMessages, fetchChatMessagesSaga);
  yield takeLatest(readChatMessage, readMessagesSaga);
}

export default chatgptSaga;
