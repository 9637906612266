import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BsFillXCircleFill, BsPencilSquare } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { GButton } from "components/genericComponents/GButton/GButton";
import {
  UpdateCourseType,
  selectConfirmationMessage,
  selectErrorMessage,
  setConfirmationMessage,
  setErrorMessage,
  updateCourse,
} from "../../../../../slices/courseSlice";
import { Course } from "../../../../../types/course";
import { InputForm } from "../../../../InputForm/InputForm";
import { UserImageUpload } from "../../../../UserImageUpload/UserImageUpload";

import "./CourseInfoSettings.css";

const { CirclePicker } = require("react-color");

type CourseInfoSettingsI = {
  course: Course;
  index: number;
};

export const CourseInfoSettings = ({ course, index }: CourseInfoSettingsI) => {
  const dispatch = useDispatch();
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [imageKey, setImageKey] = useState<string | undefined>(undefined);
  const [tempCourse, setTempCourse] = useState<Course>({
    ...course,
    color: course.color === null ? "#6842FF" : course.color,
  });
  const [courseToSubmit, setCourseToSubmit] = useState<UpdateCourseType>({
    id: course.id,
    title: course.title,
    description: course.description,
    image: null,
    color: course.color === null ? "#6842FF" : course.color,
  });
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "course-info-settings-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "course-info-settings-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  const onSubmitButtonClick = () => {
    const image =
      file === null
        ? null
        : {
            id: imageKey!,
            storage: "cache",
            metadata: {
              size: file.size,
              mime_type: file.type,
            },
          };
    setCourseToSubmit({
      ...courseToSubmit,
      image: image,
      color: tempCourse.color,
    });
    dispatch(
      updateCourse({
        ...courseToSubmit,
        image: image,
        color: tempCourse.color,
      })
    );
    setIsEditting(false);
  };

  return (
    <div className="exercises-variables-settings-inner-container">
      <Row>
        <Col className="exercises-variables-settings-inner-container-title-col">
          <div className="title-div">
            <h4>{course.title}</h4>
            {!isEditting ? (
              <Button
                className="title-edit-btn"
                onClick={() => {
                  setIsEditting(true);
                }}
              >
                <BsPencilSquare />
              </Button>
            ) : (
              <Button
                className="title-edit-btn"
                onClick={() => {
                  setIsEditting(false);
                  setTempCourse({
                    ...tempCourse,
                    color: courseToSubmit.color,
                  });
                }}
              >
                <BsFillXCircleFill />
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="course-info-settings-image-col">
          <UserImageUpload
            image={tempCourse.image_medium}
            file={file}
            setFile={setFile}
            setImageKey={setImageKey}
            showEditButton={isEditting}
          />
        </Col>
      </Row>
      <Row>
        <Col className="course-info-settings-input-col">
          <InputForm
            value={tempCourse.title}
            label="Nome da Aula"
            className="course-info-settings-input"
            onChange={(e: any) => {
              setTempCourse({
                ...tempCourse,
                title: e.target.value,
              });
              setCourseToSubmit({
                ...courseToSubmit,
                title: e.target.value,
              });
            }}
            disabled={!isEditting}
          />
        </Col>
      </Row>
      <Row>
        <Col className="course-info-settings-input-col">
          <InputForm
            value={tempCourse.description}
            label="Descrição da Aula"
            as="textarea"
            className="course-info-settings-input"
            onChange={(e: any) => {
              setTempCourse({
                ...tempCourse,
                description: e.target.value,
              });
              setCourseToSubmit({
                ...courseToSubmit,
                description: e.target.value,
              });
            }}
            disabled={!isEditting}
          />
        </Col>
      </Row>
      {isEditting ? (
        <Row>
          <Col className="course-info-settings-input-col">
            <CirclePicker
              color={tempCourse.color}
              width="100%"
              colors={[
                "#f44336",
                "#e91e63",
                "#6842FF",
                "#673ab7",
                "#3f51b5",
                "#2196f3",
                "#03a9f4",
                "#00bcd4",
                "#009688",
                "#4caf50",
                "#8bc34a",
                "#cddc39",
                "#ecc80e",
                "#ffc107",
                "#ff9800",
                "#ff5722",
                "#795548",
                "#607d8b",
              ]}
              onChangeComplete={(val: any) => {
                setTempCourse({
                  ...tempCourse,
                  color: val.hex,
                });
              }}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className="course-info-settings-input-col">
            <div
              className="color-to-show"
              style={{
                backgroundColor:
                  courseToSubmit.color === null
                    ? "#6842FF"
                    : courseToSubmit.color,
              }}
            ></div>
          </Col>
        </Row>
      )}
      {isEditting ? (
        <Row>
          <Col className="submit-col">
            <GButton label="Submeter" onClick={() => onSubmitButtonClick()} />
          </Col>
        </Row>
      ) : undefined}
    </div>
  );
};
