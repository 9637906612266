/* TODO: Implement submit on enter */

import { ReactElement } from "react";
import { Button, ButtonProps } from "react-bootstrap";

import "components/genericComponents/GButton/GButton.scss";

type GButtonProps = ButtonProps & {
  label?: string;
  icon?: ReactElement;
  isIconLeft?: boolean;
  isIconRight?: boolean;
  variant?: "primary" | "delete" | "add" | "transparent" | "secondary";
  className?: string;
  iconSize?: "big";
  as?: string;
  href?: string;
  ref?: React.RefObject<any>;
};

export const GButton = ({
  children,
  variant = "primary",
  isIconLeft = true,
  isIconRight,
  label,
  icon,
  type,
  className,
  iconSize,
  as,
  href,
  ref,
  ...props
}: GButtonProps) => {
  const classNameString = () => {
    let result: string = variant;
    if (className !== undefined) result = result.concat(` ${className}`);
    if (type === "submit") result = result.concat(" submit");
    if (icon !== undefined) result = result.concat(" icon");
    if (iconSize === "big") result = result.concat(" icon-big");
    if (props.disabled) result = result.concat(" disabled");
    return result;
  };

  return (
    <Button
      {...props}
      id="gButton"
      className={classNameString()}
      as={as}
      href={href}
      ref={ref}
    >
      {isIconLeft && icon}
      {label ? label : children}
      {isIconRight && icon}
    </Button>
  );
};
