import { useEffect, useState } from "react";

import { GInput } from "components/genericComponents/GInput/GInput";

import "components/NutritionPage/AddOrEditDiet/AddOrEditDiet.scss";
import "components/NutritionPage/DietPage/DietPage.scss";
import { GButton } from "components/genericComponents/GButton/GButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createDiet,
  fetchDiet,
  selectConfirmationMessage,
  selectDiet,
  selectErrorMessage,
  selectIsLoading as selectIsDietLoading,
  setAllIngredientsInADiet,
  setConfirmationMessage,
  setDiet,
  setErrorMessage,
  updateDiet,
} from "slices/dietSlice";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { DietMeal } from "../Diets/DietMeal/DietMeal";
import { SelectForm } from "components/SelectForm/SelectForm";
import { fetchUsers, selectIsLoading, selectUsers } from "slices/userSlice";
import { DEFAULT_IMAGE } from "helpers/constants";
import { Food } from "types/nutrition";
import tinycolor from "tinycolor2";
import { Meal, MealItem } from "types/diet";
import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";

export const AddOrEditDiet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dietId = useParams().id;
  const users = useSelector(selectUsers);
  const diet = useSelector(selectDiet);
  const isDietLoading = useSelector(selectIsDietLoading);
  const areUsersLoading = useSelector(selectIsLoading);
  const [selectedUser, setSelectedUser] = useState<{ id: string; title: string; image: string } | undefined>(undefined);
  const [fetchedDiet, setFetchedDiet] = useState<boolean>(false);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  useEffect(() => {
    dispatch(fetchUsers());
    if (dietId) {
      dispatch(fetchDiet(dietId));
    }
  }, [dispatch, dietId]);

  useEffect(() => {
    if (!fetchedDiet && diet.id) {
      setFetchedDiet(true);
      setSelectedUser({
        id: diet.user?.id ?? "",
        title: diet.user?.name ?? "",
        image: diet.user?.image_small ?? DEFAULT_IMAGE,
      });
      dispatch(
        setAllIngredientsInADiet(
          diet.meals.flatMap((meal: Meal) => meal.meal_items.map((item: MealItem) => ({ ...item.item as Food, weight_in_g: item.weight_in_g })))
        )
      );
    }
  }, [diet, fetchedDiet, dispatch]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "add-diet-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "add-diet-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
      navigate("/dashboard/nutrition/diets");
    }
  }, [dispatch, confirmationMessage, navigate]);

  const submitDiet = () => {
    if (diet.id) {
      const updatedDiet = {
        id: diet.id,
        title: diet.title,
        description: diet.description,
        user_id: selectedUser!.id,
        meals: diet.meals.map((meal) => ({
          id: meal.id,
          title: meal.title,
          description: meal.description,
          meal_items: meal.meal_items.map((item) => ({
            item_id: item.item.id,
            item_type: "Food",
            weight_in_g: item.weight_in_g,
          })),
        })),
      };
      dispatch(updateDiet(updatedDiet));
    } else {
      const newDiet = {
        title: diet.title,
        description: diet.description,
        user_id: selectedUser!.id,
        meals: diet.meals.map((meal) => ({
          title: meal.title,
          description: meal.description,
          meal_items: meal.meal_items.map((item) => ({
            item_type: "Food",
            item_id: item.item.id,
            weight_in_g: item.weight_in_g,
          })),
        })),
      };
      dispatch(createDiet(newDiet));
    }
  };

  return (
    <>
      {isDietLoading || areUsersLoading ? (
        <SpinnerWrapper />
      ) : (
        <div className="add-recipe-container">
          <div className="add-recipe-header">
            <h2>{diet.title ? diet.title : "Nova Dieta"}</h2>
          </div>
          <div className="info-div">
            <div className="info-div-inputs">
              <div>
                <p className="name">Nome da dieta</p>
                <GInput
                  value={diet.title}
                  onChange={(e: any) => dispatch(setDiet({ ...diet, title: e.target.value }))}
                />
              </div>
              <div>
                <p className="name">Cliente</p>
                <SelectForm
                  label="Cliente"
                  value={selectedUser}
                  hasImage
                  onChange={(e: any) => {
                    const user = users.find((user) => user.id === e.value)!;
                    setSelectedUser({
                      id: user.id,
                      title: user.name ?? "",
                      image: user.image_small ?? DEFAULT_IMAGE,
                    });
                  }}
                  options={users.map((user) => ({
                    id: user.id,
                    title: user.name ?? "",
                    image: user.image_small,
                  }))}
                />
              </div>
              <div>
                <p className="name">Descrição</p>
                <GInput
                  value={diet.description}
                  onChange={(e: any) => dispatch(setDiet({ ...diet, description: e.target.value }))}
                  as="textarea"
                />
              </div>
              <div className="totals-div">
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#FF9800").brighten()} 0%, #FF9800 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {diet.meals.length > 0
                      ? diet.meals
                          .reduce((acc, curr) => {
                            const calories = curr.meal_items.reduce(
                              (acc, curr) => acc + (curr.item as Food).calories,
                              0
                            );
                            return acc + calories;
                          }, 0)
                          .toFixed(0)
                      : 0}
                    kcal
                  </p>
                  <p className="totals-div-info-container-name">Calorias</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#4CAF50").brighten()} 0%, #4CAF50 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {diet.meals.length > 0
                      ? diet.meals
                          .reduce((acc, curr) => {
                            const protein = curr.meal_items.reduce((acc, curr) => acc + (curr.item as Food).protein, 0);
                            return acc + protein;
                          }, 0)
                          .toFixed(0)
                      : 0}
                    g
                  </p>
                  <p className="totals-div-info-container-name">Proteína</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#9C27B0").brighten()} 0%, #9C27B0 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {diet.meals.length > 0
                      ? diet.meals
                          .reduce((acc, curr) => {
                            const carbs = curr.meal_items.reduce((acc, curr) => acc + (curr.item as Food).carbs, 0);
                            return acc + carbs;
                          }, 0)
                          .toFixed(0)
                      : 0}
                    g
                  </p>
                  <p className="totals-div-info-container-name">Hidratos</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#03A9F4").brighten()} 0%, #03A9F4 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {diet.meals.length > 0
                      ? diet.meals
                          .reduce((acc, curr) => {
                            const lipids = curr.meal_items.reduce((acc, curr) => acc + (curr.item as Food).lipids, 0);
                            return acc + lipids;
                          }, 0)
                          .toFixed(0)
                      : 0}
                    g
                  </p>
                  <p className="totals-div-info-container-name">Lípidos</p>
                </div>
              </div>
            </div>
          </div>
          {diet.meals.map((meal, index) => (
            <DietMeal key={index} index={index} isEditing={true} />
          ))}
          <div className="add-meal-button-div">
            <div className="submit-button-div">
              <GButton
                label="Adicionar Refeição"
                onClick={() =>
                  dispatch(
                    setDiet({
                      ...diet,
                      meals: [
                        ...diet.meals,
                        {
                          title: `Refeição ${diet.meals.length + 1}`,
                          description: "",
                          meal_items: [],
                          totals: {
                            weight_in_g: 0,
                            calories: 0,
                            lipids: 0,
                            water: 0,
                            carbs: 0,
                            protein: 0,
                          },
                        },
                      ],
                    })
                  )
                }
              />
            </div>
          </div>
          {diet.meals.length > 0 && diet.title && selectedUser && (
            <div className="diet-submit-button-div">
              <div className="submit-button-div">
                <GButton label="Submeter" variant="primary" onClick={submitDiet} />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
