import { Col, Container, Row } from "react-bootstrap";

import exercisesPage from "../../../assets/ExercisesPage.png";

import "./ExerciseLibrary.css";
import { useTranslation } from "react-i18next";

export const ExerciseLibrary = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%" }} id="exercise-library">
      <Container className="el-container">
        <Row>
          <Col className="el-title-col">
            <h1 className="el-title">
              {t("landingPage.exerciseLibrary.title")}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="el-col">
            <img src={exercisesPage} className="el-image" alt="" />
          </Col>
          <Col lg={6} className="el-col">
            <p>{t("landingPage.exerciseLibrary.description")}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
