import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Notification } from "../types/notification";

type NotificationState = {
  notifications: Array<Notification>;
};

const initialState: NotificationState = {
  notifications: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    fetchNotifications(state: NotificationState) {},
    fetchNotificationsSucceeded(
      state: NotificationState,
      action: PayloadAction<Array<Notification>>
    ) {
      state.notifications = action.payload;
    },
    deleteNotification(
      state: NotificationState,
      action: PayloadAction<string>
    ) {},
    deleteNotificationSucceeded(
      state: NotificationState,
      action: PayloadAction<string>
    ) {
      const index = state.notifications.findIndex(
        (notification: Notification) => notification.id === action.payload
      );

      state.notifications.splice(index, 1);
    },
  },
});

export const {
  fetchNotifications,
  fetchNotificationsSucceeded,
  deleteNotification,
  deleteNotificationSucceeded,
} = notificationSlice.actions;

export const selectNotifications = (state: RootState) =>
  state.notificationSlice.notifications;

export default notificationSlice.reducer;
