import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchExercisesSucceeded,
  CreateExerciseType,
  UpdateExerciseType,
  createExerciseSucceeded,
  updateExerciseSucceeded,
  deleteExerciseSucceeded,
  setErrorMessage,
} from "../../slices/exerciseSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchExercisesRequest,
  createExerciseRequest,
  updateExerciseRequest,
  deleteExerciseRequest,
  ExerciseRequestResponseType,
} from "../../requests/exerciseRequests";

export function* fetchExercisesSaga(
  action: any,
  request = fetchExercisesRequest
) {
  const response: ExerciseRequestResponseType = yield call(request);

  if (response.exercises !== undefined) {
    yield put(fetchExercisesSucceeded(response.exercises));
  }
}

export function* createExerciseSaga(
  action: PayloadAction<CreateExerciseType>,
  request = createExerciseRequest
) {
  const response: ExerciseRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createExerciseSucceeded(response.exercise!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateExerciseSaga(
  action: PayloadAction<UpdateExerciseType>,
  request = updateExerciseRequest
) {
  const response: ExerciseRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateExerciseSucceeded(response.exercise!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deleteExerciseSaga(
  action: PayloadAction<string>,
  request = deleteExerciseRequest
) {
  const response: ExerciseRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteExerciseSucceeded(action.payload));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
