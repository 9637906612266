import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Chat, ChatMessage } from "../types/chat";
import { UserImageType } from "./userSlice";

type ChatState = {
  chats: Array<Chat>;
  currentChat: Chat | undefined;
  isLoading: boolean;
  error: string | undefined;
};

export type CreateMessageType = {
  chat_id: string;
  message: string | null;
  image: UserImageType | null | undefined;
};

const initialState: ChatState = {
  chats: [],
  currentChat: undefined,
  isLoading: false,
  error: undefined,
};

const chatSlice = createSlice({
  name: "chat",
  initialState: initialState,
  reducers: {
    createChatMessage(
      state: ChatState,
      action: PayloadAction<CreateMessageType>
    ) {},
    createChatMessageSucceeded(
      state: ChatState,
      action: PayloadAction<ChatMessage>
    ) {
      const message = {
        ...action.payload,
        created_at: new Date().toISOString(),
      };

      const index = state.chats.findIndex(
        (chat: Chat) => chat.id === action.payload.chat_id
      );

      if (state.chats[index].messages === undefined) {
        state.chats[index].messages = [message];
      } else {
        state.chats[index].messages =
          state.chats[index].messages.concat(message);
      }

      state.chats[index].last_message = message;
      state.chats[index].messages_to_read_count = 0;

      if (index !== 0) {
        const changed_chat = state.chats[index];
        const first_chat = state.chats[0];

        state.chats[0] = changed_chat;
        state.chats[index] = first_chat;
      }

      if (state.currentChat!.messages === undefined) {
        state.currentChat!.messages = [message];
      } else {
        state.currentChat!.messages =
          state.currentChat!.messages.concat(message);
      }
    },
    fetchChats(state: ChatState) {},
    fetchChatsSucceeded(state: ChatState, action: PayloadAction<Array<Chat>>) {
      state.chats = action.payload;
    },
    fetchChatMessages(state: ChatState, action: PayloadAction<string>) {},
    fetchChatMessagesSucceeded(
      state: ChatState,
      action: PayloadAction<Array<ChatMessage>>
    ) {
      if (action.payload.length < 1) return;

      const index = state.chats.findIndex(
        (chat: Chat) => chat.id === action.payload[0].chat_id
      );

      state.chats[index].messages = action.payload;

      if (state.currentChat === undefined) {
        state.currentChat = state.chats[index];
      }

      state.currentChat!.messages = action.payload;
    },
    setCurrentChat(state: ChatState, action: PayloadAction<Chat>) {
      state.currentChat = action.payload;
    },
    readChatMessage(state: ChatState, action: PayloadAction<string>) {},
    readChatMessageSucceeded(state: ChatState, action: PayloadAction<string>) {
      const index = state.chats.findIndex(
        (chat: Chat) => chat.id === action.payload
      );

      state.chats[index].messages_to_read_count = 0;
    },
    addMessageToChat(state: ChatState, action: PayloadAction<ChatMessage>) {
      const index = state.chats.findIndex(
        (chat: Chat) => chat.id === action.payload.chat_id
      );

      if (index < 0) return;

      if (state.chats[index].messages !== undefined) {
        state.chats[index].messages = state.chats[index].messages.concat(
          action.payload
        );
      } else {
        state.chats[index].messages = [action.payload];
      }

      state.chats[index].last_message = action.payload;

      if (state.currentChat?.id === action.payload.chat_id) {
        if (state.currentChat.messages !== undefined) {
          state.currentChat.messages = state.currentChat.messages.concat(
            action.payload
          );
        } else {
          state.currentChat.messages = [action.payload];
        }
      }

      //if (state.currentChat?.id !== action.payload.chat_id) {
      state.chats[index].messages_to_read_count =
        state.chats[index].messages_to_read_count + 1;
      //}

      if (index !== 0) {
        const changed_chat = state.chats[index];
        const first_chat = state.chats[0];

        state.chats[0] = changed_chat;
        state.chats[index] = first_chat;
      }
    },
  },
});

export const {
  createChatMessage,
  createChatMessageSucceeded,
  fetchChats,
  fetchChatsSucceeded,
  fetchChatMessages,
  fetchChatMessagesSucceeded,
  setCurrentChat,
  addMessageToChat,
  readChatMessage,
  readChatMessageSucceeded,
} = chatSlice.actions;

export const selectChats = (state: RootState) => state.chatSlice.chats;
export const selectCurrentChat = (state: RootState) =>
  state.chatSlice.currentChat;

export default chatSlice.reducer;
