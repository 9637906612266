import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { TableComponent } from "../../TableComponent/TableComponent";
import { MonthPicker } from "../../MonthPicker/MonthPicker";
import { Container } from "react-bootstrap";
import {
  fetchUserCourseScheduleCounters,
  selectUserCourseScheduleCounters,
} from "../../../slices/courseScheduleSlice";
import "./UserSchedulesChart.css";

export const UserSchedulesChart = () => {
  const dispatch = useDispatch();
  const counters = useSelector(selectUserCourseScheduleCounters);
  const [date, setDate] = useState<Date>(moment().toDate());
  const isGymView = localStorage.getItem("isGymView") === "true";

  const tableColumns = React.useMemo(() => {
    if (isGymView) {
      return [
        {
          Header: "Cliente",
          accessor: "id",
          minWidth: "150px",
          maxWidth: "150px",
          Cell: (props: any) => {
            return props.cell.row.original.user_name;
          },
        },
        {
          Header: "Aulas",
          accessor: "course_schedules_count",
        },
        {
          Header: "Marcações com o PT",
          accessor: "schedules_count",
        },
      ];
    } else {
      return [
        {
          Header: "Cliente",
          accessor: "user_name",
          minWidth: "150px",
          maxWidth: "150px",
        },
        {
          Header: "Marcações com o PT",
          accessor: "schedules_count",
        },
      ];
    }
  }, [isGymView]);

  useEffect(() => {
    const startAt = moment(date).startOf("month").toDate().toISOString();
    const endAt = moment(date).endOf("month").toDate().toISOString();

    dispatch(
      fetchUserCourseScheduleCounters({ start_at: startAt, end_at: endAt })
    );
  }, [dispatch, date]);

  return (
    <Container style={{ flex: 1 }}>
      <div className="courses-chart-container">
        <h4 style={{ textAlign: "center", marginTop: 10 }}>
          {" "}
          Marcações dos alunos
        </h4>
        <MonthPicker date={date} setDate={setDate} />
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          <TableComponent
            name="courses"
            className=""
            columns={tableColumns}
            data={counters}
            showRemoveButton={false}
            rowClickAction={(row: any) => {}}
          />
        </div>
      </div>
    </Container>
  );
};
