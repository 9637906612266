import { axiosRequest, errorMessage } from "./request";
import { Notification } from "../types/notification";

export type NotificationRequestResponseType = {
  succeeded: boolean;
  notifications: Array<Notification>;
  errorMessage: string | undefined;
};

export const fetchNotificationsRequest =
  async (): Promise<NotificationRequestResponseType> => {
    try {
      const response = await axiosRequest().get("notifications");
      const notifications = response.data as Array<Notification>;

      return {
        succeeded: true,
        notifications: notifications,
      } as NotificationRequestResponseType;
    } catch (e) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as NotificationRequestResponseType;
    }
  };

export const deleteNotificationRequest = async (
  notificationId: string
): Promise<NotificationRequestResponseType> => {
  try {
    await axiosRequest().delete(`notifications/${notificationId}`);

    return {
      succeeded: true,
    } as NotificationRequestResponseType;
  } catch (e) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NotificationRequestResponseType;
  }
};
