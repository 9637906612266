import { PayloadAction } from "@reduxjs/toolkit";
import {
  CreateMessageType,
  createChatMessageSucceeded,
  fetchChatsSucceeded,
  fetchChatMessagesSucceeded,
  readChatMessageSucceeded,
} from "../../slices/chatSlice";
import { put, call } from "redux-saga/effects";
import {
  ChatResponseType,
  createChatMessageRequest,
  fetchChatsRequest,
  fetchChatMessagesRequest,
  readMessagesRequest,
} from "../../requests/chatRequests";

export function* createChatMessageSaga(
  action: PayloadAction<CreateMessageType>,
  request = createChatMessageRequest
) {
  const response: ChatResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(createChatMessageSucceeded(response.message!));
  } else {
    // TODO
  }
}

export function* readMessagesSaga(
  action: PayloadAction<string>,
  request = readMessagesRequest
) {
  const response: ChatResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(readChatMessageSucceeded(action.payload));
  } else {
    // TODO
  }
}

export function* fetchChatsSaga(action: any, request = fetchChatsRequest) {
  const response: ChatResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchChatsSucceeded(response.chats!));
  } else {
    // TODO
  }
}

export function* fetchChatMessagesSaga(
  action: PayloadAction<string>,
  request = fetchChatMessagesRequest
) {
  const response: ChatResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(fetchChatMessagesSucceeded(response.messages!));
  } else {
    // TODO
  }
}
