import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchProductTransactionsSucceeded,
  createProductTransactionSucceeded,
  updateProductTransactionSucceeded,
  deleteProductTransactionSucceeded,
  setErrorMessage,
  CreateOrUpdateProductTransactionType,
} from "../../slices/productTransactionSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchProductTransactionsRequest,
  createProductTransactionRequest,
  updateProductTransactionRequest,
  deleteProductTransactionRequest,
  ProductTransactionRequestResponseType,
} from "../../requests/productTransactionRequests";

export function* fetchProductTransactionsSaga(
  action: any,
  request = fetchProductTransactionsRequest
) {
  const response: ProductTransactionRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchProductTransactionsSucceeded(response.productTransactions!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createProductTransactionSaga(
  action: PayloadAction<CreateOrUpdateProductTransactionType>,
  request = createProductTransactionRequest
) {
  const response: ProductTransactionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createProductTransactionSucceeded(response.productTransaction!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateProductTransactionSaga(
  action: PayloadAction<CreateOrUpdateProductTransactionType>,
  request = updateProductTransactionRequest
) {
  const response: ProductTransactionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateProductTransactionSucceeded(response.productTransaction!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deleteProductTransactionSaga(
  action: PayloadAction<string>,
  request = deleteProductTransactionRequest
) {
  const response: ProductTransactionRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteProductTransactionSucceeded(action.payload));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
