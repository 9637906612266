import { takeLatest } from "redux-saga/effects";
import {
  fetchWorkoutsHistory,
  createWorkoutHistory,
} from "../../slices/workoutHistorySlice";
import {
  fetchWorkoutsHistorySaga,
  createWorkoutsHistorySaga,
} from "./workoutHistorySaga";

function* workoutHistorySaga() {
  yield takeLatest(fetchWorkoutsHistory, fetchWorkoutsHistorySaga);
  yield takeLatest(createWorkoutHistory, createWorkoutsHistorySaga);
}

export default workoutHistorySaga;
