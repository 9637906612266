export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const getHours = (date: Date): string => {
  const hours = date.getHours();

  if (hours < 10) {
    return `0${hours}`;
  } else {
    return `${hours}`;
  }
};

export const getMinutes = (date: Date): string => {
  const minutes = date.getMinutes();

  if (minutes < 10) {
    return `0${minutes}`;
  } else {
    return `${minutes}`;
  }
};

export const getYearAndMonthAndDay = (date: Date) => {
  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];
  const day = date.getDate();

  return `${day} ${month} ${year}`;
};

export const getMonthAndYearForDate = (date: Date) => {
  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];

  return `${month} ${year}`;
};

export const getMonthAndYear = (date: string) => {
  const year = date.slice(0, 4);
  const month = monthNames[Number.parseInt(date.slice(6, 8)) - 1];

  return `${month} ${year}`;
};

export const GREETING_FROM_TIME_OF_DAY = ["Bom Dia", "Boa Tarde", "Boa Noite"];

const monthNames = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const secondsToTime = (val: string) => {
  const seconds = Number.parseInt(val);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const calculateAge = (birthday: Date | null): number => {
  if (!birthday) return 0;
  const today = new Date();
  const birthDate = new Date(birthday);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const isMonthDayInRange = (
  date: Date,
  from: Date,
  to: Date
): boolean => {
  // Create a new date for the year of the `date` parameter to avoid year comparison.
  const checkDate = new Date(date);
  const fromDate = new Date(from);
  const toDate = new Date(to);

  // Set the year of the from and to dates to the check date's year to compare only month and day.
  fromDate.setFullYear(checkDate.getFullYear());
  toDate.setFullYear(checkDate.getFullYear());

  // If the from date is after the to date, it means the range spans over the new year.
  if (fromDate > toDate) {
    // Check if the date is in the range from the fromDate to the end of the year
    // or from the start of the year to the toDate.
    return checkDate >= fromDate || checkDate <= toDate;
  } else {
    // The normal case where the range is within the same year.
    return checkDate >= fromDate && checkDate <= toDate;
  }
};
