import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Exercise } from "../types/exercise";
import {
  exerciseSuccessfullyCreated,
  exerciseSuccessfullyUpdated,
  exerciseSuccessfullyDeleted,
} from "../helpers/messages";

interface ExerciseState {
  currentExercise: Exercise | undefined;
  exercises: Array<Exercise>;
  isLoading: boolean;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
}

export type CreateExerciseType = {
  title: string;
  description: string | null;
  video_url: string | null;
  muscule_groups: Array<string>;
  exercise_category_id: string | null;
  image: any;
};

export type UpdateExerciseType = {
  id: string;
  title: string;
  description: string | null;
  video_url: string | null;
  muscule_groups: Array<string>;
  exercise_category_id: string | null;
  image: any;
};

const initialState: ExerciseState = {
  currentExercise: undefined,
  exercises: [],
  isLoading: false,
  errorMessage: undefined,
  confirmationMessage: undefined,
};

const exerciseSlice = createSlice({
  name: "exercise",
  initialState: initialState,
  reducers: {
    fetchExercises(state: ExerciseState) {
      state.isLoading = true;
    },
    fetchExercisesSucceeded(
      state: ExerciseState,
      action: PayloadAction<Array<Exercise>>
    ) {
      state.exercises = action.payload;
      state.isLoading = false;
    },
    createExercise(
      state: ExerciseState,
      action: PayloadAction<CreateExerciseType>
    ) {},
    createExerciseSucceeded(
      state: ExerciseState,
      action: PayloadAction<Exercise>
    ) {
      return {
        ...state,
        confirmationMessage: exerciseSuccessfullyCreated,
        exercises: [action.payload, ...state.exercises],
      };
    },
    updateExercise(
      state: ExerciseState,
      action: PayloadAction<UpdateExerciseType>
    ) {},
    updateExerciseSucceeded(
      state: ExerciseState,
      action: PayloadAction<Exercise>
    ) {
      const index = state.exercises.findIndex(
        (exercise: Exercise) => exercise.id === action.payload.id
      );

      state.exercises[index] = action.payload;
      state.confirmationMessage = exerciseSuccessfullyUpdated;
    },
    deleteExercise(state: ExerciseState, action: PayloadAction<string>) {},
    deleteExerciseSucceeded(
      state: ExerciseState,
      action: PayloadAction<string>
    ) {
      const index = state.exercises.findIndex(
        (exercise: Exercise) => exercise.id === action.payload
      );

      state.exercises.splice(index, 1);
      state.confirmationMessage = exerciseSuccessfullyDeleted;
    },
    setCurrentExercise(
      state: ExerciseState,
      action: PayloadAction<string | undefined>
    ) {
      state.currentExercise = state.exercises.find(
        (exercise: Exercise) => action.payload === exercise.id
      );
    },
    setErrorMessage(
      state: ExerciseState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: ExerciseState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
  },
});

export const {
  fetchExercises,
  fetchExercisesSucceeded,
  createExercise,
  createExerciseSucceeded,
  updateExercise,
  updateExerciseSucceeded,
  deleteExercise,
  deleteExerciseSucceeded,
  setErrorMessage,
  setConfirmationMessage,
  setCurrentExercise,
} = exerciseSlice.actions;

export const selectExercises = (state: RootState) =>
  state.exerciseSlice.exercises;
export const selectIsLoading = (state: RootState) =>
  state.exerciseSlice.isLoading;
export const selectCurrentExercise = (state: RootState) =>
  state.exerciseSlice.currentExercise;
export const selectErrorMessage = (state: RootState) =>
  state.exerciseSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.exerciseSlice.confirmationMessage;

export default exerciseSlice.reducer;
