import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { GymRoom } from "../types/gymRoom";
import { gymRoomSuccessfullyUpdated } from "../helpers/messages";

interface GymRoomState {
  gymRooms: Array<GymRoom>;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
}

export type SetGymRoomsType = {
  id: string | null;
  title: string;
};

const initialState: GymRoomState = {
  gymRooms: [],
  errorMessage: undefined,
  confirmationMessage: undefined,
};

const gymRoomSlice = createSlice({
  name: "gymRoom",
  initialState: initialState,
  reducers: {
    fetchGymRooms(state: GymRoomState) {},
    fetchGymRoomsSucceeded(
      state: GymRoomState,
      action: PayloadAction<Array<GymRoom>>
    ) {
      state.gymRooms = action.payload;
    },
    setGymRooms(
      state: GymRoomState,
      action: PayloadAction<Array<SetGymRoomsType>>
    ) {},
    setGymRoomsSucceeded(
      state: GymRoomState,
      action: PayloadAction<Array<GymRoom>>
    ) {
      state.gymRooms = action.payload;
      state.confirmationMessage = gymRoomSuccessfullyUpdated;
    },
    setErrorMessage(
      state: GymRoomState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: GymRoomState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
  },
});

export const {
  fetchGymRooms,
  fetchGymRoomsSucceeded,
  setGymRooms,
  setGymRoomsSucceeded,
  setErrorMessage,
  setConfirmationMessage,
} = gymRoomSlice.actions;

export const selectGymRooms = (state: RootState) => state.gymRoomSlice.gymRooms;
export const selectErrorMessage = (state: RootState) =>
  state.gymRoomSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.gymRoomSlice.confirmationMessage;

export default gymRoomSlice.reducer;
