import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Course } from "../types/course";
import {
  courseSuccessfullyCreated,
  courseSuccessfullyUpdated,
  courseSuccessfullyDeleted,
} from "../helpers/messages";

export type CourseImageType = {
  id: string;
  storage: string;
  metadata: {
    size: number;
    mime_type: string;
  };
};

export type CreateCourseType = {
  title: string;
  description: string;
  image: CourseImageType | null;
  color: string | null;
};

export type UpdateCourseType = {
  id: string;
  title: string | null;
  description: string | null;
  image: CourseImageType | null;
  color: string | null;
};

type CourseState = {
  courses: Array<Course>;
  currentCourse: Course | undefined;
  isLoading: boolean;
  error: string | undefined;
  confirmationMessage: string | undefined;
};

const initialState: CourseState = {
  courses: [],
  currentCourse: undefined,
  isLoading: false,
  error: undefined,
  confirmationMessage: undefined,
};

const courseSlice = createSlice({
  name: "course",
  initialState: initialState,
  reducers: {
    fetchCourses(state: CourseState) {
      state.isLoading = true;
    },
    fetchCoursesSucceeded(
      state: CourseState,
      action: PayloadAction<Array<Course>>
    ) {
      state.courses = action.payload;
      state.isLoading = false;
    },
    createCourse(
      state: CourseState,
      action: PayloadAction<CreateCourseType>
    ) {},
    createCourseSucceeded(state: CourseState, action: PayloadAction<Course>) {
      return {
        ...state,
        confirmationMessage: courseSuccessfullyCreated,
        courses: [action.payload, ...state.courses],
      };
    },
    updateCourse(
      state: CourseState,
      action: PayloadAction<UpdateCourseType>
    ) {},
    updateCourseSucceeded(state: CourseState, action: PayloadAction<Course>) {
      const index = state.courses.findIndex(
        (course: Course) => course.id === action.payload.id
      );

      state.courses[index] = action.payload;
      state.confirmationMessage = courseSuccessfullyUpdated;
    },
    deleteCourse(state: CourseState, action: PayloadAction<string>) {},
    deleteCourseSucceeded(state: CourseState, action: PayloadAction<string>) {
      const index = state.courses.findIndex(
        (course: Course) => course.id === action.payload
      );

      state.courses.splice(index, 1);
      state.confirmationMessage = courseSuccessfullyDeleted;
    },
    setCurrentCourse(
      state: CourseState,
      action: PayloadAction<string | undefined>
    ) {
      state.currentCourse = state.courses.find(
        (course: Course) => action.payload === course.id
      );
    },
    setErrorMessage(
      state: CourseState,
      action: PayloadAction<string | undefined>
    ) {
      state.error = action.payload;
    },
    setConfirmationMessage(
      state: CourseState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
    cleanErrorMessage(state: CourseState) {
      state.error = undefined;
    },
  },
});

export const {
  fetchCourses,
  fetchCoursesSucceeded,
  createCourse,
  createCourseSucceeded,
  updateCourse,
  updateCourseSucceeded,
  deleteCourse,
  deleteCourseSucceeded,
  setErrorMessage,
  setConfirmationMessage,
  setCurrentCourse,
  cleanErrorMessage,
} = courseSlice.actions;

export const selectCourses = (state: RootState) => state.courseSlice.courses;
export const selectIsLoading = (state: RootState) =>
  state.courseSlice.isLoading;
export const selectErrorMessage = (state: RootState) => state.courseSlice.error;
export const selectConfirmationMessage = (state: RootState) =>
  state.courseSlice.confirmationMessage;

export default courseSlice.reducer;
