import { axiosRequest, errorMessage } from "./request";
import { Workout } from "../types/workout";
import { WorkoutExercise } from "../types/exercise";
import { CreateOrUpdateWorkoutType } from "../slices/workoutSlice";

export type WorkoutRequestResponseType = {
  succeeded: boolean;
  workout: Workout | undefined;
  workouts: Array<Workout> | undefined;
  exercises: Array<WorkoutExercise> | undefined;
  exercise: WorkoutExercise | undefined;
  errorMessage: string | undefined;
};

export const fetchWorkoutsRequest =
  async (): Promise<WorkoutRequestResponseType> => {
    try {
      const response = await axiosRequest().get("workouts");
      const workouts: Array<Workout> = response.data.map(
        (workout: any) => workout
      );

      return {
        succeeded: true,
        workouts: workouts,
      } as WorkoutRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as WorkoutRequestResponseType;
    }
  };

export const fetchWorkoutRequest = async (
  workoutId: string
): Promise<WorkoutRequestResponseType> => {
  try {
    const response = await axiosRequest().get(`workouts/${workoutId}`);
    const workout: Workout = response.data;

    return {
      succeeded: true,
      workout: workout,
    } as WorkoutRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as WorkoutRequestResponseType;
  }
};

export const createWorkoutRequest = async (
  params: CreateOrUpdateWorkoutType
): Promise<WorkoutRequestResponseType> => {
  try {
    const response = await axiosRequest().post("workouts", {
      workout: params,
    });
    const workout: Workout = response.data;

    return { succeeded: true, workout: workout } as WorkoutRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as WorkoutRequestResponseType;
  }
};

export const duplicateWorkoutRequest = async (
  workoutId: string
): Promise<WorkoutRequestResponseType> => {
  try {
    const response = await axiosRequest().post(
      `workouts/${workoutId}/duplicate`
    );
    const workout: Workout = response.data;

    return { succeeded: true, workout: workout } as WorkoutRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as WorkoutRequestResponseType;
  }
};

export const updateWorkoutRequest = async (
  params: CreateOrUpdateWorkoutType
): Promise<WorkoutRequestResponseType> => {
  try {
    const response = await axiosRequest().put(`workouts/${params.id}`, {
      workout: params,
    });
    const workout: Workout = response.data;

    return { succeeded: true, workout: workout } as WorkoutRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as WorkoutRequestResponseType;
  }
};

export const deleteWorkoutRequest = async (
  workoutId: string
): Promise<WorkoutRequestResponseType> => {
  try {
    await axiosRequest().delete(`workouts/${workoutId}`);

    return { succeeded: true } as WorkoutRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as WorkoutRequestResponseType;
  }
};
