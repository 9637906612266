import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GInput } from "components/genericComponents/GInput/GInput";
import { debounce } from "helpers/utils";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  searchFood,
  selectFoods,
  selectIsLoading,
} from "slices/nutritionSchedulerSlice";
import { MealItem } from "types/diet";
import { Food } from "types/nutrition";
import 'components/NutritionPage/AddIngredientModal/IngredientModalLogic/IngredientModalLogic.scss'
import { selectAllIngredientsInADiet, selectDiet, setAllIngredientsInADiet, setMeal } from "slices/dietSlice";

type AddIngredientModalI = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  mealIndex: number;
};

type FoodWithSelection = Food & { isSelected: boolean };

export const IngredientModalLogic = ({
  showModal,
  setShowModal,
  mealIndex,
}: AddIngredientModalI) => {
  const dispatch = useDispatch();
  const diet = useSelector(selectDiet);
  const foods = useSelector(selectFoods);
  const isLoading = useSelector(selectIsLoading);
  const allIngredientsInADiet = useSelector(selectAllIngredientsInADiet);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedIngredients, setSearchedIngredients] = useState<FoodWithSelection[]>([]);

  useEffect(() => {
    dispatch(searchFood(null));
  }, [dispatch]);

  useEffect(() => {
    // First, create a map of ingredients with isSelected set to true
    const initialIngredients =
      diet.meals[mealIndex].meal_items?.reduce((acc: { [key: string]: FoodWithSelection }, food: MealItem) => {
        acc[food.item.id!] = { ...(food.item as Food), isSelected: true };
        return acc;
      }, {} as { [key: string]: FoodWithSelection }) ?? {};

    // Then, merge this map with the current searchedIngredients
    const mergedIngredients =
      foods?.map((ingredient) => {
        // If the ingredient exists in initialIngredients, use that, otherwise use the existing ingredient
        return initialIngredients[ingredient.id] ? initialIngredients[ingredient.id] : {
            ...ingredient,
            isSelected: false,
          };
      }) || [];

    // Finally, set the searchedIngredients state with the merged ingredients
    if (foods) {
      setSearchedIngredients(mergedIngredients);
    }
  }, [foods]); // eslint-disable-line

  useEffect(() => {
    if (!showModal) {
      setSearchQuery("");
      setSearchedIngredients([]);
    }
  }, [showModal]); // eslint-disable-line

  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        dispatch(searchFood(query));
      }, 500),
    [dispatch]
  );

  const searchIngredient = (query: string) => {
    debouncedSearch(query);
  };

  const handleIngredient = (ingredient: FoodWithSelection) => {
    if (ingredient.isSelected) {
      const mealItems = diet.meals[mealIndex].meal_items.filter((item: MealItem) => item.item.id !== ingredient.id);
      dispatch(setMeal({ meal: { ...diet.meals[mealIndex], meal_items: mealItems }, index: mealIndex }));
      setSearchedIngredients(searchedIngredients.map((ing: FoodWithSelection) => ({ ...ing, isSelected: ing.id === ingredient.id ? false : ing.isSelected })));
    } else {
      const idxOfIngredient = diet.meals[mealIndex].meal_items.findIndex((item: MealItem) => item.item.id === ingredient.id);
      const newMeal = { ...diet.meals[mealIndex] };
      newMeal.meal_items = [...(newMeal.meal_items || []), {
        id: ingredient.id,
        item: {
          ...ingredient,
          calories: 0,
          protein: 0,
          carbs: 0,
          lipids: 0,
        },
        weight_in_g: 0,
        type: 'Food',
      }];
      dispatch(setMeal({ meal: newMeal, index: mealIndex }));
      setSearchedIngredients(searchedIngredients.map((ing: FoodWithSelection) => ({ ...ing, isSelected: ing.id === ingredient.id ? true : ing.isSelected })));
      
      if (idxOfIngredient === -1) {
        dispatch(setAllIngredientsInADiet([...allIngredientsInADiet, { ...ingredient, weight_in_g: 100 }]));
      }
    }
  };

  return (
    <div className="add-ingredient-modal">
      <GInput
        autoFocus
        value={searchQuery}
        onChange={(e: any) => {
          setSearchQuery(e.target.value);
          searchIngredient(e.target.value);
        }}
        label="Pesquisar..."
      />
      {isLoading ? (
        <div className="loading-div">
          <SpinnerWrapper />
        </div>
      ) : (
        <div className="ingredient-list">
          {searchedIngredients.map((ingredient: FoodWithSelection) => {
            return (
              <GButton
                className="ingredient-button"
                key={ingredient.id}
                onClick={() => handleIngredient(ingredient)}
                variant={ingredient.isSelected ? "secondary" : "primary"}
              >
                <div className="ingredient-info">
                  <p>{ingredient.name}</p>
                </div>
              </GButton>
            );
          })}
        </div>
      )}
    </div>
  );
};
