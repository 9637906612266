import { axiosRequest, errorMessage } from "./request";
import { ChatGptChat, ChatGptMessage } from "../types/chatgpt";
import { CreateChatgptMessageType } from "../slices/chatgptSlice";

export type ChatGptResponseType = {
  succeeded: boolean;
  message: ChatGptMessage;
  chats: Array<ChatGptChat>;
  chat: ChatGptChat;
  errorMessage: string | undefined;
};

export const createChatGptMessageRequest = async (
  params: CreateChatgptMessageType
): Promise<ChatGptResponseType> => {
  try {
    const response = await axiosRequest().post("chatgpt_messages", {
      message: params.message,
      chatgpt_chat_id: params.chatgpt_chat_id,
    });

    return {
      succeeded: true,
      message: response.data,
    } as ChatGptResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ChatGptResponseType;
  }
};

export const fetchChatGptChatsRequest =
  async (): Promise<ChatGptResponseType> => {
    try {
      const response = await axiosRequest().get("chatgpt_chats");

      return {
        succeeded: true,
        chats: response.data,
      } as ChatGptResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as ChatGptResponseType;
    }
  };

export const fetchChatGptChatRequest = async (
  chatId: string
): Promise<ChatGptResponseType> => {
  try {
    const response = await axiosRequest().get(`chatgpt_chats/${chatId}`);

    return {
      succeeded: true,
      chat: response.data,
    } as ChatGptResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ChatGptResponseType;
  }
};
