import {
  CreateRecipe,
  CreateUserNutritionSchedulerItem,
  CreateUserNutritionSchedulerItemResponse,
  CreateUserNutritionSchedulerNote,
  CreateUserNutritionSchedulerNoteResponse,
  FetchUserNutritionScheduler,
  Recipe,
  RecipeOverview,
  SearchFood,
  SearchRecipe,
  UserNutritionScheduler,
} from "types/nutrition";
import { axiosRequest, errorMessage } from "requests/request";

export type NutritionRequestResponseType = {
  succeeded: boolean;
  recipes: RecipeOverview[];
  recipe: Recipe;
  userNutritionScheduler: UserNutritionScheduler[];
  foods: SearchFood[];
  searchRecipes: SearchRecipe[];
  createUserNutritionSchedulerNote: CreateUserNutritionSchedulerNoteResponse;
  createUserNutritionSchedulerItem: CreateUserNutritionSchedulerItemResponse;
  errorMessage: string | undefined;
};

export const fetchRecipesRequest =
  async (): Promise<NutritionRequestResponseType> => {
    try {
      const response = await axiosRequest().get("recipes");

      return {
        succeeded: true,
        recipes: response.data,
      } as NutritionRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as NutritionRequestResponseType;
    }
  };

export const fetchRecipeRequest = async (
  recipeId: string
): Promise<NutritionRequestResponseType> => {
  try {
    const response = await axiosRequest().get(`recipes/${recipeId}`);

    return {
      succeeded: true,
      recipe: response.data,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const createRecipeRequest = async (
  recipe: CreateRecipe
): Promise<NutritionRequestResponseType> => {
  try {
    const response = await axiosRequest().post(`recipes`, { recipe });

    return {
      succeeded: true,
      recipe: response.data,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const updateRecipeRequest = async (
  recipeId: string,
  payload: CreateRecipe
): Promise<NutritionRequestResponseType> => {
  try {
    const response = await axiosRequest().put(`recipes/${recipeId}`, {
      recipe: payload,
    });

    return {
      succeeded: true,
      recipe: response.data,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const deleteRecipeRequest = async (
  recipeId: string
): Promise<NutritionRequestResponseType> => {
  try {
    await axiosRequest().delete(`recipes/${recipeId}`);

    return { succeeded: true } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const fetchUserNutritionSchedulerRequest = async (
  params: FetchUserNutritionScheduler
): Promise<NutritionRequestResponseType> => {
  try {
    const response = await axiosRequest().get(`food_scheduler`, {
      params: {
        user_id: params.user_id,
        from: params.from,
        to: params.to,
      },
    });

    return {
      succeeded: true,
      userNutritionScheduler: response.data,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const createUserNutritionSchedulerNoteRequest = async (
  params: CreateUserNutritionSchedulerNote
): Promise<NutritionRequestResponseType> => {
  try {
    await axiosRequest().post(`food_scheduler_notes`, {
      note: {
        user_id: params.user_id,
        note: params.note,
        date: params.date,
      },
    });

    return {
      succeeded: true,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const updateUserNutritionSchedulerNoteRequest = async (
  noteId: string,
  params: CreateUserNutritionSchedulerNote
): Promise<NutritionRequestResponseType> => {
  try {
    const response = await axiosRequest().put(
      `food_scheduler_notes/${noteId}`,
      params
    );

    return {
      succeeded: true,
      userNutritionScheduler: response.data,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const deleteUserNutritionSchedulerNoteRequest = async (
  noteId: string
): Promise<NutritionRequestResponseType> => {
  try {
    await axiosRequest().delete(`food_scheduler_notes/${noteId}`);

    return {
      succeeded: true,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const createUserNutritionSchedulerItemRequest = async (
  params: CreateUserNutritionSchedulerItem
): Promise<NutritionRequestResponseType> => {
  try {
    await axiosRequest().post(`food_scheduler_items`, {
      item: params,
    });

    return {
      succeeded: true,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const updateUserNutritionSchedulerItemRequest = async (
  itemId: string,
  params: CreateUserNutritionSchedulerItem
): Promise<NutritionRequestResponseType> => {
  try {
    await axiosRequest().put(`food_scheduler_items/${itemId}`, {
      item: params,
    });

    return {
      succeeded: true,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const deleteUserNutritionSchedulerItemRequest = async (
  itemId: string
): Promise<NutritionRequestResponseType> => {
  try {
    await axiosRequest().delete(`food_scheduler_items/${itemId}`);

    return {
      succeeded: true,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const searchFoodRequest = async (
  search: string
): Promise<NutritionRequestResponseType> => {
  try {
    const response = await axiosRequest().get("food", {
      params: { search: search },
    });

    return {
      succeeded: true,
      foods: response.data,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};

export const searchRecipesRequest = async (
  search: string
): Promise<NutritionRequestResponseType> => {
  try {
    const response = await axiosRequest().get("recipes", {
      params: { search: search },
    });

    return {
      succeeded: true,
      searchRecipes: response.data,
    } as NutritionRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as NutritionRequestResponseType;
  }
};
