import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { Loader } from "@chatscope/chat-ui-kit-react";
import { toast } from "react-toastify";

import { TableComponent } from "components/TableComponent/TableComponent";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";

import { AddOrEditProduct } from "components/ProductsPage/ProductsSection/AddOrEditProduct/AddOrEditProduct";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateOrUpdateProductType,
  deleteProduct,
  selectConfirmationMessage,
  selectErrorMessage,
  selectIsLoading,
  selectProducts,
  setConfirmationMessage,
  setErrorMessage,
} from "slices/productSlice";

import "components/ProductsPage/ProductsSection/ProductsSection.scss";
import { ProductsFilters } from "./ProductsFilters/ProductsFilters";
import { Product } from "types/product";

export const ProductsSection = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const isLoading = useSelector(selectIsLoading);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [showAddProduct, setShowAddProduct] = useState<boolean>(false);
  const [product, setProduct] = useState<CreateOrUpdateProductType | undefined>(
    undefined
  );
  const [productFilter, setProductFilter] = useState<string | undefined>(
    undefined
  );
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);

  const tableColumns = React.useMemo(
    () => [
      {
        Header: "Título",
        accessor: "title",
      },
      {
        Header: "Preço de custo (€)",
        accessor: "purchase_price",
      },
      {
        Header: "Preço de venda (€)",
        accessor: "sale_price",
      },
      {
        Header: "IVA (%)",
        accessor: "tax",
      },
      {
        Header: "Despesas (€)",
        accessor: "other_costs",
      },
    ],
    []
  );

  const handleClose = () => {
    setShowAddProduct(false);
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "products-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "products-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  useEffect(() => {
    if (productFilter === undefined) setFilteredProducts(products);
    else
      setFilteredProducts(
        products.filter((product) => product.title.includes(productFilter))
      );
  }, [productFilter, products]);

  return (
    <>
      {isLoading ? (
        <Loader className="chat-messages-loader full-screen" />
      ) : showAddProduct ? (
        <AddOrEditProduct handleClose={handleClose} product={product} />
      ) : (
        <div>
          <ProductsFilters
            productFilter={productFilter}
            onProductChange={setProductFilter}
          />
          <GSection
            label=" "
            isSpacedBetween
            renderButtons={[
              <GButton
                variant="add"
                onClick={() => {
                  setProduct(undefined);
                  setShowAddProduct(true);
                }}
              >
                <MdAdd />
              </GButton>,
            ]}
          >
            {products.length > 0 ? (
              <TableComponent
                name={""}
                columns={tableColumns}
                data={filteredProducts}
                removeAction={(id: string) => {
                  dispatch(deleteProduct(id));
                }}
                showRemoveButton
                rowClickAction={(row: any) => {
                  setProduct(row.original);
                  setShowAddProduct(true);
                }}
              />
            ) : (
              <p style={{ textAlign: "center" }}>
                Não possui produtos. Adicione novos produtos, clicando no botão.
              </p>
            )}
          </GSection>
        </div>
      )}
    </>
  );
};
