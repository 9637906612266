import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchProductsSucceeded,
  createProductSucceeded,
  updateProductSucceeded,
  deleteProductSucceeded,
  fetchProductRevenueSucceeded,
  setErrorMessage,
  CreateOrUpdateProductType,
  FetchProductRevenueType,
} from "../../slices/productSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchProductsRequest,
  createProductRequest,
  updateProductRequest,
  deleteProductRequest,
  fetchProductRevenueRequest,
  ProductRequestResponseType,
} from "../../requests/productRequests";

export function* fetchProductsSaga(
  action: any,
  request = fetchProductsRequest
) {
  const response: ProductRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchProductsSucceeded(response.products!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createProductSaga(
  action: PayloadAction<CreateOrUpdateProductType>,
  request = createProductRequest
) {
  const response: ProductRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createProductSucceeded(response.product!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateProductSaga(
  action: PayloadAction<CreateOrUpdateProductType>,
  request = updateProductRequest
) {
  const response: ProductRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateProductSucceeded(response.product!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deleteProductSaga(
  action: PayloadAction<string>,
  request = deleteProductRequest
) {
  const response: ProductRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteProductSucceeded(action.payload));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchProductRevenueSaga(
  action: PayloadAction<FetchProductRevenueType>,
  request = fetchProductRevenueRequest
) {
  const response: ProductRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchProductRevenueSucceeded(response.productRevenue!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
