import { pt } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import logo from "../../assets/logo.svg";
import { getYearAndMonthAndDay } from "../../helpers/date";
import {
  selectConfirmationMessage,
  selectErrorMessage,
  setConfirmationMessage,
  setErrorMessage,
} from "../../slices/courseScheduleSlice";
import {
  createWorkoutSchedules,
  deleteWorkoutSchedule,
  updateWorkoutSchedules,
} from "../../slices/workoutScheduleSlice";
import { Workout } from "../../types/workout";
import { WorkoutSchedule } from "../../types/workoutSchedule";

type WorkoutScheduleModalType = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  startAt: Date | undefined;
  selectedWorkoutSchedule: WorkoutSchedule | undefined;
  setSelectedWorkoutSchedule: (value: WorkoutSchedule | undefined) => void;
  workouts: Array<Workout>;
};

export const WorkoutScheduleModal = (props: WorkoutScheduleModalType) => {
  const dispatch = useDispatch();
  const [startAt, setStartAt] = useState<any | null>(null);
  const [workout, setWorkout] = useState<Workout | null>(
    props.selectedWorkoutSchedule?.workout ?? null
  );
  const [workoutErrorMessage, setWorkoutErrorMessage] = useState<
    string | undefined
  >(undefined);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [showDayPicker, setShowDayPicker] = useState(false);

  useEffect(() => {
    setStartAt(props.startAt ?? null);
    setWorkout(props.selectedWorkoutSchedule?.workout ?? null);
  }, [props.selectedWorkoutSchedule, props.startAt]);

  const onSubmitButtonClick = (event: any) => {
    var isValid = true;

    if (!isWorkoutValid()) isValid = false;

    if (!isValid) return;

    if (props.selectedWorkoutSchedule === undefined) {
      dispatch(
        createWorkoutSchedules({
          startAt: startAt!,
          workoutId: workout!.id,
        })
      );
    } else {
      dispatch(
        updateWorkoutSchedules({
          id: props.selectedWorkoutSchedule.id,
          startAt: startAt!,
          workoutId: workout!.id,
        })
      );
    }
  };

  const isWorkoutValid = (): boolean => {
    if (workout === null) {
      setWorkoutErrorMessage("Tem de inserir um plano de treino");
      return false;
    }

    setWorkoutErrorMessage(undefined);
    return true;
  };

  const onDeleteButtonClick = (event: any) => {
    dispatch(deleteWorkoutSchedule(props.selectedWorkoutSchedule!.id));
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "workout-schedule-modal-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "workout-schedule-modal-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));

      setStartAt(null);
      props.setShowModal(false);
      props.setSelectedWorkoutSchedule(undefined);
    }
  }, [dispatch, confirmationMessage, props]);

  return (
    <Modal
      className="modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={() => {
        props.setShowModal(false);
        props.setSelectedWorkoutSchedule(undefined);
      }}
    >
      <Modal.Body>
        <Row className="modal-flex-center">
          <img className="logo" src={logo} alt="" />
        </Row>
        <p className="modal-title">
          {props.selectedWorkoutSchedule === undefined
            ? "Criar Marcação"
            : "Editar Marcação"}
        </p>
        <Form onSubmit={onSubmitButtonClick}>
          <Form.Group className="mb-3 form-floating-label workout-modal-user-group">
            <Select
              placeholder="Plano de treino"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: "calc(3.5rem + 2px)",
                  border: "1px solid #ced4da",
                  borderRadius: "0.375rem",
                }),
              }}
              value={
                workout === null
                  ? undefined
                  : {
                      value: workout,
                      label: workout.title,
                    }
              }
              onChange={(e: any) => {
                setWorkout(e.value);
                setWorkoutErrorMessage(undefined);
              }}
              options={props.workouts.map((workout: Workout) => {
                return {
                  value: workout,
                  label: workout.title,
                };
              })}
            />
            {workoutErrorMessage !== undefined ? (
              <p className="form-error-message">{workoutErrorMessage}</p>
            ) : undefined}
          </Form.Group>
          <Form.Group className="mb-3 form-floating-label workout-modal-user-group">
            <Button
              style={{
                width: "100%",
                borderColor: "black",
                backgroundColor: "white",
                color: "black",
              }}
              onClick={() => setShowDayPicker(!showDayPicker)}
            >
              {startAt !== undefined && startAt !== null
                ? getYearAndMonthAndDay(startAt)
                : undefined}
            </Button>
            {showDayPicker ? (
              <div className="course-schedule-modal-calendar-div">
                <DayPicker
                  mode="single"
                  selected={startAt}
                  locale={pt}
                  onSelect={(e: Date | undefined) => {
                    if (e === undefined) return;
                    const day = e.getDate();
                    const month = e.getMonth();
                    const year = e.getFullYear();

                    startAt.setDate(day);
                    startAt.setMonth(month);
                    startAt.setFullYear(year);
                    setStartAt(new Date(startAt));
                    setShowDayPicker(false);
                  }}
                />
              </div>
            ) : undefined}
          </Form.Group>
          <Row className="modal-flex-center padding">
            <Col xs={12} md={4} className="modal-buttons-col">
              <Button
                className="modal-submit-button-cancel"
                onClick={() => {
                  props.setShowModal(false);
                  props.setSelectedWorkoutSchedule(undefined);
                }}
                style={{ width: "100%" }}
              >
                Cancelar
              </Button>
            </Col>
            {props.selectedWorkoutSchedule !== undefined ? (
              <Col xs={12} md={4} className="modal-buttons-col">
                <Button
                  className="modal-delete-button"
                  onClick={onDeleteButtonClick}
                  style={{ width: "100%" }}
                >
                  Eliminar
                </Button>
              </Col>
            ) : undefined}
            <Col xs={12} md={4} className="modal-buttons-col">
              <Button
                className="modal-submit-button"
                onClick={onSubmitButtonClick}
                style={{ width: "100%" }}
              >
                Submeter
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
