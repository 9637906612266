import axios from "axios";
import { axiosRequest } from "./request";

export interface FetchPresignedUrlType {
  fields: Fields;
  headers: Headers;
  method: string;
  url: string;
}

export interface Fields {
  key: string;
  "Content-Disposition": string;
  "Content-Type": string;
  policy: string;
  "x-amz-credential": string;
  "x-amz-algorithm": string;
  "x-amz-date": string;
  "x-amz-signature": string;
  success_action_status: string;
}

export interface Headers {}

export interface UploadFileType {
  fields: Fields;
  url: string;
  file: File;
}

export type S3RequestResponseType = {
  succeeded: boolean;
  key: string | undefined;
};

export const uploadFileRequest = async (
  file: File
): Promise<S3RequestResponseType> => {
  try {
    // For the future. We don't need to keep getting this url, it can be reused.
    const response = await axiosRequest().get(
      `/shrine/presign?filename=${file.name}&type=${file.type}`
    );
    const data: FetchPresignedUrlType = response.data;

    const formData = new FormData();

    formData.append("key", data.fields.key);
    formData.append("Content-Disposition", data.fields["Content-Disposition"]);
    formData.append("Content-Type", data.fields["Content-Type"]);
    formData.append("policy", data.fields.policy);
    formData.append("x-amz-credential", data.fields["x-amz-credential"]);
    formData.append("x-amz-algorithm", data.fields["x-amz-algorithm"]);
    formData.append("x-amz-date", data.fields["x-amz-date"]);
    formData.append("x-amz-signature", data.fields["x-amz-signature"]);
    formData.append("success_action_status", data.fields.success_action_status);
    formData.append("file", file);

    await axios.create().post(data.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // We need this split on the key because we want to filter out cache/
    return { succeeded: true, key: data.fields.key.split("/")[1] };
  } catch (e: any) {
    console.log(e);
    return { succeeded: false, key: undefined };
  }
};
