import React from "react";
import Select, { createFilter } from "react-select";

import "./FilterDropdown.css";
import { formatToFirstTwoAndLastName } from "helpers/utils";

type FilterDropdownProps = {
  title: string;
  values: Array<any> | undefined;
  selectedValue: any | undefined;
  onChangeValue: (id: any) => void;
  isMulti?: boolean;
  fluid?: boolean;
  disabled?: boolean;
};

export const FilterDropdown: React.FC<FilterDropdownProps> = ({
  title,
  values,
  selectedValue,
  isMulti,
  fluid,
  onChangeValue,
  disabled,
}) => {
  const getTitle = (value: any) => {
    if (value === undefined || value == null) return;

    if (title === "Plano de treino") {
      return value.title;
    }

    if (title === "Utilizador" || title === "Personal Trainer") {
      return formatToFirstTwoAndLastName(value.name);
    }

    return value.title;
  };

  const label = (value: any) => {
    if (value === undefined || value == null) return;

    if (title === "Plano de treino") {
      return value.title;
    }

    if (title === "Utilizador" || title === "Personal Trainer") {
      return (
        <div>
          <img
            src={
              value.image_small ??
              "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
            }
            alt="something"
            height="30px"
            width="30px"
            className="select-img"
          />{" "}
          {formatToFirstTwoAndLastName(value.name)}
        </div>
      );
    }

    return value.title;
  };
  const value = () => {
    if (selectedValue === undefined || selectedValue === null) return undefined;
    else
      return {
        value: selectedValue?.id,
        title: selectedValue?.title,
        label: label(selectedValue),
      };
  };

  return (
    <div className="filter-dropdown-container">
      {values !== undefined && !isMulti && (
        <Select
          value={value()}
          className={`filter-dropdown-select ${fluid ? "fluid" : ""}`}
          isClearable
          placeholder={title}
          filterOption={createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            matchFrom: "any",
            stringify: (option) => `${option.data?.title}`,
            trim: true,
          })}
          options={values.map((value: any) => {
            return {
              value: value.id,
              title: getTitle(value),
              label: label(value),
            };
          })}
          onChange={(e: any) => {
            onChangeValue(e?.value);
          }}
          isDisabled={disabled}
        />
      )}
      {values !== undefined && isMulti && (
        <Select
          value={value()}
          className={`filter-dropdown-select ${fluid ? "fluid" : ""}`}
          isClearable
          isMulti
          placeholder={title}
          filterOption={createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            matchFrom: "any",
            stringify: (option) => `${option.data?.title}`,
            trim: true,
          })}
          options={values.map((value: any) => {
            return {
              value: value.id,
              title: getTitle(value),
              label: label(value),
            };
          })}
          onChange={(e: any) => {
            onChangeValue(e);
          }}
          isDisabled={disabled}
        />
      )}
    </div>
  );
};
