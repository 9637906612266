import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

import "./WorkoutHistory.css";
import { UserWorkoutHistory } from "../../../types/user_workout_history";
import { UserWorkoutExerciseHistory } from "../../../types/user_workout_exercise_history";
import {
  UserWorkoutExerciseHistorySet,
  WorkoutExerciseHistorySetVariableValues,
} from "../../../types/user_workout_exercise_history_set";
import { InputForm } from "../../../components/InputForm/InputForm";
import {
  fetchWorkoutsHistory,
  selectWorkoutsHistory,
} from "../../../slices/workoutHistorySlice";
import ReactGA from "react-ga4";
import { GChip } from "components/genericComponents/GChip/GChip";

export const WorkoutHistory = () => {
  ReactGA.send("/customer-workouts-history");

  const dispatch = useDispatch();
  const workoutsHistory = useSelector(selectWorkoutsHistory);
  const [collapsedWorkouts, setCollapsedWorkouts] = useState<Array<boolean>>(
    []
  );
  const pathSegments = window.location.href.split("/");
  const userId = pathSegments[pathSegments.length - 2];

  const workoutHistoryTitle = (workoutHistory: UserWorkoutHistory): string => {
    return `${workoutHistory.title} (${workoutHistory.created_at!.slice(
      0,
      10
    )})`;
  };

  const onTicketClick = (index: number) => {
    const newCollapsedWorkouts: Array<boolean> = collapsedWorkouts.map(
      (collapedWorkout: boolean, collapedWorkoutIndex: number): boolean => {
        if (collapedWorkoutIndex === index) {
          return !collapedWorkout;
        } else {
          return collapedWorkout;
        }
      }
    );

    setCollapsedWorkouts(newCollapsedWorkouts);
  };

  const exerciseRow = (exercise: UserWorkoutExerciseHistory) => {
    return (
      <div className="workout-history-margin-top">
        <div className="workout-history-ticket-title-div">
          <p className="workout-history-ticket-title">{exercise.title}</p>
        </div>
        <div className="workout-history-margin-top" />
        {exercise.sets?.map(
          (set: UserWorkoutExerciseHistorySet, setIndex: number) => (
            <div className="workout-history-exercise-row">
              <p className="workout-history-ticket-subtitle workout-history-exercise-justify-self-start workout-history-flex-1 workout-history-text-align-center">
                {`Set ${setIndex + 1}`}
              </p>
              {set.variable_values.map(
                (variableValue: WorkoutExerciseHistorySetVariableValues) => (
                  <div className="workout-history-flex-2">
                    <InputForm
                      value={variableValue.value}
                      label={variableValue.title}
                      disabled={true}
                    />
                  </div>
                )
              )}
            </div>
          )
        )}
      </div>
    );
  };

  useEffect(() => {
    if (workoutsHistory !== undefined && workoutsHistory.length > 0) {
      setCollapsedWorkouts(Array(workoutsHistory.length).fill(true));
    }
  }, [workoutsHistory]);

  const workoutHistoryTicket = (
    workoutHistory: UserWorkoutHistory,
    index: number
  ) => {
    return (
      <div className="workout-history-ticket-container">
        <div
          className="workout-history-row-space-between"
          onClick={() => onTicketClick(index)}
        >
          <div className="workout-history-col">
            <div className="workout-history-row">
              <p className="workout-history-ticket-title">
                {workoutHistoryTitle(workoutHistory)}
              </p>
              {workoutHistory.muscules_groups?.map(
                (muscleGroup: string, index: number) => (
                  <GChip
                    key={muscleGroup + index}
                    label={muscleGroup}
                    variant="primary"
                  />
                )
              )}
            </div>
            <p className="workout-history-ticket-subtitle">
              {workoutHistory.description}
            </p>
          </div>
          <div className="worjout-history-collapsible-icon">
            {collapsedWorkouts[index] ? (
              <IoIosArrowForward />
            ) : (
              <IoIosArrowDown />
            )}
          </div>
        </div>
        {!collapsedWorkouts[index] ? (
          <div>
            {workoutHistory?.exercises.map(
              (exercise: UserWorkoutExerciseHistory, exerciseIndex: number) =>
                exerciseRow(exercise)
            )}
          </div>
        ) : undefined}
      </div>
    );
  };

  useEffect(() => {
    dispatch(fetchWorkoutsHistory(userId));
  }, [dispatch, userId]);

  return (
    <div id="workout-history-container">
      {workoutsHistory?.map(
        (workoutHistory: UserWorkoutHistory, index: number) => (
          <div>{workoutHistoryTicket(workoutHistory, index)}</div>
        )
      )}
    </div>
  );
};
