import { GSection } from "components/genericComponents/GSection/GSection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchCRMHistory, selectCRMHistory } from "slices/crmSlice";
import { CRMCreatedNote } from "components/DashboardCustomersPage/CRM/CRMCreatedNote/CRMCreatedNote";

export const CRMLastNotes = () => {
  const dispatch = useDispatch();
  const crmHistory = useSelector(selectCRMHistory);

  useEffect(() => {
    dispatch(fetchCRMHistory());
  }, [dispatch]);

  return (
    <div>
      <p>
        Se pretender criar uma nota para um cliente pode fazê-lo na secção CRM
        na página de cada cliente.
      </p>
      <GSection label="Últimas notas">
        <div style={{ marginTop: "8px" }}>
          {crmHistory?.map((value: any) => (
            <CRMCreatedNote
              key={value.id}
              note={{
                id: value.id,
                type: "CrmNote",
                created_at: value.created_at,
                note: value.note,
              }}
              user={value.user}
            />
          ))}
        </div>
      </GSection>
    </div>
  );
};
