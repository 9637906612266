import { useEffect, useState } from "react";
import { FaArrowAltCircleLeft } from "react-icons/fa";

import { GSection } from "components/genericComponents/GSection/GSection";
import { PaymentPlanCard } from "components/DashboardCustomersPage/CustomerPayments/PaymentPlanCard/PaymentPlanCard";
import { GDatePicker } from "components/genericComponents/GDatePicker/GDatePicker";
import { GButton } from "components/genericComponents/GButton/GButton";
import { PaymentsPackage } from "types/paymentPackages";

import "components/DashboardCustomersPage/CustomerPayments/ChangePaymentsPlan/ChangePaymentsPlan.scss";
import { BsFillXCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPaymentsPackages,
  selectPackages,
  setUserPaymentsPackage,
} from "slices/paymentsPackagesSlice";
import { GInput } from "components/genericComponents/GInput/GInput";
import { Form } from "react-bootstrap";

type ChangePaymentsPlanProps = {
  handleCloseChoosePlan: () => void;
  userId?: string;
  currentPlanId: string | undefined;
};

export const ChangePaymentsPlan = ({
  handleCloseChoosePlan,
  userId,
  currentPlanId,
}: ChangePaymentsPlanProps) => {
  const dispatch = useDispatch();
  const plans: PaymentsPackage[] = useSelector(selectPackages);
  const [choosenPlan, setChoosenPlan] = useState<PaymentsPackage | undefined>(
    undefined
  );
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [signInPrice, setSignInPrice] = useState<number>(0);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchPaymentsPackages());
  }, [dispatch]);

  const handlePlanClick = (value: PaymentsPackage) => {
    setChoosenPlan(value);
  };

  const handleSetCurrDate = (val: Date | undefined) => {
    setStartDate(val!);
  };

  const handleSubmitPackage = () => {
    dispatch(
      setUserPaymentsPackage({
        userId: userId!,
        startDate: `${startDate}`,
        package: choosenPlan!,
        signInPrice: signInPrice,
      })
    );
    handleCloseChoosePlan();
  };

  const onCheckedChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      {choosenPlan ? (
        <GSection
          label="Confirmar plano"
          isSpacedBetween
          renderButtons={[
            <GButton
              icon={<FaArrowAltCircleLeft />}
              onClick={() => setChoosenPlan(undefined)}
            ></GButton>,
            <GButton
              icon={<BsFillXCircleFill />}
              onClick={handleCloseChoosePlan}
            ></GButton>,
          ]}
        >
          <div className="confirm-plan-div">
            <PaymentPlanCard plan={choosenPlan} />
            <p className="date-picker-title">Início do plano:</p>
            <GDatePicker
              setCurrDate={handleSetCurrDate}
              currDate={startDate}
              className="date-picker"
            />
            <div className="sign-in-price-div">
              <p className="sign-in-price-title">Incluir inscrição (€)</p>
              <Form.Check // prettier-ignore
                checked={checked}
                type="switch"
                className="purple-switch"
                onChange={onCheckedChange}
              />
            </div>
            {checked && (
              <GInput
                value={signInPrice}
                type="number"
                className="sign-in-price-input"
                onChange={(e: any) => setSignInPrice(e.target.value)}
              />
            )}
            <GButton
              label="Submeter"
              className="submit-btn"
              onClick={handleSubmitPackage}
            />
          </div>
        </GSection>
      ) : (
        <GSection
          label="Escolha o Plano"
          isSpacedBetween
          renderButtons={[
            <GButton
              icon={<BsFillXCircleFill />}
              onClick={handleCloseChoosePlan}
            />,
          ]}
        >
          <div className="plans-div">
            {plans.map((plan) => (
              <PaymentPlanCard
                key={plan.id}
                plan={plan}
                handleClick={handlePlanClick}
              />
            ))}
          </div>
        </GSection>
      )}
    </>
  );
};
