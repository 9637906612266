import { axiosRequest, errorMessage } from "./request";
import { Chat, ChatMessage } from "../types/chat";
import { CreateMessageType } from "../slices/chatSlice";

export type ChatResponseType = {
  succeeded: boolean;
  chats: Array<Chat> | undefined;
  messages: Array<ChatMessage> | undefined;
  message: ChatMessage | undefined;
  errorMessage: string | undefined;
};

export const createChatMessageRequest = async (
  params: CreateMessageType
): Promise<ChatResponseType> => {
  try {
    const response = await axiosRequest().post(
      `/chats/${params.chat_id}/messages`,
      {
        message: params.message,
        image: params.image,
      }
    );

    return {
      succeeded: true,
      message: response.data,
    } as ChatResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ChatResponseType;
  }
};

export const readMessagesRequest = async (
  chatId: string
): Promise<ChatResponseType> => {
  try {
    await axiosRequest().post(`/chats/${chatId}/read_messages`);

    return {
      succeeded: true,
    } as ChatResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ChatResponseType;
  }
};

export const fetchChatsRequest = async (): Promise<ChatResponseType> => {
  try {
    const response = await axiosRequest().get("chats");

    return {
      succeeded: true,
      chats: response.data,
    } as ChatResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ChatResponseType;
  }
};

export const fetchChatMessagesRequest = async (
  chatId: string
): Promise<ChatResponseType> => {
  try {
    const response = await axiosRequest().get(`chats/${chatId}/messages`);

    return {
      succeeded: true,
      messages: response.data,
    } as ChatResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as ChatResponseType;
  }
};
