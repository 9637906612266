import slices from "./slices";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import rootSaga from "./sagas";

const buildStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: slices,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware),
  });

  sagaMiddleware.run(rootSaga);
  return store;
};

export const store = buildStore();

export type RootState = ReturnType<typeof store.getState>;
