import { Dispatch, SetStateAction, useState } from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { BsPencilSquare } from "react-icons/bs";
import { TimePicker } from "react-time-picker";
import { LooseValue } from "react-time-picker/dist/cjs/shared/types";
import Countdown, { CountdownApi, CountdownTimeDelta } from "react-countdown";

import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import { GInput } from "components/genericComponents/GInput/GInput";
import {
  WorkoutExerciseSet,
  WorkoutExerciseSetVariable,
  WorkoutModeExercise,
  WorkoutModeExerciseSet,
} from "types/exercise";
import { secondsToTime } from "helpers/date";
import { WorkoutModeType } from "types/workout";

import "components/DashboardWorkoutsPage/WorkoutMode/WorkoutExerciseCard/WorkoutExerciseCardSet/WorkoutExerciseSetCard.scss";
import { FaPause, FaPlay, FaStop } from "react-icons/fa";

type WorkoutExerciseCardSetProps = {
  set: WorkoutModeExerciseSet;
  index: number;
  exercise: WorkoutModeExercise;
  exerciseIdx: number;
  isVisible: boolean;
  workoutToSubmit: WorkoutModeType | undefined;
  setWorkoutToSubmit: Dispatch<SetStateAction<WorkoutModeType | undefined>>;
};

export const WorkoutExerciseSetCard = ({
  set,
  index,
  exerciseIdx,
  isVisible,
  workoutToSubmit,
  setWorkoutToSubmit,
}: WorkoutExerciseCardSetProps) => {
  const [setDone, setSetDone] = useState<boolean>(false);
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [formData, setFormData] = useState<WorkoutExerciseSet>(set);
  const durationValue = set.variables.find(
    (variable) => variable.is_duration === true
  )?.value;
  const restValue = set.variables.find(
    (variable) => variable.is_rest_before_next_set === true
  )?.value;
  const [isTimerOn, setIsTimerOn] = useState<boolean>(false);
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [countdownApi, setCountdownApi] = useState<CountdownApi | null>(null);

  const handleSubmitEditSet = () => {
    setWorkoutToSubmit({
      ...workoutToSubmit!,
      exercises: workoutToSubmit!.exercises.reduce(
        (prev: WorkoutModeExercise[], curr, idx) => {
          var sets = curr.sets;

          if (exerciseIdx === idx) {
            sets = curr.sets.reduce(
              (cPrev: WorkoutModeExerciseSet[], cCurr, cIdx) => {
                if (index === cIdx) {
                  cCurr = {
                    ...formData,
                    isChecked: cCurr.isChecked,
                  };
                }
                cPrev.push(cCurr);
                return cPrev;
              },
              []
            );
          }

          curr.sets = sets;
          prev.push(curr);
          return prev;
        },
        []
      ),
    });

    setIsEditting(false);
  };

  const handleCancelEditSet = () => {
    const { isChecked, ...data } =
      workoutToSubmit!.exercises[exerciseIdx].sets[index];
    setFormData(data);
    setIsEditting(false);
  };

  const handleToggleDone = () => {
    setWorkoutToSubmit({
      ...workoutToSubmit!,
      exercises: workoutToSubmit!.exercises.reduce(
        (prev: WorkoutModeExercise[], curr, idx) => {
          if (exerciseIdx === idx) {
            let nrOfChecks = 0;
            curr.sets.reduce((cPrev: WorkoutModeExerciseSet[], cCurr, cIdx) => {
              if (index === cIdx) {
                cCurr.isChecked = !cCurr.isChecked;
              }
              if (cCurr.isChecked) nrOfChecks += 1;
              cPrev.push(cCurr);
              return cPrev;
            }, []);
            if (curr.sets.length === nrOfChecks) curr.isChecked = true;
            else curr.isChecked = false;
          }
          prev.push(curr);
          return prev;
        },
        []
      ),
    });

    setSetDone(!setDone);
  };

  const stopTimer = () => {
    setIsTimerRunning(false);
    setIsTimerOn(false);
    setTimer(0);
  };

  const startExercise = () => {
    setIsTimerRunning(true);
    setIsTimerOn(true);
    setTimer(Number.parseInt(durationValue!) + 1);
  };

  const startRest = () => {
    setIsTimerRunning(true);
    setIsTimerOn(true);
    setTimer(Number.parseInt(restValue!) + 1);
  };

  const handlePauseClick = (): void => {
    setIsTimerRunning(false);
    countdownApi && countdownApi.pause();
  };

  const handlePlayClick = (): void => {
    setIsTimerRunning(true);
    countdownApi && countdownApi.start();
  };

  const handleTimerEnd = () => {
    setIsTimerRunning(false);
    setIsTimerOn(false);
    setTimer(0);
  };

  const handleOnPause = ({
    hours,
    minutes,
    seconds,
  }: CountdownTimeDelta): void => {
    setTimer(hours * 3600 + minutes * 60 + seconds + 1);
  };

  const setRef = (countdown: Countdown | null): void => {
    if (countdown) {
      setCountdownApi(countdown.getApi());
    }
  };

  return (
    <GSection
      className={`workout-exercise-set-card${!isVisible ? " hidden" : ""}`}
      label={`Set ${index + 1}`}
      isSpacedBetween
      renderButtons={[
        ...(!isEditting
          ? [
              <GButton
                key="1"
                icon={<BsPencilSquare />}
                onClick={() => setIsEditting(!isEditting)}
              />,
            ]
          : []),
        <GButton
          key="2"
          variant="transparent"
          className="buttons"
          icon={set.isChecked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
          iconSize="big"
          onClick={handleToggleDone}
        />,
      ]}
    >
      <div className="set-fields">
        {formData.variables.map((variable, idx) => (
          <div
            key={variable.variable_id + idx}
            className="set-fields-inner-div"
          >
            <p className="title">{variable.variable_title}</p>
            {variable.is_duration || variable.is_rest_before_next_set ? (
              <TimePicker
                disabled={!isEditting}
                disableClock
                className="variable-time-input"
                value={
                  (variable.value !== null
                    ? [variable.value].map((val) => {
                        return secondsToTime(val);
                      })
                    : "0:0:0") as LooseValue
                }
                format="H:m:s"
                maxDetail="second"
                onChange={(value) => {
                  if (value !== null) {
                    const timeArr = value.split(":");
                    var hours = Number(timeArr[0]);
                    var minutes = Number(timeArr[1]);
                    var seconds = Number(timeArr[2]);

                    const timeInSeconds = `${
                      hours * 3600 + minutes * 60 + seconds
                    }`;

                    const newVarsArr = formData.variables.reduce(
                      (prev: WorkoutExerciseSetVariable[], curr, varIdx) => {
                        if (varIdx === idx) {
                          curr = {
                            ...curr,
                            value: timeInSeconds,
                          };
                        }
                        prev.push(curr);
                        return prev;
                      },
                      []
                    );

                    setFormData({
                      ...formData,
                      variables: newVarsArr,
                    });
                  }
                }}
              />
            ) : (
              <GInput
                value={formData.variables[idx].value}
                disabled={!isEditting}
                onChange={(e: any) => {
                  const newVarsArr = formData.variables.reduce(
                    (prev: WorkoutExerciseSetVariable[], curr, varIdx) => {
                      if (varIdx === idx) {
                        curr = {
                          ...curr,
                          value: e.target.value,
                        };
                      }
                      prev.push(curr);
                      return prev;
                    },
                    []
                  );

                  setFormData({
                    ...formData,
                    variables: newVarsArr,
                  });
                }}
              />
            )}
          </div>
        ))}
      </div>
      {!isEditting && (
        <div className="start-btns">
          {durationValue !== undefined &&
            durationValue !== "0" &&
            !isTimerOn && (
              <GButton label="Começar Exercício" onClick={startExercise} />
            )}
          {restValue !== undefined && restValue !== "0" && !isTimerOn && (
            <GButton label="Começar Descanso" onClick={startRest} />
          )}
          {isTimerOn && (
            <div className="play-btns">
              <Countdown
                date={Date.now() + timer * 1000}
                ref={setRef}
                intervalDelay={0}
                precision={3}
                renderer={(props) => (
                  <div className="timer-div">
                    <span>
                      {props.hours}:{props.minutes}:{props.seconds}
                    </span>
                  </div>
                )}
                onPause={handleOnPause}
                onComplete={handleTimerEnd}
              />
              <GButton
                icon={isTimerRunning ? <FaPause /> : <FaPlay />}
                onClick={isTimerRunning ? handlePauseClick : handlePlayClick}
              />
              <GButton icon={<FaStop />} onClick={stopTimer} />
            </div>
          )}
        </div>
      )}
      {isEditting && (
        <div className="buttons-div">
          <GButton key="3" label="Submeter" onClick={handleSubmitEditSet} />
          <GButton
            key="4"
            label="Cancelar"
            variant="secondary"
            onClick={handleCancelEditSet}
          />
        </div>
      )}
    </GSection>
  );
};
