import { takeLatest } from "redux-saga/effects";
import {
  login,
  fetchCurrentUser,
  createAccount,
  updateCompany,
  updateCompanyCourseSettings,
} from "slices/currentUserSlice";
import {
  loginSaga,
  fetchCurrentUserSaga,
  createAccountSaga,
  updateCompanySaga,
  updateCompanyCourseSettingsSaga,
} from "sagas/currentUserSaga/currentUserSaga";

function* currentUserSaga() {
  yield takeLatest(login, loginSaga);
  yield takeLatest(fetchCurrentUser, fetchCurrentUserSaga);
  yield takeLatest(createAccount, createAccountSaga);
  yield takeLatest(updateCompany, updateCompanySaga);
  yield takeLatest(
    updateCompanyCourseSettings,
    updateCompanyCourseSettingsSaga
  );
}

export default currentUserSaga;
