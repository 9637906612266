import React, { HTMLProps, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import YouTube from "react-youtube";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import {
  fetchExercises,
  selectExercises,
  selectIsLoading,
  deleteExercise,
} from "slices/exerciseSlice";
import { selectIsLoading as selectWorkoutExercisesIsLoading } from "slices/workoutSlice";
import {
  fetchExerciseCategories,
  selectExerciseCategories,
} from "slices/exerciseCategorySlice";
import { selectCurrentUser } from "slices/currentUserSlice";
import { DashboardTitle } from "components/DashboardTitle/DashboardTitle";
import { FilterDropdown } from "components/FilterDropdown/FilterDropdown";
import { TableComponent } from "components/TableComponent/TableComponent";
import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";
import { GChip } from "components/genericComponents/GChip/GChip";
import { GButton } from "components/genericComponents/GButton/GButton";
import { muscleGroups } from "helpers/muscleGroups";
import { Exercise } from "types/exercise";
import {
  formatToFirstTwoAndLastName,
  getYoutubeIdFromUrl,
} from "../../helpers/utils";

import "./DashboardExercisesPage.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

type DashBoardExercisesPageProps = {
  isPickingExerciseForWorkout?: boolean;
  setExercisePickedForWorkout?: (exercise: Exercise) => void;
  exerciseIdsPickedForWorkout?: Array<String>;
  currentExerciseIndex?: number;
};

export const DashBoardExercisesPage = (props: DashBoardExercisesPageProps) => {
  ReactGA.send("/exercises");

  let navigate = useNavigate();
  const [, setShowModal] = useState(false);
  const [, setSelectedWorkoutExercise] = useState<Exercise | undefined>();
  const dispatch = useDispatch();
  const allExercises = useSelector(selectExercises);
  const [exercises, setExercises] = useState(allExercises);
  const [selectedMuscleGroup, setSelectedMuscleGroup] = useState<
    any | undefined
  >(undefined);
  const [selectedCategory, setSelectedCategory] = useState<any | undefined>(
    undefined
  );
  const isLoading = useSelector(selectIsLoading);
  const isWorkoutExercisesLoading = useSelector(
    selectWorkoutExercisesIsLoading
  );
  const currentUser = useSelector(selectCurrentUser);
  const exerciseCategories = useSelector(selectExerciseCategories);
  const [visibleRowVideo, setVisibleRowVideo] = useState<string | undefined>(
    undefined
  );

  const exercisesToShowOnList = () => {
    if (exercises.length === 0) return [];

    if (selectedMuscleGroup !== undefined && selectedCategory !== undefined) {
      return exercises!.filter(
        (exercise: Exercise) =>
          exercise.muscules_groups?.includes(selectedMuscleGroup.id) &&
          exercise.exercise_category?.id === selectedCategory.id
      );
    }

    if (selectedMuscleGroup !== undefined) {
      return exercises!.filter((exercise: Exercise) =>
        exercise.muscules_groups?.includes(selectedMuscleGroup.id)
      );
    }

    if (selectedCategory !== undefined) {
      return exercises!.filter(
        (exercise: Exercise) =>
          exercise.exercise_category?.id === selectedCategory.id
      );
    }

    return exercises;
  };

  const TableCheckbox = ({
    className = "",
    row,
  }: { row: any } & HTMLProps<HTMLInputElement>) => {
    const ref = React.useRef<HTMLInputElement>(null!);

    return (
      <input
        type="checkbox"
        checked={
          props.exerciseIdsPickedForWorkout === undefined
            ? false
            : props.exerciseIdsPickedForWorkout.includes(row.id)
        }
        ref={ref}
        className={className}
        onClick={() => {
          props.setExercisePickedForWorkout!(row.original);
        }}
      />
    );
  };

  const toggleVideo = (rowId: string) => {
    if (visibleRowVideo === rowId) setVisibleRowVideo(undefined);
    else setVisibleRowVideo(rowId);
  };

  const exercisesTableColumnsForPT = [
    {
      Header: "Título",
      accessor: "title",
    },
    {
      Header: "Categoria",
      accessor: "exercise_category",
      Cell: (props: any) => {
        if (props.cell.value === undefined || props.cell.value === null)
          return undefined;

        return <GChip label={props.cell.value.title} variant="primary" />;
      },
    },
    {
      Header: "Grupos de Músculos",
      accessor: "muscules_groups",
      maxwidth: 100,
      Cell: (props: any) => {
        return props.cell.value.map((musculeGroup: string, idx: number) => {
          return (
            <GChip
              key={musculeGroup + idx}
              label={muscleGroups[musculeGroup].pt}
              variant="primary"
            />
          );
        });
      },
    },
  ];

  const exercisesTableColumnsForGym = [
    {
      Header: "Título",
      accessor: "title",
    },
    {
      Header: "Categoria",
      accessor: "exercise_category",
      Cell: (props: any) => {
        if (props.cell.value === undefined || props.cell.value === null)
          return undefined;

        return <GChip label={props.cell.value.title} variant="primary" />;
      },
    },
    {
      Header: "Grupos de Músculos",
      accessor: "muscules_groups",
      maxwidth: 100,
      Cell: (props: any) => {
        return props.cell.value.map((musculeGroup: string, idx: number) => {
          return (
            <GChip
              key={musculeGroup + idx}
              label={muscleGroups[musculeGroup].pt}
              variant="primary"
            />
          );
        });
      },
    },
    {
      Header: "Criado por",
      accessor: "personal_trainer",
      Cell: (props: any) => {
        return (
          <>
            {props.cell.value !== null ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  alt=""
                  style={{ width: 30, height: 30, borderRadius: 15 }}
                  src={
                    props.cell.value.image_small ??
                    "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                  }
                />
                <p style={{ margin: 0, marginLeft: 10 }}>
                  {formatToFirstTwoAndLastName(props.cell.value.name)}
                </p>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  alt=""
                  style={{ width: 30, height: 30, borderRadius: 15 }}
                  src={
                    currentUser?.company?.image_small ??
                    "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                  }
                />
                <p style={{ margin: 0, marginLeft: 10 }}>
                  {currentUser?.company?.name}
                </p>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const exercisesTableColumnsForPTWhenPickingForWorkout = [
    {
      id: "select",
      Header: "",
      Cell: (props: any) => {
        return (
          <div className="px-1">
            <TableCheckbox
              row={props.row}
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        );
      },
    },
    {
      Header: "Título",
      accessor: "title",
    },
    {
      Header: "Categoria",
      accessor: "exercise_category",
      Cell: (props: any) => {
        if (props.cell.value === undefined || props.cell.value === null)
          return undefined;

        return <GChip label={props.cell.value.title} variant="primary" />;
      },
    },
    {
      Header: "Grupos de Músculos",
      accessor: "muscules_groups",
      width: 100,
      Cell: (props: any) => {
        return props.cell.value.map((musculeGroup: string, idx: number) => {
          return (
            <GChip
              key={musculeGroup + idx}
              label={muscleGroups[musculeGroup].pt}
              variant="primary"
            />
          );
        });
      },
    },
  ];

  const exercisesTableColumnsForGymWhenPickingForWorkout = [
    {
      id: "select",
      Header: "",
      Cell: (props: any) => {
        return (
          <div className="px-1">
            <TableCheckbox
              row={props.row}
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        );
      },
    },
    {
      Header: "Título",
      accessor: "title",
    },
    {
      Header: "Categoria",
      accessor: "exercise_category",
      Cell: (props: any) => {
        if (props.cell.value === undefined || props.cell.value === null)
          return undefined;

        return <GChip label={props.cell.value.title} variant="primary" />;
      },
    },
    {
      Header: "Grupos de Músculos",
      accessor: "muscules_groups",
      width: 100,
      Cell: (props: any) => {
        return props.cell.value.map((musculeGroup: string, idx: number) => {
          return (
            <GChip
              key={musculeGroup + idx}
              label={muscleGroups[musculeGroup].pt}
              variant="primary"
            />
          );
        });
      },
    },
    {
      Header: "Criado por",
      accessor: "personal_trainer",
      Cell: (props: any) => {
        return (
          <>
            {props.cell.value !== null ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  alt=""
                  style={{ width: 30, height: 30, borderRadius: 15 }}
                  src={
                    props.cell.value.image_small ??
                    "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                  }
                />
                <p style={{ margin: 0, marginLeft: 10 }}>
                  {formatToFirstTwoAndLastName(props.cell.value.name)}
                </p>
              </div>
            ) : (
              <p style={{ margin: 0, marginLeft: 10 }}>
                {currentUser?.company?.name}
              </p>
            )}
          </>
        );
      },
    },
    {
      Header: "Vídeo",
      accessor: "video_url",
      Cell: (props: any) => {
        return (
          <>
            {props.cell.value !== null ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <GButton
                  label={
                    visibleRowVideo === props.row.id ? "Fechar" : "Ver vídeo"
                  }
                  onClick={() => toggleVideo(props.row.original.id)}
                />
                {visibleRowVideo === props.row.original.id && (
                  <YouTube
                    videoId={getYoutubeIdFromUrl(props.cell.value)}
                    opts={{ width: "200", height: "200" }}
                  />
                )}
              </div>
            ) : undefined}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setExercises(allExercises);
  }, [allExercises]);

  useEffect(() => {
    dispatch(fetchExerciseCategories());
  }, [dispatch]);

  const tableColumns = () => {
    if (currentUser?.company?.is_gym) {
      if (props.isPickingExerciseForWorkout)
        return exercisesTableColumnsForGymWhenPickingForWorkout;
      else return exercisesTableColumnsForGym;
    } else {
      if (props.isPickingExerciseForWorkout)
        return exercisesTableColumnsForPTWhenPickingForWorkout;
      else return exercisesTableColumnsForPT;
    }
  };

  useEffect(() => {
    dispatch(fetchExercises());
  }, [dispatch]);

  const changeExcercisesList = (evt: any) => {
    const inputValue = evt.target.value.toLowerCase();
    const exercisesFiltered = allExercises.filter((exercise: Exercise) =>
      exercise.title.toLowerCase().includes(inputValue)
    );
    setExercises(exercisesFiltered.length ? exercisesFiltered : []);
  };

  const renderExercisePage = (selectedExercise: Exercise) => {
    if (props.isPickingExerciseForWorkout) {
    } else {
      let path = `${selectedExercise.id}`;
      navigate(path);
    }
  };

  return (
    <Container fluid id="dashboard-exercises">
      {props.isPickingExerciseForWorkout !== true && (
        <div>
          <DashboardTitle
            title=""
            showAddButton={true}
            linkObj={{ isLink: true, link: "add-exercise" }}
            countCards={[
              {
                title: "Exercícios",
                count: exercises.length,
              },
            ]}
            infoLink="DT8xYalkHkI"
          />
        </div>
      )}
      <div className="filter-container">
        <Form.Control
          onChange={changeExcercisesList}
          placeholder="Pesquisar"
          className="search-input"
        />
        <FilterDropdown
          title={"Categoria"}
          values={exerciseCategories.map((exerciseCategory: any) => {
            return { id: exerciseCategory.id, title: exerciseCategory.title };
          })}
          selectedValue={selectedCategory}
          onChangeValue={(exerciseCategoryId: any) => {
            if (exerciseCategoryId === undefined)
              return setSelectedCategory(undefined);

            const newValue = exerciseCategories.find(
              (exerciseCategory) => exerciseCategory.id === exerciseCategoryId
            );

            setSelectedCategory({
              id: newValue!.id,
              title: newValue!.title,
            });
          }}
        />
        <FilterDropdown
          title={"Grupos de músculos"}
          values={Object.keys(muscleGroups).map((muscleGroup: any) => {
            return { id: muscleGroup, title: muscleGroups[muscleGroup].pt };
          })}
          selectedValue={selectedMuscleGroup}
          onChangeValue={(muscleGroup: any) => {
            if (muscleGroup === undefined) setSelectedMuscleGroup(undefined);
            else
              setSelectedMuscleGroup({
                id: muscleGroup,
                title: muscleGroups[muscleGroup].pt,
              });
          }}
        />
        {
          //<FilterDropdown
          //  title={"Material"}
          //  values={Object.keys(gymMaterials).map((gymMaterial: any) => {
          //    return { id: gymMaterial, title: gymMaterial };
          //  })}
          //  selectedValue={selectedGymMaterial}
          //  onChangeValue={(gymMaterial: any) => {
          //    if (gymMaterial === undefined) setSelectedGymMaterial(undefined);
          //    else
          //      setSelectedGymMaterial({ id: gymMaterial, title: gymMaterial });
          //  }}
          ///>
        }
      </div>

      {isLoading || isWorkoutExercisesLoading ? (
        <SpinnerWrapper />
      ) : (
        <div
          style={
            props.isPickingExerciseForWorkout
              ? { maxHeight: 500, overflow: "auto" }
              : {}
          }
        >
          <div className="table-component-upper-div">
            <TableComponent
              name="exercise"
              columns={tableColumns()}
              data={exercisesToShowOnList()}
              duplicateAction={(id: string) => {}}
              removeAction={(id: string) => dispatch(deleteExercise(id))}
              addAction={() => setShowModal(true)}
              editAction={(selectedExercise: Exercise) => {
                setShowModal(true);
                setSelectedWorkoutExercise(selectedExercise);
              }}
              rowClickAction={(row: any) => {
                if (props.isPickingExerciseForWorkout) return;

                renderExercisePage(row.original);
              }}
              seeChildrenText={undefined}
              seeChildrenAction={(exerciseId: string) => {}}
              showEditButton={false}
              showRemoveButton={!props.isPickingExerciseForWorkout}
              textWhenNoItems="Não tem nenhum exercício"
            />
          </div>
        </div>
      )}
    </Container>
  );
};
