import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "i18n"; // import i18n (needs to be bundled ;))

import App from "App";
import { ChatGptPage } from "components/ChatGptPage/ChatGptPage";
import { CreatePasswordPage } from "components/CreatePasswordPage/CreatePasswordPage";
import { CalendarPage } from "components/DashboardCoursesPage/CalendarPage/CalendarPage";
import { DashboardCoursesPage } from "components/DashboardCoursesPage/DashboardCoursesPage";
import { AddCustomerPage } from "components/DashboardCustomersPage/AddCustomerPage/AddCustomerPage";
import { CustomerPage } from "components/DashboardCustomersPage/CustomerPage/CustomerPage";
import { DashBoardCustomersPage } from "components/DashboardCustomersPage/DashboardCustomersPage";
import { DashBoardExercisesPage } from "components/DashboardExercisesPage/DashboardExercisesPage";
import { ExercisePage } from "components/DashboardExercisesPage/ExercisePage/ExercisePage";
import { DashboardHomePage } from "components/DashboardHomePage/DashboardHomePage";
import { DashboardPage } from "components/DashboardPage/DashboardPage";
import { DashBoardPersonalTrainersPage } from "components/DashboardPersonalTrainersPage/DashboardPersonalTrainersPage";
import { PersonalTrainerPage } from "components/DashboardPersonalTrainersPage/PersonalTrainerPage/PersonalTrainerPage";
import { DashBoardWorkoutsPage } from "components/DashboardWorkoutsPage/DashboardWorkoutsPage";
import { WorkoutPage } from "components/DashboardWorkoutsPage/WorkoutPage/WorkoutPage";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { ProductsPage } from "components/ProductsPage/ProductsPage";
import { SettingsPage } from "components/SettingsPage/SettingsPage";
import { TabletPage } from "components/TabletPage/TabletPage";
import { PersonalData } from "components/DashboardCustomersPage/PersonalData/PersonalData";
import { Evaluations } from "components/DashboardCustomersPage/Evaluations/Evaluations";
import { CRM } from "components/DashboardCustomersPage/CRM/CRM";
import { WorkoutHistory } from "components/DashboardCustomersPage/WorkoutHistory/WorkoutHistory";
import { Evolution } from "components/DashboardCustomersPage/Evolution/Evolution";
import { CustomerPayments } from "components/DashboardCustomersPage/CustomerPayments/CustomerPayments";
import { NutritionPage } from "components/NutritionPage/NutritionPage";
import reportWebVitals from "reportWebVitals";
import { store } from "store";

import "index.scss";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { BillingPage } from "components/BillingPage/BillingPage";
import { AccountBlockedPage } from "components/AccountBlockedPage/AccountBlockedPage";
import { AccountSection } from "components/SettingsPage/AccountSection/AccountSection";
import { ExercisesSettings } from "components/SettingsPage/ExercisesSettings/ExercisesSettings";
import { GymSettings } from "components/SettingsPage/GymSettings/GymSettings";
import { CoursesSettings } from "components/SettingsPage/CoursesSettings/CoursesSettings";
import { PaymentsSettings } from "components/SettingsPage/PaymentsSettings/PaymentsSettings";
import { MobileAppSettings } from "components/SettingsPage/MobileAppSettings/MobileAppSettings";
import { Diets } from "components/NutritionPage/Diets/Diets";
import { AddOrEditDiet } from "components/NutritionPage/AddOrEditDiet/AddOrEditDiet";
import { PromoterPage } from "components/PromoterPage/PromoterPage";

ReactGA.initialize("G-YS0P7Q4CQ3");

const container = document.getElementById("root")!;
const root = createRoot(container);

const router = createBrowserRouter([
  { path: "/", element: <App />, errorElement: <ErrorPage /> },
  {
    path: "dashboard",
    element: <DashboardPage />,
    errorElement: <ErrorPage />,
    children: [
      { path: "home", element: <DashboardHomePage /> },
      { path: "customers", element: <DashBoardCustomersPage /> },
      { path: "personal_trainers", element: <DashBoardPersonalTrainersPage /> },
      {
        path: "personal_trainers/add-personal-trainer",
        element: <PersonalTrainerPage clientExists={false} />,
      },
      {
        path: "personal_trainers/:id",
        element: <PersonalTrainerPage clientExists={true} />,
      },
      { path: "customers/add-customer", element: <AddCustomerPage /> },
      {
        path: "customers/:id",
        element: <CustomerPage />,
        children: [
          {
            path: "personal-data",
            element: <PersonalData clientExists={true} />,
          },
          { path: "evaluations", element: <Evaluations /> },
          { path: "workout-history", element: <WorkoutHistory /> },
          { path: "evolution", element: <Evolution /> },
          { path: "payments", element: <CustomerPayments /> },
          { path: "crm", element: <CRM /> },
        ],
      },
      { path: "workouts", element: <DashBoardWorkoutsPage /> },
      { path: "workouts/add-workout", element: <WorkoutPage /> },
      { path: "workouts/:id", element: <WorkoutPage /> },
      { path: "exercises", element: <DashBoardExercisesPage /> },
      { path: "exercises/add-workout", element: <ExercisePage /> },
      { path: "exercises/:id", element: <ExercisePage /> },
      { path: "calendar", element: <DashboardCoursesPage /> },
      { path: "calendar/:id", element: <CalendarPage /> },
      { path: "chats", element: <ChatGptPage /> },
      {
        path: "settings",
        element: <SettingsPage />,
        children: [
          { path: "account", element: <AccountSection /> },
          { path: "exercises", element: <ExercisesSettings /> },
          { path: "gym", element: <GymSettings /> },
          { path: "classes", element: <CoursesSettings /> },
          { path: "payments", element: <PaymentsSettings /> },
          { path: "mobile", element: <MobileAppSettings /> },
        ],
      },
      { path: "products", element: <ProductsPage /> },
      { path: "tablet", element: <TabletPage /> },
      {
        path: "nutrition",
        element: <NutritionPage />,
        children: [
          { path: "diets", element: <Diets /> },
          { path: "diets/add-diet", element: <AddOrEditDiet /> },
          { path: "diets/:id", element: <AddOrEditDiet /> },
        ],
      },
      { path: "billing", element: <BillingPage /> },
      { path: "promoter", element: <PromoterPage /> },
    ],
  },
  {
    path: "complete-account/:id",
    element: <CreatePasswordPage isPersonalTrainer={false} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "complete-account-personal-trainer/:id",
    element: <CreatePasswordPage isPersonalTrainer={true} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "account-blocked",
    element: <AccountBlockedPage />,
    errorElement: <ErrorPage />,
  },
]);

root.render(
  <Provider store={store}>
    <ToastContainer />
    <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
