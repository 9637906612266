import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./ErrorPage.css";

import logo from "../../assets/logo.svg";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCurrentUser,
  selectCurrentUser,
} from "../../slices/currentUserSlice";

export const ErrorPage = () => {
  const [cookies] = useCookies(["current_user"]);
  const currentUserCookies = cookies.current_user;
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (currentUser === undefined && currentUserCookies?.id !== undefined) {
      dispatch(fetchCurrentUser(currentUserCookies?.id));
    }
  }, [currentUser, dispatch, currentUserCookies]);

  return (
    <Container id="error-page">
      <Row>
        <Col>
          <h1>Oops, tropeçámos num haltere!</h1>
          <p>Por favor, atualize a página ou volte atrás.</p>
          <p>
            Em alternativa, volte ao início{" "}
            {currentUser ? (
              <Link to={"/dashboard"} className="link-to-leave">
                aqui
              </Link>
            ) : (
              <Link to={"/"} className="link-to-leave">
                aqui
              </Link>
            )}
            .
          </p>
        </Col>
        <Col className="gym-img-col">
          <img className="gym-img" src={logo} alt="" />
        </Col>
      </Row>
    </Container>
  );
};
