import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import { WorkoutExercise } from "../../types/exercise";
import { secondsToTime } from "../../helpers/date";

type PDFFileI = {
  title: string;
  description?: string;
  exercises?: WorkoutExercise[];
  createdAt: string;
  createdBy: string;
  clientName: string;
  gymLogo: string;
  maxHeartFreq: number;
};

export const PDFFile = ({
  title,
  description,
  exercises,
  createdAt,
  createdBy,
  clientName,
  gymLogo,
  maxHeartFreq,
}: PDFFileI) => {
  const styles = StyleSheet.create({
    page: { padding: 15 },
    flexCenterView: {
      display: "flex",
      justifyContent: "center",
    },
    flexInfoRowView: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    flexRowView: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-around",
      flexWrap: "wrap",
    },
    flexVariableRowView: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
    },
    title: { textAlign: "center", fontSize: 20 },
    descriptionTitle: { fontSize: 16 },
    description: { fontSize: 10 },
    exerciseTitle: {
      fontSize: 16,
      textAlign: "center",
    },
    exerciseImage: { maxWidth: 100, maxHeight: 100, alignSelf: "center" },
    gymLogoImage: { maxWidth: 100, maxHeight: 100 },
    setView: { marginTop: 20 },
    setCounter: {
      textAlign: "center",
      backgroundColor: "grey",
      color: "white",
      padding: "1 3",
      borderRadius: "5px",
      maxWidth: 50,
      fontSize: 10,
      marginBottom: 0,
      alignSelf: "center",
    },
    variableTitle: {
      fontSize: 10,
      textOverflow: "ellipsis",
      maxWidth: 85,
      overflow: "hidden",
      height: 12,
    },
    variableText: { fontSize: 10 },
    setsContainer: { marginRight: 30 },
    divider: { textAlign: "center" },
    exerciseContainer: {
      borderRadius: "10px",
      marginBottom: "20px",
    },
    notesView: { marginTop: 10 },
    headerTextView: { display: "flex", flexDirection: "row" },
    headerTextTitle: { fontSize: 12 },
    headerText: { fontSize: 12, marginBottom: 5 },
    line: {
      width: "100%",
      borderBottom: "1px solid #e9e9e9",
      marginTop: 15,
    },
  });

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.flexInfoRowView}>
          <View>
            <View style={styles.headerTextView}>
              <Text style={styles.headerTextTitle}>Nome do cliente: </Text>
              <Text style={styles.headerText}>{clientName}</Text>
            </View>
            <View style={styles.headerTextView}>
              <Text style={styles.headerTextTitle}>Nome do Treinador: </Text>
              <Text style={styles.headerText}>{createdBy}</Text>
            </View>
            <View style={styles.headerTextView}>
              <Text style={styles.headerTextTitle}>Criado a: </Text>
              <Text style={styles.headerText}>{createdAt}</Text>
            </View>
          </View>
          <View>
            <Image style={styles.gymLogoImage} src={gymLogo} />
          </View>
        </View>
        <View>
          <Text style={styles.title}>{title}</Text>
          {maxHeartFreq !== null ? (
            <View style={styles.headerTextView}>
              <Text style={styles.description}>
                Frequência Cardíaca Máxima:{" "}
              </Text>
              <Text style={styles.description}>{maxHeartFreq}</Text>
            </View>
          ) : undefined}
          {description !== null && description !== "" ? (
            <View>
              <Text style={styles.descriptionTitle}>Descrição</Text>
              <Text style={styles.description}>{description}</Text>
            </View>
          ) : undefined}
          {exercises?.map((exercise) => {
            return (
              <View style={styles.exerciseContainer}>
                <Text style={styles.exerciseTitle}>{exercise.title}</Text>
                <View style={styles.flexRowView}>
                  {exercise.exercise.image_large !== undefined &&
                    exercise.exercise.image_large !== null && (
                      <View
                        style={[
                          styles.flexCenterView,
                          styles.setView,
                          styles.setsContainer,
                        ]}
                      >
                        <Image
                          style={styles.exerciseImage}
                          src={exercise.exercise.image_large}
                        />
                      </View>
                    )}
                  {exercise.sets?.map((set, index) => {
                    return (
                      <View
                        style={[
                          styles.flexCenterView,
                          styles.setView,
                          styles.setsContainer,
                        ]}
                      >
                        <Text style={styles.setCounter}>Set {index + 1}</Text>
                        <View>
                          {set.variables.map((variable) => {
                            return (
                              <View>
                                {variable.value !== null &&
                                  variable.value !== "" && (
                                    <View style={styles.flexVariableRowView}>
                                      <Text style={styles.variableTitle}>
                                        {variable.variable_title}
                                      </Text>
                                      <Text style={styles.variableText}>
                                        :{" "}
                                        {variable.variable_title ===
                                          "Duração" ||
                                        variable.variable_title === "Descanso"
                                          ? secondsToTime(variable.value)
                                          : variable.value}
                                      </Text>
                                    </View>
                                  )}
                              </View>
                            );
                          })}
                        </View>
                      </View>
                    );
                  })}
                </View>
                {exercise.notes !== undefined &&
                  exercise.notes !== null &&
                  exercise.notes !== "" && (
                    <View style={styles.notesView}>
                      <Text style={styles.variableTitle}>Notas:</Text>
                      <Text style={styles.variableText}>{exercise.notes}</Text>
                    </View>
                  )}
                <View style={styles.line}></View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};
