import { Course } from "../types/course";
import { axiosRequest, errorMessage } from "./request";
import { CreateCourseType, UpdateCourseType } from "../slices/courseSlice";

export type CourseRequestResponseType = {
  succeeded: boolean;
  courses: Array<Course> | undefined;
  course: Course | undefined;
  errorMessage: string | undefined;
};

export const fetchCoursesRequest =
  async (): Promise<CourseRequestResponseType> => {
    try {
      const response = await axiosRequest().get("courses");
      const courses: Array<Course> = response.data;

      return {
        succeeded: true,
        courses: courses,
      } as CourseRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as CourseRequestResponseType;
    }
  };

export const createCourseRequest = async (
  params: CreateCourseType
): Promise<CourseRequestResponseType> => {
  try {
    const response = await axiosRequest().post("courses", {
      course: params,
    });
    const course: Course = response.data;

    return { succeeded: true, course: course } as CourseRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CourseRequestResponseType;
  }
};

export const updateCourseRequest = async (
  params: UpdateCourseType
): Promise<CourseRequestResponseType> => {
  try {
    const response = await axiosRequest().put(`courses/${params.id}`, {
      course: params,
    });
    const course: Course = response.data;

    return { succeeded: true, course: course } as CourseRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CourseRequestResponseType;
  }
};

export const deleteCourseRequest = async (
  courseId: string
): Promise<CourseRequestResponseType> => {
  try {
    await axiosRequest().delete(`courses/${courseId}`);

    return { succeeded: true } as CourseRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CourseRequestResponseType;
  }
};
