import { Button, Col, Container, Row } from "react-bootstrap";
import { MdAdd, MdDeleteForever } from "react-icons/md";

import "./ExercisesVariablesSettings.css";
import { ExerciseVariable } from "../../../../types/exercise";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { InputForm } from "../../../InputForm/InputForm";

type VariablesProps = {
  variables: ExerciseVariable[];
  onVariableChange: (
    categoryIndex: number,
    variable: ExerciseVariable,
    variableIndex: number
  ) => void;
  categoryIndex: number;
  isOwner: boolean;
  hasCategoryChanged: boolean[];
  setHasCategoryChanged: Dispatch<SetStateAction<boolean[]>>;
  onAddVariable: (index: number, isRest: boolean, isDuration: boolean) => void;
  onRemoveVariable: (categoryIndex: number, index: number) => void;
  isEditting: boolean[];
  setIsEditting: Dispatch<SetStateAction<boolean[]>>;
};

export const ExercisesVariablesSettings = ({
  variables,
  onVariableChange,
  categoryIndex,
  isOwner,
  hasCategoryChanged,
  setHasCategoryChanged,
  onAddVariable,
  onRemoveVariable,
  isEditting,
  setIsEditting,
}: VariablesProps) => {
  const removeVariable = (index: number) => {
    onRemoveVariable(categoryIndex, index);
  };
  const [containsRest, setContainsRest] = useState(false);
  const [containsDuration, setContainsDuration] = useState(false);

  useEffect(() => {
    if (
      variables.find((variable) => variable.is_rest_before_next_set) !==
      undefined
    )
      setContainsRest(true);
    if (variables.find((variable) => variable.is_duration) !== undefined)
      setContainsDuration(true);
  }, [variables]);

  return (
    <Container fluid id="exercises-variables-settings">
      <Row>
        <Col className="title-col">
          <h4>
            Variáveis{" "}
            <span className="title-subtitle">
              O uso de cada variável é opcional.
            </span>
          </h4>
          {isOwner && isEditting[categoryIndex] ? (
            <button
              className="dashboard-title-btn"
              onClick={() => onAddVariable(categoryIndex, false, false)}
            >
              <MdAdd />
            </button>
          ) : undefined}
        </Col>
      </Row>
      <Row>
        <Col className="add-item-button-col">
          {!containsRest ? (
            <Button
              className="add-item-button"
              onClick={() => onAddVariable(categoryIndex, true, false)}
            >
              Adicionar Descanso
            </Button>
          ) : undefined}
          {!containsDuration ? (
            <Button
              className="add-item-button"
              onClick={() => onAddVariable(categoryIndex, false, true)}
            >
              Adicionar Duração
            </Button>
          ) : undefined}
        </Col>
      </Row>
      <Row className="exercises-variables-settings-container">
        {variables.map((variable: ExerciseVariable, index: number) => {
          return (
            <Row className="exercises-variables-settings-row">
              <Col className="exercises-variables-settings-col">
                <div className="exercises-variables-settings-div first">
                  <InputForm
                    value={variable.title}
                    label="Nome da Variável"
                    className="exercises-variables-settings-input"
                    onChange={(e: any) => {
                      const tempVars: ExerciseVariable[] = JSON.parse(
                        JSON.stringify(variables)
                      );
                      tempVars[index] = {
                        title: e.target.value === "" ? null : e.target.value,
                        is_rest_before_next_set:
                          tempVars[index].is_rest_before_next_set,
                        is_duration: tempVars[index].is_duration,
                      };
                      onVariableChange(categoryIndex, tempVars[index], index);
                    }}
                    disabled={
                      variable.is_rest_before_next_set ||
                      variable.is_duration ||
                      !isOwner ||
                      !isEditting[categoryIndex]
                    }
                  />
                </div>
                <div className="exercises-variables-settings-div second">
                  {isOwner && isEditting[categoryIndex] ? (
                    <Button
                      className="exercises-container-button-close"
                      onClick={() => {
                        removeVariable(index);
                        if (variable.is_duration) setContainsDuration(false);
                        if (variable.is_rest_before_next_set)
                          setContainsRest(false);
                      }}
                    >
                      <MdDeleteForever />
                    </Button>
                  ) : (
                    <div style={{ width: "2.5rem" }}></div>
                  )}
                </div>
              </Col>
            </Row>
          );
        })}
      </Row>
    </Container>
  );
};
