import { useState } from "react";
import { Button, FloatingLabel, Form, Row } from "react-bootstrap";

import "./ForgotPassword.css";
import { forgotPasswordRequest } from "../../../requests/forgotPasswordRequests";
import { useTranslation } from "react-i18next";

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [showSucceeded, setShowSucceeded] = useState<boolean>(false);
  const [showFailed, setShowFailed] = useState<boolean>(false);

  const isEmailValid = (): boolean => {
    if (email === "") {
      setEmailError(t("errors.emptyEmail"));
      return false;
    }

    const regex = new RegExp(
      // Turned \t into \\t to escape it (if something is off, try removing a \)
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \\t]|(\\[\\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\\t -Z^-~]*])"
    );

    if (!regex.test(email)) {
      setEmailError(t("errors.invalidEmail"));
      return false;
    }

    setEmailError(undefined);
    return true;
  };

  const onSubmitButtonClick = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    var isValid = true;
    if (!isEmailValid()) isValid = false;

    // TODO: After handling the next function set email sent to true
    if (isValid) {
      const result = await forgotPasswordRequest({
        email: email.toLowerCase(),
      });
      if (result.succeeded) setShowSucceeded(true);
      else setShowFailed(true);
    }
  };

  return (
    <>
      <p className="modal-title">{t("generic.forgotPassword")}</p>
      {showSucceeded ? (
        <p className="modal-forgot-password-text">
          {t("forgotPasswordComponent.emailSent")}
          <span>{email.toLowerCase()}</span>
        </p>
      ) : (
        <Form onSubmit={onSubmitButtonClick} className="modal-form">
          <FloatingLabel label="Email" className="mb-2">
            <Form.Control
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setShowFailed(false);
              }}
              placeholder={t("generic.email")}
              type="email"
            />
            {emailError !== undefined ? (
              <p className="form-error-message">{emailError}</p>
            ) : undefined}
          </FloatingLabel>
          <Row className="modal-flex-center padding">
            <Button className="modal-submit-button" type="submit">
              {t("generic.submit")}
            </Button>
          </Row>
        </Form>
      )}
      {showFailed && (
        <p className="modal-forgot-password-text red">{t("errors.tryAgain")}</p>
      )}
    </>
  );
};
