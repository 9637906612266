import { Col, Row } from "react-bootstrap";

import { CoursesInfoContainerSettings } from "./CoursesInfoContainerSettings/CoursesInfoContainerSettings";

import "./CoursesSettings.css";
import { CoursesScheduling } from "./CoursesScheduling/CoursesScheduling";

export const CoursesSettings = () => {
  return (
    <div id="courses-settings">
      <Row className="courses-settings-row">
        <Col>
          <CoursesScheduling />
          <CoursesInfoContainerSettings />
        </Col>
      </Row>
    </div>
  );
};
