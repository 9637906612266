import { CRMHistory, CRMNote, CreateCRMNote } from "types/crm";
import { axiosRequest, errorMessage } from "requests/request";

export type CRMRequestResponseType = {
  succeeded: boolean;
  notes: Array<CRMNote> | undefined;
  note: CRMNote | undefined;
  history: CRMHistory | undefined;
  errorMessage: string | undefined;
};

export const fetchCRMUserNotesRequest = async (
  id: string
): Promise<CRMRequestResponseType> => {
  try {
    const response = await axiosRequest().get(`users/${id}/crm_notes`);
    const notes: Array<CRMNote> = response.data.map((note: CRMNote) => note);

    return {
      succeeded: true,
      notes: notes,
    } as CRMRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CRMRequestResponseType;
  }
};

export const createCRMUserNoteRequest = async (
  params: CreateCRMNote & { id: string }
): Promise<CRMRequestResponseType> => {
  try {
    const response = await axiosRequest().post(`users/${params.id}/crm_notes`, {
      note: params,
    });
    const note: CRMNote = response.data;

    return {
      succeeded: true,
      note: note,
    } as CRMRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as CRMRequestResponseType;
  }
};

export const fetchCRMHistoryRequest =
  async (): Promise<CRMRequestResponseType> => {
    try {
      const response = await axiosRequest().get(`crm_notes`);
      const history: CRMHistory = response.data;

      return {
        succeeded: true,
        history: history,
      } as CRMRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as CRMRequestResponseType;
    }
  };
