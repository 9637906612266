import { addYears, format, subDays } from "date-fns";

import "components/DashboardCustomersPage/CustomerPayments/InsuranceCard/InsuranceCard.scss";

type InsuranceCardProps = {
  startDate: Date;
};

export const InsuranceCard = ({ startDate }: InsuranceCardProps) => {
  return (
    <div className="payment-plan-card">
      <p>{`Início: ${format(new Date(startDate), "dd-MM-yyyy")}`}</p>
      <p>{`Fim: ${format(
        subDays(addYears(new Date(startDate), 1), 1),
        "dd-MM-yyyy"
      )}`}</p>
    </div>
  );
};
