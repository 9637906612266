import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchWorkoutsSucceeded,
  fetchWorkoutSucceeded,
  createWorkoutSucceeded,
  duplicateWorkoutSucceeded,
  updateWorkoutSucceeded,
  deleteWorkoutSucceeded,
  CreateOrUpdateWorkoutType,
  setErrorMessage,
} from "../../slices/workoutSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchWorkoutsRequest,
  fetchWorkoutRequest,
  createWorkoutRequest,
  duplicateWorkoutRequest,
  updateWorkoutRequest,
  deleteWorkoutRequest,
  WorkoutRequestResponseType,
} from "../../requests/workoutRequests";

export function* fetchWorkoutsSaga(
  action: any,
  request = fetchWorkoutsRequest
) {
  const response: WorkoutRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchWorkoutsSucceeded(response.workouts!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchWorkoutSaga(
  action: PayloadAction<string>,
  request = fetchWorkoutRequest
) {
  const response: WorkoutRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchWorkoutSucceeded(response.workout!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createWorkoutSaga(
  action: PayloadAction<CreateOrUpdateWorkoutType>,
  request = createWorkoutRequest
) {
  const response: WorkoutRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createWorkoutSucceeded(response.workout!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* duplicateWorkoutSaga(
  action: PayloadAction<string>,
  request = duplicateWorkoutRequest
) {
  const response: WorkoutRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(duplicateWorkoutSucceeded(response.workout!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateWorkoutSaga(
  action: PayloadAction<CreateOrUpdateWorkoutType>,
  request = updateWorkoutRequest
) {
  const response: WorkoutRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateWorkoutSucceeded(response.workout!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deleteWorkoutSaga(
  action: PayloadAction<string>,
  request = deleteWorkoutRequest
) {
  const response: WorkoutRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteWorkoutSucceeded(action.payload));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
