import { takeLatest } from "redux-saga/effects";
import {
  createUserCRMNote,
  fetchCRMHistory,
  fetchUserCRMNotes,
} from "slices/crmSlice";
import {
  createUserCRMNoteSaga,
  fetchCRMHistorySaga,
  fetchUserCRMNotesSaga,
} from "./crmSaga";

function* crmSaga() {
  yield takeLatest(fetchUserCRMNotes, fetchUserCRMNotesSaga);
  yield takeLatest(createUserCRMNote, createUserCRMNoteSaga);
  yield takeLatest(fetchCRMHistory, fetchCRMHistorySaga);
}

export default crmSaga;
