import { Company, User } from "./user";

export type Exercise = {
  id: string;
  title: string;
  title_portuguese: string;
  image_small: string;
  image_medium: string;
  image_large: string;
  description: string | undefined;
  description_portuguese: string | undefined;
  muscules_groups: Array<string> | undefined;
  personal_trainer: User | undefined;
  video_url: string | undefined;
  exercise_category: ExerciseCategory | undefined;
};

export type ExerciseCategory = {
  id?: string | undefined;
  company: Company | undefined;
  personal_trainer: User | undefined;
  title: string;
  variables: ExerciseVariable[];
};

export type ExerciseVariable = {
  id?: string | undefined;
  title: string;
  is_rest_before_next_set: boolean;
  is_duration: boolean;
};

export type WorkoutExercise = {
  id: string | null;
  company: any | null;
  created_at: string | null;
  description: string | null;
  image_large: string | null;
  image_medium: string | null;
  image_small: string | null;
  muscules_groups: string[] | null;
  order: number | null;
  title: string | null;
  type: string | null;
  updated_at: string | null;
  volume: number | null;
  notes: string | null;
  sets: WorkoutExerciseSet[];
  exercise: Exercise;
};

export type WorkoutModeExercise = Omit<WorkoutExercise, "sets"> & {
  isChecked: boolean;
  sets: WorkoutModeExerciseSet[];
};

export type WorkoutExerciseSet = {
  id?: string;
  type?: string;
  variables: Array<WorkoutExerciseSetVariable>;
};

export type WorkoutModeExerciseSet = WorkoutExerciseSet & {
  isChecked: boolean;
};

export type WorkoutExerciseSetVariable = {
  id: string | null;
  variable_id: string;
  variable_title: string;
  value: string | null;
  is_duration?: boolean;
  is_rest_before_next_set?: boolean;
};

export const isExerciseCreatedByCurrentUser = (
  exercise: Exercise | undefined,
  currentUser: User | undefined
) => {
  if (exercise === undefined || currentUser === undefined) return false;
  if (!currentUser.company!.is_gym) return true;

  const currentUserIsGymAndExerciseBelongsToGym =
    localStorage.getItem("isGymView") === "true" &&
    exercise.personal_trainer === null;
  const currentUserIsNotGymAndExerciseBelongsToHim =
    localStorage.getItem("isGymView") === "false" &&
    exercise.personal_trainer?.id === currentUser?.id;

  return (
    currentUserIsGymAndExerciseBelongsToGym ||
    currentUserIsNotGymAndExerciseBelongsToHim
  );
};

export const isExerciseCategoryCreatedByCurrentUser = (
  currentUser: User | undefined,
  category: ExerciseCategory | undefined
) => {
  if (currentUser === undefined || category === undefined) return false;
  if (!currentUser.company!.is_gym) return true;

  const currentUserIsGymAndExerciseBelongsToGym =
    localStorage.getItem("isGymView") === "true" &&
    (category.personal_trainer === undefined ||
      category.personal_trainer === null);
  const currentUserIsNotGymAndExerciseCategoryBelongsToHim =
    localStorage.getItem("isGymView") === "false" &&
    category.personal_trainer?.id === currentUser?.id;
  return (
    currentUserIsGymAndExerciseBelongsToGym ||
    currentUserIsNotGymAndExerciseCategoryBelongsToHim
  );
};
