import { WorkoutExercise, WorkoutModeExercise } from "./exercise";
import { User } from "./user";

export const workoutState = {
  ACTIVE: "Ativo",
  FINISHED: "Concluído",
  EXPIRED: "Expirado",
};

export type Workout = {
  id: string;
  title: string;
  image_small: string;
  image_medium: string;
  image_large: string;
  description: string | undefined;
  duration_in_seconds: number | undefined;
  volume: number | undefined;
  muscules_groups: Array<string> | undefined;
  exercises: WorkoutExercise[];
  user: User | undefined;
  created_at: string;
  personal_trainer: User | null;
  expiration_date: Date | undefined;
  number_of_sessions: number | undefined;
  number_of_completed_sessions: number | undefined;
  should_reset_sessions: boolean | undefined;
  workout_state: "active" | "finished" | "expired" | undefined;
};

export type WorkoutModeType = {
  id: string;
  title: string;
  image_small: string;
  image_medium: string;
  image_large: string;
  description: string | undefined;
  duration_in_seconds: number | undefined;
  volume: number | undefined;
  muscules_groups: Array<string> | undefined;
  exercises: WorkoutModeExercise[];
  user: User | undefined;
  created_at: string;
  personal_trainer: User | null;
};
