import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { BsFillXCircleFill } from "react-icons/bs";

import { CreateWorkoutExercisesType } from "../../../../slices/workoutSlice";
import { SortableItem } from "./SortableItem/SortableItem";

import "./WorkoutReorder.css";
import { GButton } from "components/genericComponents/GButton/GButton";

type WorkoutReorderProps = {
  isVisible: boolean;
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>;
  exercises: CreateWorkoutExercisesType[];
  setExercises: any;
};

export const WorkoutReorder = ({
  isVisible,
  setIsSidebarVisible,
  exercises,
  setExercises,
}: WorkoutReorderProps) => {
  const [items, setItems] = useState(
    exercises.map((exercise) => (exercise.order as number) + 1)
  );

  useEffect(() => {
    setItems(exercises.map((exercise) => (exercise.order as number) + 1));
  }, [exercises]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const onSubmitButtonClick = () => {
    items.forEach((order, index) => (exercises[order - 1].order = index));
    const tempExercises = exercises.sort((a, b) => a.order! - b.order!);
    setExercises(tempExercises);
    setIsSidebarVisible(false);
  };

  return (
    <div
      id="workout-reorder"
      className={isVisible ? "visible-sidebar" : "hidden-sidebar"}
    >
      <Container>
        <Row>
          <Col className="workout-reorder-title-col">
            <h2 className="workout-page-exercises-title">
              Ordernar Exercícios
            </h2>
            <Button
              className="personal-data-edit-button-close"
              onClick={() => setIsSidebarVisible(false)}
            >
              <BsFillXCircleFill />
            </Button>
          </Col>
        </Row>
        <Row className="sortable-items-row">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items.map((id) => (
                <SortableItem
                  key={id}
                  id={id}
                  exercise={exercises.length ? exercises[id - 1] : undefined}
                />
              ))}
            </SortableContext>
          </DndContext>
        </Row>
        <Row>
          <Col>
            <GButton label="Aplicar" onClick={onSubmitButtonClick} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
