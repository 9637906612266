import { takeLatest } from "redux-saga/effects";
import {
  fetchExerciseCategories,
  createExerciseCategory,
  updateExerciseCategory,
  deleteExerciseCategory,
} from "../../slices/exerciseCategorySlice";
import {
  fetchExerciseCategoriesSaga,
  createExerciseCategorySaga,
  updateExerciseCategorySaga,
  deleteExerciseCategorySaga,
} from "./exerciseCategorySaga";

function* exercisesSaga() {
  yield takeLatest(fetchExerciseCategories, fetchExerciseCategoriesSaga);
  yield takeLatest(createExerciseCategory, createExerciseCategorySaga);
  yield takeLatest(updateExerciseCategory, updateExerciseCategorySaga);
  yield takeLatest(deleteExerciseCategory, deleteExerciseCategorySaga);
}

export default exercisesSaga;
