import { DashboardTitle } from "components/DashboardTitle/DashboardTitle";
import { ExportSaft } from "components/BillingPage/ExportSaft/ExportSaft";
import { ExportInvoices } from "components/BillingPage/ExportInvoices/ExportInvoices";
import "components/BillingPage/BillingPage.scss";

export const BillingPage = () => {
  return (
    <div id="billing-page">
      <DashboardTitle title="Faturação" doNotShowBackButton />
      <ExportSaft />
      <ExportInvoices />
    </div>
  );
};
