import {
  Insurance,
  UpdateUserInsurancePayload,
  UserInsurance,
  UserInsurancePayload,
} from "types/insurance";
import { axiosRequest, errorMessage } from "requests/request";

export type InsuranceRequestResponseType = {
  succeeded: boolean;
  insurance: Insurance | undefined;
  userInsurance: UserInsurance | undefined;
  errorMessage: string | undefined;
};

export const fetchInsuranceRequest =
  async (): Promise<InsuranceRequestResponseType> => {
    try {
      const response = await axiosRequest().get("insurance_settings");

      return {
        succeeded: true,
        insurance: response.data,
      } as InsuranceRequestResponseType;
    } catch (e) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as InsuranceRequestResponseType;
    }
  };

export const setInsuranceRequest = async (
  params: number
): Promise<InsuranceRequestResponseType> => {
  try {
    const response = await axiosRequest().post("insurance_settings", {
      insurance_settings: {
        price: params,
      },
    });

    return {
      succeeded: true,
      insurance: response.data,
    } as InsuranceRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as InsuranceRequestResponseType;
  }
};

export const fetchUserInsuranceRequest = async (
  params: string
): Promise<InsuranceRequestResponseType> => {
  try {
    const response = await axiosRequest().get(`users/${params}/insurance`);

    return {
      succeeded: true,
      userInsurance: response.data,
    } as InsuranceRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as InsuranceRequestResponseType;
  }
};

export const setUserInsuranceRequest = async (
  params: UserInsurancePayload
): Promise<InsuranceRequestResponseType> => {
  try {
    const response = await axiosRequest().post(`users/${params.id}/insurance`, {
      insurance: {
        start_date: params.start_date,
      },
    });

    return {
      succeeded: true,
      userInsurance: response.data,
    } as InsuranceRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as InsuranceRequestResponseType;
  }
};

export const updateUserInsuranceRequest = async (
  params: UpdateUserInsurancePayload
): Promise<InsuranceRequestResponseType> => {
  try {
    const response = await axiosRequest().put(
      `users/${params.id}/insurance/${params.insurance_id}`,
      {
        insurance: {
          start_date: params.start_date,
        },
      }
    );

    return {
      succeeded: true,
      userInsurance: response.data,
    } as InsuranceRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as InsuranceRequestResponseType;
  }
};
