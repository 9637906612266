import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ProductTransaction } from "../types/productTransaction";
import {
  productTransactionSuccessfullyCreated,
  productTransactionSuccessfullyUpdated,
  productTransactionSuccessfullyDeleted,
} from "../helpers/messages";

type ProductTransactionState = {
  productTransactions: ProductTransaction[];
  confirmationMessage: string | undefined;
  errorMessage: string | undefined;
  isLoading: boolean;
};

export type CreateOrUpdateProductTransactionType = {
  id?: string;
  products: { id: string; quantity: number }[];
  create_invoice?: boolean
  date: string;
  nif?: string;
  email?: string;
  name?: string,

};

const initialState: ProductTransactionState = {
  productTransactions: [],
  confirmationMessage: undefined,
  errorMessage: undefined,
  isLoading: false,
};

const productTransactionSlice = createSlice({
  name: "productTransaction",
  initialState: initialState,
  reducers: {
    fetchProductTransactions(state: ProductTransactionState) {
      state.isLoading = true;
    },
    fetchProductTransactionsSucceeded(
      state: ProductTransactionState,
      action: PayloadAction<ProductTransaction[]>
    ) {
      state.productTransactions = action.payload;
      state.isLoading = false;
    },
    createProductTransaction(
      state: ProductTransactionState,
      action: PayloadAction<CreateOrUpdateProductTransactionType>
    ) {},
    createProductTransactionSucceeded(
      state: ProductTransactionState,
      action: PayloadAction<ProductTransaction>
    ) {
      return {
        ...state,
        confirmationMessage: productTransactionSuccessfullyCreated,
        products: [action.payload, ...state.productTransactions],
      };
    },
    updateProductTransaction(
      state: ProductTransactionState,
      action: PayloadAction<CreateOrUpdateProductTransactionType>
    ) {},
    updateProductTransactionSucceeded(
      state: ProductTransactionState,
      action: PayloadAction<ProductTransaction>
    ) {
      const index = state.productTransactions.findIndex(
        (productTransaction: ProductTransaction) =>
          productTransaction.id === action.payload.id
      );

      state.productTransactions[index] = action.payload;
      state.confirmationMessage = productTransactionSuccessfullyUpdated;
    },
    deleteProductTransaction(
      state: ProductTransactionState,
      action: PayloadAction<string>
    ) {},
    deleteProductTransactionSucceeded(
      state: ProductTransactionState,
      action: PayloadAction<string>
    ) {
      const index = state.productTransactions.findIndex(
        (productTransaction: ProductTransaction) =>
          productTransaction.id === action.payload
      );

      state.productTransactions.splice(index, 1);
      state.confirmationMessage = productTransactionSuccessfullyDeleted;
    },
    setErrorMessage(
      state: ProductTransactionState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: ProductTransactionState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
  },
});

export const {
  fetchProductTransactions,
  fetchProductTransactionsSucceeded,
  setConfirmationMessage,
  setErrorMessage,
  createProductTransaction,
  createProductTransactionSucceeded,
  updateProductTransaction,
  updateProductTransactionSucceeded,
  deleteProductTransaction,
  deleteProductTransactionSucceeded,
} = productTransactionSlice.actions;

export const selectTransactions = (state: RootState) =>
  state.productTransactionSlice.productTransactions;
export const selectErrorMessage = (state: RootState) =>
  state.productTransactionSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.productTransactionSlice.confirmationMessage;
export const selectIsLoading = (state: RootState) =>
  state.productTransactionSlice.isLoading;

export default productTransactionSlice.reducer;
