import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import "./SortableItem.css";
import { CreateWorkoutExercisesType } from "../../../../../slices/workoutSlice";
import { GChip } from "components/genericComponents/GChip/GChip";

type SortableItemProps = {
  id: number;
  exercise?: CreateWorkoutExercisesType;
};

export function SortableItem({ id, exercise }: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      id="sortable-item"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <h5>{exercise?.exercise.title}</h5>
      <GChip
        label={exercise?.exercise.exercise_category?.title ?? "Sem Categoria"}
        variant="primary"
      />
    </div>
  );
}
