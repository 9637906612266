import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { DayPicker } from "react-day-picker";
import { pt } from "date-fns/locale";
import "react-day-picker/dist/style.css";
import { fetchGymRooms, selectGymRooms } from "../../slices/gymRoomSlice";
import { fetchCourses, selectCourses } from "../../slices/courseSlice";
import {
  fetchPersonalTrainers,
  selectPersonalTrainers,
} from "../../slices/personalTrainerSlice";
import { selectCurrentUser } from "../../slices/currentUserSlice";
import { SelectForm } from "../SelectForm/SelectForm";
import { InputForm } from "../InputForm/InputForm";

import { CourseSchedule } from "../../types/courseSchedule";
import { Course } from "../../types/course";
import { GymRoom } from "../../types/gymRoom";
import { User } from "../../types/user";
import logo from "../../assets/logo.svg";
import {
  setErrorMessage,
  setConfirmationMessage,
  selectErrorMessage,
  selectConfirmationMessage,
  createCourseSchedule,
  updateCourseSchedule,
  fetchCourseSchedule,
  selectCoursesSchedules,
} from "../../slices/courseScheduleSlice";
import { selectUsers } from "../../slices/userSlice";
import {
  getHours,
  getMinutes,
  getYearAndMonthAndDay,
} from "../../helpers/date";

import "./CourseScheduleModal.css";
import { Col } from "react-bootstrap";
import { formatToFirstTwoAndLastName } from "helpers/utils";

type CourseScheduleModalType = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  startAt: Date | undefined;
  endAt: Date | undefined;
  selectedCourseSchedule: CourseSchedule | undefined;
  setSelectedCourseSchedule: (value: CourseSchedule | undefined) => void;
};

export const CourseScheduleModal = (props: CourseScheduleModalType) => {
  const currentUser = useSelector(selectCurrentUser);
  const isGymView = localStorage.getItem("isGymView") === "true";
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const courseSchedules = useSelector(selectCoursesSchedules);
  const selectedCourseSchedule =
    courseSchedules.find((courseSchedule: CourseSchedule) => {
      return courseSchedule.id === props.selectedCourseSchedule?.id;
    }) ?? undefined;

  const [selectedUsers, setSelectedUsers] = useState<User[]>(
    selectedCourseSchedule?.users ?? []
  );
  const [selectedPersonalTrainer, setSelectedPersonalTrainer] =
    useState<User | null>(selectedCourseSchedule?.personal_trainer ?? null);
  const [slotsTotal, setSlotsTotal] = useState<number | null>(
    selectedCourseSchedule?.slots_total ?? null
  );
  const [level, setLevel] = useState<string | null>(
    selectedCourseSchedule?.level ?? null
  );
  const [startAt, setStartAt] = useState<any | null>(null);
  const [endAt, setEndAt] = useState<any | null>(null);
  const [course, setCourse] = useState<Course | null>(
    selectedCourseSchedule?.course ?? null
  );
  const [title, setTitle] = useState<string | null>(
    selectedCourseSchedule?.title ?? null
  );
  const [selectedGymRoom, setSelectedGymRoom] = useState<GymRoom | null>(
    selectedCourseSchedule?.gym_room ?? null
  );
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(
    selectedCourseSchedule?.course ?? null
  );
  const [, setTitleError] = useState<string | undefined>(undefined);
  const [slotsTotalError, setSlotsTotalError] = useState<string | undefined>(
    undefined
  );
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [showDayPicker, setShowDayPicker] = useState(false);
  const [courseScheduleFetched, setCourseScheduleFetched] = useState(false);
  const gymRooms = useSelector(selectGymRooms);
  const courses = useSelector(selectCourses);
  const personal_trainers = useSelector(selectPersonalTrainers);
  const [extraClients] = useState<number>(0);

  const isCourseSchedule =
    (isGymView && selectedCourseSchedule === undefined) ||
    (selectedCourseSchedule?.course !== null &&
      selectedCourseSchedule?.course !== undefined);

  const getDateHours = (date: Date | null): string => {
    if (date === null) return "";

    return getHours(date);
  };

  const getDateMinutes = (date: Date | null): string => {
    if (date === null) return "";

    return getMinutes(date);
  };

  useEffect(() => {
    setSlotsTotal(selectedCourseSchedule?.slots_total ?? null);
    setLevel(selectedCourseSchedule?.level ?? null);

    if (selectedCourseSchedule)
      setStartAt(new Date(selectedCourseSchedule?.start_at));
    else setStartAt(props.startAt ?? null);

    if (selectedCourseSchedule)
      setEndAt(new Date(selectedCourseSchedule?.end_at));
    else setEndAt(props.endAt ?? null);

    setCourse(selectedCourseSchedule?.course ?? null);
    setTitle(selectedCourseSchedule?.title ?? null);
    setSelectedUsers(selectedCourseSchedule?.users ?? []);
    setSelectedGymRoom(selectedCourseSchedule?.gym_room ?? null);
    setSelectedCourse(selectedCourseSchedule?.course ?? null);
    setSelectedPersonalTrainer(
      selectedCourseSchedule?.personal_trainer ?? null
    );
  }, [selectedCourseSchedule, props.endAt, props.startAt]);

  useEffect(() => {
    if (props.startAt !== undefined) setStartAt(props.startAt);
    if (props.endAt !== undefined) {
      props.endAt.setDate(props.startAt!.getDate());
      setEndAt(props.endAt);
    }
  }, [props.startAt, props.endAt]);

  useEffect(() => {
    dispatch(fetchGymRooms());
    dispatch(fetchCourses());
    dispatch(fetchPersonalTrainers());
  }, [dispatch]);

  useEffect(() => {
    if (courseScheduleFetched) return;

    if (selectedCourseSchedule) {
      dispatch(fetchCourseSchedule(selectedCourseSchedule.id));
      setCourseScheduleFetched(true);
    }
  }, [dispatch, selectedCourseSchedule, courseScheduleFetched]);

  const onSubmitButtonClick = (event: any) => {
    var isValid = true;

    if (selectedCourse !== null) {
      if (!isSlotsTotalValid()) isValid = false;
    } else {
      if (!isTitleValid()) isValid = false;
    }

    if (!isValid) return;

    if (selectedCourseSchedule === undefined) {
      dispatch(
        createCourseSchedule({
          slots_total: slotsTotal ?? undefined,
          level: level ?? undefined,
          start_at: startAt!,
          end_at: endAt!,
          course_id: selectedCourse?.id,
          user_ids: selectedUsers.map((user: User) => user.id),
          title: course?.title ?? title!,
          company_room_id: selectedGymRoom?.id ?? undefined,
          other_participants_count: extraClients,
          personal_trainer_id: selectedPersonalTrainer?.id ?? undefined,
        })
      );
    } else {
      dispatch(
        updateCourseSchedule({
          id: selectedCourseSchedule.id,
          slots_total: slotsTotal ?? undefined,
          level: level ?? undefined,
          start_at: startAt!,
          end_at: endAt!,
          course_id: course?.id,
          user_ids: selectedUsers.map((user: User) => user.id),
          title: course?.title ?? title!,
          company_room_id: selectedGymRoom?.id ?? undefined,
          other_participants_count: extraClients,
          personal_trainer_id: selectedPersonalTrainer?.id ?? undefined,
        })
      );
    }
  };

  const onDeleteButtonClick = (event: any) => {};

  const isTitleValid = (): boolean => {
    if (title === null) {
      setTitleError("Por favor insira o título");
      return false;
    }

    setTitleError(undefined);
    return true;
  };

  const isSlotsTotalValid = (): boolean => {
    if (slotsTotal === null) {
      setSlotsTotalError("Por favor insira o número máximo de utilizadores");
      return false;
    }

    setSlotsTotalError(undefined);
    return true;
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "course-schedule-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "course-schedule-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));

      setSlotsTotal(null);
      setLevel(null);
      setStartAt(null);
      setEndAt(null);
      setCourse(null);
      setSelectedUsers([]);
      setSelectedGymRoom(null);
      props.setShowModal(false);
      props.setSelectedCourseSchedule(undefined);
      setCourseScheduleFetched(false);
    }
  }, [dispatch, confirmationMessage, props]);

  return (
    <Modal
      className="modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={() => {
        props.setShowModal(false);
        props.setSelectedCourseSchedule(undefined);
        setCourseScheduleFetched(false);
      }}
    >
      <Modal.Body>
        <Row className="modal-flex-center">
          <img className="logo" src={logo} alt="" />
        </Row>
        <p className="modal-title">
          {selectedCourseSchedule === undefined
            ? "Criar Marcação"
            : "Editar Marcação"}
        </p>
        <Form onSubmit={onSubmitButtonClick} style={{ color: "black" }}>
          {isCourseSchedule ? (
            <>
              <SelectForm
                className="course-schedule-modal-select"
                isClearable={true}
                options={courses.map((course: Course) => {
                  return {
                    id: course.id!,
                    title: course.title!,
                  };
                })}
                value={
                  selectedCourse === undefined || selectedCourse === null
                    ? undefined
                    : {
                        id: selectedCourse.id!,
                        title: selectedCourse.title!,
                      }
                }
                label="Aula"
                onChange={(e: any) => {
                  if (e === null) {
                    setSelectedCourse(null);
                  } else {
                    setSelectedCourse(
                      courses.find((course: Course) => course.id === e.value)!
                    );
                  }
                }}
              />
              <InputForm
                className="course-schedule-modal-select"
                value={slotsTotal ?? null}
                type="number"
                label="Número máximo de utilizadores"
                onChange={(e: any) => setSlotsTotal(e.target.value)}
                errorMessage={slotsTotalError}
              />
            </>
          ) : (
            <FloatingLabel className="mb-3 form-floating-label" label="Title">
              <Form.Control
                type="text"
                value={title ?? undefined}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
                autoFocus
              />
              {slotsTotalError !== undefined ? (
                <p className="form-error-message">{slotsTotalError}</p>
              ) : undefined}
            </FloatingLabel>
          )}
          {currentUser?.company?.is_gym && (
            <SelectForm
              className="course-schedule-modal-select"
              isClearable={true}
              options={gymRooms.map((gymRoom: GymRoom) => {
                return {
                  id: gymRoom.id!,
                  title: gymRoom.title!,
                };
              })}
              value={
                selectedGymRoom === undefined || selectedGymRoom === null
                  ? undefined
                  : {
                      id: selectedGymRoom.id!,
                      title: selectedGymRoom.title!,
                    }
              }
              label="Divisão"
              onChange={(e: any) => {
                if (e === null) {
                  setSelectedGymRoom(null);
                } else {
                  setSelectedGymRoom(
                    gymRooms.find((gymRoom: GymRoom) => gymRoom.id === e.value)!
                  );
                }
              }}
            />
          )}
          {isCourseSchedule && isGymView && (
            <SelectForm
              className="course-schedule-modal-select"
              isClearable={true}
              options={personal_trainers.map((personal_trainer: User) => {
                return {
                  id: personal_trainer.id!,
                  title: formatToFirstTwoAndLastName(personal_trainer.name),
                  image: personal_trainer.image_small,
                };
              })}
              value={
                selectedPersonalTrainer === undefined ||
                selectedPersonalTrainer === null
                  ? undefined
                  : {
                      id: selectedPersonalTrainer.id!,
                      title: formatToFirstTwoAndLastName(
                        selectedPersonalTrainer.name
                      ),
                      image: selectedPersonalTrainer.image_small,
                    }
              }
              hasImage={true}
              label="Personal Trainer"
              onChange={(e: any) => {
                if (e === null) {
                  setSelectedPersonalTrainer(null);
                } else {
                  setSelectedPersonalTrainer(
                    personal_trainers.find(
                      (personal_trainer: User) =>
                        personal_trainer.id === e.value
                    )!
                  );
                }
              }}
            />
          )}
          {!isCourseSchedule && (
            <Form.Group className="mb-3 form-floating-label workout-modal-user-group">
              <Select
                isClearable
                placeholder="Users"
                isMulti
                value={
                  selectedUsers.length === 0
                    ? undefined
                    : selectedUsers.map((user: User) => {
                        return {
                          value: user.id,
                          label: (
                            <div>
                              <img
                                src={user.image_small}
                                alt="something"
                                height="30px"
                                width="30px"
                                className="select-img"
                              />{" "}
                              {formatToFirstTwoAndLastName(user.name)}
                            </div>
                          ),
                        };
                      })
                }
                onChange={(e: any) =>
                  setSelectedUsers(
                    e.map((value: any) => {
                      return users.find(
                        (user: User) => user.id === value.value
                      );
                    })
                  )
                }
                options={users.map((user: User) => {
                  return {
                    value: user.id,
                    label: (
                      <div>
                        <img
                          src={user.image_small}
                          alt="something"
                          height="30px"
                          width="30px"
                          className="select-img"
                        />{" "}
                        {formatToFirstTwoAndLastName(user.name)}
                      </div>
                    ),
                  };
                })}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3 form-floating-label workout-modal-user-group">
            <Button
              style={{
                width: "100%",
                borderColor: "black",
                backgroundColor: "white",
                color: "black",
              }}
              onClick={() => setShowDayPicker(!showDayPicker)}
            >
              {startAt !== undefined && startAt !== null
                ? getYearAndMonthAndDay(startAt)
                : undefined}
            </Button>
            {showDayPicker ? (
              <div className="course-schedule-modal-calendar-div">
                <DayPicker
                  mode="single"
                  selected={startAt}
                  locale={pt}
                  onSelect={(e: Date | undefined) => {
                    if (e === undefined) return;
                    const day = e.getDate();
                    const month = e.getMonth();
                    const year = e.getFullYear();

                    startAt.setDate(day);
                    startAt.setMonth(month);
                    startAt.setFullYear(year);

                    endAt.setDate(day);
                    endAt.setMonth(month);
                    endAt.setFullYear(year);

                    setStartAt(new Date(startAt));
                    setEndAt(new Date(endAt));
                    setShowDayPicker(false);
                  }}
                />
              </div>
            ) : undefined}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ margin: 0, marginRight: 10 }}> Começo </p>
                <input
                  type="time"
                  required
                  min="09:00"
                  max="18:00"
                  className="course-schedule-modal-hours"
                  value={`${getDateHours(startAt)}:${getDateMinutes(startAt)}`}
                  onChange={(e) => {
                    const hoursAndMinutes = e.target.value.split(":");
                    var hours = Number(hoursAndMinutes[0]);
                    var minutes = Number(hoursAndMinutes[1]);

                    if (hours < 5) {
                      hours = 5;
                    } else if (hours > 22) {
                      hours = 22;
                    }

                    if (hours === 22) minutes = 0;

                    startAt.setHours(hours);
                    startAt.setMinutes(minutes);

                    setStartAt(new Date(startAt));
                  }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ margin: 0, marginRight: 10 }}> Fim </p>
                <input
                  type="time"
                  required
                  className="course-schedule-modal-hours"
                  value={`${getDateHours(endAt)}:${getDateMinutes(endAt)}`}
                  onChange={(e) => {
                    const hoursAndMinutes = e.target.value.split(":");
                    var hours = Number(hoursAndMinutes[0]);
                    var minutes = Number(hoursAndMinutes[1]);

                    if (hours < 5) {
                      hours = 5;
                    } else if (hours > 22) {
                      hours = 22;
                    }

                    if (hours === 22) minutes = 0;

                    endAt.setHours(hours);
                    endAt.setMinutes(minutes);
                    setEndAt(new Date(endAt));
                  }}
                />
              </div>
            </div>
            <p style={{ fontSize: 14, textAlign: "center", marginTop: 10 }}>
              (Válido entre as 07:00 e as 22:00)
            </p>
          </Form.Group>
          <div className="signup-users-title-div">Inscritos</div>
          <Row>
            <Col>
              <div className="signup-users">
                {selectedCourseSchedule?.users?.map((user) => {
                  return (
                    <div className="signup-user-div">
                      <img
                        src={user.image_small}
                        alt="something"
                        height="30px"
                        width="30px"
                        className="select-img"
                      />{" "}
                      {formatToFirstTwoAndLastName(user.name)}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row className="modal-flex-center padding">
            <Col xs={12} md={4} className="modal-buttons-col">
              <Button
                className="modal-submit-button-cancel"
                onClick={() => {
                  props.setShowModal(false);
                }}
                style={{ width: "100%" }}
              >
                Cancelar
              </Button>
            </Col>
            {selectedCourseSchedule !== undefined ? (
              <Col xs={12} md={4} className="modal-buttons-col">
                <Button
                  className="modal-delete-button"
                  onClick={onDeleteButtonClick}
                  style={{ width: "100%" }}
                >
                  Eliminar
                </Button>
              </Col>
            ) : undefined}
            <Col xs={12} md={4} className="modal-buttons-col">
              <Button
                className="modal-submit-button"
                onClick={onSubmitButtonClick}
                style={{ width: "100%" }}
              >
                Submeter
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
