import { ReactNode } from "react";

import "components/genericComponents/GCard/GCard.scss";

type GCardProps = {
  children?: ReactNode;
  label?: string;
  value?: number;
  description?: string;
  variant?: "white" | "transparent";
  isSquared?: boolean;
  isValuePrice?: boolean;
};

export const GCard = ({
  children,
  label,
  value,
  description,
  variant = "white",
  isSquared,
  isValuePrice,
}: GCardProps) => {
  return (
    <div className={`g-card ${variant}${isSquared ? " squared" : ""}`}>
      <p className="label">{label}</p>
      {!children && (
        <div>
          <p className="value">
            {value}
            {isValuePrice ? <span className="price-symbol">€</span> : ""}
          </p>
          <p className="description">{description}</p>
        </div>
      )}
      {children}
    </div>
  );
};
