import { User } from "./user";

export type FoodCharacteristicsWithoutWeight = {
  calories: number;
  lipids: number;
  water: number;
  carbs: number;
  protein: number;
};

export type FoodCharacteristics = FoodCharacteristicsWithoutWeight & {
  weight_in_g?: number;
};

export type RecipeOverview = {
  id: string;
  name: string;
  totals: FoodCharacteristics;
};

export type Food = FoodCharacteristics & {
  id: string;
  name: string;
};

export type Recipe = RecipeOverview & {
  foods: Food[];
  created_by: User;
  created_by_gym: Boolean;
};

export const canUserEditRecipe = (
  currentUser: User | undefined,
  recipe: Recipe | undefined
) => {
  if (currentUser === undefined || recipe === undefined) return false;

  const isGymView = localStorage.getItem("isGymView");

  if (
    isGymView === "true" &&
    recipe.created_by_gym &&
    (currentUser?.role === "gym" || currentUser?.role === "admin")
  )
    return true;

  if (
    !isGymView &&
    recipe.created_by.id === currentUser.id &&
    currentUser.role === "personal_trainer"
  )
    return true;

  return false;
};

export type CreateRecipe = {
  name: string;
  foods: {
    id: string;
    weight_in_g: number;
  }[];
};

export type FetchUserNutritionScheduler = {
  user_id: string;
  from: string;
  to: string;
};

export type SchedulerNote = {
  id: string;
  date: string;
  note: string;
  type: string; // TODO: enum
};

export type SchedulerItem = (FoodCharacteristics | {}) & {
  id?: string;
  date: string;
  weight_in_g: number;
  type: string; // TODO: enum
  item: (Food | Recipe) & {
    type: "food" | "recipe";
  };
};

export type UserNutritionScheduler = {
  date: Date;
  notes: SchedulerNote[];
  items: SchedulerItem[];
};

export type CreateUserNutritionSchedulerNote = {
  date: string;
  note: string;
  user_id: string;
};

export type CreateUserNutritionSchedulerNoteResponse = {
  date: Date;
  note: string;
  id: string;
};

export type CreateUserNutritionSchedulerItem = {
  weight_in_g: string;
  date: string;
  user_id: string;
  item_id: string;
  item_type: "recipe" | "food";
};

export type CreateUserNutritionSchedulerItemResponse = {
  weight_in_g: string;
  date: Date;
  id: string;
  item: Food | Recipe;
};

export type SearchFood = FoodCharacteristicsWithoutWeight & {
  id: string;
  type: string;
};

export type SearchRecipe = {
  id: string;
  type: string;
  name: string;
  totals: FoodCharacteristicsWithoutWeight;
};
