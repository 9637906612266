import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import XLSX from "xlsx";

import {
  EvaluationsType,
  fetchEvaluations,
  selectEvaluations,
} from "../../../slices/evaluationsSlice";
import { selectUsers } from "../../../slices/userSlice";
import { User } from "../../../types/user";
import { UserWorkoutHistory } from "../../../types/user_workout_history";

import "./Evolution.css";
import { FilterDropdown } from "../../FilterDropdown/FilterDropdown";
import ReactGA from "react-ga4";
import { PiFileCsvBold } from "react-icons/pi";
import { formatToFirstTwoAndLastName } from "helpers/utils";

type FilterDropdownData = {
  value: string;
  label: string;
};

type ChartsDataI = {
  title: string;
  id: string;
};

const chartsData: ChartsDataI[] = [
  {
    title: "Peso (kg)",
    id: "weight_in_kg",
  },
  {
    title: "% Massa Muscular",
    id: "muscular_mass",
  },
  {
    title: "% Massa Gorda",
    id: "fat_mass_percentage",
  },
  {
    title: "Perímetro do Bícep Esquerdo (cm)",
    id: "perimeter_bicep_left",
  },
  {
    title: "Perímetro do Bícep Direito (cm)",
    id: "perimeter_bicep_right",
  },
  {
    title: "Perímetro do Peito (cm)",
    id: "perimeter_chest",
  },
  {
    title: "Perímetro da Perna Esquerda (cm)",
    id: "perimeter_upper_leg_left",
  },
  {
    title: "Perímetro da Perna Direita (cm)",
    id: "perimeter_upper_leg_right",
  },
  {
    title: "Perímetro do Cintura (cm)",
    id: "perimeter_waist",
  },
  {
    title: "Perímetro da Anca (cm)",
    id: "perimeter_hip",
  },
  {
    title: "Perímetro do Pescoço (cm)",
    id: "perimeter_neck",
  },
  {
    title: "Perímetro dos Abdominais (cm)",
    id: "perimeter_abdominal",
  },
  {
    title: "Relação Cintura/Anca",
    id: "perimeter_abdominal",
  },
];

export const Evolution = () => {
  ReactGA.send("/customer-evolution");

  const dispatch = useDispatch();
  const pathSegments = window.location.href.split("/");
  const userId = pathSegments[pathSegments.length - 2];
  const evaluations = useSelector(selectEvaluations);
  const evaluationsReverse = [...evaluations]
    .reverse()
    .map((evaluation: EvaluationsType) => {
      return {
        ...evaluation,
        created_at: evaluation.created_at!.toString().slice(0, 10),
      };
    });
  const [chartsToShow, setChartsToShow] = useState<FilterDropdownData[]>([]);
  const users = useSelector(selectUsers);
  const user = users.find((user: User) => user.id === userId);
  const userWorkoutsHistory = [...(user?.workouts_history ?? [])]
    .reverse()
    .map((workoutHistory: UserWorkoutHistory) => {
      return {
        ...workoutHistory,
        created_at: workoutHistory.created_at!.toString().slice(0, 10),
      };
    });

  type ChartProps = {
    title: string;
    dataKey: string;
    data: any;
  };

  const onCSVButtonClick = () => {
    const availableKeys = chartsData.map((evalKeys) => evalKeys.id);
    const dataToCsv: any = {};

    availableKeys.forEach((key) => {
      const dataToCsvEntry: any[] = [];
      evaluationsReverse.forEach((evaluation: any) => {
        dataToCsvEntry.push({
          createdAt: evaluation.created_at,
          value: evaluation[key],
        });
      });
      dataToCsv[key] = dataToCsvEntry;
    });

    const workbook = XLSX.utils.book_new();

    for (const key in dataToCsv) {
      const chartsDataEntry = chartsData.find((entry) => entry.id === key);
      const sheetName = chartsDataEntry?.title.substring(0, 31);
      const sheetData = dataToCsv[key].filter(
        (entry: any) => entry.value !== null
      );
      const worksheet = XLSX.utils.json_to_sheet(sheetData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    }

    // Write the workbook to a file
    const outputFilePath = `Evolução - ${formatToFirstTwoAndLastName(
      user?.name
    )}.xlsx`;
    XLSX.writeFile(workbook, outputFilePath);
  };

  const Chart = ({ title, dataKey, data }: ChartProps) => {
    return (
      <div className="chart-col">
        <p className="chart-title"> {title} </p>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="created_at" />
            <YAxis width={5} />
            <Tooltip />
            <Line type="monotone" dataKey={dataKey} stroke="#6842ff" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const Charts = () => {
    return chartsToShow.map((chartData, index) => {
      if (chartData.value === "volume") {
        return (
          <Chart
            key={index}
            title={chartData.label}
            data={userWorkoutsHistory}
            dataKey={chartData.value}
          />
        );
      } else {
        return (
          <Chart
            key={index}
            title={chartData.label}
            data={evaluationsReverse}
            dataKey={chartData.value}
          />
        );
      }
    });
  };

  useEffect(() => {
    dispatch(fetchEvaluations(userId));
  }, [dispatch, userId, user]);

  return (
    <Container fluid>
      <Row className="evolution-row">
        <Col className="evolution-col left">
          <FilterDropdown
            title={"Selecione os gráficos a mostrar"}
            values={chartsData}
            selectedValue={undefined}
            isMulti
            fluid
            onChangeValue={(keys: FilterDropdownData[]) =>
              setChartsToShow(keys)
            }
          />
        </Col>
        <Col className="evolution-col right">
          <Button className="csv-download-button">
            <PiFileCsvBold onClick={() => onCSVButtonClick()} />
          </Button>
        </Col>
      </Row>
      <Row>{Charts()}</Row>
    </Container>
  );
};
